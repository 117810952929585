@import 'styles/_foundation';
@import 'styles/typography/_mixins';

.locations-select-confirmation-modal {
  &__heading {
    @include df-h2;
    line-height: 1.43;
    margin-bottom: $s2;
    padding: $s2-5 $s4;

    @include breakpoint(medium) {
      font-size: 24px;
      margin-bottom: $s2-5;
    }
  }

  &__message {
    @include df-p1-medium;
    padding: 0 $s4;
  }

  &__actions {
    margin-top: $s3;
  }

  &__confirm-button {
    margin-left: $s4;
  }
}
