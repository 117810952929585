@import 'styles/_variables';
@import 'styles/typography/_mixins';

.new-button {
  @include df-cta;

  background: $red;
  color: $white;
  padding: $s1-5 $s3;
  border-radius: $s1;
  border: solid 1px rgba(255, 255, 255, 0.75);
  max-height: 48px;
  white-space: nowrap;

  &:disabled {
    background-color: $light-snow;
    color: $input-gray;
    border: 1px solid $input-gray;
  }

  &:not([disabled]) {
    cursor: pointer;
  }

  .content {
    display: block;
  }

  &.primary {
    color: $white;
    background-color: $red;
    border: none;

    &:disabled {
      background-color: $input-gray;
      color: $white;
    }

    &:not([disabled]) {
      &:hover {
        background-color: $light-red;
      }

      &:active {
        background-color: $light-red;
      }
    }
  }

  &.secondary,
  &.tertiary,
  &.withIcon {
    color: $red;
    background-color: $white;
    border: 1px solid $input-gray;

    &:disabled {
      background-color: $light-snow;
      color: $input-gray;
      border: 1px solid $input-gray;
    }

    &:not([disabled]) {
      &:hover {
        background-color: $snow;
      }

      &:active {
        background-color: $white;
        border: 1px solid $red;
      }
    }
  }

  &.tertiary {
    padding: $s1 $s2;
    border-radius: 14px;
    border: 1px solid $input-gray;
  }

  &.text,
  &.link-1,
  &.link-2 {
    @include df-p1-bold;
    padding: 0;
    background: none;
    border: none;
    color: $red;
    text-decoration: underline;
    letter-spacing: 0.8px;

    &:disabled {
      color: $dark-gray;
      background-color: transparent;
      border: none;
    }

    &:not([disabled]) {
      &:hover {
        background: none;
        color: $light-red;
      }
    }
  }

  &.link-1 {
    @include df-p1-medium;
  }

  &.link-2 {
    @include df-p2-medium;
  }

  &.promo {
    color: $white;
    background-color: rgba(0, 0, 0, 0.2);
    border: solid 1px $white;
    padding: $s1-5 $s4;

    &:disabled {
      color: $input-gray;
      background-color: rgba(0, 0, 0, 0.3);
      border: solid 1px $input-gray;
    }

    &:not([disabled]) {
      &:hover,
      &:active {
        background-color: rgba(0, 0, 0, 0.7);
      }
    }
  }

  &--withIcon {
    display: flex;
    padding: $s1-5 $s4;

    img {
      width: 24px;
      height: 20px;
    }
  }
}
