@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';
@import 'styles/mixins';
@import 'styles/mixins/typography';

$search-magnifier-icon-size: 24px;
$search-close-icon-size: 22px;

.algolia-search {
  $search-field: &;
  position: relative;

  &__input,
  &__button {
    outline: none;
  }

  &__input-wrapper {
    position: relative;
    width: 100%;
    &:before {
      content: '';
      position: absolute;
      top: 10px;
      right: 8px;
      width: $search-magnifier-icon-size;
      height: $search-magnifier-icon-size;
      z-index: 1;
      pointer-events: none;
    }
  }

  &__input {
    -webkit-appearance: none;
    width: 100%;
    overflow: hidden;
    color: $black;
    border: 2px solid #605c5a;
    border-radius: 12px;
    background-color: $white;
    padding: 2px $s2;
    transition: width 200ms;

    &::placeholder {
      color: $black;
    }

    @include breakpoint(medium) {
      padding: $s1 $s8 $s1 $s2;
    }
  }

  &__input-label {
    @include srOnly();
  }

  &__button {
    position: absolute;
    right: 16px;
    top: 8px;
    width: 24px;
    height: 24px;
    font-size: 0;
  }

  &--close {
    display: none;
    width: $search-close-icon-size;
    height: $search-close-icon-size;
    svg {
      width: $search-close-icon-size;
      height: $search-close-icon-size;
      fill: #7f7f7f;
    }
  }

  .search-field-toggle.button-reset {
    display: none;
  }

  .search-field-suggestions {
    position: absolute;
    top: 41px;
    margin-top: 5px;
    width: 100%;
    border-radius: 4px;
    background-color: $white;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
    overflow: hidden;
    color: $black;
    overflow-y: auto;
    max-height: 48px * 5; // 5 lines
    z-index: $page-header-z-index;

    @include breakpoint(medium) {
      max-height: 48px * 10; // 10 lines
    }

    &__item {
      cursor: pointer;

      a,
      .custom-item {
        display: block;
        padding: $s1 $s4;
        text-decoration: none;

        &:hover {
          background: $light-coral;
        }
        .highlight-text {
          color: $red;
          font-weight: bold;
        }
        .emphasize-text {
          font-weight: bold;
          font-size: 14px;
        }
      }
      .custom-item {
        cursor: default;
        font-size: 14px;
        & > a {
          display: inline;
          padding: 0;
        }
      }

      .quick-link {
        text-decoration: underline;
      }
    }

    &__login-link {
      @include df-placeholder;
      color: $red;
      text-decoration: underline;
      padding: 0;
      margin-top: 6px;
    }
  }

  &__search-button {
    height: 100%;
    background-color: transparent;
    border: none;
    position: absolute;
    padding: 0 11px;
    right: 0;
    cursor: pointer;
  }

  &--expandable {
    #{$search-field}__input {
      position: relative;
      padding: 6px $s2 $s0-5;
      border-radius: 50px;
    }

    &#{$search-field}--search-input-visible {
      position: relative;
      width: 100%;
      z-index: $sticky-view-z-index;
      top: 0;
      left: 0;
      background-color: white;
      display: flex;
      align-items: center;

      @include breakpoint(medium) {
        position: absolute;
        min-height: 45px;
        height: 100%;

        #{$search-field}__input-wrapper {
          flex: 1 0;
          margin-right: $s1-5;
        }
      }

      #{$search-field}--close.button-reset {
        display: none;

        @include breakpoint(medium) {
          display: block;
        }
      }

      &:not(#{$search-field}--search-input-empty) {
        #{$search-field}__input {
          padding-left: $s2;
        }
        .search-field-suggestions {
          position: absolute;
          top: 45px;
          width: 100vw;
          height: 100vh;
          left: 0;
          max-height: none;
          margin: 0 -1rem;
          @include breakpoint(medium) {
            position: absolute;
            width: calc(100% - 35px);
            max-height: 48px * 10;
            height: auto;
            margin: 0;
          }

          &__list {
            padding: $s1-5 0;
          }

          &__item {
            a {
              .link-text-item {
                font-size: 14px;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
                width: 100%;
                @include breakpoint(medium) {
                  -webkit-line-clamp: 1;
                  line-clamp: 1;
                }
              }
            }
            &.divider {
              padding: 0 $s4;
              &:hover {
                cursor: default;
              }
              hr {
                height: 1px;
                background-color: $input-gray;
                border: none;
              }
            }
          }
        }
      }
    }
  }
}

body:not(.international-font) .algolia-search {
  $search-field: &;
  &__input {
    @include font-frutiger-regular($size: 16, $lineheight: 1.71);
  }

  &__input::placeholder {
    @include font-frutiger-regular($size: 13, $lineheight: 1.71);
  }

  &__item {
    a,
    .custom-item {
      @include df-p1-medium;
    }
  }

  &--expandable {
    #{$search-field}__input {
      @include font-frutiger-regular($size: 16, $lineheight: 24);
    }
  }
}

body.international-font .algolia-search {
  $search-field: &;

  &__input {
    @include font-neue-frutiger-regular($size: 16, $lineheight: 1.71);
  }

  &__input::placeholder {
    @include font-neue-frutiger-regular($size: 13, $lineheight: 1.71);
  }

  &__item {
    a,
    .custom-item {
      @include neue-df-p1-medium;
    }
  }

  &--expandable {
    #{$search-field}__input {
      @include font-neue-frutiger-regular($size: 16, $lineheight: 24);
    }
  }
}
