@import 'styles/_variables';
@import 'styles/_foundation';
@import 'styles/_cor-rich-text';

.cor-directions {
  padding: 48px 1rem;

  @include breakpoint($cor-desktop) {
    padding: 69px $s8;
  }

  &__title {
    color: $aubergine-gray;
  }

  &__rich-text {
    ul,
    ol {
      margin: 0 0 47px;
      @include breakpoint($cor-desktop) {
        margin-bottom: 72px;
      }
    }

    h3,
    h4,
    h5,
    h6 {
      color: $aubergine-gray;
      text-transform: uppercase !important;
      margin-bottom: 15px;
    }
  }
}

body:not(.international-font) .cor-directions {
  &__title {
    @include cor-rich-text();
  }

  &__rich-text {
    @include cor-rich-text();
    @include cor-rich-text(
      $map: (
        'p': (
          'size': (
            14px,
            14px,
          ),
          'lineheight': (
            24px,
            24px,
          ),
          'spacing': (
            0.01rem,
            0.01rem,
          ),
        ),
        'li': (
          'size': (
            14px,
            14px,
          ),
          'lineheight': (
            24px,
            24px,
          ),
          'spacing': (
            0.01rem,
            0.01rem,
          ),
        ),
        'ol': (
          'size': (
            14px,
            14px,
          ),
          'lineheight': (
            24px,
            24px,
          ),
          'spacing': (
            0.01rem,
            0.01rem,
          ),
        ),
      )
    );

    h3,
    h4,
    h5,
    h6 {
      @include font-frutiger-bold($size: 12);
    }
  }
}

body.international-font .cor-directions {
  &__title {
    @include cor-rich-text($map: $neue-base);
  }

  &__rich-text {
    @include cor-rich-text($map: $neue-base);
    @include cor-rich-text(
      $map: (
        'p': (
          'face': neueFrutiger,
          'size': (
            14px,
            14px,
          ),
          'lineheight': (
            24px,
            24px,
          ),
          'spacing': (
            0.01rem,
            0.01rem,
          ),
        ),
        'li': (
          'face': neueFrutiger,
          'size': (
            14px,
            14px,
          ),
          'lineheight': (
            24px,
            24px,
          ),
          'spacing': (
            0.01rem,
            0.01rem,
          ),
        ),
        'ol': (
          'face': neueFrutiger,
          'size': (
            14px,
            14px,
          ),
          'lineheight': (
            24px,
            24px,
          ),
          'spacing': (
            0.01rem,
            0.01rem,
          ),
        ),
      )
    );

    h3,
    h4,
    h5,
    h6 {
      @include font-neue-frutiger-bold($size: 12);
    }
  }
}
