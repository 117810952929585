@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

.shortpay-reasons-modal {
  overflow: visible;

  &__content {
    padding: 0 $s2-5;

    @include breakpoint(medium) {
      padding: 0 $s4;
    }
  }

  &__description {
    @include df-p3-medium;
    margin: $s2-5 0;

    @include breakpoint(medium) {
      @include df-p1-medium;
      margin: $s1 0 $s2-5 0;
    }
  }

  &__reasons-dropdown {
    margin-bottom: $s6;

    @include breakpoint(medium) {
      margin-bottom: $s4;
    }

    .select {
      &__menu-list {
        z-index: 61;
        max-height: 207px;

        .select__option {
          @include df-p1-medium;
        }
      }

      &__single-value {
        @include df-p1-medium;
        font-weight: bold;
        padding: 10px 0;
      }

      &__value-container {
        padding-left: 0;
      }
    }
  }
}
