@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/mixins';

$modal-vertical-margin: $s8;
$close-button-size: 20px;

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: $modal-z-index;
  color: $black;

  & .modal {
    background-color: transparent;
  }

  .grid-container {
    @include breakpoint(small down) {
      padding: 0 $s1;
    }
  }

  &__container {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &__content {
    position: relative;
    background: $white;
    margin-top: $s1;
    margin-bottom: $s1;
    max-height: calc(100vh - 16px);
    border-radius: 4px;
    overflow-y: auto;

    @include breakpoint(medium) {
      margin-top: $modal-vertical-margin;
      margin-bottom: $modal-vertical-margin;
      max-height: calc(100vh - 128px); // $modal-vertical-margin * 2
    }
  }

  &__close-button {
    @include cross-close-button(20px, $close-button-size);

    position: absolute;
    right: $s2;
    top: $s2-5;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;

    @include breakpoint(medium) {
      top: $s4;
      right: $s4;
    }

    &:before,
    &:after {
      position: absolute;
      top: 0;
      left: $close-button-size / 2 - 1px;
      content: '';
      height: $close-button-size;
      width: 2px;
      background-color: $dark-gray;
    }

    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }

  &-wrapper-enter {
    opacity: 0;
  }

  &-wrapper-enter-active {
    opacity: 1;
    transition: all 400ms ease-in;
  }

  &-wrapper-exit {
    opacity: 1;
  }

  &-wrapper-exit-active {
    opacity: 0;
    transition: all 400ms ease-out;
  }
}
