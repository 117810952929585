@import 'styles/_variables';

.cor-footer__legal {
  font-size: 12px;

  .legal__text {
    color: $mid-gray;
  }

  .legal__links > .links__link {
    color: $aubergine-gray;
  }
}

body.international-font .cor-footer__legal {
  font-weight: 600;
}
