@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

.schedule-payment-modal {
  overflow: visible;

  &__content-wrapper {
    padding: 0 $s2 $s4-5 $s2;

    @include breakpoint(medium) {
      padding: 0 $s4;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin-bottom: $s2-5;

    @include breakpoint(medium) {
      flex-direction: row;
      gap: 12px;
    }
  }

  &__description {
    @include df-p3-medium;
    margin-bottom: $s2-5;

    @include breakpoint(medium) {
      @include df-p1-medium;
      margin-bottom: $s2;
    }
  }

  &__calendar-container {
    margin: auto;

    @include breakpoint(medium) {
      width: 355px;
      height: fit-content;
      margin-bottom: 52px;
    }
  }

  &__link {
    @include df-p1-medium;
    color: $red;
  }

  .modal {
    &-header {
      margin-bottom: $s2-5;
      padding-left: $s4;

      @include breakpoint(medium) {
        margin-bottom: 10px;
      }
    }

    &-footer {
      justify-content: center;

      @include breakpoint(medium) {
        justify-content: flex-end;
      }

      &__cancel-button.cor-button.primary {
        @include df-p1-medium;
        line-height: 19px;
        letter-spacing: 0.2px;
        color: $red;
        text-decoration: underline;
        margin-right: $s3;
        cursor: pointer;

        .content {
          text-decoration: underline;
        }
      }

      @include breakpoint(small down) {
        .cor-button.primary {
          width: 151px;
        }
      }
    }
  }

  &__date-input {
    @include df-p2-medium;
    border: 1px solid $input-gray;
    border-radius: 8px;
    color: $black;

    @include breakpoint(medium) {
      height: 48px;
    }
  }

  &__month {
    margin-bottom: $s2-5;
    text-transform: capitalize;

    @include breakpoint(medium) {
      width: 40%;
      margin-bottom: 0;
    }
  }

  &__day-and-year {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @include breakpoint(medium) {
      width: 58%;
    }
  }

  &__day {
    width: 40%;
  }

  &__year {
    width: 55%;
  }

  .select__control {
    height: 48px;
  }

  &__date {
    &-day {
      @include df-p2-medium;
      color: $black;

      &:hover {
        width: 24px;
        height: 24px;
      }

      &[data-weekend] {
        color: $black;
      }

      &[data-selected],
      &[data-selected]:hover {
        width: 24px;
        height: 24px;
        color: $white;
        margin: 0 auto;
      }

      &[data-disabled] {
        font-size: 11px;
      }
    }

    &-calendar-header-level {
      font: 18px/1.33 $museo-700;
      color: $brown;

      &-arrows {
        color: $dark-gray;
      }
    }

    &-weekday {
      @include df-p2-bold;
      color: $brown;
    }

    &-month {
      width: 100%;
    }

    &-month {
      text-align: center;
    }

    &-calendar-header {
      @include breakpoint(small down) {
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
      }

      @include breakpoint(medium) {
        max-width: 100%;
      }
    }

    &-input {
      text-transform: capitalize;
    }

    &-select-label {
      @include df-p4-bold;
      margin-bottom: $s1;
      letter-spacing: 1.2px;
      color: $dark-gray;
    }

    @at-root .mantine-Modal-content {
      border-radius: 10px;

      @include breakpoint(small down) {
        width: 100%;
      }
    }

    @at-root .mantine-Modal-inner {
      @include breakpoint(small down) {
        top: 22%;
      }
    }
  }

  @at-root .mantine-DatePickerInput-calendar {
    @include breakpoint(medium) {
      height: fit-content;
      width: 363px;
    }
  }

  @at-root .mantine-Calendar-calendar .mantine-DatePickerInput-calendar > * {
    width: 100%;
  }

  @at-root .mantine-MonthLevel-monthLevel {
    width: 100%;

    .modal-footer__cancel-button .content {
      text-decoration: underline;
    }
  }
}
