@import 'styles/_variables';
@import '/src/styles/mixins/typography';
@import 'styles/_foundation';

@mixin buttonReset() {
  appearance: none;
  outline: none;
  border: 0;
  background: none;
  cursor: pointer;
}

.subnav-group {
  position: absolute;
  width: 100%;
  transition: all 200ms;
  opacity: 0;
  transform: translateX(100%);
  z-index: 0;
  padding-bottom: 10px;
  min-width: auto;

  @include breakpoint(medium) {
    min-width: 300px;
  }

  .columns-2 {
    @include breakpoint(medium) {
      column-count: 2;
    }
  }
  .columns-3 {
    @include breakpoint(medium) {
      column-count: 3;
    }
  }

  &.is-active {
    opacity: 1;
    transform: translateX(0%);
    z-index: 2;
  }

  &.is-previous {
    z-index: 1;
    opacity: 0;
    transform: translateX(-100%);
  }

  ul {
    padding-left: 0;

    @include breakpoint(medium) {
      padding-left: 16px;
    }
  }

  &__link {
    color: $mid-gray;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0 15px 0;
    text-align: left;

    @include breakpoint(medium) {
      justify-content: flex-start;
    }

    &.is-root {
      padding: 15px 20px 15px 15px;
    }

    button {
      @include buttonReset();
      color: $aubergine-gray;
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    &--new-arrivals {
      button {
        color: $orange;
      }
    }

    &__link {
      @include font-museo-regular($size: 14, $lineheight: 1);
      color: $mid-gray;
      text-decoration: none;
      cursor: pointer;
      width: 100%;
      padding: 0;
      text-align: left;

      &:hover {
        text-decoration: underline;
        @include breakpoint(medium) {
          text-decoration: none;
        }
      }

      @include breakpoint(medium) {
        width: auto;
      }
    }

    &__next {
      padding: 0 10px;
    }
  }

  &__heading {
    @include font-frutiger-regular($size: 12, $lineheight: 16px);
    color: $mid-gray;
    display: block;
    margin-left: 0;
    margin-bottom: 10px;
    text-align: left;

    @include breakpoint(medium) {
      margin-left: 16px;
    }
  }

  &__back-button {
    @include font-museo-light($size: 12, $lineheight: 1);
    @include buttonReset();
    color: $mid-gray;
    display: flex;
    align-items: center;
    padding: 10px 0;

    @include breakpoint(medium) {
      padding: 0;
      margin-bottom: 10px;
    }

    .back-button-label {
      display: inline-block;
      margin-left: 8px;
      white-space: nowrap;
    }
  }
}
