@import 'corporate-ui/cor-typography/cor-typography';
@import 'styles/_variables';

$iconSize: 24px;

.cor-select {
  $selectPrefix: '.cor-select';

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__label {
    color: $aubergine-gray;
    min-height: 13.53px;
    margin-bottom: 9px;
  }

  #{$selectPrefix}__control {
    min-height: 40px;
    border-radius: 8px;
    border-color: $cor-light-gray;
    background-color: $white;
    font-size: 14px;
    line-height: 1.71;
    padding-left: 20px;
    box-shadow: none;

    &:hover,
    &--is-focused {
      border-color: $cor-light-gray;

      #{$selectPrefix}__indicator-separator {
        background-color: $white;
      }
    }

    #{$selectPrefix} {
      &__indicator-separator {
        background-color: $white;
        margin: 0;
      }

      &__icon {
        width: $iconSize;
        height: $iconSize;
        margin-left: -2px;
        margin-right: 2px;
        img {
          width: 100%;
          height: auto;
        }
      }

      &__dropdown-icon {
        box-sizing: border-box;
        position: relative;
        display: block;
        transform: scale(var(--ggs, 1));
        width: 22px;
        height: 22px;
        border: 2px solid transparent;
        border-radius: 100px;
        transition: all 0.3s ease;

        &::after {
          content: '';
          display: block;
          box-sizing: border-box;
          position: absolute;
          width: 10px;
          height: 10px;
          border-bottom: 2px solid;
          border-right: 2px solid;
          border-color: $cor-dawn-red;
          transform: rotate(45deg);
          left: 4px;
          top: 2px;
        }
      }

      &__indicators {
        width: 40px;
      }

      &__indicator {
        color: $cor-dawn-red;
        width: 100%;
        justify-content: center;
        flex: 1;
      }

      &__value-container {
        padding: 7.035px 10px 7.035px 0;
      }

      &__placeholder,
      &__single-value {
        @extend .cor-typography !optional;
        @extend .cor-typography__h4 !optional;
      }

      &__single-value {
        color: $aubergine-gray;
      }
    }

    &--is-disabled {
      border-color: $light-gray;
      background: $snow;

      #{$selectPrefix}__indicator-separator {
        background: $light-gray;
      }

      #{$selectPrefix}__dropdown-icon {
        border-top-color: $dark-gray;
      }
    }

    &--menu-is-open {
      #{$selectPrefix}__dropdown-indicator {
        transform: rotate(180deg);
      }
    }
  }

  #{$selectPrefix}__menu {
    border-radius: 4px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);

    #{$selectPrefix} {
      &__option {
        font-size: 16px;
        line-height: 1.43;
        padding: $s1-5;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        color: $black;

        &--is-focused {
          background: $cor-bg-gray;

          &:not(:hover) {
            background: $cor-bg-gray;
          }
        }

        &--is-selected {
          background: transparent;
          color: $cor-dawn-red;
        }
      }
    }
  }

  #{$selectPrefix}__menu-list {
    padding: 0;
  }
}
