@import 'styles/_variables';
@import 'styles/_foundation';
@import 'styles/typography/_mixins';

.product-new-arrival-badge {
  @include df-h4-bold;
  color: $red;
  margin-right: $s0-5;

  @include breakpoint(medium) {
    @include df-h5-bold;
  }
}
