@import 'styles/_variables';
@import 'styles/mixins';

.my-account-menu {
  .dropdown-menu {
    width: 100%;
  }
}

.dropdown-button {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  min-height: 40px;
  align-items: center;
  border-radius: 8px;
  background: $white;
  border: 1px solid $input-gray;
  padding: 0;
  cursor: default;
  overflow: hidden;
  color: $black;

  &__icon {
    margin-left: $s1;
  }

  &__label {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: $s0-5 $s1 0;
    text-align: left;
    font-family: $frutiger-55-roman;
    font-size: 14px;
    line-height: 1.71;
  }

  &__indicator {
    position: relative;
    width: 40px;
    height: 38px;
    border-left: 1px solid $input-gray;
  }

  &__dropdown-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &:after {
      @include css-triangle('down', 6px, 12px, $red);
      display: block;
      content: '';
    }
  }
}
