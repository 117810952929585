@import 'styles/_variables';
@import 'styles/_foundation';
@import 'styles/typography/_mixins';

$checkmarkSize: 18px;

.custom-checkbox {
  $custom-checkbox: &;
  position: relative;
  display: flex;
  align-items: center;
  color: $black;

  &__label {
    @include df-p1-bold;
    color: $red;
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    line-height: 1.5;
  }

  &__checkmark {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: $checkmarkSize;
    height: $checkmarkSize;
    margin-right: 9px;
    border-radius: 4px;
    border: 1px solid $aubergine-gray;
    flex: 1 0 auto;
  }

  &__input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;

    &:hover {
      & ~ #{$custom-checkbox}__checkmark {
        border: 2px solid $aubergine-gray;
      }
    }

    & ~ #{$custom-checkbox}__checkmark {
      &:after {
        display: inline-block;
        visibility: hidden;
        background: url('../../assets/images/icons/icon-check.svg') center no-repeat;
        content: '';
        height: $checkmarkSize;
        width: 14px;
      }
    }

    &:checked {
      & ~ #{$custom-checkbox}__checkmark {
        background-color: $aubergine-gray;

        &:after {
          visibility: visible;
        }
      }

      &:hover {
        & ~ #{$custom-checkbox}__checkmark {
          background: $aubergine-gray;
        }
      }
    }
  }

  &.disabled {
    #{$custom-checkbox}__label {
      color: $dark-gray;
    }
  }

  .custom-checkbox__input[disabled] ~ .custom-checkbox__checkmark {
    background-color: $gray;
    border: none;
    outline: none;
    &::after {
      display: none;
    }
  }

  .custom-checkbox__input[disabled]:hover ~ .custom-checkbox__checkmark {
    border: 1px solid $input-gray;
    cursor: not-allowed;
  }
}
