@import '../functions/font-units';

$fallback-fonts: (
  'frutiger': sans-serif,
  'museo': serif,
  'neueFrutiger': sans-serif,
);

@mixin font-sans-base($size, $lineheight, $spacing, $important, $family) {
  $important: if($important, ' !important', '');
  font-family: $family, map-get($fallback-fonts, $family) #{$important};
  font-size: if(type_of($size) == string, $size, rem($size)) #{$important};
  letter-spacing: $spacing#{$important};
  // lineheight can cause issues so sometimes we just don't want it at all.
  // pass null to omit it
  @if ($lineheight != null) {
    line-height: $lineheight#{$important};
  }
}

// Important must be explicitly overridden

@mixin font-style($family: museo, $size: 16, $lineheight: 1.5, $spacing: 0.45px, $important: false) {
  @include font-sans-base($size, $lineheight, $spacing, $important, $family);
}

@mixin font-museo-light($size: 16, $lineheight: 1.5, $spacing: 0.45px, $important: false) {
  font-weight: 300;
  @include font-sans-base($size, $lineheight, $spacing, $important, $family: museo);
}

@mixin font-museo-regular($size: 16, $lineheight: 1.5, $spacing: 0.45px, $important: false) {
  font-weight: 500;
  @include font-sans-base($size, $lineheight, $spacing, $important, $family: museo);
}

@mixin font-museo-bold($size: 16, $lineheight: 1.5, $spacing: 0.45px, $important: false) {
  font-weight: 700;
  @include font-sans-base($size, $lineheight, $spacing, $important, $family: museo);
}

@mixin font-frutiger-regular($size: 16, $lineheight: 1.5, $spacing: 0.45px, $important: false) {
  font-weight: 500;
  @include font-sans-base($size, $lineheight, $spacing, $important, $family: frutiger);
}

@mixin font-frutiger-italic($size: 16, $lineheight: 1.5, $spacing: 0.45px, $important: false) {
  font-weight: 500;
  font-style: italic;
  @include font-sans-base($size, $lineheight, $spacing, $important, $family: frutiger);
}

@mixin font-frutiger-bold($size: 16, $lineheight: 1.5, $spacing: 0.45px, $important: false) {
  font-weight: 700;
  @include font-sans-base($size, $lineheight, $spacing, $important, $family: frutiger);
}

@mixin font-neue-frutiger-light($size: 16, $lineheight: 1.5, $spacing: 0.45px, $important: false) {
  font-weight: 300;
  @include font-sans-base($size, $lineheight, $spacing, $important, $family: neueFrutiger);
}

@mixin font-neue-frutiger-regular($size: 16, $lineheight: 1.5, $spacing: 0.45px, $important: false) {
  font-weight: 500;
  @include font-sans-base($size, $lineheight, $spacing, $important, $family: neueFrutiger);
}

@mixin font-neue-frutiger-italic($size: 16, $lineheight: 1.5, $spacing: 0.45px, $important: false) {
  font-weight: 400;
  font-style: italic;
  @include font-sans-base($size, $lineheight, $spacing, $important, $family: neueFrutiger);
}

@mixin font-neue-frutiger-bold($size: 16, $lineheight: 1.5, $spacing: 0.45px, $important: false) {
  font-weight: 700;
  @include font-sans-base($size, $lineheight, $spacing, $important, $family: neueFrutiger);
}
