@import 'styles/_variables';
@import 'styles/_foundation';

.cor-button-link {
  width: 100%;
  max-width: 270px;
  display: flex;
  justify-content: center;
  text-decoration: none;

  &__icon {
    display: inline-flex;
    align-self: center;
    & svg {
      width: $s1-5;
      height: $s1-5;
    }
    &:not(.no-fill) svg {
      fill: currentColor;
    }
    &:not(.no-stroke) svg {
      stroke: currentColor;
    }
    &.baseline svg {
      top: -0.075em;
      position: relative;
    }
  }

  &:hover &__icon {
    &:not(.is-stroke) svg {
      fill: currentColor;
    }
    &:not(.is-fill) svg {
      stroke: currentColor;
    }
  }

  &--is-international {
    max-height: fit-content;
    word-wrap: break-word;
    max-width: 295px;
    width: auto;

    .content {
      display: inline-block;
      white-space: normal;
      width: 100%;
    }
  }
}
