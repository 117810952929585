@import 'styles/_foundation';
@import 'styles/_variables';

.cor-featured-content__card-with-title {
  & {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    overflow: hidden;
    height: 100%;
    max-width: 271px;
    min-width: 50px;

    @include breakpoint($cor-desktop) {
      max-width: initial;
    }
  }

  &__image__container {
    height: 270px;
    overflow: hidden;

    @include breakpoint($cor-desktop) {
      height: 406px;
    }
  }

  &__image {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  &__description {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: $cor-white;
    padding: 22px 30px;

    @include breakpoint($cor-desktop) {
      padding: 20px 30px;
    }
  }
}
