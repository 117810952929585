@import 'styles/_foundation';
@import 'styles/_variables';

.cor-featured-content__cards-carousel-full-width {
  & {
    width: calc(100% + 2rem);
    padding-right: 30px !important;
    padding-left: 30px !important;
    margin-left: -1rem !important;
    overflow: initial !important;
  }

  .swiper-button-next,
  .swiper-button-prev {
    width: initial;
    height: initial;
    top: 110px;
  }

  .swiper-button-next {
    right: 0;
  }

  .swiper-button-prev {
    left: 0;
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    width: 50px;
    min-width: 50px;
    height: 50px;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $cor-white;
    border-radius: 50%;
    font-size: 15px;
    font-weight: bolder;
    color: $cor-dawn-red;
  }

  &
    .swiper-slide:not(.swiper-slide-active, .swiper-slide-active + *, .swiper-slide-active + * + *, .swiper-slide-active
      + *
      + *
      + *, .swiper-slide-active + * + * + * + *) {
    .cor-featured-content__card__image__container {
      background-color: $aubergine-gray;
    }

    .cor-featured-content__card__image {
      opacity: 0.7;
    }
  }

  .swiper-slide {
    height: auto;
    width: initial;
  }
}
