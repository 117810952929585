@import 'styles/_variables';
@import 'styles/typography/_mixins';
@import 'styles/_foundation';

.add-items-to-shopping-list-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: $black;
  padding: 0;

  .modal-header {
    &__title {
      padding-right: 22px;
    }
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;
  }

  &.modal__content {
    min-height: 300px;

    @include breakpoint(medium) {
      min-height: 500px;
    }
  }

  &__subheading {
    display: flex;
    justify-content: space-between;
    padding: 14px 0 $s2 0;

    @include breakpoint(medium) {
      padding: 18px 0;
    }
  }

  &__chosen-lists-counter {
    @include df-p1-medium;
    display: none;

    @include breakpoint(medium) {
      display: block;
    }
  }

  .modal__close-button {
    top: $s2;

    &:before,
    &:after {
      height: 28px;
    }

    @include breakpoint(medium) {
      top: 25px;
    }
  }

  &__footer {
    .add-items-to-shopping-list-modal__chosen-lists-counter {
      padding: $s1-5 $s4;
      display: block;

      @include breakpoint(medium) {
        display: none;
      }
    }

    &-buttons {
      display: flex;
      justify-content: space-between;
    }
  }

  &__shopping-lists-info {
    overflow-y: auto;
    padding: 0 $s2;
    max-height: 378px;

    @include breakpoint(medium) {
      padding: 0 $s4;
    }
  }

  &__header {
    padding: $s2-5 $s2 0;

    @include breakpoint(medium) {
      padding: $s4 $s4 0;
    }
  }

  .add-items-to-shopping-list-modal-list-item {
    margin-bottom: $s1-5;
  }

  &__add-items {
    padding: $s1-5;
    margin-left: $s1;

    @include breakpoint(small only) {
      &.cor-button {
        padding: $s1-5 14px;
      }
    }
  }

  &__cross {
    background: $red;
    height: 2px;
    position: relative;
    width: 10px;
    margin-left: $s1;
    margin-top: -2px;

    &:after {
      background: $red;
      content: '';
      height: 10px;
      left: 4px;
      position: absolute;
      top: -4px;
      width: 2px;
    }
  }

  &__create-new-list {
    display: flex;
    align-items: center;
    margin-right: 15px;

    &:hover {
      .add-items-to-shopping-list-modal__cross {
        background: $light-red;
        &:after {
          background: $light-red;
        }
      }
    }
  }

  &__no-capacity-warning {
    margin-top: $s1-5;

    @include breakpoint(medium) {
      margin-top: $s3;
    }
  }

  .general-warning {
    &__title {
      @include df-p1-bold;
    }
  }

  .modal-footer {
    padding: $s1-5;

    @include breakpoint(medium) {
      padding: $s3;
    }
  }
}
