@import 'styles/_variables';
@import 'styles/_foundation';
@import 'styles/_cor-rich-text';

.cor-accordion-item {
  border-bottom: 1px solid $cor-light-gray;
  padding: 26px 0;

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__rich-text {
    padding-top: 18px;
  }

  &__description {
    max-height: 0;
    height: 0;
    opacity: 0;
    overflow: hidden;
    &.enter {
      max-height: 0;
      height: 0;
      opacity: 0;
    }

    &.enter-done {
      opacity: 1;
      transition: all 400ms ease-in;
      height: auto;
      max-height: 9999px;
    }

    &.exit {
      height: auto;
      max-height: 9999px;
      opacity: 1;
    }

    &.exit-active {
      max-height: 0;
      height: 0;
      opacity: 0;
      transition: all 400ms ease-out;
    }
  }

  &__button {
    border: 0;
    cursor: pointer;
    background-color: $cor-bg-gray;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: relative;
    flex: 0 0 40px;
    margin-left: 1rem;
  }

  &__link-button {
    margin-top: 25px;
  }

  &__icon {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 14px;
    width: 14px;
    transform: translate(-50%, -50%);
    z-index: 0;
    & > svg {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

body:not(.international-font) .cor-accordion-item {
  &__rich-text {
    @include cor-rich-text();
  }
}
