@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

.subscriptions {
  margin-bottom: $s2-5;

  @include breakpoint(medium) {
    margin-bottom: $s4;
  }

  &__item {
    display: flex;
    align-items: flex-start;
    margin-bottom: $s1-5;

    @include breakpoint(medium) {
      align-items: center;
    }
  }

  &__text {
    font: 14px/1.43 $frutiger-55-roman;
    padding-left: $s1;

    @include breakpoint(medium) {
      font-size: 16px;
      padding-left: $s1-5;
    }
  }

  img {
    max-width: 21px;

    @include breakpoint(medium) {
      max-width: 24px;
    }
  }
}

.location-users-info-modal {
  .subscriptions {
    margin-top: $s4;

    &__text {
      font: 14px/1.43 $frutiger-55-roman;
    }
  }
}

.edit-locations-user-info-modal {
  .subscriptions {
    margin-top: $s2-5;

    @include breakpoint(medium) {
      margin-top: $s1-5;
    }

    &__text {
      font: 14px/1.43 $frutiger-55-roman;
    }
  }
}
