@import 'styles/_variables';
@import 'styles/_foundation';
@import 'styles/typography/_mixins';

.order-summary-discount {
  &__item {
    @include df-p2-medium;
    display: flex;
    justify-content: space-between;

    @include breakpoint(medium) {
      @include df-p1-medium;
    }

    &-name {
      padding-right: $s10;
      word-break: break-all;
      padding-left: 22px;

      @include breakpoint(medium) {
        padding-right: 10px;
        width: 85%;
      }
    }

    &-price {
      width: 40%;
      text-align: end;
    }

    &:first-child {
      padding-bottom: 6px;
    }

    &:last-child {
      padding-bottom: $s2;
    }
  }
}

.order-summary-discount-tbd {
  @include df-p1-medium;
  display: flex;
  justify-content: space-between;
  margin-bottom: $s1-5;
}
