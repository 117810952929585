@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

.cor-product-tile {
  flex: 0 1 auto;
  width: 47%;
  background: $cor-white;
  border-radius: 6px;
  margin: 24px 1.5%;
  position: relative;
  z-index: $base-z-index;

  @include breakpoint($cor-desktop) {
    width: 17%;
  }

  &__container {
    min-height: 370px;
  }

  &__graphic {
    cursor: pointer;
    box-shadow: 0 0 20px rgba(219, 219, 219, 0.5);
    margin-bottom: 1rem;
    border-radius: 6px;

    @include breakpoint($cor-desktop) {
      margin-bottom: $s3;
    }

    &--inactive {
      cursor: default;
    }
  }

  &__image {
    height: 100%;
    width: 100%;
    object-position: center;
    object-fit: cover;
    z-index: 0;
    position: relative;
  }

  &__statuses-wrapper {
    @include df-caption;
    position: absolute;
    display: flex;
    flex-direction: column-reverse;
    top: 135px;
    right: 8px;
    left: 8px;
    color: $white;
    line-height: 1.2;

    @include breakpoint(medium) {
      top: 184px;
      left: 15px;
      right: 20px;
      flex-direction: row;
      line-height: 1.45;
    }

    &--multiple-statuses {
      top: 105px;

      @include breakpoint(medium) {
        top: 155px;
      }
    }

    .product-status-labels {
      width: 100%;

      &__wrapper {
        justify-content: flex-start;
      }

      .product-status {
        padding: 2px $s1 0;
        line-height: 14px;

        &--out-of-stock {
          line-height: 10px;
        }

        @include breakpoint(medium) {
          padding: 2px 12px 0;
          line-height: 16px;

          &--out-of-stock {
            line-height: 12px;
          }
        }
      }
    }
  }

  &__name {
    @include df-h4-medium;
    line-height: 26px;

    @include breakpoint(medium) {
      font-size: 18px;
    }
  }

  &__sku-no {
    @include df-p2-medium;
    margin-bottom: 10px;
    line-height: 24px;

    &-with-status-label {
      margin-bottom: $s0-5;
    }
  }

  &__check-badge {
    position: absolute;
    top: -8px;
    right: -8px;
    z-index: 1;

    &--is-deleted-location {
      position: absolute;
      top: -8px;
      right: -8px;
      z-index: 1;
      cursor: auto;

      g#Artboard {
        border-radius: 50%;
        pointer-events: none;
        fill: $gray;
        stroke: $gray;
        border: 1px solid $gray;
      }
    }
  }

  &__stock,
  &__name {
    margin-bottom: $s0-5;
  }

  &.is-special-order {
    z-index: revert;

    @include breakpoint(medium) {
      z-index: $base-z-index;
    }
  }

  .product-image__media img,
  .product-image__media--with-overlay:after {
    border-radius: 6px;
  }
}

.cor-typography__body-regular.cor-product-tile__brand {
  @include df-caption;
  font-weight: 700;
  color: $brown;
  margin-bottom: $s0-5;
}
