@import 'styles/_variables';

.cor-footer-mobile {
  .cor-footer__menu-link {
    &__container--expanded {
      background-color: #f2eeeb;
    }

    &__title {
      cursor: pointer;
      padding-top: 25px;
      padding-bottom: 25px;
      justify-content: space-between;

      & > img {
        display: initial;
        transform: rotate(90deg);
      }

      &--no-menu-links {
        justify-content: initial;

        & > img {
          transform: initial;
        }
      }

      &--expanded > img {
        transform: rotate(270deg);
        padding-bottom: 0;
      }
    }

    &__content {
      max-height: 0;
      overflow: hidden;
      transition: max-height 200ms ease-out;
      display: flex;
      flex-direction: column;
      row-gap: 35px;

      &--expanded {
        overflow: initial;
        max-height: 1000px;
        transition: max-height 200ms ease-in;
        padding-bottom: 25px;
      }

      ul {
        display: flex;
        flex-direction: column;
        row-gap: 25px;
      }
    }
  }
}
