@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

.edit-user-personal-info-modal {
  &__form-fields {
    display: flex;
    flex-wrap: wrap;
  }

  &__field {
    width: 100%;
    padding: $s1 0;
    margin: 0;

    @include breakpoint(medium) {
      &:nth-child(odd) {
        padding-right: $s4;
      }
      width: 50%;
      padding: $s2 0;
    }
  }

  &__email-field-label {
    @include df-p1-medium;
    margin-bottom: 5px;
  }

  &__email-field-value {
    @include breakpoint(medium) {
      padding: $s2 0;
    }
    color: $dark-gray;
    padding: 0;
  }

  &__subscription {
    width: 100%;
    margin-top: $s2;
    margin-bottom: $s4;
  }

  &__general-error {
    width: 100%;
  }

  .checkbox-field__label {
    margin-bottom: $s1-5;
  }

  &__form {
    padding: 0 $s2-5;

    @include breakpoint(medium) {
      padding: 0 $s4;
    }
  }

  &__footer {
    justify-content: flex-start;
    gap: 8px;

    @include breakpoint(small down) {
      flex-wrap: wrap;
    }

    @include breakpoint(medium) {
      gap: 0;
    }
  }
}
