@import 'styles/_foundation';
@import 'styles/_variables.scss';
@import 'styles/typography';

.change-email-address-form {
  $change-email-address-form: &;

  margin-top: $s2;

  @include breakpoint(medium) {
    margin-top: $s2-5;
  }

  &--invalid {
    margin-top: 0;

    input {
      border: 1px solid $red;
    }
  }

  .text-field {
    &__label {
      color: $black;
      margin-bottom: $s1;
    }
  }

  &__buttons {
    margin-top: $s4;

    @include breakpoint(medium) {
      margin-top: 155px;

      button.cell {
        flex: 0 auto;
      }

      #{&}-btn {
        padding: $s1-5 $s3;

        &:not(:first-child) {
          margin-right: $s3;
        }
      }

      &.grid-x {
        flex-flow: row-reverse;
      }
    }

    @include breakpoint(small only) {
      button:first-child,
      &-resend-email-btn {
        margin-bottom: 10px;
      }
    }
  }

  &__current-email {
    font: 16px/2 $museo-500;

    #{$change-email-address-form}--updated-successfully & {
      margin-bottom: 0;
    }

    @include breakpoint(medium) {
      display: inline-block;
      margin-right: $s3;

      #{$change-email-address-form}--updated-successfully & {
        margin-bottom: $s1;
      }
    }
  }

  &__success-message {
    @include df-p1-bold;
    display: flex;
    align-items: flex-start;
    color: $green;
    margin-bottom: $s0-5;
    margin-top: $s2;

    @include breakpoint(medium) {
      font-size: 16px;
      margin-top: $s3;
    }

    &-icon {
      width: 20px;
      height: 20px;
    }
  }

  &__discard-icon {
    width: 12px;
    height: 12px;
  }

  &__discard-icon,
  &__success-message-icon {
    margin-right: $s1;
  }

  &__current-email,
  &__change-email-button {
    @include breakpoint(small down) {
      margin-left: $s0-5;
    }
  }

  &__change-email-button.cor-button {
    @include df-p1-bold;

    @include breakpoint(small down) {
      max-width: 160px;
      text-align: start;
    }
  }

  &__change-email-button {
    .content {
      display: flex;
      align-items: center;
    }
  }

  @include breakpoint(small down) {
    &,
    form {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }

    &__spacer {
      flex-grow: 1;
    }
  }

  &__paper_plane {
    margin-right: 10px;
    height: 16px;
    width: 16px;
  }

  &__current-email-label {
    @include df-p1-medium;
    color: $dark-gray;
  }

  &__fields {
    border-top: 1px solid $input-gray;
    padding-top: $s2;
    margin-top: $s2;

    .text-field {
      width: 100%;
      @include breakpoint(medium) {
        width: 32%;
      }

      &:first-child {
        @include breakpoint(medium) {
          margin-right: $s5;
        }
      }
    }

    @include breakpoint(medium) {
      margin-top: $s3;
      padding-top: $s3;
    }
  }
}
