@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

.text-field {
  $text-field: &;
  margin-bottom: $s1;
  color: $black;

  &__label {
    @include df-p1-medium;
    display: flex;
    margin-bottom: 5px;

    .custom-tooltip {
      margin-left: $s1;
    }
  }

  &__label-asterisk {
    color: $red;
  }

  &__input-decorated {
    position: relative;

    #{$text-field}__input {
      padding-left: 36px;
    }
  }

  &__input-adornment {
    position: absolute;
    top: 50%;
    right: auto;
    left: $s2;
    transform: translateY(-50%);
    margin-right: $s1;
    font-family: $museo-700;
    font-size: 23px;
    color: $red;
  }

  &__input-highlight {
    width: 100%;
    padding: 11px $s2;
    font-size: 16px;
    line-height: 1.5;
    border: 1px solid $input-gray;
    border-radius: 8px;
    background-color: $light-yellow;
  }

  &__input {
    width: 100%;
    padding: 11px $s2;
    font-size: 16px;
    line-height: 1.5;
    border: 1px solid $input-gray;
    border-radius: 8px;

    &:hover,
    &:active,
    &:focus {
      border-color: $dark-gray;
    }

    &:disabled {
      border-color: $light-gray;
      background-color: $snow;
    }
  }

  &__error-message,
  &__description {
    @include df-p2-italic;
  }

  &__error-message {
    margin-top: $s1;
    color: $red;
  }

  &__description {
    margin-top: $s1-5;
    line-height: 1;
  }

  &--error {
    #{$text-field}__label {
      color: $red;
    }

    #{$text-field}__input {
      border-color: $red;
    }

    #{$text-field}__description {
      display: none;
    }
  }

  &--literal {
    #{$text-field}__input:disabled {
      color: $black;
      background-color: transparent;
      border: 0;
      padding-left: 0;
    }
  }
}
