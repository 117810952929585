@import 'styles/_variables';

.cor-text-link {
  & {
    display: flex;
    align-items: center;
    column-gap: 10px;
    text-decoration: none;
    height: fit-content;
    width: fit-content;
    position: relative;
  }

  &__hover[href],
  &[href]:hover {
    text-decoration: underline;
  }

  & > img {
    height: 20px;
    width: auto;
  }

  &__caret {
    padding-right: 17px;

    &::after {
      content: url('../assets/images/icons/icon-single-arrow-red.svg');
      padding-left: 10px;
      position: absolute;
    }
  }

  &__caret__isolated {
    &::after {
      content: url('../assets/images/icons/icon-single-arrow-red.svg');
    }
  }
}
