@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

.navigation-tiles {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: $s6 0;

  @include breakpoint(medium) {
    flex-direction: row;
    margin: $s6 #{-$s2};
  }

  .navigation-tile {
    flex: 1 0;
    margin: $s2 0;

    @include breakpoint(medium) {
      margin: 0;
      padding: 0 $s2;
      max-width: percentage(1/3);
    }
  }
}
