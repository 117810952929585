@import 'styles/_variables';
@import 'styles/_foundation';
@import 'styles/_cor-rich-text';
@import 'styles/mixins';

.cor-hero-content {
  padding: 34px 24px;
  position: relative;

  @include breakpoint($cor-desktop) {
    position: absolute;
    top: 50%;
    z-index: 99999;
    max-width: 630px !important;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  &__headline {
    font-family: $museo-500;
    color: $aubergine-gray;
    font-size: 32px;
    margin-bottom: 1rem;
    text-align: center;
    @include breakpoint($cor-desktop) {
      font-size: 50px;
      text-align: left;
    }
  }

  &__description {
    position: relative;
    margin-bottom: 1rem;
    text-align: center;
    z-index: 15;
    &.cor-rich-text.extend-h2 h2 {
      font-size: 3.125rem !important;
      line-height: 3.5rem !important;
    }
    @include breakpoint($cor-desktop) {
      text-align: left;
      & > *:not(h5) {
        color: inherit !important;
      }
    }
    a,
    span {
      width: min-content;
      @include breakpoint(480px) {
        width: initial;
      }
    }
    ul {
      list-style: none !important;
    }
    ul,
    ol {
      columns: 2;
      gap: 2rem;
    }

    li {
      display: flex;
      align-items: center;
      text-align: left;
      & > a {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: left;
        text-decoration: none;
        &::before {
          height: auto !important;
        }
        &::after {
          background-image: url('/assets/images/icons/icon-orange-menu-arrow.svg');
          background-size: contain;
          background-repeat: no-repeat;
          width: rem(12px);
          height: rem(12px);
          margin-left: rem(10px);
          content: '';
          display: block;
        }
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    text-align: center;
    .cor-button-link {
      width: auto;
    }
    @include breakpoint($cor-desktop) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 16px;
      text-align: left;
      flex-wrap: wrap;
    }
  }

  &__pagination {
    display: none;
    position: absolute;
    bottom: -24px;
    left: 50%;
    transform: translateX(-50%);
    align-items: center;
    justify-content: center;
    gap: 10px;
    @include breakpoint($cor-desktop) {
      display: flex;
    }
    & .is-active {
      color: red;
    }
  }

  &__bullet {
    display: inline-block;
    background: $white;
    border-radius: 50%;
    opacity: 0.5;
    height: 12px;
    width: 12px;
    &.is-active {
      opacity: 1;
    }
  }

  &__arrows {
    display: none;
    position: absolute;
    align-items: center;
    justify-content: space-between;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    z-index: 10;
    @include breakpoint($cor-desktop) {
      &.is-visible {
        display: flex;
      }
    }
  }

  &__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    background: $white;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: 0 0 20px rgba(119, 118, 118, 0.19);
    cursor: pointer;
    &-prev {
      margin-left: -25px;
    }
    &-next {
      margin-right: -25px;
    }
  }

  &__arrow-label {
    @include srOnly;
  }

  &--inset {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 34px 55px !important;
    border-radius: 6px !important;
    text-align: center;
    align-items: center;
    transform: translate(-50%, -50%);
    width: 85%;
    max-width: 366px;
    z-index: 1;
    @include breakpoint($cor-desktop) {
      padding: 72px;
      max-width: 540px !important;
    }
    &.cor-hero-content--card {
      box-shadow: 0 0 20px rgba(219, 219, 219, 0.5);
    }
  }

  &--carousel {
    @include breakpoint($cor-desktop) {
      min-height: 450px;
    }
  }

  &--carousel,
  &--card {
    background-color: $white;
    border-radius: 20px;
    @include breakpoint($cor-desktop) {
      padding: 72px;
    }
  }

  &--left {
    @include breakpoint($cor-desktop) {
      left: 90px;
    }
  }

  &--right {
    @include breakpoint($cor-desktop) {
      right: 90px;
    }
  }

  &--center {
    text-align: center;
    align-items: center;
    @include breakpoint($cor-desktop) {
      align-items: center;
      left: 50%;
      transform: translate(-50%, -50%);
      .cor-hero-content__buttons {
        justify-content: center;
      }
      .cor-hero-content__headline,
      .cor-hero-content__description {
        text-align: center;
      }
    }
  }

  &--light {
    color: $white;
  }

  &--dark {
    color: $black;
  }

  &--overlay-light {
    &:not(.cor-hero-content--inset) {
      background-color: $white;
      @include breakpoint($cor-desktop) {
        background-color: unset;
      }
    }
  }

  &--overlay-dark {
    &:not(.cor-hero-content--inset) {
      background-color: $black;
      @include breakpoint($cor-desktop) {
        background-color: unset;
      }
    }
  }
}

body:not(.international-font) .cor-hero-content {
  &__description {
    @include cor-rich-text(
      $map: (
        'h2': (
          'margin': 20px,
          'color': inherit,
        ),
        'p': (
          'size': [14px,
          16px],
          'lineheight': [24px,
          28px],
          'spacing': initial,
          'margin': 10px,
          'color': inherit,
        ),
        'span': (
          'size': [14px,
          16px],
          'lineheight': [24px,
          28px],
          'spacing': initial,
          'margin': 10px,
          'color': inherit,
        ),
        'li': (
          'face': museo,
          'size': [16px,
          16px],
          'lineheight': [24px,
          24px],
          'spacing': initial,
          'weight': 400,
          'color': inherit,
        ),
      )
    );
  }
}

body.international-font .cor-hero-content {
  &__description {
    @include cor-rich-text(
      $map: (
        'h2': (
          'face': neueFrutiger,
          'margin': 20px,
          'color': inherit,
        ),
        'p': (
          'face': neueFrutiger,
          'size': [14px,
          16px],
          'lineheight': [24px,
          28px],
          'spacing': initial,
          'margin': 10px,
          'color': inherit,
        ),
        'span': (
          'face': neueFrutiger,
          'size': [14px,
          16px],
          'lineheight': [24px,
          28px],
          'spacing': initial,
          'margin': 10px,
          'color': inherit,
        ),
        'li': (
          'face': neueFrutiger,
          'size': [16px,
          16px],
          'lineheight': [24px,
          24px],
          'spacing': initial,
          'weight': 400,
          'color': inherit,
        ),
      )
    );
  }
}
