@import 'styles/_variables';
@import 'styles/_foundation';
@import 'styles/typography/_mixins';

.buy-it-again {
  border: 1px solid $input-gray;
  border-radius: 4px;
  background-color: $white;
  height: 469px;
  overflow-y: auto;
  padding: $s2 $s3 0;

  &--mobile {
    position: relative;
  }

  &__see-all {
    @include df-cta;
    position: absolute;
    color: $black;
    text-decoration: underline;
    background: none;
    border: none;
    cursor: pointer;
    right: 16px;
    bottom: -5px;
    text-align: end;
    z-index: 1;
    padding: $s2-5 0;

    @include breakpoint(medium) {
      position: relative;
      right: 0;
      bottom: 0;
      padding: 0 $s2 0 0;
    }

    &-link {
      padding-right: $s2;
      text-align: end;
      padding: 0;
    }

    img {
      margin-left: 10px;
      vertical-align: middle;
      width: 9px;
      height: 16px;
    }
  }
}
