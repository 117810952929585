@import 'styles/_variables';
@import 'styles/_foundation';
@import '/src/styles/mixins/typography';

$inset-left: 12px;

.user-locations-panel {
  &__container {
    position: relative;
    padding: 20px 0 0;
    display: block;

    @include breakpoint(medium) {
      display: none;
      position: absolute;
      z-index: 200;
      top: -100px;
      left: 100%;
      padding: 0 0 0 10px;
    }

    ul {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0;
    }

    li {
      margin: 0 0 5px $inset-left;
      order: 1;
      position: static;

      &.is-selected {
        margin: 0 0 10px;
        order: 0;
      }

      .addy {
        @include font-frutiger-regular($size: 12px, $lineheight: 14px);
        position: static;
        display: inline-block;
        width: initial;
        z-index: 10;

        @include breakpoint(medium) {
          @include font-frutiger-regular($size: 12px, $lineheight: 14px);
          position: absolute;
          top: 50%;
          left: 110%;
          display: none;
          width: 110px;
          padding: 10px;
          border-radius: 5px;
          transform: translateY(-50%);
          background: #eee;
        }
      }
    }

    .user-location {
      &__selected {
        @include font-frutiger-regular($size: 12px);
        background-color: #fff;
        border: 1px solid #dddddc;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        padding: $inset-left;
        width: 100%;
      }

      button {
        @include font-frutiger-regular($size: 12px);
        background: none;
        border: 0;
        text-align: left;
        display: inline-block;
        cursor: pointer;
        padding: 0;
        position: relative;

        &:hover {
          .loc-item {
            text-decoration: underline;
          }
          .addy {
            @include breakpoint(medium) {
              display: block;
              text-decoration: none;
            }
          }
        }
      }
    }

    span {
      display: block;
      text-transform: capitalize;
    }
  }

  &__scroll {
    background-color: white;
    box-shadow: none;
    border-radius: 0;
    overflow-y: visible;
    overflow-x: visible;
    width: 100%;
    padding: 0;

    @include breakpoint(medium) {
      background-color: white;
      box-shadow: 0 0 30px rgba($gray, 0.45);
      border-radius: 10px;
      overflow-y: visible;
      overflow-x: visible;
      width: 200px;
      padding: 20px;
    }
  }
}

.is-scroll {
  @include breakpoint(medium) {
    width: 275px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
