@import 'styles/_variables';
@import 'styles/_foundation';
@import 'styles/typography/_mixins';

.buy-it-again-item {
  display: flex;
  flex-direction: column;
  padding: $s3 $s2;

  &:first-child {
    background-color: #000;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $input-gray;
  }

  .line-item-image__wrapper {
    margin-right: $s1-5;
  }

  &__content {
    display: flex;
    width: 100%;
  }

  &__line-item-info {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__info {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  &__brand {
    @include df-caption;
    color: $brown;
  }

  &__name {
    @include df-p2-bold;
  }

  &__sku-info {
    width: 26%;

    &-item {
      @include df-p2-medium;
      display: flex;
      align-items: center;
      color: $dark-gray;

      &:not(:last-child) {
        border-right: 1px solid $input-gray;
      }

      &:not(:first-child) {
        padding: 0 $s1;
      }

      span {
        margin-right: $s0-5;
      }
    }

    &-characteristics {
      display: flex;
      width: 100%;
      margin-top: $s1;
    }
  }

  &__item-number {
    color: $black;
    padding-right: $s1-5;
    margin-bottom: 0;
  }

  &__price-block {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 45%;
    margin-top: 0;
  }

  &__price {
    width: 20%;

    @include breakpoint(medium) {
      margin-bottom: $s0-5;
    }

    &-label {
      @include df-caption;
      color: $dark-gray;
      margin-bottom: 15px;
    }

    &-value {
      @include df-cta;
    }

    &-quantity {
      display: flex;
      flex-direction: column;

      &-label {
        @include df-caption;
        color: $dark-gray;
        margin-bottom: $s2;

        @include breakpoint(medium) {
          margin-bottom: $s1;
        }
      }
    }
  }

  .product-status {
    padding: $s0-5 $s1-5 2px;
  }

  .hierarchy-accounts-special-order-message {
    max-width: 200px;
  }
}
