@import 'styles/_variables';

.transaction-timestamp-modal {
  &__body {
    padding: 25px $s2 0 $s2;
    background-color: $white;
  }

  .modal-footer {
    box-shadow: none;
    margin-top: $s1;
  }

  &__scheduled-on,
  &__paid-on {
    line-height: 25px;
  }
}
