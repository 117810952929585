@import 'corporate-ui/cor-typography/cor-typography';
@import 'styles/_variables';

.cor-form-submission-modal {
  &.modal__content {
    padding: 54px 31px 120px 25px;
    display: flex;
    column-gap: 40px;
    row-gap: 24px;
    flex-direction: column;
    border-radius: 20px;

    background-image: url('../../../assets/images/patterns/newsletter-background.png');
    background-size: 80% auto;
    background-repeat: no-repeat;
    background-position: bottom left;

    @include breakpoint($cor-desktop) {
      background-size: 55% auto;
      padding: 60px;
      flex-direction: row;
      min-height: 422px !important;
    }

    .modal__close-button {
      top: 24.5px;
      right: 24.5px;
    }

    & > * {
      flex: 1;
    }
  }

  &__text__container {
    text-align: center;

    @include breakpoint($cor-desktop) {
      text-align: initial;
    }
  }

  &__cta__container {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  &__cta {
    background-color: #faf7f5;
    width: 100%;
    padding: 28px 36px;
    border-radius: 6px;
    color: #605c5a;
    display: flex;
    flex-direction: column;
    row-gap: 13px;

    @include breakpoint($cor-desktop) {
      min-width: 278px;
    }
  }

  &__cta > a {
    display: flex;
    align-items: center;
    column-gap: 13px;
    color: #231f20;

    & > img {
      height: 20px;
      width: auto;
    }
  }
}
