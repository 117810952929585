@import 'styles/_foundation';
@import 'styles/_variables';

.cor-detail-related-content {
  &.cor-featured-content {
    background-color: initial;
  }

  .cor-featured-content__header {
    margin-bottom: 35px;

    @include breakpoint($cor-desktop) {
      margin-bottom: 55px;
    }
  }
}
