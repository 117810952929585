@import 'styles/_variables';
@import 'styles/_foundation';
@import 'styles/_cor-rich-text';

.cor-ingredients-list {
  background-color: $cor-bg-gray-light;
  padding: 48px 1rem;

  @include breakpoint($cor-desktop) {
    padding: 83px 1rem;
    padding: 69px $s8;
  }

  &__shop-ingredients {
    text-decoration: none;
    margin-top: 14px;
    display: inline-block;

    @include breakpoint(small down) {
      margin: $s2-5 auto 0 auto;
      display: block;
      max-width: 244px;
      text-align: center;
    }
  }

  &__ingredients-list {
    color: $mid-gray;

    ul {
      margin: 0;
    }
  }

  &--white {
    background-color: $white;
  }
  &--light-tan {
    background-color: $cor-bg-gray;
  }
  &--yellow {
    background-color: $cor-dawn-yellow;
  }
}

body:not(.international-font) .cor-ingredients-list {
  &__ingredients-list {
    @include cor-rich-text();
    @include cor-rich-text(
      $map: (
        'p': (
          'size': (
            14px,
            14px,
          ),
          'lineheight': (
            24px,
            24px,
          ),
          'spacing': (
            0.01rem,
            0.01rem,
          ),
        ),
        'li': (
          'size': (
            14px,
            14px,
          ),
          'lineheight': (
            24px,
            24px,
          ),
          'spacing': (
            0.01rem,
            0.01rem,
          ),
        ),
      )
    );
  }
}
body.international-font .cor-ingredients-list {
  &__ingredients-list {
    @include cor-rich-text($map: $neue-base);
    @include cor-rich-text(
      $map: (
        'p': (
          'face': neueFrutiger,
          'size': (
            14px,
            14px,
          ),
          'lineheight': (
            24px,
            24px,
          ),
          'spacing': (
            0.01rem,
            0.01rem,
          ),
        ),
        'li': (
          'face': neueFrutiger,
          'size': (
            14px,
            14px,
          ),
          'lineheight': (
            24px,
            24px,
          ),
          'spacing': (
            0.01rem,
            0.01rem,
          ),
        ),
      )
    );
  }
}
