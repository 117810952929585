@import 'styles/_variables';
@import 'styles/_foundation';
@import 'styles/mixins/typography';

.cor-footer {
  & {
    background-color: #f9f7f6;
  }

  a {
    text-decoration: none;
  }

  &__menu-link__title {
    color: $aubergine-gray;
    display: flex;
    column-gap: 12px;
    align-items: center;

    & > img {
      display: none;
    }

    &--no-menu-links > img {
      display: initial;
    }
  }

  &__menu-link__sub-title {
    color: $mid-gray;
    margin-bottom: 2px;
  }

  &__menu-link__child-link {
    color: $aubergine-gray;
  }

  &__social-networks {
    margin-left: auto;
    display: flex;
    column-gap: 15px;
  }
}

body:not(.international-font) .cor-footer {
  &__menu-link__title {
    @include font-museo-regular($size: 14);

    @include breakpoint(960px) {
      @include font-museo-regular($size: 16);
    }
  }

  &__menu-link__sub-title {
    @include font-frutiger-regular($size: 12);
  }

  &__menu-link__child-link {
    @include font-museo-regular($size: 14);
  }
}

body.international-font .cor-footer {
  &__menu-link__title {
    @include font-neue-frutiger-regular($size: 14);

    @include breakpoint(960px) {
      @include font-neue-frutiger-regular($size: 16);
    }
  }

  &__menu-link__sub-title {
    @include font-neue-frutiger-regular($size: 12);
  }

  &__menu-link__child-link {
    @include font-neue-frutiger-regular($size: 14);
  }
}
