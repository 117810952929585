@import 'styles/_foundation';
@import 'styles/_variables';

.basic-header {
  background: $red;
  padding: $s2 0;

  img {
    width: 80px;
    height: $s4;
    display: block;
    margin: 0 auto;
  }

  @include breakpoint(medium) {
    img {
      margin: 0;
    }
  }
}
