@import 'styles/_variables';
@import 'styles/mixins/typography';

.cor-footer-mobile__legal {
  & > div {
    display: flex;
    flex-direction: column;
    text-align: center;
    row-gap: 20px;
    align-items: center;
  }

  &-links {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &-text {
    border-top: 1px solid #e3e2e1;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

body:not(.international-font) .cor-footer-mobile__legal {
  @include font-frutiger-regular();
}

body.international-font .cor-footer-mobile__legal {
  @include font-neue-frutiger-regular();
}
