@import 'styles/_variables';
@import 'styles/_foundation';

.cor-accordion-component {
  z-index: $base-z-index;
  padding: 50px 0;
  @include breakpoint($cor-desktop) {
    padding: 58px 0;
  }

  &__wrapper {
    @include breakpoint($cor-desktop) {
      margin: 0 auto;
      max-width: 1052px !important;
    }
  }

  &__head {
    text-align: center;
    margin-bottom: 30px;
    @include breakpoint($cor-desktop) {
      margin-bottom: 50px;
    }
    & .cor-typography {
      margin-bottom: 20px;
    }
  }

  &__description {
    color: $mid-gray;
  }

  &__tabs {
    margin: 0 auto 30px auto;
    @include breakpoint($cor-desktop) {
      margin: 0 auto 40px auto;
    }
  }

  &__accordion {
    display: none;
    &.is-active {
      display: block;
    }
  }

  &--white {
    background-color: $cor-bg-gray-lightest;
  }
  &--light-tan {
    background-color: $cor-bg-gray;
  }
  &--yellow {
    background-color: $cor-dawn-yellow;
  }
}
