/*!
 * Functions for recalculating font units
 */

// Base text size; unless another value is passed in for the context,
// this value will be used for pixel-to-em font calculations
$basetextsize: 16;
// em - function converts from pixels to em given a context
//
// Parameters:
//  - $pixels:	The number of pixels to convert from. No units, numeric only
//  - $context: The context for the conversion. Usually the font size in pixels
//				of the parent element when setting font size, or the font size
//				of the element itself for line height. No units, numeric only
//
// Returns: Size in em, including the em units
@function em($pixels, $context: $basetextsize) {
  @if (type-of($pixels) == 'string') {
    @return $pixels;
  }
  @return #{$pixels/$context}em;
}

@function rem($pixels: $basetextsize) {
  @if (type-of($pixels) == 'string') {
    @return $pixels;
  }
  $unit: if(unitless($pixels), $pixels, strip-unit($pixels));

  @return #{$unit/$basetextsize}rem;
}

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  } @else {
    @return $number;
  }
}
