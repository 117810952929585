@import 'styles/_variables';
@import 'styles/typography/_mixins';
@import 'styles/_foundation';

$modal-width-desktop: 640px;

.create-shopping-list-success-modal {
  z-index: $modal-z-index;
  color: $black;

  @include breakpoint(medium) {
    &.modal__content.cell {
      width: $modal-width-desktop;
      margin: 0 auto;
    }
  }

  &__link {
    color: $red;
    text-decoration: underline;
    cursor: pointer;
  }

  .modal__close-button {
    display: none;
  }
}
