@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

.cancel-scheduled-payment-modal {
  &__content-wrapper {
    padding: 0 $s2;

    @include breakpoint(medium) {
      padding: 0 $s4;
    }
  }

  &__subtitle {
    @include df-p3-medium;
    margin: $s1-5 0 $s1-5;
    padding: 0 $s2;

    @include breakpoint(medium) {
      @include df-p1-medium;
      margin: 9px 0 14px;
      padding: 0;
    }
  }

  &__content {
    margin-bottom: $s2-5;
    padding: 0 $s2;

    @include breakpoint(medium) {
      margin-bottom: 13px;
      padding: 0;
    }
  }

  &__label {
    @include df-p2-bold;

    @include breakpoint(medium) {
      @include df-p1-bold;
    }
  }

  &__value {
    @include df-p2-medium;
    margin-left: $s0-5;

    @include breakpoint(medium) {
      @include df-p1-medium;
    }
  }

  &__date,
  &__amount,
  &__account-number {
    margin-bottom: $s1-5;

    @include breakpoint(medium) {
      margin-bottom: 5px;
    }
  }

  &__account-holder-type {
    display: flex;
    justify-content: space-between;
  }

  &__payment-method-icon {
    width: 30px;
  }
  .success-error-message-modal {
    .modal-header__divider {
      display: none;
    }
  }
}
