@import 'styles/_variables';
@import '/src/styles/mixins/typography';
@import 'styles/_foundation';

.mobile-panel-header {
  padding: 50px $s3 18px;
  box-shadow: 0 1px 10px rgba($black, 0.1);
  position: relative;
  z-index: 1;

  &__heading {
    display: flex;
    align-items: center;
  }

  @include breakpoint(medium) {
    display: none;
  }

  img {
    width: 20px;
  }

  button {
    appearance: none;
    outline: none;
    border: 0;
    background: none;
    cursor: pointer;
    position: absolute;
    z-index: 10;
    padding: 0;
  }

  span {
    flex: 1;
    text-align: center;
  }
}

body:not(.international-font) .mobile-panel-header {
  span {
    @include font-museo-regular($size: 18);
  }
}

body.international-font .mobile-panel-header {
  span {
    @include font-neue-frutiger-regular($size: 18);
    font-weight: 600;
  }
}
