@import 'styles/_variables';
@import 'styles/_foundation';
@import 'styles/typography/_mixins';

.shopping-list {
  background-color: $white;
  border-bottom: 1px solid $input-gray;
  position: relative;

  @include breakpoint(medium) {
    height: 469px;
    border: 1px solid $input-gray;
    border-radius: 4px;
    overflow-y: auto;
  }

  &__desktop-view {
    padding: 0 $s5;
  }

  &__column-name {
    @include df-caption;
    display: flex;
    align-items: center;
    font-size: 11px;
    color: $dark-gray;
    padding: 19px 0;
    margin-right: $s2;
  }

  &__column-name#{&}__total-quantity,
  &__column-name#{&}__total-items {
    justify-content: center;
  }

  &__info-block {
    margin-right: $s2;
  }

  &__list-name {
    width: 30%;
  }

  &__total-items {
    width: 17%;
    text-align: center;
  }

  &__total-quantity {
    width: 12%;
    text-align: center;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-left: auto;
    flex: 1 1;
  }

  &__list {
    padding: 19px 0;
    border-top: 1px solid $input-gray;
    align-items: center;
  }

  &__info-block#{&}__list-name {
    @include df-p2-bold;
  }

  &__info-block#{&}__total-quantity,
  &__info-block#{&}__total-items {
    @include df-p1-medium;
  }

  #{&}__view-list.text {
    color: $black;
    margin-left: 31px;

    &:hover,
    &:focus {
      color: $black;
    }
  }

  &__add-more {
    display: inline-flex;
    align-items: center;

    &-button {
      &.cor-button.text {
        &:hover,
        &:focus {
          color: $black;
        }
      }
    }

    .custom-tooltip__icon {
      width: $tooltipIconSize;
      height: $tooltipIconSize;
      margin-left: $s1;

      img {
        width: 100%;
      }
    }
  }

  &__create-list-tooltip {
    @include df-p2-medium;
    background: none;
    border: none;
    padding: 0;
  }

  &__see-all {
    img {
      vertical-align: middle;
      margin-left: 10px;
      width: 9px;
      height: 16px;
    }
  }

  &__action {
    padding: 19px 0;
    text-align: end;
    flex: 1 1;

    .text,
    a {
      @include df-cta;
      color: $black;
      text-decoration: underline;
      background: none;
      border: none;
      cursor: pointer;
      display: block;
    }

    @include breakpoint(small down) {
      position: absolute;
      padding: $s2 0;
      right: 16px;
      bottom: -2px;
      width: 50%;
      z-index: 11;
    }
  }

  &__add-all {
    &.text:disabled {
      color: $input-gray;
    }
  }
}
