@import 'styles/_foundation';
@import 'styles/_variables';

.cor-image {
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  aspect-ratio: 365 / 205;
  @include breakpoint($cor-desktop) {
    aspect-ratio: 888 / 420;
  }

  &__img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }
}
