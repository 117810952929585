@import 'styles/_variables';
@import 'styles/typography/_mixins';
@import 'styles/_foundation';

.all-items-added-to-cart-result-message {
  &__context {
    padding: 0 $s4;

    &-with-slider {
      min-height: 400px;
    }
  }

  &__message {
    margin-bottom: $s4;
  }

  &__text {
    @include df-p1-medium;
    margin-bottom: $s4;
  }

  &__footer {
    flex-direction: column;
    margin-top: $s3;
    align-items: stretch;

    &-success {
      margin-top: 0;
      box-shadow: none;
    }

    button {
      min-height: $s6;
      max-height: none;
    }

    .cor-button.continue-shopping-btn {
      border-radius: $s1;

      @include breakpoint(small down) {
        margin-bottom: $s1-5;
      }
    }

    @include breakpoint(medium) {
      justify-content: flex-end;
      flex-direction: row;

      button {
        width: 50%;
        word-break: break-word;
        white-space: initial;
      }

      .continue-shopping-btn {
        margin: 0 $s2-5 0 0;
      }
    }

    .cancel-button {
      width: 100%;

      @include breakpoint(medium) {
        width: 120px;
      }
    }
  }
}
