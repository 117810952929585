@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

.impersonation-mode-header {
  background: $black;
  color: $white;
  padding: 5px 0 0;
  z-index: $sticky-view-z-index;
  text-align: center;

  @include breakpoint(medium) {
    padding: $s1-5 0;
  }

  &__inner {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

    @include breakpoint(small down) {
      display: inline;
    }

    @include breakpoint(medium) {
      align-items: center;
      flex-flow: row nowrap;
    }

    .cor-button--is-international {
      .content {
        text-decoration: underline;
      }
    }
  }

  &__info {
    @include df-p2-medium;
    margin-right: $s0-5;
    text-align: center;

    @include breakpoint(small down) {
      display: inline;
    }

    strong {
      @include df-p2-bold;

      @include breakpoint(medium) {
        @include df-p1-bold;
      }
    }
  }

  #{&}__end-impersonation-button.link-2 {
    color: $white;
    font-style: italic;

    &:not([disabled]):hover {
      color: $white;
    }
  }
}
