@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

.cor-featured-content__card-with-button {
  & {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 20px rgba(219, 219, 219, 0.5);
    border-radius: 6px;
    overflow: hidden;
    height: 100%;
    max-width: 362px;
    min-width: 50px;

    @include breakpoint($cor-desktop) {
      max-width: initial;
    }
  }

  &__image__container {
    height: 234.8px;
    overflow: hidden;

    @include breakpoint($cor-desktop) {
      height: 252px;
    }
  }

  &__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__description {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: $cor-white;
    padding: 28px 21px;

    @include breakpoint($cor-desktop) {
      padding: 30px;
    }
  }

  &__description__category {
    @include df-h4-medium;
    margin-bottom: 13px;
    text-decoration: none;

    @include breakpoint($cor-desktop) {
      font-size: 18px;
      margin-bottom: 16px;
    }
  }

  &__description__content {
    margin-bottom: 16.84px;

    @include breakpoint($cor-desktop) {
      margin-bottom: 25px;
    }
  }

  &__description > .cor-button {
    margin-top: auto;
    width: fit-content;
  }
}
