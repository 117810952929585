@import 'styles/_variables';
@import 'styles/_foundation';
@import 'styles/typography/_mixins';

.remove-payment-card-modal {
  .modal {
    &__content {
      min-height: 300px;
    }

    &-header {
      padding-bottom: $s2;
      margin-bottom: $s2-5;

      @include breakpoint(medium) {
        padding-bottom: $s2-5;
      }
    }
  }

  &__description {
    @include df-p1-medium;
    margin-bottom: $s2-5;
    padding: 0 $s2;

    @include breakpoint(medium) {
      margin-bottom: $s1-5;
      padding: 0 $s4;
    }
  }

  &__card-details {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    margin-bottom: $s2-5;
    padding: 0 $s2;
    flex-direction: column;
    padding: 0 $s2;

    @include breakpoint(medium) {
      margin-bottom: $s3;
      padding: 0 $s4;
    }
  }

  &__card-labels {
    display: flex;
    justify-content: space-between;
    width: 100%;

    :last-child {
      display: flex;
      justify-content: flex-start;

      @include breakpoint(medium) {
        justify-content: flex-end;
      }
    }
  }

  &__card-data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    :nth-child(3) {
      display: flex;
      justify-content: flex-start;

      @include breakpoint(medium) {
        justify-content: flex-end;
      }
    }
  }

  &__acc-number {
    @include df-p1-medium;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include breakpoint(medium) {
      gap: 12px;
    }
  }

  &__label {
    @include df-caption;
    width: 30%;
  }

  &__value {
    @include df-p3-medium;
    width: 30%;

    @include breakpoint(medium) {
      @include df-p1-medium;
    }
  }

  &__expiration {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;

    @include breakpoint(medium) {
      gap: 12px;
      align-items: flex-end;
    }
  }
  &__payment-type {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__card-img {
    img {
      height: 23px;
      width: 32px;

      @include breakpoint(medium) {
        height: 31px;
        width: 44px;
      }
    }
  }

  &__card-type {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 30%;

    @include breakpoint(medium) {
      flex-direction: row;
      align-items: center;
      gap: 6px;
    }
  }

  &__buttons {
    padding-top: $s2;
    display: flex;
    justify-content: flex-end;
    gap: 24px;
    align-items: center;

    @include breakpoint(medium) {
      padding-top: $s0-5;
      gap: $s4;
    }

    .cor-button {
      padding: $s1-5 $s3;
    }
  }

  &__cancel-button {
    @include df-p1-medium;
    cursor: pointer;
    color: $red;
    text-decoration: underline;
  }
}
