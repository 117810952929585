@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

.checkbox-field {
  $checkbox-field: &;
  position: relative;
  display: flex;
  color: $black;

  &__label {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    font-size: 14px;
    line-height: 1.43;

    @include breakpoint(medium) {
      align-items: center;
      @include df-p1-medium;
    }
  }

  &__checkmark {
    position: relative;
    width: 20px;
    height: 20px;
    margin-right: $s1-5;
    border-radius: 4px;
    border: 1px solid $aubergine-gray;
    flex: 1 0 auto;

    &:after {
      display: none;
      content: '';
      position: absolute;
      left: 7px;
      top: 2px;
      width: 5px;
      height: 10px;
      border: solid $white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  &__input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;

    &:hover {
      & ~ #{$checkbox-field}__checkmark {
        border: 2px solid $aubergine-gray;
      }
    }

    &:focus {
      & ~ #{$checkbox-field}__checkmark {
        outline: 3px solid #aec9f5;
      }
    }

    &:checked {
      & ~ #{$checkbox-field}__checkmark {
        border: none;
        background: $aubergine-gray;

        &:after {
          display: block;
        }
      }

      &:hover {
        & ~ #{$checkbox-field}__checkmark {
          background: $aubergine-gray;
        }
      }
    }

    &:disabled {
      & ~ #{$checkbox-field}__checkmark {
        border: none;
        background: $input-gray;
      }
    }
  }

  &--error {
    #{$checkbox-field}__checkmark {
      border-color: $red;
    }
  }
}
