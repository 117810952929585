@import 'styles/_variables';

.icons-menu {
  background-color: $cultured;

  &__list {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 36px;
    padding: 34px 20px;
  }

  &__item {
    width: 48px;
    height: 48px;
  }

  &__icon {
    object-fit: contain;
    object-position: center;
    height: 100%;
    width: 100%;

    .header-icon__counter {
      right: 0;

      span {
        top: 2px;
      }
    }
  }
}
