@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

.general-warning {
  $general-warning: &;
  $warning-icon-size-mobile: 20px;
  $warning-icon-size-desktop: 24px;

  background-color: $light-yellow;
  border-radius: 4px;
  border: 1px solid $yellow;
  position: relative;
  color: $black;
  padding: $s1-5;

  @include breakpoint(medium) {
    padding: $s2-5 $s3;
  }

  &__warning-icon {
    width: $warning-icon-size-mobile;
    height: $warning-icon-size-mobile;

    @include breakpoint(medium) {
      width: $warning-icon-size-mobile;
      height: $warning-icon-size-mobile;
    }

    @include breakpoint(small down) {
      align-self: baseline;
    }
  }

  #{$general-warning}__message {
    @include df-p2-bold;
    padding-left: $s3-5;
    padding-top: $s2;

    @include breakpoint(medium) {
      padding-left: 36px;
    }
  }

  &__title-block {
    justify-content: flex-start;
  }

  #{$general-warning}__title {
    @include df-p2-medium;
    padding-left: $s1-5;
    width: 90%;

    @include breakpoint(medium) {
      @include df-p1-medium;
    }
  }

  &__close {
    background: url('../../assets/images/icons/icon-cross.svg') no-repeat;
    border: none;
    padding: $s1;
    position: absolute;
    right: $s1-5;
    top: 14px;

    &:hover {
      cursor: pointer;
    }

    @include breakpoint(medium) {
      top: 23px;
      right: $s3;
    }
  }
}
