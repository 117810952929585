@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

.recent-orders {
  position: relative;
  background-color: $white;
  border-radius: 4px;

  @include breakpoint(medium) {
    min-height: 466px;
    max-height: 466px;
    padding: 0 $s3;
    border: 1px solid $input-gray;
  }

  &__column-name {
    @include df-caption;
    display: flex;
    align-items: center;
    font-size: 11px;
    color: $dark-gray;
    padding: 19px 0;
  }

  &__order {
    border-top: 1px solid $input-gray;

    &-info-block {
      @include df-p1-medium;
      color: $black;
      padding: 21px 0;
    }
  }

  &__link {
    @include df-cta;
    color: $black;
    text-decoration: underline;
    background: none;
    border: none;
    cursor: pointer;
    display: block;
  }

  &__order-actions {
    display: flex;
    justify-content: flex-end;
  }

  &__reorder-button {
    margin-right: $s3;

    &[disabled] {
      cursor: not-allowed;

      &.text {
        color: $input-gray;
      }
    }
  }

  &__order-date {
    width: 28%;
    padding-left: $s2-5;
  }

  &__order-number {
    width: 29%;
  }

  &__order-status {
    width: 23%;
  }

  &__see-all,
  &__order-actions {
    width: 20%;
  }

  &__see-all {
    padding: 19px 0;
    text-align: end;

    img {
      vertical-align: middle;
      margin-left: 10px;
      width: 9px;
      height: 16px;
    }

    @include breakpoint(small down) {
      position: absolute;
      right: 16px;
      bottom: -8px;
      width: 50%;
      z-index: 1;
    }
  }
}
