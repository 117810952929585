@import 'styles/_foundation';
@import 'styles/_variables';

.cor-filter-dropdown-options {
  & {
    border-radius: 6px;
    box-shadow: 0px 0px 15px rgba(200, 199, 199, 0.45);
    padding: 24px;
    background-color: $cor-white;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }

  &__apply-filters {
    margin-top: 15px;
    width: fit-content;
  }

  &__checkboxes {
    display: grid;
    gap: 8px;
  }
}
