@import 'styles/_foundation';
@import 'styles/_variables';

.search-results-page__filter-button {
  & {
    background-color: $cor-white;
    padding: 14px 30px;
    border-radius: 6px;
    border: 1px solid $cor-light-gray;
    color: $aubergine-gray;
  }

  &--selected {
    color: $cor-white;
    background-color: $cor-dawn-orange;
  }

  &:not([disabled]) {
    cursor: pointer;
  }
}
