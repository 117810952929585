@import 'styles/_foundation';
@import 'styles/_variables';

$tooltipIconSize: 20px;

.custom-tooltip {
  $custom-tooltip: &;
  cursor: pointer;

  &--disabled {
    cursor: default;
  }

  &__icon {
    width: $tooltipIconSize;
    height: $tooltipIconSize;

    img {
      width: 100%;
    }
  }

  &__content {
    width: 100%;
    max-width: 200px;
    box-shadow: 1px 2px 12px 0 rgba(0, 0, 0, 0.35);
    border-radius: 4px;
    padding: $s1;
    word-break: break-word;
    white-space: normal;

    &.show {
      &.place-top::after,
      &.place-bottom::after {
        left: var(--tooltip-left-position, 50%);
      }
    }

    @include breakpoint(medium) {
      width: auto;
      max-width: 224px;
    }

    img {
      width: 100%;
      height: auto;
      vertical-align: top;
    }

    #{$custom-tooltip} &.show {
      opacity: 1;
    }
  }
}
