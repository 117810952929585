@import 'styles/_foundation';
@import 'styles/_variables';

$border-radius: 8px;

.quantity-control {
  $quantity-control: &;

  &:hover {
    .quantity-control__input,
    .quantity-control__button-minus,
    .quantity-control__button-plus {
      border: solid 1px $dark-gray;
    }

    .quantity-control__input {
      border-left: none;
      border-right: none;
    }
  }

  &.disabled {
    pointer-events: none;

    #{$quantity-control}__input,
    #{$quantity-control}__button-minus,
    #{$quantity-control}__button-plus {
      background-color: $light-snow;
      color: $input-gray;
    }
  }

  &__content {
    padding-left: $s1;
  }

  &__input,
  &__button-minus,
  &__button-plus {
    border: solid 1px $input-gray;
  }

  &__input:hover {
    border: solid 1px $dark-gray;
  }

  &__input {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    font: 16px $frutiger-55-roman;
    width: 46px;
    height: 100%;
    border-left: none;
    border-right: none;
    border-radius: 0;
    padding: $s0-5 3px 0;
    text-align: center;
    color: $black;
  }

  &__input::-webkit-outer-spin-button,
  &__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  #{$quantity-control}__button-minus,
  #{$quantity-control}__button-plus {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 10px 11px;
    background-color: $white;
    font-size: 28px;
    color: $red;
    z-index: 1;
  }

  &__button-minus {
    border-radius: $border-radius 0 0 $border-radius;
  }

  &__button-plus {
    border-radius: 0 $border-radius $border-radius 0;
  }

  &__button-minus:hover,
  &__button-plus:hover {
    cursor: pointer;
    background-color: $light-coral;
    border: 1px solid $dark-gray;
  }
}
