@import 'styles/_foundation';
@import 'styles/_variables';

.corporate--insights {
  $max-width-container: 890px;

  .cor-share-social {
    color: $aubergine-gray;
  }

  .corporate__breadcrumbs {
    background-color: $cor-bg-gray-lightest;
  }
  .cor-rich-content {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .cor-two-column {
    .grid-container {
      max-width: none;
      padding: 0;
    }
  }

  .cor-two-column--white {
    background-color: $cor-white;
  }

  .cor-detail-hero-banner__heading {
    font-size: 36px;
    line-height: 40px;
    @include breakpoint($cor-desktop) {
      font-size: 50px;
      line-height: 56px;
    }
  }

  .cor-detail-hero-banner__date-author {
    &,
    &:hover {
      color: $mid-gray;
    }
  }

  .cor-detail-hero-banner__description {
    font-size: 14px;
    line-height: 26px;
    @include breakpoint($cor-desktop) {
      font-size: 16px;
      line-height: 28px;
    }
  }

  .cor-video-component .grid-container,
  .cor-image,
  .cor-rich-content,
  .cor-two-column__container {
    padding-left: 1rem;
    padding-right: 1rem;
    @include breakpoint($cor-desktop) {
      padding-left: 0;
      padding-right: 0;
      max-width: $max-width-container;
    }
  }
}

.cor-detail-related-content__insight {
  padding-top: 15px !important;
  padding-bottom: 160px !important;

  @include breakpoint($cor-desktop) {
    padding-top: 30px !important;
    padding-bottom: 175px !important;
  }
}
