@import 'styles/_variables';
@import 'styles/_foundation';

.cor-tab-menu {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  .cor-button {
    &.tertiary {
      border-radius: 6px;
      &.is-active {
        color: $white !important;
        background-color: $cor-dawn-orange !important;
        border-color: $cor-dawn-orange !important;
      }
    }
  }
}
