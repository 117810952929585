@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

.add-out-of-stock-to-cart-error-modal {
  &__section-message {
    @include df-p1-medium;
    margin-bottom: $s2;
  }

  ul {
    margin-top: 10px;
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  &__out-of-stock-sku {
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: $s2-5;

    strong {
      @include df-p1-bold;
      margin-right: $s2;
    }

    p {
      @include df-p2-medium;
      line-height: 24px;
      color: $black;
    }

    button.cor-button,
    .cor-button {
      @include df-p1-medium;
      align-self: flex-end;
      margin-left: auto;
      white-space: nowrap;

      @include breakpoint(medium) {
        align-self: flex-start;
      }
    }
  }
}
