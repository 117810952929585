@import 'styles/_foundation';
@import 'styles/_variables';

.cor-featured-content__cards-carousel {
  & {
    width: calc(100% + 2rem);
    padding-right: 30px !important;
    padding-left: 30px !important;
    margin-left: -1rem !important;
  }

  .swiper-button-next,
  .swiper-button-prev {
    width: initial;
    height: initial;
    top: 155px;
  }

  .swiper-button-next {
    right: 0;
  }

  .swiper-button-prev {
    left: 0;
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    width: 50px;
    min-width: 50px;
    height: 50px;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $cor-white;
    border-radius: 50%;
    font-size: 15px;
    font-weight: bolder;
    color: $cor-dawn-red;
  }

  .swiper-slide {
    height: auto;
    width: initial;
  }

  .swiper {
    overflow: initial;

    @include breakpoint($cor-desktop) {
      overflow: hidden;
    }
  }
}
