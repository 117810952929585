@import 'styles/_variables';
@import '/src/styles/mixins/typography';

.cor-distribution-center {
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 0 20px 0 rgba(219, 219, 219, 0.5);
  padding: 24px;
  max-width: 278px;
  gap: 8px;
  justify-content: space-between;
  &__title {
    @include font-museo-regular($size: 18, $lineheight: 26px);
    font-weight: 400;
  }

  &__address {
    @include font-frutiger-regular($size: 16, $lineheight: 28px, $spacing: 0.01em);
    font-weight: 400;
  }

  &__contact-info {
    @include font-frutiger-regular($size: 16, $lineheight: 28px, $spacing: 0.01em);
    font-weight: 400;
    & a {
      text-decoration: none;
    }
  }
  &__icon {
    fill: $cor-dawn-red;
  }
}
