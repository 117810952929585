@import 'styles/_variables';

.cor-footer-shop-products {
  & {
    height: fit-content;
    width: 257px;
    min-width: 257px;
    max-width: 257px;
  }

  & > ul {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }

  .shop-products__cta {
    width: 100%;
    padding: 19px 0;
    background-color: #fff;
    display: flex;
    justify-content: center;
    border-radius: 6px;
  }
}

.cor-footer-shop-products--logged {
  & {
    font-size: 14px;
    background-color: #fff;
  }

  & > ul {
    row-gap: initial !important;
  }

  & > ul > li:not(.shop-products--no-padding) {
    padding: $s3-5 $s3;
    border-top: 1px solid #e3e1e0;

    &.sales-representative,
    &.credit-representative {
      padding: 10px $s3 14px;

      .cor-expandable-section__inner {
        li:last-child {
          padding-bottom: $s1;
        }
      }
    }
  }

  & > ul > li.customer-service {
    &,
    li,
    ul {
      display: flex;
      flex-direction: column;
      row-gap: 15px;
    }
  }

  & > ul > li:last-of-type {
    row-gap: 25px;
  }

  .customer-service {
    &__phone {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
