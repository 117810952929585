@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';
@import 'styles/mixins';

.cor-account-menu {
  &__locations-submenu {
    position: relative;
    padding: 0;
    display: block;
    left: initial;

    @include breakpoint(medium) {
      position: absolute;
      left: calc(100% - 40px);
      top: 0;
      z-index: $base-z-index;
      padding-left: 45px;
      height: 100%;
    }

    &-list {
      background-color: $white;
      width: 100%;

      @include breakpoint(medium) {
        background-color: $white;
        width: 250px;
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
        border-radius: 4px;
      }
    }

    &-item {
      @include df-placeholder;
      padding: $s1 $s1-5;

      &--selected {
        color: $red;
        display: flex;
      }

      &--deleted {
        color: $dark-gray;
        display: flex;
      }
      &__icon {
        display: block;
        width: 20px;
        height: 18px;
        margin-right: $s1;
        background: center / contain no-repeat url('/assets/images/icons/icon-warning-gray.svg');
        background-size: contain;

        &--notice {
          background: center / contain no-repeat url('/assets/images/icons/icon-notice.svg');
        }
      }

      &__label {
        flex: 1;
      }

      &:hover {
        cursor: pointer;
        background-color: $light-coral;
      }

      &:first-child {
        padding-top: $s2;
      }

      &:last-child {
        padding-bottom: $s2;
      }
    }
  }
}
