@import 'styles/_variables';

.cor-text-color {
  &__dawn-orange {
    color: $cor-dawn-orange;
  }

  &__dawn-red {
    color: $cor-dawn-red;

    &[href]:hover {
      color: $cor-dawn-red-hover;
    }
  }

  &__dawn-yellow {
    color: $cor-dawn-yellow;
  }

  &__dark-gray {
    color: $aubergine-gray;
  }

  &__mid-gray {
    color: $mid-gray;
  }

  &__light-gray {
    color: $cor-light-gray;
  }

  &__bg-gray {
    color: $cor-bg-gray;
  }

  &__bg-gray-light {
    color: $cor-bg-gray-light;
  }

  &__white {
    color: $cor-white;
  }
  &__green {
    color: $green;
  }

  &__brown {
    color: $brown;
  }
}
