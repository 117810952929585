@import 'styles/_variables';

.cor-footer__menu-links {
  & > ul {
    columns: 3;
    column-gap: 45px;

    & > li {
      break-inside: avoid;
    }
  }

  a:hover {
    text-decoration: underline;
  }
}

.cor-footer__menu-links.international-site {
  display: flex;
  justify-content: space-between;
  width: 70%;

  & > ul {
    column-gap: 100px;
  }
}

.cor-footer__menu-link__parent > ul {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.cor-footer__menu-link__parent:not(:first-child) {
  margin-top: 45px;
}
