@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography';

.remove-payment-bank-account-modal {
  &__description {
    margin: $s2-5 0;
    line-height: 24px;

    @include breakpoint(medium) {
      margin-bottom: $s1-5;
    }
  }

  &__content {
    padding: 0 $s2-5;

    @include breakpoint(medium) {
      padding: 0 $s4;
    }
  }

  &__account-info-container {
    align-items: center;
    display: flex;
    gap: 15px;
    justify-content: space-between;
    margin-bottom: $s3;
    padding: 23px 0 0 $s1-5;

    & --is-canada-french {
      height: 89px;
      gap: 10px;
    }

    @include breakpoint(medium) {
      padding: 0 0 $s1 0;
      margin-bottom: 0;
    }
  }

  &__account-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 48px;
    width: 102px;

    &:first-child {
      width: 89px;
    }

    &--is-canada-french {
      height: 70px;
    }

    @include breakpoint(medium) {
      height: 62px;
      width: fit-content;

      &:first-child {
        width: fit-content;
      }

      &--is-canada-french {
        height: 95px;
        width: 115px;
      }
    }

    &-label {
      @include df-p3-medium;
      font-size: 10px;
      font-weight: 700;
      line-height: 12px;
      letter-spacing: 0.8px;
      margin-bottom: $s1-5;
      height: 36px;

      @include breakpoint(medium) {
        margin-bottom: $s1-5;
        font-size: 11px;
        line-height: 16px;
        letter-spacing: 1.2px;
        height: 48px;
      }
    }

    &-info {
      @include df-p3-medium;

      @include breakpoint(medium) {
        @include df-p1-medium;
      }

      &:last-child {
        text-transform: lowercase;

        &::first-letter {
          text-transform: capitalize;
        }
      }
    }
  }

  .modal-footer {
    padding-top: $s2;

    &__cancel-button {
      .content {
        @include df-p1-medium;
        letter-spacing: 0.2px;
        line-height: 19.2px;
      }
    }
  }
  &.modal__content {
    min-height: 300px;
  }

  .cor-button--is-international.modal-footer__cancel-button {
    .content {
      text-decoration: underline;
    }
  }
}
