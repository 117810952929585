@import 'styles/_variables';
@import 'styles/_foundation';
@import 'styles/components/_carousel';
@import 'styles/typography/_mixins';

$slider-arrow-size: 36px;
$slider-dot-size: 16px;

.buy-it-again-skus-slider-item {
  height: 100%;

  &__info {
    display: flex;
    margin: 0 auto;
    padding: $s2 0 $s3 0;
    justify-content: center;
    border-top: 1px solid $input-gray;
  }

  &__button {
    height: 40px;
    width: 100%;

    &:last-child {
      margin-top: $s2;
    }
  }

  &__counter {
    padding: $s2-5 0;
    font: 18px/1.33 $museo-500;
    letter-spacing: 0.4px;
    text-align: center;
    color: $black;
  }

  &__item-info {
    display: block;
    width: 100%;
    color: $black;
  }

  &__item-name {
    font: 16px/1.33 $museo-700;
    margin-bottom: $s1;
  }

  &__item-number {
    @include df-p2-medium;
    margin-bottom: $s1-5;
    display: flex;
    justify-content: space-between;
  }

  &__item-brand {
    @include df-caption;
    color: $red;
  }

  &__price-block {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin-top: $s2;
  }

  &__price {
    margin-right: $s3;
  }

  &__price {
    &-label,
    &-quantity-label {
      @include df-caption;
      color: $dark-gray;
    }

    &-label {
      margin-bottom: 15px;
    }

    &-quantity {
      width: fit-content;

      &-label {
        margin-bottom: $s1;
      }
    }

    &-value {
      font: 18px/1.33 $museo-700;
    }
  }

  .product-status {
    padding: 2px $s1-5 0;
  }

  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: $s2;
  }

  &__buttons {
    margin-top: auto;
  }
}
