@import 'styles/_variables';
@import 'styles/_foundation';
@import 'styles/mixins/typography';

.cor-credit-rep {
  display: flex;
  flex-direction: column;
  row-gap: 15px;

  .title {
    @include font-frutiger-regular($size: 12);
    color: $mid-gray;
  }

  .info {
    list-style: none;
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    .icon-container {
      min-width: 20px;
    }

    .rep-name,
    .rep-phone,
    .rep-email {
      @include font-museo-regular($size: 14, $lineheight: 24px);
    }

    .rep-email {
      color: $cor-dawn-red;
    }

    .rep-phone,
    .rep-email {
      &:hover {
        text-decoration: underline;
      }
    }

    img {
      height: 12px;
      width: auto;
    }

    li {
      display: flex;
      flex-direction: row;
      column-gap: 10px;
      align-items: baseline;
    }

    a {
      word-break: break-word;
    }
  }
}
