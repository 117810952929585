@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';
@import 'styles/mixins';

.account-menu {
  &__dropdown {
    width: 100%;
    margin-right: $s2;
    position: relative;

    &-icon {
      position: absolute;
      right: 16px;
      @include css-triangle('right', 12px, 8px);
    }

    .select__menu {
      min-width: 190px;

      .select__option {
        padding: 0;
      }
    }

    .select__menu-list {
      max-height: 400px;
      color: $red;
    }

    .select__control {
      flex-wrap: nowrap;

      .select__single-value-container-wrapper {
        display: inherit;
      }
    }

    &--has-deleted-location {
      .select__value-container {
        .select__single-value {
          width: 100%;
        }
      }
    }

    .account-menu__option {
      padding: 0;

      .dropdown-menu-item {
        display: block;
        text-decoration: none;
        padding: $s1-5;
        width: 100%;

        &.active {
          color: $red;
        }
      }
    }

    .account-menu__locations {
      display: flex;
      align-items: center;
    }
  }

  &__locations-submenu {
    position: absolute;
    left: 272px;
    top: 50px;
    z-index: $base-z-index;
    padding-left: $s1-5;

    &-list {
      background-color: $white;
      width: 198px;
      height: 306px;
      overflow: hidden;
      overflow-y: auto;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
      border-radius: 4px;
    }

    &-item {
      @include df-placeholder;
      padding: $s1 $s1-5;

      &--selected {
        color: $red;
        display: flex;
      }

      &--deleted {
        color: $dark-gray;
        display: flex;
      }
      &-icon {
        display: block;
        width: 20px;
        height: 18px;
        margin-right: $s1;
        flex: 1 0 auto;
        background: center / contain no-repeat url('../../assets/images/icons/icon-warning-gray.svg');
        background-size: contain;

        &--notice {
          background: center / contain no-repeat url('../../assets/images/icons/icon-notice.svg');
        }
      }

      &:hover {
        cursor: pointer;
        background-color: $light-coral;
      }

      &:first-child {
        padding-top: $s2;
      }

      &:last-child {
        padding-bottom: $s2;
      }
    }
  }
}
