@import 'styles/_foundation';
@import 'styles/_variables';

.cor-featured-content {
  & {
    width: 100%;
    background-color: $cor-bg-gray-lightest;
    overflow: hidden;

    color: $aubergine-gray;
    padding: 42px 0 24px 0;

    @include breakpoint($cor-desktop) {
      padding: 93px 0 80px 0;
    }
  }

  &__header {
    display: flex;
    margin-bottom: 26px;
    column-gap: 20px;
    justify-content: space-between;

    @include breakpoint($cor-desktop) {
      margin-bottom: 73px;
    }
  }

  &__header__text {
    flex: 1;
    display: flex;
    flex-direction: column;
    row-gap: 11px;

    @include breakpoint($cor-desktop) {
      row-gap: 15px;
    }
  }

  &__header > &__button {
    width: fit-content;
  }

  & > &__button {
    display: block;
    width: fit-content;
    margin: 30px auto 0 auto;
  }

  &__card__image__container {
    background-color: $mid-gray;
  }
}
