@import 'styles/_foundation.scss';
@import 'styles/_variables';

.ordered-product-image {
  &__wrapper {
    position: relative;
    flex-shrink: 0;
  }

  &__picture {
    position: relative;
  }

  &__label {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -6px;
    margin: 0 -#{$s0-5};
    z-index: 2;

    @include breakpoint(medium) {
      margin: 0 -#{$s1};
      height: $s3;
    }
  }

  &--discontinued:before {
    position: absolute;
    content: '';
    background-color: #000;
    opacity: 0.4;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 1;
    @include breakpoint(medium) {
      margin-right: $s2;
      width: 100%;
      height: 100%;
    }
  }
}
