@import 'corporate-ui/cor-typography/cor-typography';

.cor-filter-expandable-section.expandable-section {
  &,
  &:last-child {
    padding-right: 0;
    border-bottom: 1px solid $cor-light-gray;
  }

  &:first-child {
    border-top: 1px solid $cor-light-gray;
  }

  &.expandable-section--expanded > .expandable-section__title {
    background-color: $cor-bg-gray;
  }

  & > .expandable-section__title {
    color: $aubergine-gray;
    padding-top: 24px;
    padding-bottom: 24px;
    text-transform: initial;
    background-color: $cor-bg-gray-light;
    padding-right: 1rem;
    padding-left: 1rem;

    @extend .cor-typography !optional;
    @extend .cor-typography__navigation-headings !optional;
  }

  & > .expandable-section__inner {
    display: flex;
    flex-direction: column;
    row-gap: 11px;
    padding: 0 1rem;
    background-color: $cor-bg-gray;
  }

  &.expandable-section--expanded > .expandable-section__inner {
    border-top: 1px solid $cor-light-gray;
    padding: 19px 1rem;
  }
}
