@import 'styles/_variables';
@import 'styles/_foundation';
@import 'styles/mixins/_typography';
@import 'styles/_cor-rich-text';

.cor-three-column-text-tile {
  &__text-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    @include breakpoint($cor-desktop) {
      gap: 20px 0;
    }
  }

  &__title {
    color: $cor-dawn-orange;
    text-transform: uppercase;
  }

  &__description {
    &,
    & a,
    & a:hover,
    & a:visited,
    & a:active {
      color: $mid-gray;
    }
  }
}

body:not(.international-font) .cor-three-column-text-tile {
  &__description {
    @include cor-rich-text(
      $map: (
        'p': (
          'size': [14px,
          14px],
          'lineheight': [24px,
          24px],
          'spacing': [.01rem,
          .01rem],
        ),
      )
    );
  }
}

body.international-font .cor-three-column-text-tile {
  &__description {
    @include cor-rich-text(
      $map: (
        'p': (
          'face': neueFrutiger,
          'size': [14px,
          14px],
          'lineheight': [24px,
          24px],
          'spacing': [.01rem,
          .01rem],
        ),
      )
    );
  }
}
