/*
  font variables
*/

$museo-300: 'MuseoLight', serif;
$museo-500: 'MuseoRegular', serif;
$museo-700: 'MuseoBold', serif;

$frutiger-65-bold: 'FrutigerBold', sans-serif;
$frutiger-55-roman: 'FrutigerRegular', sans-serif;
$frutiger-56-italic: 'FrutigerRegularItalic', sans-serif;

$frutiger-lt-pro: 'Frutiger LT Pro', sans-serif;

$neue-frutiger-300: 'Neue Frutiger World Thin', sans-serif;
$neue-frutiger-400: 'Neue Frutiger World Regular', sans-serif;
$neue-frutiger-700: 'Neue Frutiger World Heavy', sans-serif;
$neue-frutiger-italic: 'Neue Frutiger World Italic', sans-serif;

/*
  spacings
*/
$s0-5: 4px;
$s1: 8px;
$s1-5: 12px;
$s2: 16px;
$s2-5: 20px;
$s3: 24px;
$s3-5: 28px;
$s4: 32px;
$s4-5: 36px;
$s5: 40px;
$s6: 48px;
$s7: 56px;
$s8: 64px;
$s9: 72px;
$s10: 80px;

/*
  Achromatic Colors
  (search color names by hex ref: https://www.color-name.com)
*/

$achromatic-gray: #949494;
$black: #2d2926;
$dark-gray: #575656;
$input-gray: #ccc;
$light-gray: #ededed;
$light-gray-text: #757575;
$light-gray-header: #8e8886;
$caption-gray: #a8a8a8;
$snow: #f9f9f9;
$white: #fff;
$brown: #653819;
$gray: #d8d8d8;
$light-snow: #faf9f9;
$cultured: #faf7f5;
$light-tan: #f2eeeb;
$yellow: #e9b550;
$dark-green: #138881;
$mid-gray: #605c5a;
$aubergine-gray: #231f20;

/*
  Main Colors
*/

$red: #e03c31;
$light-red: #f24d41;
$light-coral: #fff5f5;
$orange: #ff8200;
$light-orange: #ffae47;
$light-yellow: #fff7e6;

/*
  Secondary Colors
*/

$highlight-gold: #e8b550;
$legal-pad-yellow: #fef7e8;
$mustard-yellow: #ecc168;
$yellow: #f1b434;
$green: #72b431;
$light-green: #f2ffe6;

/*
  Corporate colors
*/

$cor-region-link: #337ab7;
$cor-dawn-orange: #ff7300;
$cor-dawn-nav-orange: #d8400e;
$cor-dawn-red: $red;
$cor-dawn-red-hover: #b8360c;
$cor-dawn-yellow: #e9b550;
$cor-light-gray: #dddddc;
$cor-bg-gray: #f2eeeb;
$cor-bg-gray-light: $cultured;
$cor-bg-gray-lightest: #fcfbfa;
$cor-bg-gray-brightest: #fbf7f5;
$cor-bg-gray-bright: #f9f7f6;
$cor-white: #ffffff;

/*
  Z Indexes
*/

$base-z-index: 10;
$dropdown-z-index: 20;
$loader-z-index: 30;
$sticky-view-z-index: 40;
$sorting-dropdown-z-index: 45;
$page-header-z-index: 50;
$modal-z-index: 60;
$drop-zone-z-index: 70;
$top-menu-z-index: 80;

/*
  Breakpoints
*/

$cor-desktop: 960px; // This size matches desktop size use on: b2b-storefront/src/hooks/use-breakpoint.ts
$ipad-portrait: 768px;
$tooltipIconSize: 18px;
$mobile-main-page-height: 472px;
$desktop-main-page-height: 676px;

$environment-impersonation-mobile-height: 132px;
$environment-notification-mobile-height: 63px;
$environment-notification-desktop-height: 44px;
$environment-notification-background: #fd870e;
