@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

.guide-section {
  $guide-section: &;
  $item-bottom-margin: 14px;

  display: flex;
  flex-flow: column nowrap;
  padding: $s3-5 $s1-5;
  border: 1px solid $input-gray;
  background-color: rgba(255, 255, 255, 0.51);
  text-align: center;
  color: $light-gray-text;

  @include breakpoint(medium) {
    padding: 30px 50px 40px;
    border: 2px solid $input-gray;
  }

  &__header {
    width: 100%;
  }

  &__content {
    margin-bottom: -$item-bottom-margin;
  }

  &__gif-image {
    margin-bottom: $s4;

    @include breakpoint(medium) {
      align-self: flex-end;
      margin-right: $s2;
    }

    img {
      width: 100%;
      border: 1px solid $input-gray;
      border-radius: 8px;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);

      @include breakpoint(medium) {
        width: 350px;
      }
    }
  }

  &__title {
    @include df-h3-bold;
    margin-bottom: $s2;

    @include breakpoint(medium) {
      margin-bottom: $s1-5;
      font-size: 20px;
    }
  }

  &__description {
    @include df-p2-medium;
    margin-bottom: $s4;

    @include breakpoint(medium) {
      font-size: 16px;
      margin-bottom: $s3;
    }
  }

  &__tiles {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    margin: 0 auto;

    @include breakpoint(medium) {
      justify-content: center;
      width: 95%;
    }
  }

  &__footer {
    width: 100%;
    margin-top: $s4;
    white-space: nowrap;
    height: 80px;

    @include breakpoint(medium) {
      margin-top: $s2;
    }
  }

  &__get-started-btn {
    width: fit-content;
    text-decoration: none;
    padding: $s1-5 $s5;

    @include breakpoint(small down) {
      padding: $s1-5 $s2-5;
      width: 100%;
      text-align: center;
    }
  }

  &--with-gif-image {
    #{$guide-section}__tiles & {
      @include breakpoint(medium) {
        width: 100%;
      }
    }
    #{$guide-section}__tiles {
      .guide-tile__item {
        @include breakpoint(medium) {
          width: 33%;

          &:nth-child(2n + 1):last-child {
            width: 33%;
          }
        }
      }
    }
  }

  .guide-tile {
    &__item {
      width: 48%;
      margin-bottom: $item-bottom-margin;
      padding: $s3-5 $s1-5;
      border: 1px solid $input-gray;
      border-radius: 8px;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);

      &--with-link {
        cursor: pointer;

        &:hover {
          background: $white;
          border-color: $input-gray;
          border-radius: 8px;
          box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
        }

        a {
          text-decoration: none;
        }
      }

      &:nth-child(2n + 1):last-child {
        width: 100%;

        .guide-tile {
          &__content {
            display: flex;
            justify-content: space-between;
          }

          &__description {
            width: 60%;
          }

          &__icon-wrapper {
            flex-grow: 1;
          }
        }

        @include breakpoint(medium) {
          width: 25%;

          .guide-tile {
            &__content {
              display: block;
            }
            &__description {
              width: 100%;
            }
          }
        }
      }

      @include breakpoint(medium) {
        width: 25%;
        padding: $s4 $s3;
        border-color: transparent;
        background: none;
        box-shadow: none;
      }
    }

    &__icon {
      display: inline-block;
      height: 55px;
      margin-bottom: $s3-5;

      @include breakpoint(medium) {
        height: 95px;
        margin-bottom: $s5;
      }
    }

    &__description {
      @include df-p3-medium;
      text-align: left;

      // TODO: remove this styles after RichTextContentstack component will be created
      strong {
        font-weight: bold;
      }

      em {
        font-style: italic;
      }

      del {
        text-decoration: line-through;
      }

      sup {
        vertical-align: super;
        font-size: smaller;
      }

      sub {
        vertical-align: sub;
        font-size: smaller;
      }

      ol {
        list-style: auto;
      }

      ul {
        list-style: disc;
      }

      ol,
      ul {
        margin: 0 $s3;
      }

      p {
        @include breakpoint(small down) {
          line-height: 1.8;
        }
      }

      @include breakpoint(medium) {
        @include df-p2-medium;
      }
    }
  }
}
