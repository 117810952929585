@import 'styles/_variables';
@import 'styles/_foundation';
@import 'styles/mixins/_typography';
@import 'styles/_cor-rich-text';

// Generic rules
.cor-content-column {
  z-index: $base-z-index;

  &__paragraph {
    color: $mid-gray;
    margin-bottom: rem(40px);
  }

  &__react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
}

// variants
.cor-content-fifty-fifty {
  &__container {
    align-items: flex-start;
    gap: 25px;
    @include breakpoint($cor-desktop) {
      padding: 80px 0;
      gap: 115px;
      max-width: 890px;
    }
  }

  &__column {
    &--video,
    &--image {
      width: 100%;
      @include breakpoint($cor-desktop) {
        overflow: hidden;
        border-radius: 10px;
        flex: 1 0 50%;
        max-width: 300px;
        max-height: 300px;
        width: 50%;
      }
    }
    &--text {
      text-align: center;
      margin: 0 auto;
      @include breakpoint($cor-desktop) {
        text-align: left;
        flex: 1 1 auto;
      }
    }
  }

  &__image {
    img {
      display: block;
      object-fit: cover;
      object-position: center;
      aspect-ratio: 366 / 205;
      border-radius: 20px;
      max-width: 366px;
      max-height: 205px;
      margin: 0 auto;
      @include breakpoint($cor-desktop) {
        border-radius: unset;
        max-height: 300px;
        max-width: 300px;
        aspect-ratio: 1 / 1;
      }
    }
  }

  &__heading {
    margin-bottom: rem(15px);
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    @include breakpoint($cor-desktop) {
      align-items: flex-start;
    }
  }
}

.cor-content-inset {
  &__container {
    gap: 25px;
    @include breakpoint($cor-desktop) {
      gap: 100px;
      max-width: 1170px !important;
    }
  }

  &__column {
    &--video,
    &--image {
      order: 1;
      width: 100%;
      @include breakpoint($cor-desktop) {
        overflow: hidden;
        border-radius: 30px;
        flex: 1 1 50%;
        order: unset;
        width: 50%;
        max-width: 467px;
        max-height: 467px;
      }
    }
    &--text {
      order: 2;
      width: 100%;
      text-align: center;
      @include breakpoint($cor-desktop) {
        flex: 1 1 50%;
        order: unset;
        max-width: 535px;
        width: 50%;
        text-align: left;
      }
    }
  }

  &__column--text {
    @include breakpoint($cor-desktop) {
      & > * {
        max-width: 467px;
      }

      &.cor-content-inset__column-left {
        & > * {
          margin-left: auto;
        }
      }
      &.cor-content-inset__column-right {
        & > * {
          margin-right: auto;
        }
      }
    }
  }

  &__image {
    img {
      display: block;
      object-fit: cover;
      object-position: center;
      max-height: 236px;
      max-width: 236px;
      aspect-ratio: 1 / 1;
      border-radius: 20px;
      margin: 0 auto;
      @include breakpoint($cor-desktop) {
        max-height: 467px;
        max-width: 467px;
        border-radius: unset;
      }
    }
  }

  &__heading {
    margin-bottom: rem(15px);
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    @include breakpoint($cor-desktop) {
      align-items: flex-start;
    }
  }
}

.cor-content-cta {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 45px;
    @include breakpoint($cor-desktop) {
      flex-direction: row;
      align-items: stretch;
      justify-content: space-between;
      gap: 0;
      max-width: 1185px !important;
    }
  }

  &__divider {
    background: $gray;
    width: 1px;
  }

  &__column {
    text-align: center;
    max-width: 500px;
    @include breakpoint($cor-desktop) {
      display: grid;
      grid-template-rows: 48px auto auto 48px;
      padding: 16px 0;
      margin: 0 20px;
      flex: 0 1 50%;
      width: 50%;
    }
  }

  &__button {
    margin: 0 auto;
  }

  &__icon {
    margin-bottom: rem(15px);
  }

  &__icon-image {
    object-fit: contain;
    object-position: center;
    height: 38px;
    width: 38px;
  }

  &__heading {
    margin-bottom: rem(15px);
  }
}

body:not(.international-font) .cor-content-column {
  &__paragraph {
    @include cor-rich-text(
      $map: (
        'p': (
          'size': [14px,
          16px],
          'lineheight': [24px,
          28px],
          'spacing': [.01rem,
          .01rem],
        ),
      )
    );
  }
}

body.international-font .cor-content-column {
  &__paragraph {
    @include cor-rich-text(
      $map: (
        'p': (
          'face': neueFrutiger,
          'size': [14px,
          16px],
          'lineheight': [24px,
          28px],
          'spacing': [.01rem,
          .01rem],
        ),
      )
    );
  }
}
