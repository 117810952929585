@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';
@import 'styles/mixins';

.mobile-search-panel {
  z-index: $page-header-z-index;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $cultured;

  .algolia-search {
    margin-top: 18px;

    &__input {
      background-color: $cultured;
      border: none;
      border-radius: 100px;
      padding: $s1 15px;
      line-height: 18px;

      &-wrapper::before {
        right: 12px;
        width: 12px;
        height: 12px;
        background-size: contain;
      }
    }
  }

  .search-field-suggestions {
    top: 52px;
    box-shadow: none;
    background-color: transparent;
    max-height: calc(100vh - 147px);

    &__list {
      background-color: transparent;
      height: 100%;
    }

    &__item a,
    .custom-item {
      padding: $s1 0;
    }

    .link-text-item,
    .highlight-text,
    .custom-item {
      @include df-p2-medium;
    }

    .highlight-text {
      @include df-p2-bold;
    }
  }

  .mobile-panel-header {
    background-color: $white;
  }
}
