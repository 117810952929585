@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography';

.quote-order-summary-modal {
  &.modal {
    &__content {
      min-height: 330px;

      @include breakpoint(medium) {
        max-height: 400px;
        min-width: 650px;
      }
    }
  }

  .modal__close-button {
    right: 40px;
    top: 18px;
    width: fit-content;

    &:before,
    &:after {
      height: 28px;
      background-color: $dark-gray;
    }
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 $s2-5;

    @include breakpoint(medium) {
      flex-direction: row;
      justify-content: space-between;
      padding: 0 $s4;
    }

    & > div {
      &:first-child {
        width: 55%;
      }

      &:last-child {
        width: 32%;
        margin-right: $s1;
        margin-bottom: auto;
      }

      @include breakpoint(small down) {
        &:first-child,
        &:last-child {
          width: 100%;
          margin-right: 0;
        }
      }
    }
  }

  &__order-summary {
    padding-top: $s3;
    border-bottom: 1.5px solid $input-gray;
    @include df-p2-medium;

    @include breakpoint(medium) {
      border-bottom: none;
      @include df-p1-medium;
    }
  }

  &__submit-section {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    button {
      margin: 6px 0;
    }

    @include breakpoint(medium) {
      margin: 0 -#{$s2};

      button {
        margin: 0 $s2;
      }
    }
  }

  &__po-number {
    display: flex;
    flex-direction: column;
    padding-top: $s2;
    padding-left: $s1-5;
    width: 100%;
    margin-bottom: $s2-5;

    @include breakpoint(medium) {
      margin-bottom: $s4;
    }
  }

  &__po-number-label {
    @include df-p1-medium;
    margin-bottom: $s1;
  }

  &__po-number-input {
    font: 14px/1.71 $frutiger-55-roman;
    padding: $s1-5 $s2;
    border-radius: $s1;
    border: 1px solid $input-gray;
    box-sizing: border-box;

    &::placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &::-webkit-input-placeholder {
      color: $dark-gray;
    }
  }

  .order-summary {
    border: none;
    margin: 0;

    &__content {
      padding: 0;
      margin-bottom: $s1-5;
    }

    &__details {
      @include breakpoint(medium) {
        border-bottom: 3px solid $light-gray;
      }
    }
  }

  &__final-sale-warning {
    padding: $s2 0;
    font-style: italic;
    text-align: left;
    width: 100%;
    line-height: 1.75;
    font-size: 14px;

    @include breakpoint(medium) {
      text-align: center;
      padding: $s1 0 0;
    }
  }

  &__footer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    padding: $s1-5 $s2;

    @include breakpoint(medium) {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }

    &-total {
      @include df-h1;
      margin-right: $s2-5;
    }

    &-wrapper {
      margin-top: $s4;

      @include breakpoint(small down) {
        margin-top: #{-$s3};
        position: sticky;
        bottom: 0;
        background-color: $white;
      }
    }
  }

  button {
    width: 80%;

    @include breakpoint(medium) {
      width: 95%;
      margin-bottom: $s1-5;
    }
  }
}
