@import 'styles/_variables';
@import 'styles/typography/_mixins';
@import 'styles/_foundation';

.add-special-order-item-to-cart-error-modal {
  &__message {
    @include df-p1-medium;
    margin-bottom: $s3;
    margin-top: $s2;
    padding: 0 $s2-5;

    @include breakpoint(medium) {
      margin-top: $s2-5;
      padding: 0 $s4;
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    @include breakpoint(medium) {
      align-items: center;
    }
  }

  &__cancel-button {
    letter-spacing: 0.1px;
  }

  &__request-quote-button {
    margin-left: $s4;
    width: 182px;
  }
}
