@import 'styles/_foundation';
@import 'styles/_variables';

.cor-hero-container {
  $gradient: 255, 255, 255;
  position: relative;
  overflow: hidden;

  &__video-wrapper {
    z-index: -1;
    .cor-contentstack-video {
      z-index: -1;
    }
  }

  &__image {
    display: block;
    object-position: top center;
    object-fit: cover;
    height: 100%;
    width: 100%;
    aspect-ratio: 425 / 239;
    @include breakpoint($cor-desktop) {
      height: 100%;
    }
  }

  &--xlarge {
    @include breakpoint($cor-desktop) {
      height: 952px;
    }
  }

  &--large {
    @include breakpoint($cor-desktop) {
      height: 670px;
    }
  }

  &--medium {
    @include breakpoint($cor-desktop) {
      height: 540px;
    }
  }

  &--small {
    @include breakpoint($cor-desktop) {
      height: 450px;
    }

    .cor-hero-content {
      min-height: 270px;
      @include breakpoint($cor-desktop) {
        padding: 42px 60px;
      }
    }
  }

  &--inset {
    .cor-hero-content {
      position: absolute;
      top: 50%;
      left: 50%;
      align-items: center;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
    @include breakpoint($cor-desktop) {
      height: 590px;
      max-width: 1180px;
      margin: 40px auto;
      border-radius: 40px;
      overflow: hidden;
    }
    .cor-hero-container__image {
      min-height: 414px;
    }

    .cor-hero-container--overlay {
      display: block;
      position: absolute;
      height: 101%;
      width: 100%;
      left: 0;
      top: 0;
      z-index: 1;
    }
  }

  &--overlay {
    display: none;
    @include breakpoint($cor-desktop) {
      display: block;
      position: absolute;
      height: 101%;
      width: 100%;
      left: 0;
      top: 0;
      z-index: 1;
    }
  }

  &--overlay-right {
    @include breakpoint($cor-desktop) {
      background: rgb(42, 46, 53);
      background: linear-gradient(
        90deg,
        rgba(42, 46, 53, 0) 30%,
        rgba(42, 46, 53, 0.6376925770308124) 50%,
        rgba(42, 46, 53, 1) 100%
      );
    }
    &.cor-hero-container--overlay-color-light {
      @include breakpoint($cor-desktop) {
        background: rgb(42, 46, 53);
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 30%,
          rgba(255, 255, 255, 0.6376925770308124) 50%,
          rgba(255, 255, 255, 1) 100%
        );
      }
    }
  }

  &--overlay-left {
    @include breakpoint($cor-desktop) {
      background: rgb(42, 46, 53);
      background: linear-gradient(
        90deg,
        rgba(42, 46, 53, 1) 0%,
        rgba(42, 46, 53, 0.6376925770308124) 50%,
        rgba(42, 46, 53, 0) 70%
      );
    }
    &.cor-hero-container--overlay-color-light {
      @include breakpoint($cor-desktop) {
        background: rgb(42, 46, 53);
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 0.6376925770308124) 50%,
          rgba(255, 255, 255, 0) 70%
        );
      }
    }
  }

  &--overlay-center {
    &.cor-hero-container--overlay-inset {
      background: rgb(42, 46, 53);
      background: linear-gradient(
        90deg,
        rgba(42, 46, 53, 0) 5%,
        rgba(42, 46, 53, 0.95) 36%,
        rgba(42, 46, 53, 0.95) 66%,
        rgba(42, 46, 53, 0) 95%
      );
    }
    @include breakpoint($cor-desktop) {
      background: rgb(42, 46, 53);
      background: linear-gradient(
        90deg,
        rgba(42, 46, 53, 0) 5%,
        rgba(42, 46, 53, 0.95) 36%,
        rgba(42, 46, 53, 0.95) 66%,
        rgba(42, 46, 53, 0) 95%
      );
    }

    &.cor-hero-container--overlay-color-light {
      &.cor-hero-container--overlay-inset {
        background: rgb(42, 46, 53);
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 5%,
          rgba(255, 255, 255, 0.95) 36%,
          rgba(255, 255, 255, 0.95) 66%,
          rgba(255, 255, 255, 0) 95%
        );
      }
      @include breakpoint($cor-desktop) {
        background: rgb(42, 46, 53);
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 5%,
          rgba(255, 255, 255, 0.95) 36%,
          rgba(255, 255, 255, 0.95) 66%,
          rgba(255, 255, 255, 0) 95%
        );
      }
    }
  }
}
