.cor-featured-content__cards {
  &--no-carousel {
    display: grid;
    column-gap: 50px;
    grid-template-columns: repeat(3, minmax(50px, 1fr));
  }

  &--columns-4 {
    grid-template-columns: repeat(4, 1fr);
  }

  &--columns-5 {
    grid-template-columns: repeat(5, 1fr);
  }
}
