@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

.modify-your-order-modal {
  &__content {
    padding: 0 $s2-5;

    @include breakpoint(medium) {
      padding: 0 $s4;
    }
  }

  &__description {
    @include df-p1-medium;

    a {
      color: $red;
    }
  }

  &__note-text {
    @include df-p2-italic;
    display: flex;
    align-items: flex-start;
    margin-top: $s1;
    color: $dark-gray;

    &-icon {
      width: 18px;
      margin-right: $s1;
    }
  }

  .modal-footer {
    margin-top: $s3;
  }

  // TODO: remove this styles after RichTextContentstack component will be created
  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  del {
    text-decoration: line-through;
  }

  sup {
    vertical-align: super;
    font-size: smaller;
  }

  sub {
    vertical-align: sub;
    font-size: smaller;
  }

  ol {
    list-style: auto;
  }

  ul {
    list-style: disc;
  }

  ol,
  ul {
    margin: 0 $s3;
  }
}
