@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography';

.autopay-opt-out-modal {
  $autopay-opt-out-modal: &;
  overflow: visible;

  display: flex;
  flex-direction: column;

  &.modal__content {
    min-height: 260px;

    @include breakpoint(medium) {
      min-height: 300px;
    }
  }

  &__wrapper {
    @include df-p3-medium;
    padding: 0 $s2 $s0-5;
    height: 100%;

    @include breakpoint(medium) {
      @include df-p1-medium;
      padding: 0 $s4 5px;
    }
  }
  &__footer {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    .cor-button.modal-footer__cancel-button {
      @include df-p1-medium;
      letter-spacing: 0.2px;
      line-height: 19.2px;
    }

    .cor-button.modal-footer__save-button {
      height: 48px;
    }
  }
}
