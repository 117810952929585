@import 'styles/_variables';
@import 'styles/_foundation';

.cor-typography {
  font-weight: 400;

  &__h1 {
    font-size: 32px;
    line-height: 36px;

    @include breakpoint($cor-desktop) {
      font-size: 50px;
      line-height: 56px;
    }
  }

  &__h2 {
    font-size: 26px;
    line-height: 32px;

    @include breakpoint($cor-desktop) {
      font-size: 36px;
      line-height: 44px;
    }
  }

  &__h3 {
    font-size: 20px;
    line-height: 28px;

    @include breakpoint($cor-desktop) {
      font-size: 22px;
    }
  }

  &__h4 {
    font-size: 16px;
    line-height: 24px;
    @include breakpoint($cor-desktop) {
      font-size: 18px;
      line-height: 26px;
    }
  }

  &__h5-orange {
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.05em;
  }

  &__body-large {
    font-size: 16px;
    line-height: 28px;
  }

  &__body-regular,
  &__body-regular-link {
    font-size: 14px;
    line-height: 24px;
  }

  &__body-regular-link {
    text-decoration: underline;
  }

  &__body-small {
    font-size: 12px;
    line-height: 26px;
  }

  &__small-caps {
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.05em;
    line-height: 13.53px;
    text-transform: uppercase;
  }

  &__footer-headings {
    font-size: 16px;
    line-height: 19.2px;
  }

  &__button-label {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.008em;
    line-height: 24px;
  }

  &__primary-button-label {
    font-size: 14px;
    line-height: 16.8px;
  }

  &__secondary-button-label {
    font-size: 14px;
    line-height: 16.8px;
  }

  &__navigation-headings {
    font-size: 16px;
    line-height: 19px;
  }

  &__navigation-subheadings {
    font-size: 12px;
    line-height: 16px;
  }

  &__navigation-body-1 {
    font-size: 14px;
    line-height: 24px;
  }

  &__navigation-body-2 {
    font-size: 14px;
    line-height: 24px;
  }
}

body:not(.international-font) .cor-typography {
  font-family: $museo-500;

  &__h5-orange,
  &__body-large,
  &__body-regular,
  &__body-regular-link,
  &__body-small,
  &__small-caps,
  &__button-label,
  &__navigation-subheadings,
  &__navigation-body-1 {
    font-family: $frutiger-55-roman;
  }
}

body.international-font .cor-typography {
  font-family: $neue-frutiger-400;
  font-weight: 600;
}
