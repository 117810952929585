@import 'styles/_variables';

.cor-chip {
  & {
    background-color: $cor-white;
    border: 1px solid $cor-light-gray;
    border-radius: 100px;
    padding: 2px 20px;
    padding-right: 12px;
    display: flex;
  }

  &__close-button {
    cursor: pointer;
    width: 8.5px;
    padding: 0 8px;
    box-sizing: content-box;
  }
}
