@import 'styles/_variables';

.cor-filter-drawer-opener-button {
  & {
    background-color: $cor-white;
    padding: 14px 20px;
    border-radius: 6px;
    border: none;
    display: flex;
    column-gap: 27px;
    justify-content: space-between;
    font-size: 14px;
    font-family: $museo-500;
    color: $aubergine-gray;
  }

  &:not([disabled]) {
    cursor: pointer;
  }
}
