@import 'styles/_foundation';
@import 'styles/_variables.scss';
@import 'styles/typography';

.order-timestamp-modal {
  @include df-p2-medium;
  padding: $s2;

  &__text {
    display: block;
    width: max-content;

    .creation-date,
    .requested-date {
      padding-right: $s0-5;
    }
  }

  &__close-button {
    margin-top: $s3;
    width: 100%;

    &:hover {
      cursor: pointer;
    }
  }

  .modal__close-button {
    display: none;
  }
}
