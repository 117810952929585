.cor-footer-mobile__social-networks {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 13px;
  padding-bottom: 13px;

  .social-networks__logo {
    display: block;
    width: 100%;
  }

  .social-networks__logo,
  .social-networks__logo > * {
    max-width: 66px;
    height: auto;
  }

  .cor-footer__social-networks {
    img {
      max-width: 20px;
      max-height: 20px;
    }
  }
}
