@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

.product-variant-selector {
  $product-variant-selector: &;

  @include df-p1-medium;

  &__single-selectors {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $s1;

    @include breakpoint(medium) {
      margin-bottom: $s1-5;
    }
  }

  &__multiple-selectors {
    display: flex;
    flex-direction: column;
  }

  &__dropdown-wrapper {
    position: relative;
    width: 100%;
    margin-bottom: $s4;

    @include breakpoint(medium) {
      margin-bottom: 30px;
    }

    .select__control {
      height: 60px;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    }
    &:last-child {
      #{$product-variant-selector}__dropdown {
        &:after {
          display: none;
        }
      }
    }
  }

  &__dropdown-label {
    @include df-caption;
    margin-bottom: 19px;
    color: $dark-gray;

    @include breakpoint(medium) {
      margin-bottom: 18px;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}
