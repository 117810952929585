@import 'styles/_variables';
@import 'styles/_foundation';

.cor-two-column-details {
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1312px;
  gap: 1rem;
  margin: 0 auto;

  @include breakpoint($cor-desktop) {
    padding: 0;
    grid-template-columns: 1fr 725px;
    gap: 2rem;
  }
}
