@import 'styles/_variables';
@import 'styles/typography/_mixins';
@import 'styles/_foundation';

$desktopIconSize: 32px;
$mobileIconSize: 24px;

.modal-header {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
  padding: $s2-5;
  margin-bottom: $s2;

  @include breakpoint(medium) {
    padding: $s4;
    margin-bottom: $s2-5;
  }

  img {
    margin-right: $s2-5;
    width: $mobileIconSize;
    height: $mobileIconSize;

    @include breakpoint(medium) {
      width: $desktopIconSize;
      height: $desktopIconSize;
    }
  }

  &__title {
    display: flex;
  }

  &__title-text {
    @include df-h2;
    color: $black;
    padding-right: $s3;
    overflow-wrap: break-word;
    hyphens: auto;

    @include breakpoint(medium) {
      @include df-h3-bold;
    }
  }

  &__divider {
    border: none;
    width: 100%;
    height: 1px;
    background: $input-gray;
    margin: $s2 0 0 0;

    @include breakpoint(medium) {
      margin: $s2-5 0 0 0;
    }
  }
}
