@import 'styles/_variables';
@import 'corporate-ui/cor-typography/cor-typography';

.cor-filter-search-term {
  &__input {
    background-color: $cor-white;
    border-radius: 100px;
    height: 45px;
    padding: 11px 55px 10px 20px;
    color: $aubergine-gray;
    width: 100%;
    @extend .cor-typography__body-large !optional;

    &,
    &:hover,
    &:active,
    &:focus {
      border: none;
      outline: none;
    }

    &::placeholder {
      color: $mid-gray;
    }

    @include breakpoint($cor-desktop) {
      width: 257px;
    }
  }

  & > fieldset {
    position: relative;
  }

  &__search-button {
    height: 100%;
    background-color: transparent;
    border: none;
    position: absolute;
    padding: 0 20px;
    right: 0;
    cursor: pointer;
  }
}
