@import 'corporate-ui/cor-typography/cor-typography';
@import 'styles/_variables';
@import 'styles/mixins/typography';

.cor-footer-newsletter-modal {
  & {
    padding: 40px 30px;
    border-radius: 10px;

    @include breakpoint($cor-desktop) {
      padding: 40px 60px;
    }

    .modal__close-button {
      top: 24.5px;
      right: 24.5px;
    }
  }

  & > .cor-form {
    padding: 0;
    background-color: initial;
  }

  & .cor-form__title {
    text-align: center;
    margin-bottom: 25px;

    @include breakpoint($cor-desktop) {
      text-align: left;
    }
  }

  & .cor-form__description {
    text-align: left;
  }

  & .cor-form__container {
    margin: 0;
    padding: 0 !important;
  }

  & .cor-form__form {
    row-gap: 0;
    column-gap: 35px;
  }

  & .cor-submit-button {
    margin-top: 0;
  }

  &--submitted {
    visibility: hidden;
  }

  & .modal {
    visibility: initial;
  }
}

body:not(.international-font) .cor-footer-newsletter-modal {
  & .cor-form__title {
    @include breakpoint($cor-desktop) {
      @include font-museo-regular($size: 22px, $lineheight: 28px);
    }
  }
}

body.international-font .cor-footer-newsletter-modal {
  & .cor-form__title {
    @include breakpoint($cor-desktop) {
      @include font-neue-frutiger-regular($size: 22px, $lineheight: 28px);
    }
  }
}
