@import 'styles/_variables';

.cor-footer-mobile {
  &__menu-links--shop-products {
    & {
      background-color: #fff;
    }

    & > .cor-footer-mobile__menu-link:last-child {
      border-bottom: 1px solid #dbdbdb;
    }
  }

  &__menu-link__logged {
    padding-bottom: 25px;
    display: flex;
    flex-direction: column;
    font-size: 14px;

    .cor-credit-rep {
      border-top: 1px solid #e3e1e0;
    }

    .cor-credit-rep,
    .cor-sales-rep {
      padding: 13px $s2;

      .cor-expandable-section__inner {
        li:last-child {
          padding-bottom: $s1;
        }
      }
    }

    & > ul,
    & > ul ul {
      display: flex;
      flex-direction: column;
    }

    & > ul {
      row-gap: 30px;
      padding: 25px 0;

      &:first-of-type {
        row-gap: 20px;
      }

      &:last-of-type {
        border-top: 1px solid #e3e1e0;
        padding-bottom: 0;
      }
    }

    & > ul ul {
      row-gap: 20px;
      margin-top: 15px;
    }
  }

  &__customer-service {
    padding-left: 43px !important;
  }
}
