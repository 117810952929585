.custom-video {
  font-size: 0;

  video {
    width: 100%;
    height: auto;
  }

  &--youtube {
    > div {
      position: relative;
      padding-bottom: 56.25%;
    }
  }

  &__youtube-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
