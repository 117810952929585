@import 'styles/_foundation';
@import 'styles/_variables.scss';
@import 'styles/typography';

$short-account-status-badge-size: 24px;

.account-status-badge {
  font: 11px/1.45 $frutiger-65-bold;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  text-align: center;
  padding: 3px $s1 5px;
  border-radius: 12px;
  color: $white;
  height: 20px;
  width: 94px;

  @include breakpoint(medium) {
    height: 24px;
    width: 96px;
    padding: $s0-5 10px;
  }

  &--short {
    width: $short-account-status-badge-size;
    height: $short-account-status-badge-size;
    padding: 6px $s1;

    @include breakpoint(medium) {
      padding: $s0-5 $s1;
    }
  }

  &--locked {
    background: $yellow;
  }

  &--blocked {
    height: auto;
    color: $red;
    border: 1px solid $red;
    padding: 3px $s1-5;
    background-color: inherit;

    @include breakpoint(medium) {
      padding: $s0-5 $s1-5;
    }
  }

  &--unverified {
    background: $black;
  }
}
