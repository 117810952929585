@import 'styles/_foundation';
@import 'styles/typography';
@import 'styles/_variables';

.internal-user-header {
  $internal-user-header: &;
  $internal-user-header-top-row-height: 112px;
  $my-account-dropdown-width: 260px;

  @include breakpoint(medium) {
    border-bottom: 8px solid $red;
  }

  &__top-row {
    &-inner {
      display: flex;
      align-items: flex-end;
      flex-flow: row wrap;
      padding: $s2 0 0;

      @include breakpoint(medium) {
        height: auto;
        flex-flow: row nowrap;
        padding: $s4 0 $s2;
        margin: 0;

        > div {
          margin: 0 $s1-5;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  #{&}__controls {
    width: 100%;
    min-width: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
    order: 2;
    margin: $s1-5 (-$s2) 0;
    padding: $s1-5 $s2;
    background: $red;

    @include breakpoint(medium) {
      background: initial;
      margin: 0 $s4 0 $s1-5;
      order: 0;
      padding: 0;
    }
  }

  &__logo {
    flex-grow: 0;
    margin-right: auto;
    height: 45px;
    margin-bottom: 3px;

    img {
      height: 100%;
    }

    @include breakpoint(medium) {
      height: 50px;
      margin-bottom: 0;
    }
  }

  &__account-menu {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    flex-grow: 0;
  }

  #{&}__account-menu {
    width: 100%;

    @include breakpoint(medium) {
      width: auto;
    }

    .my-account-menu {
      width: 100%;

      @include breakpoint(medium) {
        width: $my-account-dropdown-width;
      }

      .select__value-container {
        color: $red;
      }

      &__option {
        padding: 0;

        .dropdown-menu-item {
          display: block;
          text-decoration: none;
          padding: $s1-5;

          &.active {
            color: $red;
          }
        }
      }

      &__dropdown {
        &--has-deleted-location {
          .select__single-value {
            color: $red;
            font-style: italic;
          }
        }
      }
    }
  }

  #{$internal-user-header}__navigation {
    display: flex;
    margin-left: 0;

    &-item {
      margin-left: $s1-5;

      @include breakpoint(medium) {
        margin-left: $s4;
      }

      a {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        text-decoration: none;

        #{$internal-user-header}__navigation-item-text {
          @include df-p2-bold;
          display: none;
          margin: $s1 0 0 $s1;
          white-space: nowrap;
          color: $red;

          @include breakpoint(1140px) {
            display: block;
          }
        }

        &:hover {
          #{$internal-user-header}__navigation-item-text {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &__icon-wrapper {
    position: relative;
    display: flex;
  }

  #{$internal-user-header}__navigation-item-counter {
    position: absolute;
    right: -2px;
    top: -3px;
    padding: 3px $s0-5 1px;
    border: 1px solid $red;
    border-radius: $s2-5;
    background-color: $white;
    font-size: 10px;
    color: $red;
  }

  #{&}__sharepoint-link {
    @include df-p2-bold;
    white-space: nowrap;
    height: $s5;
    line-height: $s5;
    color: $red;
    margin: 0 2px 0 auto;

    @include breakpoint(medium) {
      margin: 0;
    }
  }
}
