@import 'styles/_foundation';
@import 'styles/_variables';

.cor-featured-content__cards-mobile {
  &.grid-container {
    padding-right: 2rem;
  }

  .swiper-slide {
    height: auto;
    width: initial;
  }

  .swiper {
    overflow: initial;
    z-index: 0;

    @include breakpoint($cor-desktop) {
      overflow: hidden;
    }
  }

  .swiper-slide > .cor-featured-content__card--small-size {
    max-width: 41vw;
  }

  .swiper-slide > .cor-featured-content__card--medium-size {
    max-width: 85vw;
  }
}
