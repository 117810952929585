@import 'styles/_variables';
@import 'styles/_foundation';

.cor-hero-carousel {
  .swiper-pagination {
    @include breakpoint($cor-desktop) {
      display: none;
    }
  }

  .swiper-pagination-bullet {
    background: $red;
    &.swiper-pagination-bullet-active {
      background: $red;
    }
  }
}
