@import 'styles/_variables';
@import 'styles/_foundation';
@import '/src/styles/mixins/typography';

.utility-menu {
  $gap-mobile: 20px;
  $gap-desktop: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: $gap-mobile;

  @include breakpoint(medium) {
    gap: $gap-desktop;
  }

  &__items {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: $gap-mobile;

    @include breakpoint(medium) {
      gap: $gap-desktop;
    }
  }

  &__search-field {
    & > .search-field__input {
      border: 1px solid $dark-gray;
      max-height: 36px;
      border-radius: 100px;
      padding-top: 6px;
      padding-bottom: 4px;
    }
    & > .search-field__button {
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      width: 18px;
    }
  }

  &__shop-button {
    @include font-frutiger-bold($size: 16);
    display: none;
    max-height: 45px;
    min-width: 100px;
    @include breakpoint(medium) {
      display: block;
    }
  }

  &__user-dropdown {
    display: none;
    @include breakpoint(medium) {
      display: block;
    }
    .menu-dropdown__label {
      display: inline-block;
      max-width: 114px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  #{&}__shop-btn {
    padding: 5px 9px;
    text-transform: uppercase;
    font: 12px/1.43 $frutiger-65-bold;
    letter-spacing: 0.8px;
  }
}
