@import 'styles/_variables';

.cor-share-button {
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;

  svg {
    path {
      fill: $aubergine-gray;
    }
  }
}
