@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

.edit-shopping-list-name-modal {
  &__content {
    border-radius: 4px;
  }

  &__form-fields {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $s2;
  }

  &__field {
    width: 100%;
    padding: $s2-5 $s2;
    margin: 0;

    @include breakpoint(medium) {
      padding: $s3 $s4;
    }
  }

  .text-field__input-group {
    margin-top: $s1;
  }

  &__save-button {
    padding: $s1-5 $s4;
  }

  &__form-container {
    padding: 0 $s2-5;

    @include breakpoint(medium) {
      padding: 0 $s4;
    }
  }
}
