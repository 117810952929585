@import 'styles/_variables';
@import 'styles/typography/_mixins';
@import 'styles/_foundation';

.success-error-message-modal {
  &__text {
    @include df-p1-medium;
    margin-bottom: $s4;
  }

  #{&}__close {
    display: block;
    margin-top: $s2-5;
    margin-left: auto;
    width: 100%;
    border-radius: 8px;

    @include breakpoint(medium) {
      width: 120px;
    }
  }

  &__body {
    @include df-p1-medium;
    padding: 0 $s2-5;
    margin-bottom: $s2-5;

    @include breakpoint(medium) {
      padding: 0 $s4;
    }
  }

  &--no-close-button {
    .modal__close-button {
      display: none;
    }
  }

  &__header-title-only {
    box-shadow: none;
  }
}
