@import 'styles/_variables';
@import 'styles/_foundation';
@import 'styles/typography/_mixins';

.internal-user-view-quote-modal {
  $internal-user-view-quote-modal: &;
  color: $black;

  &__body {
    padding: 0 $s2;

    @include breakpoint(medium) {
      padding: 0 $s4;
    }
  }

  &-messages {
    margin-bottom: $s4;

    > *:not(:last-child) {
      margin-bottom: $s1-5;

      @include breakpoint(medium) {
        margin-bottom: $s2-5;
      }
    }
  }

  &__message {
    @include df-p2-medium;

    @include breakpoint(medium) {
      @include df-p1-medium;
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: $s2-5 0 $s2-5 0;

    #{$internal-user-view-quote-modal}--short-quote-info & {
      justify-content: flex-start;
    }

    @include breakpoint(medium) {
      margin: $s2-5 0 $s3 0;
    }

    &-label {
      @include df-caption;
      color: $dark-gray;
      display: block;
      margin-bottom: $s1;

      &.info-label-valid-until,
      &.info-label-price {
        display: flex;
        align-items: center;

        .custom-tooltip {
          margin-left: $s0-5;
          margin-top: -6px;
        }
      }
    }

    &-input {
      max-width: 140px;
    }

    &-block {
      @include breakpoint(small down) {
        width: 50%;
      }

      @include breakpoint(medium) {
        #{$internal-user-view-quote-modal}--short-quote-info & {
          margin-right: $s2-5;
        }
      }

      &-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: $s2 0;

        @include breakpoint(medium) {
          width: initial;
          margin: 0;
        }
      }
    }

    &-value {
      .text-field__input {
        padding: 7px $s2;
      }
    }

    &-warning {
      padding: $s2;
      border-radius: 4px;
      border: 1px solid $red;
      background: $light-coral;
    }
  }

  .info-block__price,
  .info-block__valid-until {
    .text-field__input-group {
      position: relative;
    }

    .currency-symbol,
    img {
      position: absolute;
      width: 24px;
      height: 24px;
      top: 8px;
      left: 10px;
    }

    .currency-symbol {
      font-size: 20px;
      color: $dark-gray;
      top: 10px;
    }

    .text-field__input {
      padding-left: 37px;
    }
  }

  .info-block__price {
    @include breakpoint(medium) {
      margin-right: $s1;
    }
  }

  &__estimated-delivery {
    min-width: 230px;

    @include breakpoint(small down) {
      width: 100%;
    }

    .select-field .select-field__control {
      .select-field__icon img {
        visibility: visible;
      }

      &.select-field__control--is-disabled .select-field__icon {
        background: url('../../../assets/images/icons/icon-calendar-disabled.svg') no-repeat;

        img {
          visibility: hidden;
        }
      }
    }
  }

  .modal-footer {
    .cor-button.secondary {
      min-width: 144px;
    }
  }

  .quantity-control {
    &__input {
      height: auto;
    }
  }

  &__customer-message {
    margin-top: $s2-5;
  }

  &__button:last-child {
    margin-left: $s4;
  }

  .info-label-valid-until {
    .custom-tooltip__content {
      width: auto;
    }
  }
}
