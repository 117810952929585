@import 'styles/_variables';
@import 'styles/typography/_mixins';
@import 'styles/_foundation';

.select-location-user-modal {
  $select-location-user-modal: &;

  &__heading {
    @include df-h2-mobile;
    color: $black;
    padding: 0 $s2-5 $s3 0;
    border-bottom: 1px solid $input-gray;
    margin-bottom: $s2;

    @include breakpoint(medium) {
      @include df-h3-bold;
      padding-bottom: $s2;
    }
  }

  &__content {
    max-height: 400px;
    overflow-y: auto;
    padding-right: 10px;
  }

  &__group {
    &:not(:last-child) {
      margin-bottom: $s7;
    }
  }

  &__group-label {
    @include df-p2-bold;
    margin-bottom: 10px;
    text-transform: none;
    color: $black;
  }

  &__option-label {
    @include df-p2-medium;
    display: flex;
    align-items: center;
    padding: 10px 0;

    .account-status-badge {
      margin-left: auto;
    }

    &.active {
      background: $light-coral;
    }
  }

  &__done-button {
    margin-top: $s3;
    width: 100%;
  }
}
