@import 'styles/_foundation';
@import 'styles/typography/_mixins';
@import 'styles/_variables';

.locked-account-message-modal {
  $icon-size: 32px;

  .modal-header {
    &__title {
      display: flex;
      align-items: center;
    }

    img {
      height: $icon-size;
      width: $icon-size;
      margin-right: 14px;

      @include breakpoint(medium) {
        margin-right: 17px;
      }
    }
  }

  &__description {
    @include df-p2-medium;
    padding: 0 $s2-5;

    @include breakpoint(medium) {
      @include df-p1-medium;
      padding: 0 $s4;
    }
  }

  &__buttons {
    display: flex;
    flex-flow: column;
    flex-direction: column-reverse;
    width: 100%;

    @include breakpoint(medium) {
      flex-flow: row;
      justify-content: flex-end;
    }

    .cor-button {
      margin: 6px;
      width: 100%;

      @include breakpoint(medium) {
        margin: $s1-5;
      }
    }
  }
}
