@import 'styles/_variables';
@import 'styles/_foundation.scss';

.cor-video-component {
  margin: 60px 0;

  @include breakpoint(medium) {
    margin: 80px 0;
  }
}
