@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

.next-order-message {
  $next-order-message: &;

  @include df-h4-medium;
  font-size: 14px;
  margin: 0 $s2;
  border: 1px solid $orange;
  background-color: $light-yellow;
  box-shadow: 0.6px 0.8px 18px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 10px 22px 10px 11px;
  color: $black;

  @include breakpoint(medium) {
    padding: $s2-5 $s2;
    margin: 0;
  }

  &--full-size {
    height: 100%;
    align-items: center;
    text-align: center;
    padding: 0 $s5;
    margin-bottom: 0;

    #{$next-order-message}__icon {
      width: 80px;
      height: 80px;
      margin-right: 0;
      margin-bottom: $s3;
    }

    #{$next-order-message}__title-wrapper {
      flex-direction: column;
    }

    #{$next-order-message}__title {
      font-size: 20px;
    }
  }

  &__title-wrapper {
    display: flex;
    align-items: center;
  }

  &__icon {
    width: 40px;
    height: 40px;
    margin-right: $s2;
    flex: 1 0 auto;

    @include breakpoint(medium) {
      width: 56px;
      height: 56px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  &__title {
    font: 13px/1.6 $museo-500;
    letter-spacing: 0.5px;

    @include breakpoint(medium) {
      font: 18px/1.33 $museo-500;
      letter-spacing: 0.2px;
    }
  }

  &__due-date {
    font-weight: bold;
  }
}
