@import 'styles/_variables';
@import 'styles/_foundation';

.sticky-view {
  $sticky-view: &;
  z-index: $sticky-view-z-index;

  &--sticky {
    #{$sticky-view}__content {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: $sticky-view-z-index;
    }
  }
}
