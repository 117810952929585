@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

$iconSize: 23px;

.news-section {
  $news-section: &;

  position: relative;
  flex: 1;
  overflow: hidden;
  margin-top: $s3-5;
  padding: 0 $s2;

  @include breakpoint(medium) {
    margin-top: 0;
    padding: 0;
  }

  &__title-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-bottom: 26px;

    @include breakpoint(medium) {
      margin-bottom: $s3;
    }
  }

  &__title {
    font: 20px/1.25 $museo-700;
    letter-spacing: 0.1px;
    color: $black;

    @include breakpoint(medium) {
      line-height: 1.4;
    }
  }

  &__item {
    padding-bottom: $s2;
    border-bottom: 1px solid $input-gray;

    &:last-child {
      border-bottom: none;

      @include breakpoint(medium) {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }

  &__item,
  &__selected-item {
    display: flex;
    margin-bottom: $s2;
  }

  &__selected-item {
    flex-direction: column;
  }

  &__item-title-section {
    display: flex;
  }

  &__item-image {
    width: $iconSize;
    height: $iconSize;
    flex: 0 0 auto;
    margin-right: $s0-5;

    @include breakpoint(medium) {
      margin-right: $s1;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  &__item-content {
    a {
      text-decoration: none;
      color: $black;
    }
  }

  &__item-title {
    @include df-p1-bold;
    margin-bottom: $s0-5;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    img {
      width: $iconSize;
      height: $iconSize;
      margin-right: $s0-5;
      vertical-align: bottom;
    }

    @include breakpoint(medium) {
      -webkit-line-clamp: 1;
    }
  }

  &__item-title-link {
    @extend .news-section__item-title;
    letter-spacing: 0;
    text-decoration: none;

    @include breakpoint(medium) {
      &:hover {
        text-decoration: underline;
      }
    }

    a {
      text-decoration: none;
    }
  }

  &__item-title-expanded {
    @extend .news-section__item-title;
    letter-spacing: 0;
    margin-bottom: 2px;
    word-break: break-word;

    @include breakpoint(medium) {
      margin-bottom: $s3;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
  }

  &__item-title-link-expanded {
    @extend .news-section__item-title-expanded;

    &:hover {
      text-decoration: underline;
    }
  }

  &__item-description {
    @include df-p1-medium;
    color: $dark-gray;
    position: relative;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow-y: hidden;

    > div {
      padding-right: 50px;

      > * {
        display: inline;
      }
    }

    a {
      text-decoration: underline;
      color: $black;

      &:hover {
        opacity: 0.85;
      }
    }

    &--expanded {
      .news-section__item-description {
        padding-right: 0;

        > div {
          padding-right: 0;
        }
      }
    }
  }

  &__hide_button {
    @include df-p1-bold;
    display: block;
    text-align: right;
    width: 100%;
    margin-top: 19px;
    padding-right: 0;
    background: none;
    border: none;
    text-decoration: underline;
    color: $red;

    @include breakpoint(medium) {
      margin-bottom: 0;

      &:hover {
        cursor: pointer;
        color: $light-red;
      }
    }
  }

  &__expand-button {
    @include df-p1-bold;
    position: absolute;
    bottom: 0;
    right: 0;
    border: 0;
    background: none;
    color: $red;
    padding: 0;
    text-decoration: underline;

    &.hidden {
      display: none;
    }

    &:hover {
      cursor: pointer;
      color: $light-red;
    }
  }

  &.item-selected {
    overflow: visible;

    #{$news-section} {
      &__title-wrapper {
        margin-bottom: $s1;
      }

      &__item {
        height: 100%;
        background-color: $white;
        border: 1px solid $red;
        box-shadow: 0.6px 0.8px 18px rgba(0, 0, 0, 0.08);
        border-radius: 4px;
        margin: 0;
        padding: 15px 11px 6px;

        @include breakpoint(medium) {
          margin: 0 -13px;
        }
      }

      &__item-description {
        -webkit-line-clamp: unset;
      }

      &__expand-button {
        display: none;
      }

      &__item-content {
        @include breakpoint(medium) {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }

      &__item-title-expanded,
      &__item-title-link-expanded {
        margin-bottom: $s0-5;
      }
    }
  }

  &__more-notifications-button {
    @include df-p2-bold;
    font-size: 16px;
    display: flex;
    justify-content: flex-end;
    flex: 1 0 auto;
    color: $red;

    img {
      width: 8px;
      margin-left: 7px;
    }
  }
}
