@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

.remove-items-from-cart-confirm-modal {
  $remove-items-from-cart-confirm-modal: &;

  &__heading {
    @include df-h2-mobile;
    padding-bottom: $s2;
    padding-right: $s2-5;
    margin-bottom: $s4;
    border-bottom: 1px solid $input-gray;
    color: $black;

    @include breakpoint(medium) {
      font: 23px/1.4 $museo-700;
      letter-spacing: 0.8px;
      padding-bottom: $s2-5;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }

  .modal__close-button {
    top: 14px;
    right: 18px;

    @include breakpoint(medium) {
      top: 22px;
      right: 33px;
    }

    &:before,
    &:after {
      height: 25px;
    }
  }

  #{$remove-items-from-cart-confirm-modal}__cancel-button {
    font-family: $frutiger-55-roman;
    letter-spacing: 0.4px;
    padding: 11px 31px;
  }

  #{$remove-items-from-cart-confirm-modal}__remove-all-button {
    padding: 11px 46px;
  }
}
