@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

.delete-shopping-list-modal {
  &__footer {
    box-shadow: none;
  }
  .modal-header {
    margin-bottom: $s4;

    &__title-text {
      color: $black;
    }
  }
}
