@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

.environment-notification {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: $environment-notification-background;
  color: $white;
  font: 12px/1.5 $frutiger-65-bold;
  padding: 14px 0;

  @include breakpoint(medium) {
    font: 16px/19px $frutiger-65-bold;
  }
}

.sticky-view--sticky {
  .environment-notification {
    padding: 5px 0;

    &__text {
      display: none;
    }
  }
}
