@import 'styles/_variables';
@import 'styles/_foundation';

.cor-detail-hero-banner {
  & {
    background-color: $cor-bg-gray-lightest;
    padding-bottom: 34px;

    @include breakpoint($cor-desktop) {
      padding-bottom: 25px;
      padding-top: 30px;
    }
    @media print {
      padding-bottom: 25px;
      padding-top: 30px;
    }
  }

  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 1164px;
    margin: 0 auto;

    @include breakpoint($cor-desktop) {
      background-color: $cor-white;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border-radius: 20px;
      box-shadow: 0 0 30px #ece9e8;
      overflow: hidden;
    }
    @media print {
      background-color: $cor-white;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border-radius: 20px;
      box-shadow: 0 0 30px #ece9e8;
      overflow: hidden;
    }
  }

  &__text-content {
    position: relative;
    border-radius: 10px;
    width: 90%;
    margin: 0 auto;
    padding: 2rem 30px;
    box-shadow: 0 0 30px #ece9e8;
    order: 1;
    top: -24px;
    background: $white;

    @include breakpoint($cor-desktop) {
      border-radius: 0;
      width: auto;
      box-shadow: none;
      padding: 40px 79px;
      order: 0;
      top: 0;
    }
    @media print {
      border-radius: 0;
      width: auto;
      box-shadow: none;
      padding: 40px 79px;
      order: 0;
      top: 0;
    }
  }

  &__heading {
    text-align: center;
    margin-bottom: 22px;

    @include breakpoint($cor-desktop) {
      text-align: left;
      margin-bottom: 25px;
    }
    @media print {
      text-align: left;
      margin-bottom: 25px;
    }
  }

  &__description {
    text-align: center;
    margin-bottom: 30px;

    @include breakpoint($cor-desktop) {
      text-align: left;
    }
    @media print {
      text-align: left;
    }
  }

  &__date-author {
    text-align: center;

    @include breakpoint($cor-desktop) {
      text-align: left;
    }
    @media print {
      text-align: left;
    }
  }

  &__graphic {
    order: 0;

    @include breakpoint($cor-desktop) {
      order: 1;
    }
    @media print {
      order: 1;
    }
  }

  &__tags {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 6px;

    @include breakpoint($cor-desktop) {
      justify-content: flex-start;
      margin-bottom: 10px;
    }
    @media print {
      justify-content: flex-start;
      margin-bottom: 10px;
    }
  }

  &__tag {
    text-align: center;

    &:not(:last-child) {
      margin-right: 0.75rem;
    }

    @include breakpoint($cor-desktop) {
      text-align: left;
    }
    @media print {
      text-align: left;
    }
  }

  &__image {
    display: block;
    aspect-ratio: 1/1;
    object-fit: cover;
    object-position: center;
    width: 100%;

    @include breakpoint($cor-desktop) {
      height: auto;
      width: 580px;
    }
    @media print {
      height: auto;
      width: 580px;
    }
  }

  &__icons-titles {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    padding: 0;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 30px;

    @include breakpoint($cor-desktop) {
      justify-content: flex-start;
    }
    @media print {
      justify-content: flex-start;
    }
  }

  &__icon-title {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__icon {
    height: 20px;
    width: 20px;
    object-position: center;
    object-fit: contain;
  }
}
