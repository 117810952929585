@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

.product-group-selector {
  $product-group-selector: &;

  &__dropdown {
    @include breakpoint(medium) {
      margin-bottom: $s1;
    }
  }

  &__label {
    @include df-p2-medium;
    color: $black;
    margin-bottom: $s1;

    @include breakpoint(medium) {
      @include df-p1-medium;
      margin-bottom: $s1-5;
    }
  }

  .product-selector-button {
    margin: 0;
    width: 32%;

    &--active {
      pointer-events: auto;
    }

    &:not(:last-child) {
      margin-right: $s1;
    }

    @include breakpoint(medium) {
      width: 30%;
      max-width: 112px;
      margin: 0 0 $s1 0;

      &:not(:last-child) {
        margin-right: $s1-5;
      }
    }
  }

  &__selector-block {
    margin-bottom: 28px;

    &:last-child {
      margin-bottom: 41px;
    }

    @include breakpoint(medium) {
      margin-bottom: $s3;

      &:last-child {
        margin-bottom: 46px;
      }
    }
  }

  .select__menu {
    z-index: $base-z-index;
  }
}
