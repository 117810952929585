@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';
@import 'styles/mixins/typography';

$counter-size: 15px;
$counter-color: $cor-dawn-red;

.header-icon {
  width: 100%;
  height: 100%;
  text-align: center;
  position: relative;

  img {
    max-height: 100%;
    max-width: 100%;
    height: 100%;
  }

  &__counter {
    display: block;
    background-color: $white;
    position: absolute;
    text-align: center;
    border: 2px solid $counter-color;
    border-radius: $counter-size;
    height: $counter-size;
    min-width: $counter-size;
    top: 0;
    right: -5px;

    span {
      @include font-frutiger-regular($size: 9px, $lineheight: null, $spacing: 0);
      color: $counter-color;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.header-icon.notification-counter {
  .header-icon__counter {
    border: 2px solid $light-orange;

    span {
      color: $light-orange;
    }
  }
}

.is-cart .header-icon__counter {
  top: -1px;
  left: 50%;
  right: auto;
  transform: translateX(-25%);

  @include breakpoint(medium) {
    top: 0;
  }
}
