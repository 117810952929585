@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

.custom-radio-button {
  $custom-radio-button: &;
  $button-size: 25px;

  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: $s2-5;

  &__label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    line-height: 1.43;

    @include breakpoint(medium) {
      @include df-p1-medium;
    }

    &--disabled {
      color: $input-gray;
    }
  }

  &__checkmark {
    position: relative;
    width: 20px;
    height: 20px;
    margin-right: $s1-5;
    border-radius: 50%;
    border: 1px solid $input-gray;
    flex: 0 0 auto;
    cursor: pointer;
    outline: none;

    @include breakpoint(medium) {
      margin-top: 1px;
    }
  }

  &__input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;

    &:hover {
      & ~ #{$custom-radio-button}__checkmark {
        border-color: $red;
      }
    }

    &:checked {
      & ~ #{$custom-radio-button}__checkmark {
        border-color: $red;

        &:after {
          position: absolute;
          content: '';
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background: $red;
        }
      }
    }

    &:disabled {
      & ~ #{$custom-radio-button}__checkmark {
        border: none;
        background: $input-gray;
      }
    }
  }
}
