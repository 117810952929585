@import 'styles/_variables';
@import 'styles/_foundation';
@import 'styles/typography/_mixins';

.cor-expandable-section {
  $expandable-section: &;

  border-bottom: 1px solid $light-gray;
  padding-right: $s2-5;

  @include breakpoint(medium) {
    padding-right: 0;
  }

  &:last-child {
    border: none;
  }

  &__title {
    @include df-p3-medium;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $mid-gray;
    cursor: pointer;
    padding: $s1-5 0;
    gap: $s1-5;

    @include breakpoint(medium) {
      padding: $s2 0 $s1-5;
    }
  }

  &__inner {
    overflow: hidden;
    max-height: 0;

    #{$expandable-section}--expanded & {
      max-height: none;
    }
  }

  #{$expandable-section} {
    &__inner-enter {
      max-height: 0;
    }

    &__inner-enter-done {
      max-height: 5000px;
      transition: all 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    }

    &__inner-exit {
      max-height: 5000px;
    }

    &__inner-exit-done {
      max-height: 0;
      transition: all 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    }
  }

  &--without-animation {
    #{$expandable-section} {
      &__inner {
        overflow: visible;
      }

      &__inner-enter-done,
      &__inner-exit-done {
        max-height: none;
        transition: none;
      }
    }
  }
}
