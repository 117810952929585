@import 'styles/_foundation';
@import 'styles/_variables';

.cor-featured-content__card-faq {
  & {
    display: flex;
    flex-direction: column;
    max-width: initial;

    @include breakpoint($cor-desktop) {
      max-width: 572px;
    }
  }

  &__category {
    margin-bottom: 11px;

    @include breakpoint($cor-desktop) {
      margin-bottom: 4px;
    }
  }

  &__link {
    margin-bottom: 20px;
  }

  p {
    margin-top: 0;
  }

  &__description {
    text-align: left;
  }
}
