@import 'styles/_variables';
@import 'styles/_foundation';
@import '/src/styles/mixins/typography';

.main-subnav-content {
  &__highlights {
    display: none;
    @include breakpoint(medium) {
      display: block;
      min-width: 300px;
    }

    &__list {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
    }

    .highlight-panel {
      @include font-museo-regular($size: 14, $lineheight: 22px);
      text-decoration: none;
      padding: 15px;
      background: $cultured;
      border-radius: 8px;
      display: flex;
      align-items: center;
      column-gap: 10px;
      justify-content: flex-start;
      min-height: 90px;

      &:hover {
        background: darken($cultured, 2%);
      }

      &--center {
        justify-content: center;
      }
    }

    .highlight-image {
      &--cover {
        > div {
          width: 70px;
          padding-bottom: 100%;
          position: relative;
          overflow: hidden;
          border-radius: 4px;

          img {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
            height: 100%;
          }
        }
      }

      &--icon {
        > div {
          width: 25px;
          position: relative;

          img {
            width: 100%;
          }
        }
      }
    }

    .highlight-title {
      text-align: left;
      span {
        display: block;
      }

      &__main {
        @include font-frutiger-regular($size: 12, $lineheight: 16px);
        color: $mid-gray;
        text-transform: uppercase;
        margin-bottom: 5px;
      }

      &__sub {
        color: $aubergine-gray;
      }
    }
  }
}

body:not(.international-font) .main-subnav-content {
  &__highlights .highlight-title {
    &__sub {
      @include font-museo-regular($size: 14, $lineheight: 24px);
    }
  }
}

body.international-font .main-subnav-content {
  &__highlights .highlight-title {
    &__sub {
      @include font-neue-frutiger-regular($size: 14, $lineheight: 24px);
    }
  }
}
