body {
  font-family: $frutiger-55-roman;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.international-font {
  font-family: $neue-frutiger-400;
}

.not-scrollable {
  overflow: hidden;

  .modal-wrapper-enter-done {
    touch-action: none;
    -ms-touch-action: none;
  }
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}
