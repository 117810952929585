@import 'styles/_variables';

.cor-footer-desktop__legal {
  & {
    border-top: 1px solid #e3e2e1;
  }

  & > .grid-container {
    display: flex;
    width: 100%;
    column-gap: 55px;
    padding-top: 20px;
    padding-bottom: 20px;
    align-items: center;
  }

  .legal__links {
    display: flex;
    column-gap: 45px;
  }

  .cor-footer__social-networks {
    img {
      max-width: 20px;
      max-height: 20px;
    }
  }
}
