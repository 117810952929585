@import 'styles/_variables';
@import 'styles/typography/_mixins';
@import 'styles/_foundation';

.sort-customer-locations-options-mobile-modal {
  &__filter-button {
    display: flex;
    align-items: center;
    -webkit-appearance: none;
    color: #0d0d0d;
    font: 14px/1.71 $frutiger-55-roman;
    border: 1px solid $input-gray;
    border-radius: 8px;
    background-color: $white;
    padding: $s1 7px $s1 10px;
    justify-content: space-between;
    height: 40px;

    @include breakpoint(medium) {
      width: 100%;
    }

    span {
      @include df-p2-medium;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @include breakpoint(medium) {
        @include df-p1-medium;
      }
    }

    img {
      margin-top: -2px;
      margin-left: $s0-5;
    }
  }

  &__sort-modal {
    border-radius: 4px;

    .modal__close-button {
      &:before,
      &:after {
        height: 26px;
      }
    }

    .cor-button {
      width: 100%;
    }
  }

  &__sort-modal-body {
    padding: $s2 $s2-5 $s2-5;

    .radio-button {
      margin-bottom: $s2-5;
    }
  }

  &__sort-modal-heading {
    @include df-h2-mobile;
    color: $black;
  }
}
