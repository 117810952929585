@import 'styles/_variables';
@import 'styles/_foundation.scss';

.page-layout {
  color: $black;
  min-height: 100vh;

  &__header {
    z-index: $page-header-z-index;
  }

  &__main {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    min-height: $mobile-main-page-height;

    @include breakpoint(medium) {
      min-height: $desktop-main-page-height;
    }
  }

  &__footer {
    margin-top: auto;
  }
}
