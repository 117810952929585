@import 'styles/_variables';
@import 'styles/mixins/_typography';

/** The structure of the config map is:
{element-tag}:
  {property-name}: [mobile-value, desktop-value] ==> for differing values
  {property-name}: value ==> can be used if both mobile and desktop values are the same

Additional properties can be added as needed.
*/

/** BASE CONFIG

values should match base styleguide
 */
$base: (
  'h1': (
    'face': museo,
    'weight': 400,
    'size': (
      32px,
      50px,
    ),
    'lineheight': (
      2.25rem,
      3.5rem,
    ),
    'spacing': initial,
    'margin': (
      10px,
      20px,
    ),
    'color': #{$aubergine-gray},
  ),
  'h2': (
    'face': museo,
    'weight': 400,
    'size': (
      26px,
      36px,
    ),
    'lineheight': (
      2rem,
      2.75rem,
    ),
    'spacing': initial,
    'margin': 10px,
    'color': #{$aubergine-gray},
  ),
  'h3': (
    'face': museo,
    'weight': 400,
    'size': (
      20px,
      22px,
    ),
    'lineheight': 1.75rem,
    'spacing': initial,
    'margin': 10px,
    'color': #{$aubergine-gray},
  ),
  'h4': (
    'face': museo,
    'weight': 700,
    'size': (
      16px,
      18px,
    ),
    'lineheight': (
      1.5rem,
      1.625rem,
    ),
    'spacing': initial,
    'margin': 10px,
    'color': #{$aubergine-gray},
  ),
  'h5': (
    'face': frutiger,
    'weight': 700,
    'size': 12px,
    'lineheight': 0.9375rem,
    'spacing': initial,
    'margin': 10px,
    'color': #{$cor-dawn-orange},
  ),
  'h6': (
    'face': museo,
    'weight': 400,
    'size': (
      16px,
      18px,
    ),
    'lineheight': (
      1.5rem,
      1.625rem,
    ),
    'spacing': initial,
    'margin': 10px,
  ),
  'p': (
    'face': frutiger,
    'weight': 400,
    'size': (
      14px,
      16px,
    ),
    'lineheight': 1.75rem,
    'spacing': initial,
    'margin': 10px,
    'color': #{$mid-gray},
  ),
  'span': (
    'face': frutiger,
    'weight': 400,
    'size': 16px,
    'lineheight': 1.75rem,
    'spacing': initial,
    'margin': 10px,
    'color': #{$mid-gray},
  ),
  'li': (
    'face': frutiger,
    'weight': 400,
    'size': 14px,
    'spacing': initial,
    'margin': 10px,
    'color': #{$mid-gray},
  ),
  'ol': (
    'face': neueFrutiger,
    'weight': 400,
    'size': 14px,
    'spacing': initial,
    'margin': 10px,
    'color': #{$mid-gray},
  ),
);

$neue-base: (
  'h1': (
    'face': neueFrutiger,
    'weight': 400,
    'size': (
      32px,
      50px,
    ),
    'lineheight': (
      2.25rem,
      3.5rem,
    ),
    'spacing': initial,
    'margin': (
      10px,
      20px,
    ),
    'color': #{$aubergine-gray},
  ),
  'h2': (
    'face': neueFrutiger,
    'weight': 400,
    'size': (
      26px,
      36px,
    ),
    'lineheight': (
      2rem,
      2.75rem,
    ),
    'spacing': initial,
    'margin': 10px,
    'color': #{$aubergine-gray},
  ),
  'h3': (
    'face': neueFrutiger,
    'weight': 400,
    'size': (
      20px,
      22px,
    ),
    'lineheight': 1.75rem,
    'spacing': initial,
    'margin': 10px,
    'color': #{$aubergine-gray},
  ),
  'h4': (
    'face': neueFrutiger,
    'weight': 700,
    'size': (
      16px,
      18px,
    ),
    'lineheight': (
      1.5rem,
      1.625rem,
    ),
    'spacing': initial,
    'margin': 10px,
    'color': #{$aubergine-gray},
  ),
  'h5': (
    'face': neueFrutiger,
    'weight': 700,
    'size': 12px,
    'lineheight': 0.9375rem,
    'spacing': initial,
    'margin': 10px,
    'color': #{$cor-dawn-orange},
  ),
  'h6': (
    'face': neueFrutiger,
    'weight': 400,
    'size': (
      16px,
      18px,
    ),
    'lineheight': (
      1.5rem,
      1.625rem,
    ),
    'spacing': initial,
    'margin': 10px,
  ),
  'p': (
    'face': neueFrutiger,
    'weight': 400,
    'size': (
      14px,
      16px,
    ),
    'lineheight': 1.75rem,
    'spacing': initial,
    'margin': 10px,
    'color': #{$mid-gray},
  ),
  'span': (
    'face': neueFrutiger,
    'weight': 400,
    'size': 16px,
    'lineheight': 1.75rem,
    'spacing': initial,
    'margin': 10px,
    'color': #{$mid-gray},
  ),
  'li': (
    'face': neueFrutiger,
    'weight': 400,
    'size': 14px,
    'spacing': initial,
    'margin': 10px,
    'color': #{$mid-gray},
  ),
  'ol': (
    'face': neueFrutiger,
    'weight': 400,
    'size': 14px,
    'spacing': initial,
    'margin': 10px,
    'color': #{$mid-gray},
  ),
);
/** NON-DESTRUCTIVE MERGE MAPS
@summary: recursively merge two scss maps.
@purpose: native map-merge does not handle merging multi-dimensional maps
without, potentially, removing properties.
 */
@function mergeMaps($base-map, $updated-map) {
  $result: $base-map;
  @each $key, $value in $updated-map {
    @if (not map-has-key($result, $key)) or
      (type-of(map-get($result, $key)) != type-of($value)) or
      (not (type-of(map-get($result, $key)) == map and type-of($value) == map))
    {
      $result: map-merge(
        $result,
        (
          $key: $value,
        )
      );
    } @else {
      $result: map-merge(
        $result,
        (
          $key: mergeMaps(map-get($result, $key), $value),
        )
      );
    }
  }
  @return $result;
}

@function getProperty($el, $map, $key) {
  // merge the provided map with the base map.
  // we want to provide all the values for this property
  $mergedMap: mergeMaps(map-get($base, $el), $map);

  // if the property exists on the merged config, use it.
  @if map-has-key($mergedMap, $key) {
    $values: map-get($mergedMap, $key);
    // if the value provided is a list with 2 items: (mobile and desktop values), use it
    @if (length($values) > 1) {
      @return $values;
    } @else {
      // if the value provided only has a single item,
      // return a list with the single item duplicated (used for both mobile and desktop)
      @return [$values, $values];
    }
  } @else {
    // If, for some reason, the merged config doesn't contain the property,
    // return initial for both mobile and desktop values
    @return [initial, initial];
  }
}

// [$override: true] will return an !important declaration for all the properties.
// Because this mixin is also provided in the corportate page layout,
// the $override property must be explicitly disabled.

@mixin cor-rich-text($map: $base, $override: true) {
  @each $el, $props in $map {
    // safely get the properties from the provided config.
    // EXTEND: if new properties are added, you can provide them here
    $font-size: getProperty($el, $props, 'size');
    $font-face: getProperty($el, $props, 'face');
    $line-height: getProperty($el, $props, 'lineheight');
    $letter-spacing: getProperty($el, $props, 'spacing');
    $margin-bottom: getProperty($el, $props, 'margin');
    $color: getProperty($el, $props, 'color');
    $weight: getProperty($el, $props, 'weight');

    $important: if($override, '!important', '');
    // Create the css class based on the element
    // IMPORTANT: -- An element is required
    @if ($el) {
      #{$el} {
        @include font-style(
          $family: nth($font-face, 1),
          $size: nth($font-size, 1),
          $lineheight: nth($line-height, 1),
          $spacing: nth($letter-spacing, 1),
          $important: $override
        );

        // Add additional mobile properties here:
        @if ($margin-bottom) {
          margin-bottom: nth($margin-bottom, 1) #{$important};
        }

        @if ($color) {
          color: nth($color, 1) #{$important};
        }

        @if ($weight) {
          font-weight: nth($weight, 1) #{$important};
        }

        @include breakpoint($cor-desktop) {
          @include font-style(
            $family: if(length($font-face) > 1, nth($font-face, 2), nth($font-face, 1)),
            $size: if(length($font-size) > 1, nth($font-size, 2), nth($font-size, 1)),
            $lineheight: if(length($line-height) > 1, nth($line-height, 2), nth($line-height, 1)),
            $spacing: if(length($letter-spacing) > 1, nth($letter-spacing, 2), nth($letter-spacing, 1)),
            $important: $override
          );

          // Add additional desktop properties here:
          @if ($margin-bottom) {
            margin-bottom: if(length($margin-bottom) > 1, nth($margin-bottom, 2), nth($margin-bottom, 1)) #{$important};
          }

          @if ($color) {
            color: if(length($color) > 1, nth($color, 2), nth($color, 1)) #{$important};
          }
        }
      }
    }
  }

  p {
    min-height: 1rem;
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  a {
    color: $aubergine-gray;
  }

  span,
  a {
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    color: inherit;
  }

  ol {
    margin: 1rem 0;
    list-style: none;
    list-style-position: inside;

    > li {
      counter-increment: steps;
      line-height: rem(24px);

      // build filled circle with number, per style guide
      &::before {
        content: counter(steps);
        margin-right: 1rem;
        background: #faf7f5;
        color: #231f20;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        display: inline-grid;
        place-items: center;
        font-size: 14px;
      }
    }
  }

  ul {
    list-style: disc;
    list-style-position: outside;
    margin: 1rem 0;
  }

  li {
    margin-left: rem(10px);
    line-height: rem(32px);
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }
}

sub {
  font-size: xx-small !important;
  line-height: 0 !important;
  position: relative !important;
}

sup {
  vertical-align: super !important;
  font-size: smaller !important;
}

sub {
  bottom: -0.25em;
}
