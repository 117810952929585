@import 'styles/_variables';
@import 'styles/_foundation';

.product-image {
  position: relative;
  padding-bottom: calc(100% - 2px);

  &__placeholder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../../assets/images/product-image-fallback.svg');
    background-size: cover;
  }

  &__media {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    img {
      width: 100%;
      height: 100%;
      background: $white;
    }

    &--with-overlay:after {
      position: absolute;
      top: 0;
      left: 0;
      z-index: $base-z-index;
      content: '';
      width: 100%;
      height: 100%;
      background-color: rgba(40, 40, 40, 0.6);
      pointer-events: none;
    }
  }

  &__zoomed-container {
    display: none;
    position: absolute;
    left: 360px;
    top: $s2-5;
    padding: $s2 $s2-5;
    background: $white;
    border-radius: 4px;
    box-shadow: 0 1.5px 16px 0 rgba(0, 0, 0, 0.21);
    z-index: $base-z-index;

    @include breakpoint(1200) {
      left: 430px;
    }

    &:before {
      content: '';
      position: absolute;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid $white;
      top: 50%;
      left: -10px;
      z-index: $base-z-index;
    }
  }

  &--zooming {
    .product-image__zoomed-container {
      display: block;
    }
  }
}
