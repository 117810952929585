@import 'styles/_variables';
@import 'styles/_foundation.scss';
@import 'styles/_cor-rich-text';

.cor-page-layout {
  color: $black;
  min-height: 100vh;

  &__header {
    z-index: $page-header-z-index;
  }

  &__main {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    min-height: $mobile-main-page-height;

    @include breakpoint(medium) {
      min-height: $desktop-main-page-height;
    }
  }

  &__footer {
    margin-top: auto;
  }
}

// Provides base styling for Rich Text components.
// IMPORTANT!! --> Keep this shallow so that individual components can override.
body:not(.international-font) .cor-rich-text {
  @include cor-rich-text($override: false);
}

body.international-font .cor-rich-text {
  @include cor-rich-text($map: $neue-base, $override: false);
}
