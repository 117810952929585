@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

.navigation-tile {
  &__inner {
    position: relative;
  }

  &__content {
    position: relative;
    width: 100%;
    padding-bottom: 95.5%;

    @include breakpoint(medium) {
      padding-bottom: 86.5%;
    }
  }

  &__text {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, transparent, change_color($black, $lightness: 3%));
    padding: $s2;
    color: $white;
    display: inline-block;
  }

  &__title {
    @include df-h1-mobile;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: auto;
    margin-bottom: 0.5em;

    @include breakpoint(medium) {
      @include df-h3-bold;
      margin-bottom: $s0-5;
    }
  }

  &__category {
    @include df-caption;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    height: 1em;
    margin-bottom: 0.5em;
    margin-top: 1em;
  }

  &__description {
    @include df-p2-medium;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: $s0-5;

    @include breakpoint(medium) {
      -webkit-line-clamp: 2;
      height: 3em;
    }
  }

  &__video-button {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
