@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

.product-selector-button {
  @include df-p1-medium;

  box-shadow: inset 0 0 0 1px $input-gray;
  border-radius: 8px;
  text-align: center;
  padding: $s1-5 $s0-5;
  width: 30%;
  margin: $s1 5px;
  background-color: $white;
  border: none;

  @include breakpoint(medium) {
    width: 29%;
    margin: $s1-5 5px;
  }

  @include breakpoint(1200px) {
    width: 30%;
  }

  &--active {
    @include df-p1-bold;
    box-shadow: inset 0 0 0 2px $red;
    pointer-events: none;
    background-color: $light-coral;
    color: $red;
  }

  &--disabled {
    pointer-events: none;
    background: $light-gray;
    font-family: $frutiger-56-italic;
    box-shadow: inset 0 0 0 1px $input-gray;
  }

  &:hover {
    cursor: pointer;
  }
}
