@import 'styles/_foundation';
@import 'styles/_variables.scss';
@import 'styles/typography';

.customer-location-edit-section {
  padding: $s2-5 $s2;

  @include breakpoint(medium) {
    padding: $s2 $s3;
    background: $snow;
  }

  &__tabs {
    display: flex;

    @include breakpoint(small down) {
      justify-content: space-between;
    }

    &-item {
      @include df-caption;
      position: relative;
      padding: 13px $s1-5;
      cursor: pointer;
      color: $dark-gray;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: -1px;
        width: 1px;
        height: 22px;
        transform: translateY(-50%);
        background: $input-gray;
      }

      &:first-child:before {
        display: none;
      }

      &:hover,
      &.active {
        color: $red;
      }

      &.active {
        background-color: $white;
        border-radius: 4px;

        &:before {
          display: none;
        }

        & + .customer-location-edit-section__tabs-item {
          &:before {
            display: none;
          }
        }
      }

      &.disabled {
        color: $input-gray;
        pointer-events: none;
      }
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    background-color: $light-gray;
    border-radius: 4px;
    padding: $s0-5;

    @include breakpoint(medium) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__content {
    padding-top: $s2;
    @include breakpoint(small down) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-top: $s1-5;
      flex-grow: 1;
      margin: 0;
    }
  }

  .account-status-badge {
    margin-bottom: $s2;
    margin-left: auto;
    min-width: 96px;

    @include breakpoint(medium) {
      order: 2;
      margin: 0 0 0 $s0-5;
    }
  }
}
