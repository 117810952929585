@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/_cor-rich-text';

.cor-form {
  & {
    background-color: $cor-bg-gray-lightest;
    padding: 50px 24px;

    @include breakpoint($cor-desktop) {
      padding-top: 100px;
      padding-bottom: 100px;
    }
  }

  &__container {
    background-color: $cor-white;
    padding: 33px 24px 43px 24px !important;
    border-radius: 10px;

    @include breakpoint($cor-desktop) {
      padding: 100px 190px !important;
      border-radius: 20px;
      max-width: 1178px;
    }
  }

  &__form {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 80px;
    row-gap: 10px;

    @include breakpoint($cor-desktop) {
      grid-template-columns: repeat(2, 1fr);
      row-gap: 15px;
    }
  }

  &__title {
    text-align: center;
    color: $aubergine-gray;
    margin-bottom: 11px;

    @include breakpoint($cor-desktop) {
      margin-bottom: 21px;
    }
  }

  &__description {
    margin-bottom: 15px;

    @include breakpoint($cor-desktop) {
      max-width: 500px;
      margin: 0 auto 49px auto;
    }

    & p span {
      font-size: 14px;

      @include breakpoint($cor-desktop) {
        font-size: 16px;
      }
    }
  }
}

body:not(.international-font) .cor-form {
  &__description {
    @include cor-rich-text(
      $map: (
        'p': (
          'lineheight': [24px,
          28px],
        ),
      )
    );
  }
}

body.international-font .cor-form {
  &__description {
    @include cor-rich-text(
      $map: (
        'p': (
          'face': neueFrutiger,
          'lineheight': [24px,
          28px],
        ),
      )
    );
  }
}
