.cor-footer-desktop {
  & {
    padding-top: 85px;
    display: flex;
    flex-direction: column;
    row-gap: 70px;
  }

  &__main {
    display: flex;
    column-gap: 45px;
    justify-content: space-between;
    width: 100%;
  }

  .main__newsletter {
    display: flex;
    flex-direction: column;
  }

  .logo {
    margin-top: auto;

    & > * {
      max-width: 120px;
      height: auto;
    }
  }
}
