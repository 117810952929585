@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

.cancel-order-confirm-modal {
  .modal__close-button {
    top: 20px;

    &:before,
    &:after {
      height: 24px;
    }
  }

  &__content {
    padding: 0 $s2-5;

    @include breakpoint(medium) {
      padding: 0 $s4;
    }
  }
  &__subtext {
    line-height: $s3;
    padding: $s2 0 $s2 0;

    @include breakpoint(medium) {
      padding: $s3-5 0 0 0;
    }
  }

  &__button-group {
    display: flex;
    justify-content: flex-end;
    margin-top: 27px;

    @include breakpoint(medium) {
      margin-top: 37px;
    }
  }

  &__go-back-button {
    margin-right: $s4;
    height: fit-content;
    align-self: center;
    font-family: $frutiger-55-roman;
  }

  &__cancel-button {
    width: 180px;
  }
}
