@import 'styles/_variables';
@import 'styles/typography/_mixins';
@import 'styles/_foundation';

.csr-edit-user-info-mobile-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $white;
  z-index: $modal-z-index;
  padding-bottom: $s2;

  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .modal__content {
      padding: 0;
      margin: 0;
      border-radius: 0;
      max-height: 100%;
    }

    .modal__container {
      position: absolute;
      height: 100%;
      width: 100%;
    }
  }

  header {
    padding: $s2-5 $s2 0;
  }

  .page-layout {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    min-height: 100%;
  }

  .page-layout__header {
    padding: 0;
  }

  .back-button {
    margin: $s1 0;
    padding: 0;
    height: 24px;

    .csr-edit-user-info-mobile-modal__caption {
      vertical-align: top;
      margin-left: $s0-5;
      font: 14px/1.71 $frutiger-55-roman;
      color: $black;
    }
  }

  &__back-icon {
    @include breakpoint(small down) {
      margin-left: -5px;
    }
  }

  h1 {
    @include df-h1-mobile();
    color: $black;
    margin: $s1 0;
  }

  .user-name-header {
    display: flex;
    justify-content: space-between;

    .user-name {
      @include df-h3-medium();
    }
  }

  .select-user-dropdown {
    .modal__container {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      transform: none;

      > .grid-container {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        transform: none;
        display: flex;
        align-items: center;
      }

      .select-location-user-modal {
        padding: $s2;
        border-radius: 4px;
      }
    }

    .location-select-user-button {
      width: 100%;
      margin-bottom: 0;
    }
  }

  .customer-location-edit-section {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    padding: $s2;

    .change-email-address-form {
      .modal__container {
        height: unset;
      }

      &__current-email {
        margin-bottom: $s2-5;
        &-label {
          margin-bottom: $s1;
        }
      }

      .text-field {
        &:first-child {
          margin-bottom: $s2;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      &__buttons {
        margin-top: $s3;
      }
    }
  }

  .internal-user-footer {
    margin: $s2-5 0;
  }

  .internal-user-footer__copyright {
    padding-bottom: 0;
  }
}
