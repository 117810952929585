@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

.product-solution-carousel {
  padding: $s3 0;
  background-color: $white;

  @include breakpoint(medium) {
    padding: 44px 0;
  }

  .products-carousel {
    h2 {
      margin-bottom: $s2;
      text-align: center;

      @include breakpoint(medium) {
        margin-bottom: $s4;
      }
    }
    &__item {
      padding-bottom: $s1-5;
    }

    .swiper {
      &-pagination {
        &.swiper-pagination-horizontal {
          position: absolute;
          right: 0;
          bottom: -12px;

          @include breakpoint(medium) {
            position: relative;
            bottom: -22px;
            padding-bottom: $s2-5;
          }
        }
      }
    }
  }

  &__see-more {
    @include df-p1-bold;
    border-radius: 8px;
    padding: 11px $s8;
    border: 1px solid $input-gray;
    background: $white;
    text-decoration: none;
    text-align: center;
    align-self: center;
    margin-top: $s6;

    @include breakpoint(medium) {
      padding: 11px $s9;
      margin-top: 0;
    }
  }
}
