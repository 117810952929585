@import 'styles/_variables';
@import 'styles/_foundation';
@import 'styles/typography/_mixins';

.sku-quantity-limitation-warning {
  @include df-p1-medium;
  display: flex;
  align-items: flex-start;
  padding: $s1 $s1 $s1 $s2;
  background: $light-yellow;

  @include breakpoint(medium) {
    padding: $s1-5;
  }

  &--bordered {
    border: 1px solid $yellow;
  }

  &__icon {
    margin-right: 9px;
  }
}
