@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';
@import '/src/styles/mixins/typography';

$mobile-modal-min-height: 700px;
$desktop-modal-top-offset: 143px;
$full-mobile-header-offset: 64px;
$internal-full-mobile-header-offset: 190px;
$collapsed-mobile-header-offset: 130px;
$internal-collapsed-mobile-header-offset: 125px;
$authorized-desktop-header-sticky: 99px;
$authorized-desktop-header-sticky-impersonation: 148px;
$authorized-desktop-header-impersonation: 169px;

.notifications-list-modal {
  $notification-modal: &;

  margin: 0;
  display: flex;
  flex-direction: column;

  @include breakpoint(medium) {
    box-shadow: 1px 2px 12px 0 rgba(0, 0, 0, 0.35);
  }

  .mobile-panel-header {
    padding: $s5 $s3 $s3-5;

    span {
      font-size: 20px;
      font-weight: 600;
    }
  }

  &__button-wrapper {
    margin: 25px $s3;

    &--button {
      width: 100%;
    }
  }

  &__wrapper {
    &.modal {
      background: none;
      position: fixed;
      right: 0;
      left: 0;

      @include breakpoint(medium) {
        position: fixed;
        top: $desktop-modal-top-offset;
        right: 59%;
        bottom: unset;
        left: unset;
      }

      @media (min-width: 1050px) {
        right: 48%;
      }

      @media (min-width: 1100px) {
        right: 45%;
      }

      @media (min-width: 1200px) {
        right: 41%;
      }

      @media (min-width: 1300px) {
        right: 39%;
      }

      @media (min-width: 1500px) {
        right: 40%;
      }

      @media (min-width: 1700px) {
        right: 41%;
      }

      @media (min-width: 1700px) {
        right: 42%;
      }

      @media (min-width: 2400px) {
        right: 44%;
      }

      .modal__container {
        position: absolute;
        height: 100%;
      }

      .grid-container {
        padding: 0;
        height: 100%;

        .grid-x {
          height: 100%;
        }
      }
    }

    &.internal-collapsed-mobile-header {
      top: $internal-collapsed-mobile-header-offset;
    }

    &.collapsed-mobile-header {
      top: $collapsed-mobile-header-offset;
    }

    &.internal-full-mobile-header {
      top: $internal-full-mobile-header-offset;

      @include breakpoint(small down) {
        top: 0;
      }
    }

    &.environment-mobile-header {
      top: $full-mobile-header-offset;

      @include breakpoint(small down) {
        top: 0;
      }
    }

    &.full-mobile-header {
      top: 0;
    }

    &.authorized-desktop-header-sticky {
      top: $authorized-desktop-header-sticky;
    }

    &.authorized-desktop-header-impersonation {
      top: $authorized-desktop-header-impersonation;
    }

    &.authorized-desktop-header-sticky-impersonation {
      top: $authorized-desktop-header-sticky-impersonation;
    }
  }

  &.modal__content.cell {
    padding: 0;
    margin: 0;
    border-radius: 0;
    height: 100%;

    @include breakpoint(medium) {
      border-radius: 4px;
      width: 415px;
      height: auto;
      max-height: 500px;
    }

    @include breakpoint(small down) {
      max-height: 100%;
    }
  }

  &__content {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;

    &-wrapper {
      @include breakpoint(small down) {
        padding: 3px 23px $s3 $s3;
        flex: 1;
      }

      @include breakpoint(medium) {
        padding: 0 $s4 $s7;
      }
    }

    &__notification {
      .expandable-section {
        .expandable-section__title {
          @include font-frutiger-bold($size: 16, $lineheight: 24px, $spacing: 0.2px);
          padding: 30px $s2-5 0 0;
          text-transform: none;
          color: $black;

          @include breakpoint(small down) {
            padding-right: 0;
          }

          @include breakpoint(medium) {
            padding: $s2-5 10px 0 0;
            color: $dark-gray;
          }
        }

        .expandable-section__custom-expand-icon {
          width: 14px;
        }

        &--expanded {
          .expandable-section__custom-expand-icon {
            transform: rotate(90deg);
          }
        }
      }

      .notification-message {
        @include font-frutiger-regular($size: 16, $lineheight: 24px);
        margin-top: 10px;
        color: $red;

        @include breakpoint(medium) {
          margin-top: $s1;
        }
      }
    }
  }
}
