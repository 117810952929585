@import 'styles/_variables';
@import 'styles/typography/_mixins';
@import 'styles/_foundation';

.invite-new-user-modal {
  &.modal__content {
    @include breakpoint(medium) {
      min-width: 640px;
    }
  }

  &__content {
    margin-top: $s3;
    padding: 0 $s2-5;
    @include breakpoint(medium) {
      padding: 0 $s4;
    }
  }

  &__field {
    margin-bottom: $s3-5;

    @include breakpoint(medium) {
      margin-bottom: $s2-5;
    }
  }

  &__label,
  .text-field__label {
    @include df-p1-medium;
    margin-bottom: $s1-5;
  }

  &__locations-value {
    .select .select__menu-list {
      max-height: 110px;
    }
  }

  .text-field__input {
    &::placeholder {
      @include df-p2-italic;

      @include breakpoint(medium) {
        font-size: 16px;
      }
    }
  }

  .custom-tooltip .custom-tooltip__content {
    margin-left: -28px;
    max-width: 200px;

    @include breakpoint(medium) {
      margin-left: initial;
    }

    &:after,
    &:before {
      right: 8px;
      left: auto;

      @include breakpoint(medium) {
        right: 50%;
        left: 50%;
      }
    }
  }

  .select__placeholder {
    @include df-p2-italic;

    @include breakpoint(medium) {
      font-size: 16px;
    }
  }

  &__locations-value,
  &__email {
    width: 100%;

    @include breakpoint(medium) {
      width: 60%;
    }
  }

  &__account {
    display: flex;
    flex-direction: column;

    &-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      @include breakpoint(medium) {
        justify-content: flex-start;
      }

      .custom-tooltip {
        &__content {
          max-width: max-content;
        }
      }
    }

    &-value {
      width: 90%;

      @include breakpoint(medium) {
        width: 190px;
        margin-right: $s3;
      }
    }
  }
}
