@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

.basic-footer {
  background: $black;
  color: $white;
  padding: $s2 0;

  @include breakpoint(medium) {
    padding: $s2-5 $s4 $s2-5;
  }

  .copyright {
    @include df-p2-medium;
    width: 100%;
    text-align: center;

    @include breakpoint(medium) {
      width: auto;
      text-align: left;
    }
  }
}
