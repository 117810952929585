@import 'styles/_variables';

$icon-width: 28px;
$icon-height: 26px;

.password-field {
  .text-field__input-group {
    position: relative;
  }

  .text-field__input {
    padding-right: 36px;
  }

  &__value--visible,
  &__value--hidden {
    position: absolute;
    top: 50%;
    left: auto;
    right: $s2;
    transform: translateY(-50%);
    margin-left: $s1;

    &:hover {
      cursor: pointer;
    }
  }

  &__value--visible {
    background: url('../../../assets/images/icons/icon-eye-crossed.svg') no-repeat 50%;
    width: $icon-width;
    height: $icon-height;
  }

  &__value--hidden {
    background: url('../../../assets/images/icons/icon-eye.svg') no-repeat 50%;
    width: $icon-width;
    height: $icon-height;
  }
}
