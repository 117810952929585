@import 'styles/_variables';
@import '/src/styles/mixins/typography';

.menu-dropdown {
  z-index: $base-z-index;
  position: relative;

  &__button {
    @include font-frutiger-regular($size: 14);
    background-color: $white;
    display: flex;
    border-radius: 6px;
    border: 0;
    padding: 6px 10px;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    &.is-active {
      background-color: $light-tan;
    }

    .hint-text {
      @include font-frutiger-regular($size: 11);
      position: absolute;
      display: none;
      white-space: nowrap;
      left: 50%;
      transform: translateX(-50%);
      bottom: 124%;
      box-shadow: 0px 1px 10px rgba($black, 0.4);
      padding: 4px 8px;
      background: $white;
      border-radius: 3px;

      &::after {
        content: '';
        border-width: 5px 5px 0;
        border-style: solid;
        border-color: $white transparent transparent;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &:hover {
      .hint-text {
        display: block;
      }
    }
  }

  &__title {
    @include font-frutiger-regular($size: 18, $lineheight: 26px);
    margin-bottom: 1.5625rem;
  }

  &__icon {
    height: 16px;
    width: 16px;
    object-fit: contain;
    object-position: center;
  }

  &__panel {
    background: $white;
    position: absolute;
    width: 255px;
    top: 3rem;
    left: 0;
    padding: 1.75rem 2.5rem;
    box-shadow: 0 0 30px rgba($gray, 0.45);
    border-radius: 6px;
  }

  &__panel-item {
    @include font-museo-regular($size: 14);
    display: flex;
    justify-content: space-between;
    &:not(:last-of-type) {
      margin-bottom: 1.25rem;
    }
    & > a {
      text-decoration: none;
      white-space: nowrap;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.user-locations-panel-popover {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  padding: 0;
  width: 100%;
  cursor: pointer;

  > div {
    display: none;
    position: absolute;
  }

  &.is-open > div {
    display: block;
  }
}
