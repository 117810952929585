@import 'styles/_foundation.scss';
@import 'styles/_variables';

.line-item-image {
  &__wrapper {
    position: relative;
    flex-shrink: 0;
  }

  &__picture {
    position: relative;
  }

  &__indicator {
    position: absolute;
    width: 100%;
    top: 0;
    z-index: $base-z-index;
  }

  &__status {
    position: relative;
    margin: -#{$s1} -6px -#{$s0-5};

    @include breakpoint(medium) {
      margin-top: -13px;
    }

    .product-status-labels__wrapper {
      margin: -1px 0;
    }

    .product-status {
      width: 100%;
      padding: 2px $s0-5 0;
      margin: 1px 0;

      @include breakpoint(medium) {
        padding: 5px $s0-5 3px;
      }
    }
  }

  &--discontinued:before {
    position: absolute;
    content: '';
    background-color: $black;
    opacity: 0.4;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 1;

    @include breakpoint(medium) {
      margin-right: $s2;
      width: 100%;
      height: 100%;
    }
  }
}
