@import 'styles/_variables';
@import 'styles/_foundation';
@import '/src/styles/mixins/typography';

.menu-panel {
  $paddingSides: 24px;
  color: $black;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__title {
    @include font-museo-bold($size: 20);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: center;
    height: 50px; /* to match mobile header height */
    box-shadow: 0 0 15px rgba(200, 199, 199, 0.45);
  }

  &__scroll {
    flex: 1;
    overflow-y: auto;
  }

  &__item {
    @include font-museo-bold($size: 16);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 20px $paddingSides;
    border-bottom: 1px solid $gray;

    &.has-subnav {
      cursor: pointer;
    }

    .notification {
      width: 100%;
      display: flex;
      column-gap: 20px;
    }
  }

  &__close-button {
    border: 0;
    background-color: transparent;
    padding: 0;
    position: absolute;
    top: 50%;
    left: $paddingSides;
    transform: translateY(-50%);
  }

  &__subitems {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
