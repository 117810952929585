@import 'styles/_variables';
@import 'styles/typography/_mixins';
@import 'styles/_foundation';

.add-payment-card-modal {
  $add-payment-card-modal: &;
  padding: 0;

  &__header {
    margin-bottom: 0;
  }

  &__wrapper {
    padding: $s2-5 $s2 $s1-5;
    margin-bottom: $s10;
    overflow: auto;

    @include breakpoint(medium) {
      padding: $s2-5 $s4 $s1-5;
    }
  }

  .modal {
    &__content {
      display: flex;
      flex-direction: column;
      padding: 0;
      height: 100dvh;
      overflow: hidden;

      @include breakpoint(medium) {
        min-height: 524px;
        height: auto;
      }
    }
  }

  &__description {
    @include df-p3-medium;
    margin-bottom: $s2-5;

    @include breakpoint(medium) {
      @include df-p1-medium;
      margin-bottom: $s1-5;
      color: $black;
    }
  }

  &__modal--with-loading-indicator {
    &.modal__content {
      min-height: 300px;

      @include breakpoint(medium) {
        min-height: 500px;
      }
    }
  }
}
