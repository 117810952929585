@import 'styles/_variables';
@import 'styles/typography/_mixins';
@import 'styles/_foundation';

$desktop-modal-top-offset: 60px;

.select-shopping-list-action-modal {
  $select-shopping-list-action-modal: &;

  &__heading {
    @include df-h2-mobile;
  }

  &__options {
    padding: 0 $s2-5;

    .radio-button {
      margin-bottom: $s2-5;
    }

    @include breakpoint(medium) {
      padding: 0 $s4;
    }
  }

  &__button {
    width: 100%;
  }

  &--edit-shopping-list {
    #{$select-shopping-list-action-modal}__heading {
      color: $black;
    }

    #{$select-shopping-list-action-modal}__options {
      & > div {
        margin-bottom: $s4-5;

        &:last-child {
          margin-bottom: 0;
        }

        @include breakpoint(medium) {
          padding-left: $s2;
        }

        @include breakpoint(medium) {
          margin-bottom: $s2-5;
        }
      }
    }

    .modal__close-button {
      &:before,
      &:after {
        height: 27px;
      }

      @include breakpoint(medium) {
        display: none;
      }
    }
  }
}

.custom-action-option {
  $button-size-mobile: 22px;
  $button-size-desktop: 16px;
  $custom-action-option: &;

  @include df-p1-medium;
  display: flex;
  align-items: center;
  cursor: pointer;
  line-height: 1.43;

  @include breakpoint(medium) {
    width: 100%;
  }

  &:hover {
    color: $red;

    #{$custom-action-option}__icon {
      display: none;

      &-hover {
        display: block;
      }
    }
  }

  &__icon,
  &__icon-hover {
    width: $button-size-mobile;
    height: $button-size-mobile;
    margin-right: $s1-5;

    @include breakpoint(medium) {
      width: $button-size-desktop;
      height: $button-size-desktop;
    }
  }

  &__icon-hover {
    display: none;
  }
}
