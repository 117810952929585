@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

$mobile-icon-size: 76px;
$desktop-icon-size: 88px;

.proprietary-item-modal {
  &__title {
    @include df-h2;
    display: flex;
    margin-bottom: $s2-5;

    img {
      width: 24px;
      margin-right: $s2;

      @include breakpoint(medium) {
        width: 32px;
        margin-right: $s2-5;
      }
    }

    @include breakpoint(medium) {
      @include df-h3-bold;
    }
  }

  &__content {
    margin-bottom: $s8;
    padding: 0 $s2-5;

    @include breakpoint(medium) {
      margin-bottom: 27px;
      padding: 0 $s4;
    }
  }

  &__message {
    @include df-p1-medium;
    margin-bottom: $s2;

    @include breakpoint(medium) {
      margin-bottom: $s3;
    }
  }

  &__product {
    display: flex;
    align-items: center;
    color: $black;
    padding-left: $s2-5;

    @include breakpoint(medium) {
      padding-left: $s4;
    }

    img {
      width: $mobile-icon-size;
      height: $mobile-icon-size;
      margin-right: $s2;

      @include breakpoint(medium) {
        width: $desktop-icon-size;
        height: $desktop-icon-size;
      }
    }

    &-title {
      @include df-p1-bold;
      margin-bottom: $s1;
    }

    &-sku {
      @include df-p2-medium;
    }
  }

  &__footer {
    display: flex;
    flex-wrap: wrap;
  }

  &__close-btn {
    flex: 1 0;
    white-space: nowrap;
    margin: 6px 10px;

    @include breakpoint(medium) {
      max-width: 120px;

      @at-root button#{&} {
        margin-left: auto;
      }
    }
  }

  .ordered-product-image__wrapper {
    margin-right: 30px;
  }
}
