@import 'styles/_foundation';
@import 'styles/_variables';

.site-wide-banner {
  background: $dark-green;
  padding: $s1 0;
  font: 14px/22px $frutiger-56-italic;
  color: $white;
  text-align: center;

  @include breakpoint(medium) {
    font: 16px/24px $frutiger-56-italic;
    padding: 10px 0;
  }

  &__inner {
    text-align: center;

    @include breakpoint(small down) {
      text-align: left;

      & > div {
        display: inline;
      }

      &--collapsed {
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    strong,
    a {
      font-weight: 700;
    }

    @include breakpoint(medium) {
      width: 100%;
    }
  }

  &#{&} #{&}__content-toggle {
    &.cor-button.link-2 {
      color: $white;
      font-weight: 700;
      display: inline;
    }

    &.hidden {
      display: none;
    }
  }

  &__content {
    display: flex;
    align-items: flex-end;

    @include breakpoint(medium) {
      max-width: 1115px;
    }

    p {
      display: inline;
    }
  }
}
