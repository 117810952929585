@import 'components/cor-button/cor-button';
@import 'styles/_variables';
@import 'styles/_foundation';

.cor-jump-links {
  background-color: $cor-bg-gray-lightest;
  padding: 0 1rem 20px;
  @include breakpoint($cor-desktop) {
    padding: 40px 0;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    @include breakpoint($cor-desktop) {
      flex-direction: row;
      gap: 1.75rem;
    }
  }

  &__text {
    font: 12px/1.5 $frutiger-55-roman;
    text-transform: uppercase;
  }

  &__link {
    font: 14px/1.5 $frutiger-55-roman;
    color: $aubergine-gray;
    background-color: transparent;
    padding: 15px 30px;
    letter-spacing: 0.8px;
    border-radius: $s1;
    text-decoration: none;
    border: 1px solid $cor-dawn-orange;
    width: 250px;
    @include breakpoint($cor-desktop) {
      width: auto;
    }

    &:disabled {
      background-color: $light-snow;
      color: $input-gray;
      border: 1px solid $input-gray;
    }

    &:not([disabled]) {
      cursor: pointer;
    }

    &:disabled {
      background-color: $light-snow;
      color: $input-gray;
      border: 1px solid $input-gray;
    }
    &:not([disabled]) {
      &:hover,
      &:active {
        color: $white;
        background-color: $cor-dawn-orange;
        border: 1px solid $cor-dawn-orange;
      }
    }
  }
}
