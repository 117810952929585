.button {
  $button: &;
  @include df-p1-bold;
  background: $red;
  color: $white;
  padding: 11px 54px;
  border-radius: 8px;
  border: solid 1px rgba(255, 255, 255, 0.75);

  &:disabled {
    background-color: $input-gray;
  }

  &:not(:disabled) {
    cursor: pointer;

    &:hover,
    &:active {
      background-color: $light-red;
    }
  }

  &#{$button}--cancel {
    @include df-p1-medium;
    padding: 11px 31px;
    background: none;
    border: none;
    color: $red;
    text-decoration: underline;

    &:disabled {
      background-color: $dark-gray;
    }

    &:hover {
      background: none;
      text-decoration: underline;
      color: $light-red;
    }
  }
}

.button-reset {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
}
