@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

.cor-product-tile-status {
  text-align: left;
  &__stock {
    font-weight: 700;
    margin-bottom: $s0-5;
    &:hover {
      color: $cor-dawn-red;
    }
  }

  &__request-quote-button-tooltip {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    button {
      margin-right: $s1;
    }
  }

  &__price {
    @include df-p2-medium;
    color: $aubergine-gray;
  }

  &__special-order-key-account {
    display: flex;

    &-icon {
      margin-right: $s1;
      height: 100%;

      img {
        height: 20px;
        width: 23px;
      }
    }
  }

  &__special-order {
    font-weight: 700;
    margin-bottom: $s0-5;

    &:hover {
      color: $green;
    }
  }

  &__button {
    display: inline;
    cursor: pointer;
    border: 0;
    background-color: transparent;
    padding: 0;
    font: inherit;
    color: $aubergine-gray;
    text-decoration: underline;
    text-decoration-color: $aubergine-gray;
  }

  &__contact-rep {
    &-link.cor-button.primary {
      @include df-p2-medium;
      text-decoration: underline;
      color: $black;
      line-height: 22px;
      letter-spacing: normal;
      background-color: transparent;
      padding: 0;
      display: inline;
      cursor: pointer;

      &:hover {
        background-color: transparent;
      }
    }
  }
}
