@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/_cor-rich-text';

.cor-card-list {
  & {
    width: 100%;
    padding: 35px 0;
    background-color: $cor-bg-gray-lightest;

    @include breakpoint($cor-desktop) {
      padding: 95px 0;
    }
  }

  &__title,
  &__sub-title {
    text-align: center;
  }

  &__title {
    color: $aubergine-gray;
  }

  &__sub-title {
    color: $mid-gray;
    margin-top: 35px;
    margin-left: auto;
    margin-right: auto;

    @include breakpoint($cor-desktop) {
      max-width: 600px;
    }
  }

  &__cards__container {
    margin-top: 35px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 32px;

    @include breakpoint($cor-desktop) {
      grid-template-columns: repeat(2, 1fr);
      gap: 60px;
      margin-top: 60px;
    }
  }

  &__card {
    overflow: hidden;
    background-color: $cor-white;
    border-radius: 6px;
    box-shadow: 0px 0px 20px rgba(219, 219, 219, 0.5);
    text-decoration: initial;

    &[href]:hover .cor-text-link__caret {
      @include breakpoint($cor-desktop) {
        text-decoration: underline;
      }
    }
  }

  &__card__picture {
    display: flex;
    height: 180px;
    background-color: $mid-gray;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @include breakpoint($cor-desktop) {
      height: 300px;
    }
  }

  &__card__content {
    display: flex;
    flex-direction: column;
    padding: 18px 25px;
    gap: 8px;

    @include breakpoint($cor-desktop) {
      flex-direction: row;
      padding: 25px 40px;
      gap: 40px;
    }
  }

  &__card__link {
    color: #231f20;
    display: flex;
    align-items: center;

    @include breakpoint($cor-desktop) {
      flex: 1;
    }
  }

  &__card__description {
    color: $mid-gray;

    @include breakpoint($cor-desktop) {
      flex: 1.6;
    }
  }
}

body:not(.international-font) .cor-card-list {
  &__sub-title {
    @include cor-rich-text();
  }

  &__card__description {
    @include cor-rich-text(
      $map: (
        'p': (
          'size': 14px,
          'lineheight': 24px,
          'spacing': 0.01rem,
        ),
      )
    );
  }
}

body.international-font .cor-card-list {
  &__card__description {
    @include cor-rich-text(
      $map: (
        'p': (
          'face': neueFrutiger,
          'size': 14px,
          'lineheight': 24px,
          'spacing': 0.01rem,
        ),
      )
    );
  }
}
