@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography';

.autopay-modal {
  $autopay-modal: &;
  overflow: visible;

  display: flex;
  flex-direction: column;

  &.modal__content {
    min-height: 300px;

    @include breakpoint(medium) {
      min-height: 400px;
    }
  }

  &__wrapper {
    padding: 0 $s2 $s0-5;

    @include breakpoint(medium) {
      height: 100%;
      padding: 0 $s4 5px;
    }

    .credit-card-surcharge-text__text-container--is-canada-french {
      a {
        text-decoration: underline;
      }
    }
  }

  &__field {
    margin: 0;

    @include breakpoint(medium) {
      margin: $s3 0;
    }
  }

  &__field-description {
    line-height: 24px;
  }

  &__footer {
    align-items: flex-end;
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    .cor-button.modal-footer__cancel-button {
      @include df-p1-medium;
      letter-spacing: 0.2px;
      line-height: 19.2px;

      .content {
        text-decoration: underline;
      }
    }

    .cor-button.modal-footer__save-button {
      height: 48px;
    }
  }
}
