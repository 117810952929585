@import 'styles/_variables';
@import 'styles/typography/_mixins';
@import 'styles/_foundation';

.geolocation-redirect-modal {
  &__content {
    padding: 0;
  }

  &__content-wrapper {
    padding: $s2 $s2 $s3;
    @include breakpoint(medium) {
      padding: 22px 25px;
    }
  }

  &__description {
    margin-top: $s2;

    &--link-text {
      color: $red;
      text-decoration: underline;
      cursor: pointer;
    }

    @include df-p1-medium;
    @include breakpoint(medium) {
      margin-top: 18px;
    }
  }

  &__buttons {
    margin-top: $s4;
    display: block;

    @include breakpoint(medium) {
      display: flex;
      justify-content: space-between;
    }

    &--stay {
      margin-top: $s2;
      width: 100%;

      @include breakpoint(medium) {
        margin-top: 0;
        width: 42%;
        margin-left: $s2-5;
      }
    }

    &--redirect {
      width: 100%;
      max-width: 100%;

      @include breakpoint(medium) {
        width: 55%;
      }
    }
  }

  &__checkbox {
    margin-top: $s2;

    .custom-checkbox__label-text {
      @include df-p2-medium;
      color: $black;
    }

    @include breakpoint(medium) {
      margin-top: $s2-5;
    }
  }
}
