@import 'styles/_foundation';
@import 'styles/_variables';

.search-results-page {
  & {
    width: 100%;
    min-height: 482px;
  }

  &__container.grid-container {
    @include breakpoint($cor-desktop) {
      padding-left: auto;
      padding-right: auto;
    }
  }

  &__filter-buttons {
    padding-bottom: 21px;

    @include breakpoint($cor-desktop) {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      justify-content: center;
      padding-bottom: 37px;
    }
  }

  &__filter-buttons--hidden {
    visibility: hidden;
  }

  &__breadcrumb {
    padding-top: 20px;
    padding-bottom: 37px;

    @include breakpoint($cor-desktop) {
      padding: 29px;
      padding-bottom: 44px;
    }
  }

  &__cards-container {
    overflow: hidden;
    padding-top: 35px;
    padding-bottom: 35px;

    @include breakpoint($cor-desktop) {
      padding-top: 70px;
      padding-bottom: 70px;
    }
  }

  &__cards > .cor-featured-content__card {
    width: initial;

    &-product {
      width: 240px;
    }
  }

  &__cards-container > .cor-featured-content__cards-mobile {
    margin-top: 23px;
  }

  &__cards-container:nth-child(odd) {
    background-color: $cor-bg-gray-lightest;
  }

  &__cards-container--first {
    padding-top: 31px;

    @include breakpoint($cor-desktop) {
      padding-top: 28px !important;
    }
  }

  &__cards {
    display: grid;
    padding-top: 28px;
  }

  &__cards-see-all {
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  &__cards--list-page {
    column-gap: 16px;
    row-gap: 32px;
    grid-template-columns: repeat(2, minmax(50px, 1fr));

    @include breakpoint($cor-desktop) {
      row-gap: 44px !important;
    }
  }

  &__cards--recipes {
    @include breakpoint($cor-desktop) {
      gap: 40px;
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &__cards--products {
    @include breakpoint($cor-desktop) {
      gap: 30px;
      grid-template-columns: repeat(5, 1fr);
    }
  }

  &__cards--insights {
    @include breakpoint($cor-desktop) {
      gap: 52px;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__cards--faqs {
    gap: 23px;
    grid-template-columns: repeat(1, 1fr);

    @include breakpoint($cor-desktop) {
      gap: 48px;
    }
  }

  &__cards-header {
    display: flex;
    flex-direction: column;

    @include breakpoint($cor-desktop) {
      column-gap: 5px;
      flex-direction: row;
      align-items: flex-end;
    }
  }

  &__cards-header > .cor-typography > span {
    color: $cor-dawn-red;
  }

  &__cards-header > .cor-button {
    margin-left: auto;
  }

  &__cards-results {
    display: block;

    @include breakpoint($cor-desktop) {
      padding-left: 10px;
      display: inline-block;
    }
  }

  &__pagination {
    justify-content: center;
    display: flex;
    margin-bottom: 30px;

    @include breakpoint($cor-desktop) {
      margin-bottom: 70px;
    }
  }
}
