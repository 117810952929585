@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography';

.account-actions {
  margin-top: $s2;

  &__buttons {
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    margin-top: 50px;

    @include breakpoint(medium) {
      flex-direction: row;
      margin-top: 191px;
    }
  }

  &__save {
    order: 1;
    margin-bottom: $s2;

    @include breakpoint(medium) {
      order: 2;
      margin: 0;
    }
  }

  &__close {
    order: 2;

    @include breakpoint(medium) {
      order: 1;
      margin-right: 28px;
    }
  }

  &__action {
    .custom-checkbox__label {
      @include df-caption;
      color: $black;
      display: flex;
      align-items: flex-end;
    }

    &--disabled {
      .custom-checkbox__label {
        color: $achromatic-gray;
      }
    }
  }

  &__success-message {
    @include df-p1-bold;
    display: flex;
    align-items: flex-start;
    color: $green;
    font-size: 16px;
    margin-top: 18px;

    @include breakpoint(medium) {
      margin-top: 26px;
    }

    img {
      margin-right: $s1;
    }
  }

  @include breakpoint(small down) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    &__action {
      margin: 0 $s2;
    }
  }
}
