@import 'styles/_foundation';
@import 'styles/typography/_mixins';
@import 'styles/_variables';

.promotion-code-details-modal {
  &__body {
    @include df-p3-medium;
    padding: $s0-5 $s2 0 $s2;
    letter-spacing: 0.002em;
    height: 256px;
    overflow: auto;

    @include breakpoint(medium) {
      @include df-p1-medium;
      padding: 0 $s4;
      height: 288px;
    }
  }

  &__footer {
    @include breakpoint(medium) {
      margin-top: $s4;
    }
  }
}
