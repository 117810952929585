@import 'styles/_variables';
@import 'styles/typography/_mixins';
@import 'styles/_foundation';

.modal-footer {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.08);
  padding: $s2 $s2-5 $s1-5;
  margin-top: $s2-5;

  @include breakpoint(medium) {
    padding: $s2-5 $s4;
    margin-top: $s3-5;
  }
  &__cancel-button {
    margin-right: $s4;
  }

  &__close-button {
    width: 100%;
    min-width: 224px;

    @include breakpoint(medium) {
      width: initial;
    }
  }
}
.modal-footer__vertical-buttons {
  flex-direction: column;
  align-items: center;
  padding: $s2;
  .text .content {
    line-height: 19px;
  }
}
