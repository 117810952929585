@import 'styles/_variables';
@import 'styles/typography/_mixins';
@import 'styles/_foundation';

.product-status-labels {
  text-transform: uppercase;
  text-align: center;
  color: $white;
  overflow: hidden;

  &__wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin: -#{$s0-5} -6px;
  }

  &--small {
    font: 10px/1.45 $frutiger-65-bold;
    letter-spacing: 1px;
  }

  &--large {
    font: 11px/1.45 $frutiger-65-bold;
    letter-spacing: 1.2px;
  }

  &--responsive {
    @include df-caption;
  }
}

.product-status {
  padding: 5px $s1-5 3px;
  border-radius: $s1-5;
  margin: $s0-5 6px;
  z-index: $base-z-index;

  &--special-order {
    background: $green;
  }

  &--discontinued {
    background: $brown;
  }

  &--phasing-out {
    background: $yellow;
    color: $black;
  }

  &--cancelled {
    background: $dark-gray;
  }

  &--out-of-stock {
    background-color: $white;
    color: $dark-gray;
    border: 2px solid $dark-gray;
  }
}
