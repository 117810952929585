@import 'styles/_variables';
@import 'styles/_foundation';
@import 'styles/mixins/_typography';
@import 'styles/_cor-rich-text';

.cor-three-column-tile {
  display: flex;
  gap: 15px;
  @include breakpoint($cor-desktop) {
    flex-direction: column;
  }

  &__title {
    margin-bottom: 10px;
    @include breakpoint($cor-desktop) {
      margin-bottom: 14px;
    }
  }

  &__icon {
    height: 28px;
    width: 28px;
    flex-shrink: 0;
    > img {
      object-fit: contain;
      object-position: center;
      height: 100%;
      width: 100%;
    }
  }

  &__description {
    &,
    & a,
    & a:hover,
    & a:visited,
    & a:active {
      color: $mid-gray;
    }
  }

  &--right {
    @include breakpoint($cor-desktop) {
      grid-column: 2;
    }
  }

  &--left {
    @include breakpoint($cor-desktop) {
      grid-column: 1;
    }
  }
}

body:not(.international-font) .cor-three-column-tile {
  &__description {
    @include cor-rich-text(
      $map: (
        'p': (
          'size': [14px,
          14px],
          'lineheight': [24px,
          24px],
          'spacing': [.01rem,
          .01rem],
        ),
      )
    );
  }
}

body.international-font .cor-three-column-text-tile {
  &__description {
    @include cor-rich-text(
      $map: (
        'p': (
          'face': neueFrutiger,
          'size': [14px,
          14px],
          'lineheight': [24px,
          24px],
          'spacing': [.01rem,
          .01rem],
        ),
      )
    );
  }
}
