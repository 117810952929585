@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/_cor-layouts.scss';
@import 'styles/mixins/_typography';
@import 'styles/_cor-rich-text';
@import 'styles/typography/_mixins';

.corporate {
  &__breadcrumbs {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .cor-image {
    margin-bottom: 60px;
    @include breakpoint($cor-desktop) {
      margin-bottom: 80px;
    }
  }

  body:not(.international-font) .cor-content-fifty-fifty__paragraph {
    @include cor-rich-text(
      $map: (
        'p': (
          'size': [14px,
          16px],
          'lineheight': [24px,
          28px],
          'spacing': [.01rem,
          .01rem],
        ),
        'span': (
          'size': [14px,
          16px],
          'lineheight': [24px,
          28px],
          'spacing': [.01rem,
          .01rem],
        ),
      )
    );
  }
}

body.international-font .cor-content-fifty-fifty__paragraph {
  @include cor-rich-text(
    $map: (
      'p': (
        'face': neueFrutiger,
        'size': [14px,
        16px],
        'lineheight': [24px,
        28px],
        'spacing': [.01rem,
        .01rem],
      ),
      'span': (
        'face': neueFrutiger,
        'size': [14px,
        16px],
        'lineheight': [24px,
        28px],
        'spacing': [.01rem,
        .01rem],
      ),
    )
  );
}
