@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography';

.view-my-special-order-quote-modal {
  .modal-header {
    margin-bottom: $s2-5;
  }

  &__content-wrapper {
    padding: 0 $s2;
    @include breakpoint(medium) {
      padding: 0 $s4;
    }
  }

  &__comment-title,
  &__info-label {
    @include df-caption;
    color: $red;
  }

  &__info-value {
    font: 18px/1.5 $frutiger-65-bold;
    min-height: 24px;
    @include breakpoint(medium) {
      font: 20px/1.5 $frutiger-65-bold;
    }
  }

  &__quote-info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include breakpoint(medium) {
      margin-bottom: $s3;
    }
  }

  &__comment {
    margin-bottom: $s3;
  }

  .view-my-special-order-quote-modal-warning {
    margin-bottom: $s4;

    &__location-deleted {
      .quote-contact-rep-notification {
        display: none;
      }
    }

    .general-warning__title {
      @include df-p1-medium;
    }

    a {
      color: $red;

      &:hover {
        color: $light-red;
      }
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    flex-direction: column;

    .cor-button.text {
      padding-bottom: $s1-5;
    }

    @include breakpoint(medium) {
      justify-content: flex-end;
      flex-direction: row;

      .cor-button.text {
        padding-bottom: 0;
      }
    }
  }

  &__checkout {
    width: 100%;

    @include breakpoint(medium) {
      margin-left: $s4;
      width: auto;
    }
  }

  &__info-block {
    margin: 0 $s2 $s3 0;

    @include breakpoint(medium) {
      margin: 0;
    }
  }

  &__comment-text {
    @include df-p2-medium;
    word-break: break-word;

    @include breakpoint(medium) {
      @include df-p1-medium;
    }
  }

  .modal__close-button {
    top: 32px;
    right: 32px;
  }
}
