@import 'styles/_variables';
@import 'styles/_foundation';
@import 'styles/components/_carousel';
@import 'styles/typography/_mixins';

$slider-arrow-size: 36px;
$slider-dot-size: 16px;

.buy-it-again-skus-slider {
  padding-bottom: $s2;
  position: relative;
  background-color: $white;

  &--single-order {
    padding-bottom: $s6;
  }

  .swiper {
    &-button-next,
    &-button-prev {
      top: 32px;
      height: $slider-arrow-size;
      width: $slider-arrow-size;
      background: url('~assets/images/icons/icon-recent-orders-slider-arrow.svg') $white no-repeat;
      background-size: contain;

      &:after {
        display: none;
      }
    }

    &-button-prev {
      z-index: $dropdown-z-index;
    }

    &-button-next {
      top: 50px;
      transform: translate(0, -50%) rotate(180deg);
    }

    &-pagination {
      position: static;
      text-align: start;
      padding-left: $s2;
      margin-bottom: $s2;
      display: flex;

      &-horizontal {
        &.swiper-pagination-bullets {
          .swiper-pagination-bullet {
            width: $slider-dot-size;
            height: $slider-dot-size;
            border: 2px solid $red;
            background-color: transparent;
            border-radius: 50%;
            opacity: 1;
            margin: 0 2px;

            &-active {
              background-color: $red;
            }
          }
        }
      }
    }
  }

  &--single-order {
    .swiper-pagination {
      margin: $s4 0 $s2 0;
    }
  }
}
