@import 'corporate-ui/cor-typography/cor-typography';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

.cor-text-field {
  $text-field: &;
  color: $black;

  &__label {
    display: flex;
    margin-bottom: 9px;
    min-height: 13.53px;
  }

  &__label-asterisk {
    color: $red;
  }

  &__input-decorated {
    position: relative;

    #{$text-field}__input {
      padding-left: 36px;
    }
  }

  &__input-adornment {
    position: absolute;
    top: 50%;
    right: auto;
    left: $s2;
    transform: translateY(-50%);
    margin-right: $s1;
    font-family: $museo-700;
    font-size: 23px;
    color: $red;
  }

  &__input-highlight {
    width: 100%;
    padding: 11px $s2;
    font-size: 16px;
    line-height: 1.5;
    border: 1px solid $input-gray;
    border-radius: 8px;
    background-color: $light-yellow;
  }

  &__input {
    width: 100%;
    padding: 9px 20px;
    border: 1px solid $cor-light-gray;
    border-radius: 6px;
    background-color: $cor-bg-gray-light;
    color: $aubergine-gray;
    @extend .cor-typography__body-large !optional;

    &:hover,
    &:active,
    &:focus {
      border-color: $cor-light-gray;
    }

    &:disabled {
      border-color: $cor-light-gray;
      background-color: $cor-bg-gray-light;
    }
  }

  &__error-message__container {
    min-height: 26px;
  }

  &__error-message {
    color: $cor-dawn-red;
    @extend .cor-typography__body-small !optional;
  }

  &--error {
    #{$text-field}__input {
      border-color: $red;
    }

    #{$text-field}__description {
      display: none;
    }
  }

  &--literal {
    #{$text-field}__input:disabled {
      color: $black;
      background-color: transparent;
      border: 0;
      padding-left: 0;
    }
  }
}
