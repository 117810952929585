@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

.import-shopping-list-modal {
  $bullet-size: 20px;
  $bullet-font-size: 14px;
  $bullet-margin: $s2;

  z-index: $modal-z-index;
  color: $black;

  @include df-p1-medium();
  @include breakpoint(small down) {
    @include df-p2-medium();
  }

  .modal-footer {
    padding: $s2;

    @include breakpoint(medium) {
      padding: $s4;
    }
  }

  &.modal__content {
    min-height: 300px;

    @include breakpoint(medium) {
      min-height: 500px;
    }
  }

  &__content {
    padding: 0 $s2-5;

    @include breakpoint(medium) {
      padding: 0 $s4;
    }
  }

  .modal-header {
    padding: $s2;
    margin-bottom: $s2-5;

    @include breakpoint(medium) {
      padding: $s4;
    }
  }

  &__instructions {
    > ol > li {
      margin: $s3 0;
    }

    ol {
      list-style-type: none;
      list-style-position: outside;
      counter-reset: upload-instructions-list-counter;

      @include breakpoint(small down) {
        padding: 0 $s1;
      }

      li {
        position: relative;
        margin-left: 0;
        padding-left: $bullet-size + $bullet-margin;
        line-height: $bullet-size + 4px;

        &:before {
          color: $white;
          display: block;
          content: counter(upload-instructions-list-counter) ' ';
          counter-increment: upload-instructions-list-counter;
          font-size: $bullet-font-size;

          position: absolute;
          top: 10px;
          left: 0;
          transform: translateY(-50%);
          width: $bullet-size;
          height: $bullet-size;
          border-radius: 50%;
          background: $red;
          padding: 1px;
          text-align: center;
        }
      }
    }

    a,
    .learn-more-handle {
      text-decoration: underline;
      color: $red;
    }

    .learn-more-handle {
      cursor: pointer;

      img {
        width: 14px;
        height: 14px;
        vertical-align: -2px;
        margin-left: 1px;
      }

      &.expanded img {
        transform: rotate(90deg);
      }
    }

    .example {
      padding-right: $s4-5;

      .header {
        margin-top: $s1;
        line-height: 2em;
        font-weight: bold;
      }

      img {
        max-width: 100%;
      }
    }

    .sample-content {
      margin: $s1 0;
      font-family: monospace;
      font-weight: bold;
      line-height: initial;
      overflow-x: auto;
    }
  }

  .submit-spinner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $white;
  }

  &__upload-ui {
    position: relative;
    margin: $s2 0;

    .upload-status {
      &.unsuccessful {
        font-weight: bold;
        color: $red;
      }
    }

    .upload-details {
      @include breakpoint(small down) {
        display: flex;
        justify-content: space-between;
        line-height: 24px;
        &.unsuccessful {
          padding-right: $s1;
        }
      }
      @include breakpoint(medium) {
        .file-size {
          &::before {
            content: ' (';
          }
          &::after {
            content: ')';
          }
        }
      }
    }

    @include breakpoint(small down) {
      hr {
        border-color: #ccc;
        border-width: 0 0 1px 0;
        margin-bottom: $s2;
      }

      div {
        line-height: 2em;
        padding-left: $bullet-size + $bullet-margin + $s1;
      }

      button {
        width: 100%;
        display: block;
        margin-top: $s2;
      }

      .upload-icon {
        position: absolute;
        left: 7px;
        top: 19px;
        width: 24px;
        padding: 0;

        img {
          position: relative;
          display: inline-block;
          line-height: 2em;
        }
      }

      .upload-status {
        font-weight: bold;
        &:after {
          content: ':';
        }
      }
    }

    @include breakpoint(medium) {
      padding: $s1;
      border: 1px solid $input-gray;
      border-radius: 4px;
      background-color: $light-snow;
      text-align: center;
      line-height: 2em;
      margin-left: $s4;

      hr,
      .upload-icon {
        display: none;
      }
    }
  }

  &__footer {
    width: 100%;
    background-color: $white;

    @include breakpoint(medium) {
      margin: 0;
    }

    &__action-button {
      text-align: right;

      button {
        width: 160px;
      }

      @include breakpoint(small down) {
        button {
          width: 100%;
          display: block;
        }
      }
    }
  }

  &__drop-zone {
    outline: 0;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
  }

  .drop-zone-overlay {
    $icon-width: 72px;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(254, 248, 234, 0.9);
    z-index: $drop-zone-z-index;
    border: 4px solid #b0aca3;
    pointer-events: none;

    transition: opacity 0.2s linear;

    &:not(.drag-active) {
      opacity: 0;
    }

    img {
      position: absolute;
      top: calc(50% - #{$icon-width / 2});
      left: calc(50% - #{$icon-width / 2});
      height: $icon-width;
      width: $icon-width;
    }

    .instructions {
      text-align: center;
      position: absolute;
      width: 100%;
      top: calc(50% + #{$icon-width / 2} + #{$s2});
    }
  }

  &__results {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    footer {
      text-align: right;
    }
  }

  &__errors {
    flex: 1;
    padding: 0 $s2;

    @include breakpoint(medium) {
      padding: 0 $s4 $s3 $s4;
    }

    li {
      list-style-position: inside;
      list-style-type: disc;
      line-height: 1.5em;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__errors-messages {
    @include df-p1-medium;
  }
}
