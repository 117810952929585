@import 'styles/_variables';
@import 'styles/typography/_mixins';
@import 'styles/_foundation';

.discontinued-item-info {
  display: flex;
  position: relative;

  &__info {
    padding-top: $s0-5;
    display: flex;
    align-items: flex-start;
    flex-flow: column;

    @include breakpoint(medium) {
      padding-top: 0;
    }
  }

  &__info-wrapper {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
  }

  &__status {
    margin-right: $s2;
    margin-bottom: 19px;
  }

  &__name {
    font: 14px/1.43 $museo-700;
    letter-spacing: 0.3px;
    margin-bottom: $s1-5;

    @include breakpoint(medium) {
      margin-bottom: $s0-5;
    }
  }

  &__number {
    @include df-p2-medium;
    display: inline-block;
    color: $red;
    margin-bottom: $s1;
  }

  &__price {
    @include df-h1-mobile;
    text-align: center;
    margin-top: $s2;

    @include breakpoint(medium) {
      margin-top: $s1;
    }

    &.price-status {
      margin-top: $s1;

      @include breakpoint(medium) {
        margin-top: 0;
      }
    }

    @include breakpoint(medium) {
      text-align: left;
    }
  }

  .product-indicators {
    padding: 2px;
  }

  .hierarchy-accounts-special-order-message {
    align-items: center;
    margin-top: $s1;
  }
}
