@import '../variables';

.hamburger-menu {
  width: 32px;

  &:before,
  &:after {
    display: block;
    content: '';
  }

  &:before,
  &:after,
  &__line {
    height: 4px;
    margin: 4px 0;
    background: $black;
  }
}

.corporate-hamburger-menu {
  width: 26px;
  height: 26px;
  padding: 0;
  appearance: none;
  border: 0;
  background-color: transparent;
  position: relative;
  cursor: pointer;

  &:before,
  &:after {
    display: block;
    content: '';
  }

  &:before,
  &:after,
  .horiz-line {
    height: 1px;
    width: 100%;
    margin-bottom: 8px;
    background: $black;
    transition: transform 200ms;
  }

  &:after {
    margin-bottom: 0;
  }

  &.is-active {
    .horiz-line {
      display: none;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
    &:before,
    &:after {
      position: absolute;
    }
  }
}
