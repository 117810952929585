@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

.edit-locations-user-info-modal {
  &__form-fields {
    display: flex;
    flex-wrap: wrap;
  }

  &__divider {
    height: 1px;
    background: $input-gray;
    margin: $s2-5 0;
  }

  &__field {
    width: 100%;
    padding: $s1 0;
    margin: 0;

    @include breakpoint(medium) {
      &:nth-child(odd) {
        padding-right: $s4;
      }
      width: 50%;
      padding: $s2 0;
    }
  }

  &__email-field-label,
  &__account-field-label {
    @include df-p1-medium;
    margin-bottom: 5px;
  }

  &__email-field-value {
    color: #979797;
    padding: 0;

    @include breakpoint(medium) {
      padding: $s2 0;
    }
  }

  &__account-field-label {
    display: flex;

    .custom-tooltip {
      margin-left: $s1;

      &__content {
        max-width: 100%;

        @include breakpoint(medium) {
          max-width: 475px;
        }
      }
    }
  }

  &__account-field-text {
    margin-top: $s3;
    display: flex;
    align-items: center;

    .custom-tooltip {
      margin-left: $s1;
    }
  }

  &__locations-label {
    display: flex;
    align-items: center;
  }

  &__locations-dropdown {
    .select__menu-list {
      max-height: 130px;
    }
  }

  &__submit-btn {
    padding: 11px $s2;
    margin-left: $s2;

    @include breakpoint(medium) {
      padding: 11px $s4;
    }
  }

  &__general-error {
    width: 100%;
  }

  .custom-tooltip .custom-tooltip__content {
    margin-left: -28px;
    max-width: 200px;

    @include breakpoint($ipad-portrait) {
      margin-left: initial;
    }

    &:after {
      right: 45px;
      left: auto;

      @include breakpoint($ipad-portrait) {
        right: 50%;
        left: 50%;
      }
    }
  }

  &__body {
    padding: 0 $s2;

    @include breakpoint(medium) {
      padding: 0 $s4;
    }
  }
}
