@import 'styles/_variables';
@import 'styles/typography/_mixins';
@import 'styles/_foundation';

$mobileIconSize: 25px;
$desktopIconSize: 32px;

.request-quote-success-message {
  flex: 0 1 auto;
  border-top: 0;
  margin-top: 0;
  margin-left: 44px;

  @include breakpoint(medium) {
    margin-left: 52px;
  }

  a {
    color: $red;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
