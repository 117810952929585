@import 'styles/_variables';
@import 'styles/typography/_mixins';
@import 'styles/_foundation';

$thumbnail-mobile-size: 80px;
$thumbnail-desktop-size: 104px;
$thumbnail-loading-mobile-size: 32px;
$thumbnail-loading-desktop-size: 48px;

.product-zoom-gallery-modal {
  &__header {
    padding-right: $s2-5;
    padding-bottom: $s1-5;
    margin-bottom: $s2;

    @include breakpoint(medium) {
      padding-bottom: $s2-5;
      margin-bottom: $s5;
    }
  }

  .swiper {
    margin-left: 0;

    @include breakpoint(small down) {
      max-width: 100%;

      &-wrapper {
        margin-top: $s2;
        width: 100%;
      }

      &-slide {
        width: $thumbnail-mobile-size;
      }
    }
  }

  &__brand {
    @include df-p2-bold;
    margin-bottom: $s0-5;

    @include breakpoint(medium) {
      @include df-p1-bold;
    }
  }

  &__name {
    @include df-h1;
    color: $black;
  }

  &__gallery {
    display: flex;
    flex-direction: column;
    padding: $s4;

    @include breakpoint(medium) {
      flex-direction: row;
    }
  }

  &__items-wrapper {
    margin-right: $s2;
    max-width: 224px;
  }

  &__items {
    display: flex;
    flex-flow: row wrap;
    margin: -$s1;
  }

  &__item {
    flex: 0 1 50%;
    padding: $s1;
  }

  &__main-image {
    flex: 1 0 auto;
    border: 1px solid $input-gray;
  }

  &__thumbnail {
    border: 1px solid $input-gray;
    outline: none;
    box-sizing: border-box;
    width: $thumbnail-mobile-size;
    height: $thumbnail-mobile-size;
    overflow: hidden;

    &:hover {
      cursor: pointer;
    }

    @include breakpoint(small only) {
      .product-image {
        width: 100%;
        height: $thumbnail-mobile-size;
      }
    }

    @include breakpoint(medium) {
      height: $thumbnail-desktop-size;
      width: $thumbnail-desktop-size;
    }

    img {
      width: 100%;
      height: 100%;
      vertical-align: top;
    }

    .loading-indicator {
      height: $thumbnail-loading-mobile-size;
      width: $thumbnail-loading-mobile-size;

      @include breakpoint(medium) {
        height: $thumbnail-loading-desktop-size;
        width: $thumbnail-loading-desktop-size;
      }
    }

    &--active {
      border: 2px solid $red;
    }
  }
}
