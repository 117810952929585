@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography';

.credit-card-surcharge-text {
  $credit-card-surcharge-text: &;

  display: flex;
  flex-direction: row;
  background-color: $snow;
  border: 2px solid $light-gray;
  border-radius: 6px;
  position: relative;
  margin-bottom: $s3;

  @include breakpoint(medium) {
    margin-bottom: 0;
  }

  &__text-container {
    @include df-p2-medium;
    color: $achromatic-gray;
    margin: $s1 0 10px $s1-5;
    width: 100%;
    overflow: hidden;

    @include breakpoint(medium) {
      margin-left: $s2-5;
    }

    &--is-collapsed {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    &--is-expanded {
      max-height: none;
      height: fit-content;
    }
  }

  &__arrow {
    height: 16px;
    width: 16px;
    cursor: pointer;
    transform: rotate(90deg);

    &--is-expanded {
      transform: rotate(-90deg);
    }

    &-container {
      width: $s6;
      display: flex;
      justify-content: center;
      padding-top: 8px;
    }
  }
}
