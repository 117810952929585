@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

.breadcrumbs {
  margin: 0 -#{$s0-5};

  &__item {
    display: inline-block;
    color: $red;
    margin: 0 $s0-5;

    &--active {
      color: $black;
    }

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__separator {
    padding-left: $s1;

    img {
      vertical-align: middle;
      padding-bottom: 3px;
      width: 12px;

      @include breakpoint(medium) {
        width: 16px;
      }
    }
  }
}

body:not(.international-font) .breadcrumbs__item {
  @include df-p2-medium;

  @include breakpoint(medium) {
    font-size: 16px;
  }
}
body.international-font .breadcrumbs__item {
  @include neue-df-p2-medium;
  font-weight: 600;

  @include breakpoint(medium) {
    @include neue-df-p1-medium;
    font-weight: 600;
  }
}
