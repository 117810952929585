@import 'styles/_foundation';
@import 'styles/_variables';
@import '/src/styles/mixins/typography';

.main-nav {
  // padding should change to match the layout container
  $padding: 24px;
  overflow-y: auto;
  height: calc(100% - 95px);
  position: relative;
  overflow-x: hidden;

  &.is-logged {
    .main-nav-item {
      background-color: $cor-bg-gray-brightest;
      @include breakpoint(medium) {
        background-color: $cor-bg-gray;
      }
    }
    .icons-menu {
      background-color: $cor-bg-gray-lightest;
    }
    .main-nav__menu--shop-button {
      background-color: $cor-bg-gray-brightest;
    }
  }

  @include breakpoint(medium) {
    overflow-y: initial;
    overflow-x: initial;
    height: initial;
  }

  .is-shop-nav {
    background-color: $cor-bg-gray;
  }

  &__container {
    position: relative;
  }

  &__menu {
    display: flex;
    flex-direction: column;

    @include breakpoint(medium) {
      flex-direction: row;
      display: none;
    }

    &.is-open {
      display: flex;
    }

    &--shop-button {
      color: $cor-dawn-red;
      border-top: 1px solid $cor-light-gray;
    }

    &--shop {
      .main-nav-item {
        background-color: $white;

        &.is-open {
          background-color: $cor-bg-gray;
        }
      }
    }

    &--corporate {
      position: relative;

      &.authenticated .main-nav-item {
        background-color: $white !important;
      }
      @include breakpoint(medium) {
        border-top: 1px solid $gray;
      }
    }

    &--shop,
    &--corporate {
      box-shadow: none;
      @include breakpoint(medium) {
        justify-content: center;
        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
      }
    }

    &__sales-rep {
      padding: 30px $padding;
    }
  }

  &__menu-item {
    border-top: 1px solid $gray;
    overflow-x: hidden;

    @include breakpoint(medium) {
      border-top: 0;
    }
  }

  &__go-back,
  &__submenu-link {
    line-height: 1.25;
  }

  &__go-back {
    display: flex;
    padding: 25px;
    column-gap: 5px;

    a {
      text-decoration: none;
    }
  }

  &__submenu {
    display: none;
    background-color: lightblue;

    &:not(:last-child) {
      padding-bottom: $padding;
    }

    &--main {
      position: relative;
    }

    &--group {
      display: block;
    }

    &.is-open {
      display: block;
    }

    & .main-nav__submenu {
      background-color: lightcyan;
      position: absolute;
      right: -100%;
      top: 0;
      width: 100%;

      & .main-nav__submenu {
        background-color: lavender;
        position: absolute;
        right: -100%;
        top: 0;
        width: 100%;
      }
    }
  }

  &__submenu-link {
    display: block;
    padding-bottom: $padding;
  }

  &__my-account-menu {
    outline: none;
    border: none;
    align-items: center;
    display: flex;
    padding: $s3;
    width: 100%;

    &-chevron-icon {
      width: 25px;
    }

    &-btn-text {
      margin: 0 7px 0 $s1-5;
      text-transform: capitalize;
      font: 16px/1.43 $museo-700;
      letter-spacing: 0.6px;
      color: $dark-gray;
    }
  }

  .menu-panel__item a {
    text-decoration: none;
  }
}

.shop-menu,
.corporate-menu {
  &-enter {
    transition: all 100ms;
    transform: translateY(0);

    @include breakpoint(medium) {
      transform: translateY(-100%);
    }
  }

  &-enter-done {
    transition: all 100ms;
    transform: translateY(0);
  }

  &-exit {
    transition: all 100ms;
    transform: translateY(0);
  }

  &-exit-done {
    transition: all 100ms;
    transform: translateY(0);

    @include breakpoint(medium) {
      transform: translateY(-100%);
    }

    &.is-open {
      transform: translateY(0);
    }
  }
}
