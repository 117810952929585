@import 'styles/_foundation';
@import 'styles/_variables';

.cor-product-list {
  background-color: $cor-white;
  position: relative;
  padding: 0 $s2;
  margin-bottom: 50px;
  z-index: $base-z-index;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @include breakpoint(medium) {
    padding: 0 $s8;
    justify-content: center;
    margin-bottom: 0;
  }

  &__heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: $s5 0;

    @include breakpoint($cor-desktop) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 44px 0;
    }
  }

  &__title {
    margin-bottom: 20px;
    @include breakpoint($cor-desktop) {
      margin-bottom: 0;
    }
  }

  &__products {
    position: relative;
    display: inline-flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 0;
    row-gap: 24px;
    column-gap: 20px;

    .cor-product-tile:nth-child(2n) {
      .cor-product-tile-status {
        &__request-quote-button-tooltip {
          width: auto;

          .custom-tooltip {
            .custom-tooltip__content {
              &.show {
                @include breakpoint(415px down) {
                  margin-left: -$s1;

                  &:after {
                    right: 36%;
                    left: auto;
                  }
                }

                @include breakpoint(393px down) {
                  &:after {
                    right: 28%;
                  }
                }

                @include breakpoint(376px down) {
                  &:after {
                    right: 25%;
                  }
                }

                @include breakpoint(360px down) {
                  &:after {
                    right: 42%;
                  }
                }

                @include breakpoint(medium) {
                  margin-left: 0;

                  &:after {
                    left: 50%;
                  }
                }
              }
            }
          }
        }
      }
    }

    .cor-product-tile:nth-child(2n + 1) {
      .cor-product-tile-status {
        &__request-quote-button-tooltip {
          width: auto;

          .custom-tooltip {
            .custom-tooltip__content {
              &.show {
                @include breakpoint(415px down) {
                  margin-left: -$s1;

                  &:after {
                    right: 42%;
                    left: auto;
                  }
                }

                @include breakpoint(medium) {
                  margin-left: 0;

                  &:after {
                    left: 50%;
                  }
                }
              }
            }
          }
        }
      }
    }

    @include breakpoint(medium) {
      column-gap: 49px;
      row-gap: 48px;
    }
  }

  &__see-all-wrapper {
    position: relative;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 3.76%,
      rgba(255, 255, 255, 0.83) 38.17%,
      #fff 69.89%,
      #fff 100%
    );
    text-align: center;
    padding-top: 100px;
    transition: all 100ms linear;
    z-index: 1;

    @include breakpoint(medium) {
      padding-bottom: $s4-5;
    }

    &.is-expanded {
      margin-top: 0 !important;
      padding-top: 5px;
    }
  }

  .cor-product-tile {
    margin: 0 0 14px;
  }

  .select {
    &__control {
      height: 48px;
      width: 198px;

      &--is-disabled {
        .select__single-value {
          color: $input-gray;
        }
      }
    }

    &__single-value {
      font: 16px/1.5 $frutiger-65-bold;
      color: $black;
    }
  }

  &__add-to-dropdown {
    #{&}--disabled {
      &.select__option {
        pointer-events: none;
        color: $input-gray;
        cursor: unset;
        font-style: italic;

        &:hover {
          background-color: $white;
        }
      }
    }
  }

  &__add-to-button {
    width: 100%;
  }

  .scondary {
    background-color: #ffffff;
  }

  &__see-all-button {
    width: 100%;
    margin-bottom: $s2;
    background-color: $white;

    @include breakpoint(medium) {
      width: 105px;
    }
  }

  .cor-button.secondary:not([disabled]):hover {
    background-color: $white;
  }
}
