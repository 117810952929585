@import 'styles/_variables';

@media print {
  .print-version {
    &--order-history-product-list {
      .page-layout__header,
      .page-layout__footer,
      .breadcrumbs,
      .pagination,
      .account-tabs-nav,
      .account-tabs-dropdown,
      .order-view-switcher,
      .order-history-tab__controls-top-row,
      .order-history-tab__print-btn,
      .order-history-tab__actions-block-wrapper,
      .order-history-tab__selected-items-banner-wrapper,
      .ordered-product-item__buttons,
      .custom-tooltip,
      .ordered-product-item__add-to-cart,
      .custom-checkbox,
      .order-history-tab__controls-top-row-products,
      .ordered-product-item--special .ordered-product-item__buttons {
        display: none;
      }

      .product-image__placeholder {
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
      }

      .account__tab-content {
        border: none;
        background: none;
        padding: 0;
      }

      .ordered-product-item {
        display: flex;
        justify-content: flex-start;

        &__main-info {
          width: 100%;
          margin-bottom: $s2;

          &__product-info {
            width: 100%;
          }
        }

        .ordered-product-item__info {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          margin-top: $s0-5;
          width: 100%;

          .ordered-product-item__info-block-title {
            width: 26%;
            max-width: 26%;
          }

          .grid-x.align-justify {
            width: 100%;
          }
        }
      }
    }

    &--order-details {
      .page-layout__header,
      .page-layout__footer,
      .breadcrumbs,
      .pagination,
      .custom-checkbox,
      .custom-tooltip,
      .order-details__print-button,
      .order-details__print-icon,
      .order-details__active-print-icon,
      .order-details__order-cancel-button,
      .order-details__reorder-btn-wrapper,
      .order-details-purchased-products__buttons,
      .order-details-purchased-products__select-all-wrapper,
      .order-details-purchased-products__add-selection,
      .order-details-purchased-product-item__proprietary-icon,
      .purchased-product-info__buttons,
      .purchased-product-info__request-quote-button,
      .purchased-product-info__add-to-cart,
      .purchased-product-info__discontinued-button,
      .sticky-view,
      .icon-added-to-cart,
      .reorder-btn,
      .order-details__help-notification {
        display: none !important;
      }

      .grid-x {
        display: block;
      }

      .order-details-purchased-products,
      .order-details__payment-info,
      .order-summary-sticky,
      .order-delivery-details,
      .order-summary-sticky-total-section,
      .order-details__payment-details,
      .order-details-delivery-details {
        min-width: 700px;
      }

      .order-summary {
        margin: $s3 0;
        position: absolute;
        top: 300px;
      }

      .order-delivery-details {
        margin-top: 325px;
      }

      .order-details-purchased-product-item.grid-x {
        display: flex;
      }

      .purchased-product-info__block {
        white-space: nowrap;
        width: fit-content;
      }

      .product-image__placeholder {
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
      }

      .product-status {
        print-color-adjust: exact;

        &--special-order {
          background: $green;
        }

        &--discontinued,
        &--phasing-out {
          background: $brown;
        }

        &--cancelled {
          background: $dark-gray;
        }
      }

      .product-indicators {
        print-color-adjust: exact;

        &--out-of-stock {
          background-color: $brown;
        }
        &--low-in-stock {
          background-color: $yellow;
        }
      }
    }

    &--shopping-list-details {
      button,
      .page-layout__header,
      .page-layout__footer,
      .breadcrumbs,
      .pagination,
      .custom-checkbox,
      .custom-tooltip,
      .modal,
      .shopping-list-details__print-btn,
      .shopping-list-details__mobile-edit-button,
      .shopping-list-details__search,
      .shopping-list-details__view-toggle,
      .shopping-list-details__desktop-edit-button,
      .selected-items-section,
      .selected-items-section__buttons,
      .selected-items-section__buttons--add-to-list,
      .selected-items-section__buttons--add-button,
      .shopping-list-total-section__add-to-cart-button,
      .list-view-line-item__actions-wrapper,
      .list-view-items-list-header__title-actions,
      .shopping-list-product__buttons,
      .shopping-list-product__add-to-cart,
      .shopping-list-product__icons,
      .shopping-list-product__request-quote,
      .hierarchy-accounts-special-order-message,
      .icon-added-to-cart,
      .quantity-control > button {
        display: none;
      }

      .list-view-line-item {
        margin-top: -10px;
        padding-top: 0;
        margin-bottom: $s2;
        height: fit-content;

        &__info-block-quantity {
          max-width: 33.33%;
        }

        &__info-block-total {
          margin-left: 0;
        }

        &__quantity {
          min-width: 400px;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          border-top: none;
          flex-wrap: nowrap;
        }

        .quantity-control__input {
          padding: 0;
          text-align: left;
        }
      }

      .selected-items-section {
        &--marketing-list {
          .sticky-view__content {
            border: 1px solid $yellow;
          }
        }
      }

      .list-view-items-list-header {
        box-shadow: none !important;

        .compensate {
          display: none;
        }
      }

      .shopping-list-total-section {
        &__content {
          border: 1px solid $red;
        }

        &__content-list-view--mobile {
          border: none;
        }

        &__content-details-view--mobile {
          border: none;
        }

        &__values {
          height: 100%;
        }
      }

      .shopping-list-total-section__values {
        display: flex;
        flex-wrap: nowrap;
      }

      .list-view-items-list-header__wrapper {
        min-width: 400px;
        white-space: nowrap;
        margin-left: $s1-5;
      }

      input {
        border: none;
        padding-left: 30px;
      }

      .shopping-list-product {
        &__main-info {
          min-width: 40%;
        }

        &__info-block-values {
          min-width: max-content;
          white-space: nowrap;
        }

        &__info-block {
          text-align: center;

          .quantity-control__input {
            text-align: left;
            min-width: fit-content;
          }
        }

        &__info-block-text {
          text-align: center;
          min-width: fit-content;
        }

        &__info-block-quantity-mobile {
          .quantity-control__input {
            text-align: left;
            min-width: fit-content;
            margin-left: $s5;
          }
        }

        &__additional-info-block {
          text-align: left;

          &-mobile {
            width: 100%;
          }
        }
      }

      .product-status-labels {
        display: flex;
        flex-direction: row;
      }

      .shopping-list-details__selected-items-section {
        @media (max-width: 960px) {
          position: absolute;
          top: 145px;
          height: fit-content;
        }
      }

      .product-status {
        print-color-adjust: exact;
        padding: 5px 12px 3px !important;
        margin-left: 8px !important;

        &--special-order {
          background: $green;
        }

        &--discontinued,
        &--phasing-out {
          background: $brown;
        }

        &--cancelled {
          background: $dark-gray;
        }
      }

      .product-indicators {
        print-color-adjust: exact;

        &--out-of-stock {
          background-color: $brown;
        }
        &--low-in-stock {
          background-color: $yellow;
        }
      }

      .sticky-view__content {
        position: inherit;

        .grid-container {
          padding: 0;
        }

        .list-view-items-list-header__details {
          padding-left: 0;
        }
      }

      .product-image__media--with-overlay:after {
        display: none;
      }
    }

    .shopping-list-details--marketing-list {
      .shopping-list-total-section {
        &__content {
          border: 1px solid $yellow;
        }

        &__content-details-view--mobile {
          border: none;
        }

        &__content-list-view--mobile {
          border: none;
        }
      }
    }
  }

  .corp-header__sticky-wrapper {
    display: none;
  }
  .environment-notification__wrapper {
    display: none;
  }
  .cor-page-layout__footer {
    display: none;
  }
}
