@import 'styles/_variables';
@import 'styles/typography/_mixins';
@import 'styles/_foundation';

.select-option-modal {
  &__options {
    padding: 0 $s2-5;
    margin: $s3-5 0 $s3-5 0;

    @include breakpoint(medium) {
      padding: 0 $s4;
    }
  }

  &__button {
    width: 100%;
  }

  .modal__close-button {
    &:before,
    &:after {
      height: 27px;
      background-color: $dark-gray;
    }
  }

  &__cancel-button {
    display: flex;
    justify-content: center;
    margin-top: $s3;
  }
}
