@import 'styles/_foundation';
@import 'styles/_variables';

$iconSize: 24px;

.select-field {
  $selectPrefix: '.select-field';
  #{$selectPrefix}__control {
    min-height: 40px;
    border-radius: 8px;
    border-color: $input-gray;
    font-size: 14px;
    line-height: 1.71;
    padding-left: 10px;
    box-shadow: none;

    &:hover,
    &--is-focused {
      border-color: $dark-gray;

      #{$selectPrefix}__indicator-separator {
        background-color: $dark-gray;
      }
    }

    #{$selectPrefix} {
      &__indicator-separator {
        background-color: $input-gray;
        margin: 0;
      }

      &__icon {
        width: $iconSize;
        height: $iconSize;
        margin-left: -2px;
        margin-right: 2px;
        img {
          width: 100%;
          height: auto;
        }
      }

      &__dropdown-icon {
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid $red;
        transition: all 0.3s ease;
      }

      &__indicators {
        width: 40px;
      }

      &__indicator {
        width: 100%;
        justify-content: center;
        flex: 1;
      }

      &__value-container {
        padding: 2px 10px 2px 0;
      }

      &__placeholder,
      &__single-value {
        top: 58%;
      }

      &__single-value {
        color: $black;
      }
    }

    &--is-disabled {
      border-color: $light-gray;
      background: $snow;

      #{$selectPrefix}__indicator-separator {
        background: $light-gray;
      }

      #{$selectPrefix}__dropdown-icon {
        border-top-color: $dark-gray;
      }
    }

    &--menu-is-open {
      #{$selectPrefix}__dropdown-icon {
        transform: rotate(180deg);
      }
    }
  }

  #{$selectPrefix}__menu {
    border-radius: 4px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);

    #{$selectPrefix} {
      &__option {
        font-size: 14px;
        line-height: 1.43;
        padding: $s1-5;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        color: $black;

        &:hover {
          background: $light-coral;
        }

        &--is-focused {
          background: $light-coral;

          &:not(:hover) {
            background: $light-coral;
          }
        }

        &--is-selected {
          background: transparent;
          color: $red;
        }
      }
    }
  }

  #{$selectPrefix}__menu-list {
    padding: 0;
  }
}
