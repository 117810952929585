@import 'styles/_variables';
@import 'styles/_foundation';
@import '/src/styles/mixins/typography';

.main-subnav-content {
  $msc-root: &;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  padding: 0 25px 25px;

  @include breakpoint(medium) {
    align-items: flex-start;
    column-gap: 20px;
    padding: 40px;
    background: $white;
    border-radius: 6px;
    box-shadow: 0px 0px 20px $gray;
  }

  &.two-col {
    @include breakpoint(medium) {
      grid-template-columns: 1fr minmax(40%, auto);
    }

    #{$msc-root}__links {
      min-width: 200px;
    }
  }

  &__drill-down,
  &__links {
    @include breakpoint(medium) {
      display: flex;
      column-gap: 50px;
    }
  }

  &__links {
    min-width: 100px;
  }

  &__drill-down {
    min-width: auto;
    @include breakpoint(medium) {
      min-width: 400px;
    }
  }

  &__link-panel {
    text-align: left;
    margin-bottom: 25px;

    @include breakpoint(medium) {
      margin-bottom: auto;
    }
    header {
      color: $light-gray-header;
      margin-bottom: 25px;
      text-transform: uppercase;
    }

    ul {
      padding: 0;
      margin: 0;
    }

    li:not(:last-of-type) {
      margin-bottom: 20px;
    }

    a,
    span {
      text-decoration: none;
    }
    a:hover {
      text-decoration: underline;
    }
  }

  &__highlights {
    display: none;
    @include breakpoint(medium) {
      display: block;
      min-width: 300px;
    }
  }

  &-link {
    .notification a,
    .notification p {
      color: $cor-dawn-red;
    }
  }
}

body:not(.international-font) .main-subnav-content {
  &__link-panel {
    header {
      @include font-frutiger-regular($size: 12, $spacing: 0.05rem);
    }
    a,
    span {
      @include font-museo-regular($size: 14, $lineheight: 22px);
    }
  }
}

body.international-font .main-subnav-content {
  &__link-panel {
    header {
      @include font-neue-frutiger-regular($size: 12, $spacing: 0.05rem);
    }
    a,
    span {
      @include font-neue-frutiger-regular($size: 14, $lineheight: 22px);
    }
  }
}
