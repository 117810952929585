@import 'styles/_variables';
@import 'styles/typography/_mixins';
@import 'styles/_foundation';

.create-shopping-list-modal {
  z-index: $modal-z-index;
  color: $black;

  &__form {
    padding: 0 $s2;

    @include breakpoint(medium) {
      padding: 0 $s4;
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    margin-top: $s4;
  }

  &__input {
    .text-field__label {
      margin-bottom: $s1;
      margin-top: $s2-5;

      @include breakpoint(medium) {
        margin-top: $s3;
      }
    }
  }

  &__create-list.button {
    padding: $s1-5 $s3;
  }

  &__cancel {
    margin-right: $s4;
  }
}
