@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/_cor-layouts.scss';
@import '/src/styles/mixins/typography';

.cor-distribution-map {
  background-color: $cor-bg-gray-lightest;
  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    @include content-inset($padding-mobile: 1rem);
  }
  &__map {
    height: 229px;
    width: 100%;
    max-width: 375px;
    margin: 16px auto;
    @include breakpoint(medium) {
      max-width: 1105px;
      height: 673px;
      margin: 64px auto;
    }
    & iframe {
      width: 100%;
      max-width: 375px;
      height: 229px;
      @include breakpoint(medium) {
        max-width: 1105px;
        height: 673px;
      }
    }
  }
  &__title {
    @include font-museo-regular($size: 26, $lineheight: 32px);
    font-weight: 400;
    text-align: center;
    margin: 0 auto;
    @include breakpoint(medium) {
      font-size: 36px;
      line-height: 44px;
      margin: 52px auto;
    }
  }
  &__subtitle {
    @include font-museo-regular($size: 20, $lineheight: 28px);
    font-weight: 400;
    @include breakpoint(medium) {
      font-size: 22px;
    }
    &-description {
      padding-top: 12px;
      font-size: 14px;
    }
  }
  &__main {
    display: flex;
    flex-direction: column-reverse;
    @include breakpoint(medium) {
      flex-direction: column;
    }
  }
  &__header {
    margin: 24px;
    @include breakpoint(medium) {
      margin: 0;
    }
  }
  &__content {
    margin: 24px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
    @include breakpoint(640px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include breakpoint(1024px) {
      margin: 24px 0;
      grid-template-columns: repeat(3, 1fr);
    }
    @include breakpoint(1200px) {
      margin: 24px 0;
      grid-template-columns: repeat(5, 1fr);
    }
    & .cor-distribution-center {
      display: none;
      @include breakpoint(medium) {
        display: flex;
        flex-direction: column;
      }
      &:nth-child(-n + 3) {
        display: flex;
        flex-direction: column;
      }
    }
    &.show-all {
      & .cor-distribution-center {
        display: flex;
        flex-direction: column;
      }
    }
  }
  &__btn-see-all {
    margin: 24px;
    display: block;
    width: fit-content;
    @include breakpoint(medium) {
      display: none;
    }
  }
}
