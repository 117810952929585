@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';
@import 'styles/mixins/typography';
@import 'styles/_cor-layouts.scss';

$utility-horizontal-margin: 20;

.corp-header {
  top: 0;
  z-index: $page-header-z-index;
  pointer-events: all;

  @include breakpoint(medium) {
    pointer-events: initial;
    box-shadow: 0 1px 10px rgba($black, 0.1);
  }

  &__top {
    position: relative;
    display: grid;
    z-index: $page-header-z-index;
    grid-template-areas:
      'logo nav'
      'search search';
    grid-template-rows: 45px;
    gap: 10px;
    align-items: center;
    background-color: $white;
    @include content-inset($padding-mobile: 1rem, $padding-vert: 25px 10px);

    @include breakpoint(medium) {
      grid-template-areas: 'logo nav';
      @include content-inset($padding-mobile: 1rem);
    }

    .utility-logo {
      grid-area: logo;
    }

    .utility-nav {
      grid-area: nav;
      position: static;
      @include breakpoint(medium) {
        position: relative;
      }

      .utility-menu-item {
        &.is-last-item + .utility-menu-item {
          display: none;
        }
      }
    }

    &--impersonation {
      padding-top: 14px;
      padding-bottom: 14px;
      border: $s1 solid $black;
      border-bottom: none;

      @include breakpoint(medium) {
        border: 13px solid $black;
        border-top: none;
      }
    }
  }

  &__navigation {
    position: absolute;
    width: 100%;
    overflow: hidden;
    z-index: $page-header-z-index;
    transition: transform 200ms;
    background-color: $white;

    @include breakpoint(small down) {
      top: 0;
      height: 100%;
    }

    &.hide-nav {
      overflow: hidden;
    }

    @include breakpoint(medium) {
      position: relative;
      overflow: visible;
      z-index: $dropdown-z-index;
    }
  }

  .utility-logo {
    height: 30px;
    align-items: flex-end;
    grid-column-gap: 25px;

    .corporate-hamburger-menu {
      &:disabled {
        opacity: 0.3;
        cursor: initial;
      }
    }

    a,
    img {
      height: 100%;
    }
  }

  &__sticky-wrapper {
    .sticky-view__content {
      height: 100%;
      z-index: $page-header-z-index;
      pointer-events: none;

      @include breakpoint(medium) {
        pointer-events: initial;
        height: auto;
      }
    }
  }

  .utility-menu__products-menu,
  .utility-menu__search-button {
    display: none;
  }

  &__wrapper {
    .utility-search {
      @include breakpoint(small down) {
        padding-bottom: $s2;
        box-shadow: 0 1px 10px rgba(45, 41, 38, 0.1);
        background-color: $white;

        &__shop-btn.cor-button {
          text-transform: uppercase;
          font: 12px/1.43 $frutiger-65-bold;
          letter-spacing: 0.8px;
          padding: 10px $s3;
          margin-right: $s2;
        }

        .algolia-search--expandable {
          flex-grow: 1;
        }

        &__panel {
          flex-flow: row-reverse;
          justify-content: space-between;
        }

        .algolia-search--search-input-visible + .utility-search__shop-btn {
          display: none;
        }
      }

      &--impersonation {
        @include breakpoint(small down) {
          border: 8px solid $black;
          border-top: none;
        }
      }

      .search-field__input {
        background-color: $cultured;
        border-radius: 30px;
      }

      .search-field__button {
        right: 10px;
        left: auto;
      }
    }
  }

  &__sticky-wrapper--authorized.sticky-view--sticky {
    @include breakpoint(small down) {
      .utility {
        &-menu__search-button {
          display: block;
        }

        &-menu-item {
          &.is-hidden-for-mobile-sticky {
            display: none;
          }
        }
      }

      .corp-header {
        box-shadow: 0 1px 10px rgba($black, 0.1);

        &__top--impersonation {
          border-bottom: 8px solid $black;
        }
      }
    }

    @include breakpoint(medium) {
      & + .corp-header__navigation {
        .main-nav-item.main-nav-item--first {
          pointer-events: none;
        }
      }

      .algolia-search--expandable .search-field-suggestions {
        top: 55px;
      }

      .corp-header__top {
        box-shadow: 0.6px 0.8px 18px rgba(0, 0, 0, 0.08);
        padding-top: 22px;
        padding-bottom: 22px;
      }

      .corp-header__top,
      .algolia-search__input,
      .header-icon__counter {
        background-color: $light-tan;
      }

      .utility-menu__user-dropdown,
      .corporate-hamburger-menu {
        display: none;
      }

      .utility-menu__products-menu {
        display: flex;
        align-items: center;

        &:after {
          content: '';
          height: 43px;
          width: 1px;
          display: block;
          background-color: $black;
          margin-left: $s3-5;
        }

        &.main-nav__menu--shop {
          box-shadow: none;
          transform: none;
        }

        &.main-nav__menu--shop .main-nav-item {
          background-color: $light-tan;

          &__menu-link {
            &.is-open {
              border-bottom-color: transparent;
            }

            > button {
              padding: $s2-5 0;
              text-transform: uppercase;
            }
          }
        }
      }

      .utility-menu__search-box {
        max-width: 17%;
      }

      .algolia-search--expandable {
        &.algolia-search--search-input-visible {
          background-color: $light-tan;
        }

        .algolia-search__input {
          &::placeholder {
            color: $black;
          }
        }
      }
    }

    @include breakpoint(small down) {
      .corp-header__navigation {
        top: -10px;
        height: calc(100% + 10px);

        &--impersonation {
          top: -55px;
          height: 100%;
        }
      }
    }
  }
}
