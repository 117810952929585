@import 'styles/_foundation';
@import 'styles/_variables';

.cor-submit-button {
  & {
    width: fit-content;
    @include breakpoint($cor-desktop) {
      margin-top: 22px;
    }
  }

  &--left {
    @include breakpoint($cor-desktop) {
      grid-column: 1 / 2;
    }
  }

  &--right {
    @include breakpoint($cor-desktop) {
      grid-column: 2 / 3;
    }
  }

  // Submit button may have more content than what can fit on one line in mobile view.

  &.cor-button {
    white-space: initial;
    max-height: initial;
    @include breakpoint($cor-desktop) {
      white-space: nowrap;
      max-height: inherit;
    }
  }
}
