@import 'styles/_foundation';
@import 'styles/_variables.scss';
@import 'styles/typography';

.change-password-form {
  $change-email-address-form: &;

  .text-field {
    &__label {
      @include df-caption;
      color: $red;
      margin-bottom: 10px;
    }
  }

  @include breakpoint(medium) {
    margin-top: $s2;
  }

  .temporary-password-instructions {
    @include df-p1-medium;
    display: flex;
    padding: $s2 $s2-5;
    margin-bottom: $s2;
    border-radius: 4px;
    background-color: $legal-pad-yellow;
    border: 1px solid $mustard-yellow;

    @include breakpoint(small down) {
      padding: $s1-5;

      strong:first-of-type {
        @include df-p1-bold;
      }
    }

    &__text-cut {
      word-break: break-all;
    }

    &--collapsed {
      .contents {
        .temporary-password-instructions__text-cut {
          br {
            display: none;
          }
        }
      }
    }

    .contents {
      position: relative;
    }

    &__show-more {
      letter-spacing: 0.25px;
      position: absolute;
      right: 0;
    }

    img {
      height: 16px;
      width: 18px;
      margin-right: $s1;

      @include breakpoint(medium) {
        margin-right: $s1-5;
      }
    }
  }

  &__generate-password-forbidden-warning {
    @include df-p1-medium;
    background-color: $legal-pad-yellow;
    border: 1px solid $mustard-yellow;
    padding: $s1-5 $s1;
    border-radius: 4px;
    color: $black;
    margin-bottom: $s2;

    img {
      width: 18px;
      height: 16px;
      margin-right: $s1;

      @include breakpoint(medium) {
        margin-right: $s1-5;
      }
    }

    @include breakpoint(medium) {
      padding: $s2;
    }
  }

  .temporary-password {
    @include df-p1-bold;
  }

  &__buttons {
    margin-top: $s4;

    @include breakpoint(medium) {
      margin-top: 165px;

      button:first-child {
        width: 17%;
      }

      button:last-child {
        width: 8%;
        margin-right: $s3;
        min-width: 94px;
      }

      &.grid-x {
        flex-flow: row-reverse;
      }
    }

    @include breakpoint(small only) {
      button:first-child {
        margin-bottom: $s2;
      }

      button:first-child,
      button:last-child {
        width: 100%;
      }
    }
  }

  @include breakpoint(small down) {
    &,
    form {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }

    &__spacer {
      flex-grow: 1;
    }
  }

  &__change-password-button.cor-button {
    @include df-p2-medium;

    @include breakpoint(medium) {
      @include df-p1-medium;
    }
  }

  &__current-password {
    color: $dark-gray;

    &-label {
      margin-bottom: $s1;
    }

    &--hidden {
      font-size: 28px;
    }
  }
}
