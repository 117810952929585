@import 'styles/_variables';
@import 'styles/_foundation';
@import 'styles/mixins';

.cor-share-social {
  text-align: center;
  padding: $s2-5 0;
  margin: $s2-5 0;
  & p {
    font-size: $s1-5;
  }
  &__icons {
    display: flex;
    gap: $s2;
    justify-content: center;
    padding: $s2-5;
    & svg {
      width: $s3;
      height: $s3;
    }
  }

  &__icon-label {
    @include srOnly;
  }

  @media print {
    display: none;
  }
}
