@import '../variables';

@mixin df-h1-mobile {
  font: 24px/1.33 $museo-700;
  letter-spacing: 0.4px;
}

@mixin df-h1 {
  @include df-h1-mobile;

  @include breakpoint(medium) {
    font-size: 36px;
  }
}

@mixin df-h2-mobile {
  font: 20px/1.2 $museo-700;
  letter-spacing: 0.4px;
}

@mixin df-h2 {
  @include df-h2-mobile;

  @include breakpoint(medium) {
    font-size: 28px;
    line-height: 1.43;
  }
}

@mixin df-h3-bold {
  font: 18px/1.33 $museo-700;
  letter-spacing: 0.4px;

  @include breakpoint(medium) {
    font-size: 24px;
  }
}

@mixin df-h3-medium {
  font: 18px/1.33 $museo-500;
  letter-spacing: 0.4px;

  @include breakpoint(medium) {
    font-size: 24px;
  }
}

@mixin df-h4-bold {
  font: 16px/1.25 $museo-700;
  letter-spacing: 0.2px;

  @include breakpoint(medium) {
    font-size: 20px;
    line-height: 1.4;
    letter-spacing: 0.4px;
  }
}

@mixin df-h4-medium {
  font: 16px/1.25 $museo-500;
  letter-spacing: 0.2px;

  @include breakpoint(medium) {
    font-size: 20px;
    line-height: 1.4;
    letter-spacing: 0.4px;
  }
}

@mixin df-h5-bold {
  font: 14px/1.43 $museo-700;
  letter-spacing: 0.2px;

  @include breakpoint(medium) {
    font-size: 18px;
    line-height: 1.33;
    letter-spacing: 0.4px;
  }
}

@mixin df-h5-medium {
  font: 14px/1.43 $museo-500;
  letter-spacing: 0.2px;

  @include breakpoint(medium) {
    font-size: 18px;
    line-height: 1.33;
    letter-spacing: 0.4px;
  }
}

@mixin df-s1 {
  font: 18px/1.33 $museo-700;
  letter-spacing: 1.4px;
  text-transform: uppercase;

  @include breakpoint(medium) {
    line-height: 1.55;
  }
}

@mixin df-s2 {
  font: 14px/1.43 $museo-700;
  letter-spacing: 1.2px;
  text-transform: uppercase;

  @include breakpoint(medium) {
    font-size: 16px;
    line-height: 1.5;
  }
}

@mixin df-p1-bold {
  font: 16px/1.5 $frutiger-65-bold;
  letter-spacing: 0.2px;
}

@mixin df-p1-medium {
  font: 16px/1.5 $frutiger-55-roman;
}

@mixin df-p1-italic {
  font: 16px/1.5 $frutiger-56-italic;
}

@mixin df-p2-bold {
  font: 14px/1.43 $frutiger-65-bold;
  letter-spacing: 0.2px;
}

@mixin df-p2-medium {
  font: 14px/1.43 $frutiger-55-roman;
}

@mixin df-p2-italic {
  font: 14px/1.43 $frutiger-56-italic;
}

@mixin df-p3-italic {
  font: 12px/1.33 $frutiger-56-italic;
}

@mixin df-p3-medium {
  font: 12px/1.33 $frutiger-55-roman;
}

@mixin df-p4-bold {
  font: 11px/1.33 $frutiger-65-bold;
  text-transform: uppercase;
}

@mixin df-cta {
  font: 16px/1.5 $frutiger-65-bold;
  letter-spacing: 0.8px;
}

@mixin df-placeholder {
  font: 14px/1.43 $frutiger-55-roman;
}

@mixin df-caption {
  font: 10px/1.45 $frutiger-65-bold;
  letter-spacing: 0.8px;
  text-transform: uppercase;

  @include breakpoint(medium) {
    font-size: 11px;
    line-height: 1.45;
    letter-spacing: 1.2px;
  }
}

///Neue Frutiger
@mixin neue-df-cta {
  font: 16px/1.5 $neue-frutiger-700;
  letter-spacing: 0.8px;
}

@mixin neue-df-p1-bold {
  font: 16px/1.5 $neue-frutiger-700;
  letter-spacing: 0.2px;
}

@mixin neue-df-p1-medium {
  font: 16px/1.5 $neue-frutiger-400;
}

@mixin neue-df-h3-bold {
  font: 18px/1.33 $neue-frutiger-700;
  letter-spacing: 0.4px;

  @include breakpoint(medium) {
    font-size: 24px;
  }
}

@mixin neue-df-h4-bold {
  font: 16px/1.25 $neue-frutiger-700;
  letter-spacing: 0.2px;

  @include breakpoint(medium) {
    font-size: 20px;
    line-height: 1.4;
    letter-spacing: 0.4px;
  }
}

@mixin neue-df-h5-bold {
  font: 14px/1.43 $neue-frutiger-700;
  letter-spacing: 0.2px;

  @include breakpoint(medium) {
    font-size: 18px;
    line-height: 1.33;
    letter-spacing: 0.4px;
  }
}

@mixin neue-df-p2-medium {
  font: 14px/1.43 $neue-frutiger-400;
}

@mixin ipad-landscape-only {
  @media screen and (min-width: 960px) and (max-width: 1024px) {
    @content;
  }
}
