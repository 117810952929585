@import 'src/styles/variables';
@import '/src/styles/mixins/typography';
@import 'styles/_foundation';

.utility-menu-item {
  color: $black;
  width: auto;
  height: auto;

  &.is-icon {
    width: auto;
    height: 26px;

    @include breakpoint(medium) {
      height: 40px;
    }
  }

  &.is-notifications {
    .header-icon__counter {
      border: 2px solid $light-orange;

      span {
        color: $light-orange;
      }
    }
  }

  a {
    position: relative;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &.is-disabled {
    opacity: 0.3;

    & * {
      pointer-events: none;
    }
  }

  .hint-text {
    position: absolute;
    display: none;
    white-space: nowrap;
    left: 50%;
    transform: translateX(-50%);
    bottom: 124%;
    box-shadow: 0px 1px 10px rgba($black, 0.4);
    padding: 4px 8px;
    background: $white;
    border-radius: 3px;

    &::after {
      content: '';
      border-width: 5px 5px 0;
      border-style: solid;
      border-color: $white transparent transparent;
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &.is-last-item .hint-text {
    transform: translateX(-75%);
    @include breakpoint(xxlarge) {
      transform: translateX(-50%);
    }
  }

  &.is-last-item .hint-text::after {
    left: 75%;
    @include breakpoint(xxlarge) {
      left: 50%;
    }
  }

  &:hover {
    .hint-text {
      display: block;
    }
  }

  & > button {
    position: relative;
    appearance: none;
    outline: none;
    border: 0;
    background: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    text-decoration: none;
    transition: opacity 500ms ease-in-out;
    gap: 5px;
    padding: 0;
  }

  &__label {
    color: $black;
    &::after {
      position: relative;
      content: attr(data-mobile-label);
      top: 2px;
    }
  }
}

.utility-menu-panel-container {
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  z-index: $top-menu-z-index;
  background: $white;
  transition: opacity 150ms, transform 150ms;
  opacity: 0;
  transform: translateY(-10px);

  &-enter {
    opacity: 0;
    transform: translateY(-10px);
  }

  &-enter-done {
    opacity: 1;
    transform: translateY(0);
  }

  &-exit {
    opacity: 1;
    transform: translateY(0);
  }

  &-exit-done {
    opacity: 0;
    transform: translateY(-10px);
  }
}

body:not(.international-font) .utility-menu-item {
  @include font-frutiger-regular($size: 13);

  .hint-text {
    @include font-frutiger-regular($size: 11);
  }
}

body.international-font .corp-header {
  @include font-neue-frutiger-regular($size: 13);

  .hint-text {
    @include font-neue-frutiger-regular($size: 11);
  }
}
