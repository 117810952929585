@import 'styles/_variables';
@import 'styles/typography/_mixins';
@import 'styles/_foundation';

.product-label {
  padding: 0;
  border-radius: 12px;
  text-align: center;
  color: $white;

  &__title {
    @include df-caption;
  }

  &--discontinued {
    background-color: $brown;
  }

  &--special {
    background-color: $green;
  }

  &--substitute {
    background-color: $orange;
  }

  &--cancelled {
    background-color: $dark-gray;
  }

  @include breakpoint(medium) {
    padding: $s0-5;
  }
}
