@import 'styles/_variables';
@import 'styles/_foundation';
@import 'styles/typography/_mixins';

.alternative-items-line-item-buttons {
  &__add-to-cart-btn.tertiary {
    &.active {
      font-family: $frutiger-65-bold;
      background-color: $light-gray;
      border-color: $light-gray;
      color: $aubergine-gray;
      display: flex;
      justify-content: center;
      align-items: baseline;
      width: 144px;

      .content {
        padding-left: $s2-5;
      }

      &::before {
        content: '';
        transform: rotate(45deg);
        height: 16px;
        width: 9px;
        border-bottom: 2px solid $achromatic-gray;
        border-right: 2px solid $achromatic-gray;
      }
    }

    &.disabled {
      pointer-events: none;
      display: flex;
      justify-content: center;
      align-items: baseline;
      width: 100%;
    }
  }

  &__request-quote {
    display: flex;
    align-items: center;
    width: 100%;

    button {
      margin-right: $s2;

      @include breakpoint(medium) {
        margin-right: $s0-5;
      }
    }

    @media (max-width: 960px) {
      .custom-tooltip {
        .custom-tooltip__content {
          width: auto;
          margin-left: -35px;

          &:after,
          &:before {
            right: 43px;
            left: auto;
          }
        }
      }
    }
  }

  &__add-to-cart-btn {
    @include breakpoint(medium) {
      width: 144px;
    }
  }

  &__add-to-cart-btn,
  &__request-quote-btn {
    max-height: 40px;

    @include breakpoint(small down) {
      width: 100%;
    }
  }
}
