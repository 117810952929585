@import 'styles/_variables';
@import 'styles/typography/_mixins';
@import 'styles/_foundation';

$tooltipIconSize: 28px;
$specialOrderFooterTooltipMobileIconSize: 25px;

.request-quote-modal {
  $request-quote-modal: &;

  color: $black;
  padding-bottom: 0;
  max-width: 864px;

  &#{&} {
    margin: 0 auto;
  }

  &--with-loading-indicator {
    &.modal__content {
      min-height: 300px;

      @include breakpoint(medium) {
        min-height: 500px;
      }
    }
  }

  &.modal__content {
    padding: 0;

    @include breakpoint(medium) {
      overflow-y: hidden;
    }
  }

  &__content {
    height: 100%;

    @include breakpoint(medium) {
      overflow-y: auto;
    }
  }

  &__product-details {
    display: flex;
    padding: $s2 $s2 0;

    @include breakpoint(medium) {
      padding: $s4 $s2 0 $s4;
      max-width: 766px;
    }
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .modal__container {
      position: relative;
      transform: none;
      top: 0;
    }
  }

  &__image {
    width: 100%;
    position: relative;
    margin-bottom: $s2;
    align-self: flex-start;

    @include breakpoint(medium) {
      margin-bottom: 0;
      max-width: 328px;
    }

    .product-card__statuses-wrapper {
      @include df-caption;
      position: absolute;
      bottom: 19px;
      left: 20px;
    }
  }

  .product-card__link .product-indicators {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  &__brand {
    @include df-caption;
    color: $brown;
    margin-bottom: $s0-5;
  }

  &__name {
    font: 20px/1.2 $museo-700;
    margin-bottom: $s2;
    width: 100%;

    @include breakpoint(medium) {
      font: 24px/1.33 $museo-700;
      margin-bottom: $s3;
      width: 95%;
    }

    a {
      text-decoration: none;
    }
  }

  &__label {
    @include df-p2-medium;
    width: 46%;
    margin-right: $s2;

    @include breakpoint(medium) {
      @include df-p1-medium;
      width: 33%;
    }
  }

  &__info-value {
    @include df-p2-bold;
    color: $red;

    @include breakpoint(medium) {
      @include df-p1-bold;
    }
  }

  &__product-info {
    border-bottom: 1px solid $input-gray;
  }

  &__info-item {
    margin-bottom: $s1;
    display: flex;

    &:last-child {
      margin-bottom: $s2-5;

      @include breakpoint(medium) {
        margin-bottom: $s3;
      }
    }
  }

  &__special-order-actions {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    justify-content: space-between;
    width: 100%;

    @include breakpoint(medium) {
      flex-flow: row nowrap;
    }

    .custom-tooltip {
      margin-left: 0;
      margin-bottom: $s1;

      @include breakpoint(medium) {
        margin-left: $s1-5;
        margin-bottom: 0;
      }

      &__icon {
        width: $tooltipIconSize;
        height: $tooltipIconSize;
      }

      &__content {
        @include df-p2-medium;
        width: 100%;
        box-shadow: 0 1.5px 16px 0 rgba(0, 0, 0, 0.21);
        position: relative;

        @include breakpoint(medium) {
          max-width: 226px;
        }
      }
    }
  }

  #{$request-quote-modal}__special-order-footer {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @include breakpoint(medium) {
      justify-content: flex-end;
    }

    .custom-tooltip,
    .custom-tooltip__icon {
      width: $specialOrderFooterTooltipMobileIconSize;
      height: $specialOrderFooterTooltipMobileIconSize;

      @include breakpoint(medium) {
        width: $tooltipIconSize;
        height: $tooltipIconSize;
      }
    }

    .custom-tooltip {
      margin-bottom: 0;
      margin-left: $s1-5;

      .custom-tooltip__icon {
        img {
          width: 100%;
          height: 100%;
        }
      }

      .custom-tooltip__content {
        @include df-p2-medium;
        width: 100%;
        box-shadow: 0 1.5px 16px 0 rgba(0, 0, 0, 0.21);
        margin-left: -36px;

        &:after {
          right: 0;
          left: auto;
        }

        @include breakpoint(910px) {
          &:after {
            right: 10px;
          }
        }

        @include breakpoint(medium) {
          max-width: 226px;
          margin-left: 0;

          &:after {
            left: 50%;
          }
        }
      }
    }
  }

  &__request-quote-button {
    width: 100%;

    @include breakpoint(medium) {
      width: auto;
      min-width: 186px;
      display: flex;
      justify-content: center;
    }

    &.cor-button.active {
      background-color: $light-red;
      border-color: $light-red;
      color: $white;
    }

    &.disabled {
      pointer-events: none;
    }
  }

  &__cancel-button {
    margin: 0 0 $s1 0;

    @include breakpoint(medium) {
      margin-right: $s4;
      margin-bottom: 0;
    }
  }

  .modal__close-button {
    background-color: $white;
    padding: $s2;
    right: 10px;
    top: 10px;
    z-index: 1;
    position: fixed;

    @include breakpoint(medium) {
      right: 28px;
      top: 18px;
      position: absolute;
    }
  }

  .modal__close-button:before,
  .modal__close-button:after {
    top: 2px;
    left: 15px;
    height: 28px;
  }

  &__message-block {
    padding-bottom: $s2-5;
  }

  &__message-block-label {
    margin-bottom: 10px;
    color: $dark-gray;
  }

  &__message-block-input {
    outline: none;
    resize: none;
    width: 100%;
    font: 14px/1.71 $frutiger-55-roman;
    color: $black;
    border: 1px solid $input-gray;
    border-radius: 8px;
    background-color: $white;
    margin: 0;
    padding: $s1;
    min-height: 97px;
  }

  &__footer {
    position: sticky;
    bottom: $s1;
    background-color: $white;
    left: 0;
    right: 0;
    bottom: 0;
    padding: $s1-5 $s2;
    z-index: $sticky-view-z-index;
    border-radius: 0 0 4px 4px;

    @include breakpoint(medium) {
      padding: $s2-5 $s4;
    }
  }

  &__quantity-block {
    display: flex;
    flex-direction: column;
    padding: $s2-5 0;

    @include breakpoint(medium) {
      padding: $s3 0;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
  }

  &__main-content {
    @include breakpoint(medium) {
      padding-left: $s4;
    }
  }

  &__quantity-label-area {
    @include breakpoint(medium) {
      width: auto;
      margin-right: 34px;
    }
  }

  &__quantity-label {
    @include df-caption;
    color: $dark-gray;
  }

  &__quantity-control {
    margin-top: $s1;
  }

  &__sku-weight {
    @include df-p2-medium;
    margin-bottom: $s1-5;

    b {
      @include df-p1-bold;
    }

    @include breakpoint(medium) {
      @include df-p1-medium;
    }
  }

  &__delivery-info-label {
    @include df-caption;
    color: $red;
    margin-bottom: 18px;
  }

  .quantity-control__button-minus,
  .quantity-control__button-plus {
    z-index: initial;
  }

  &__quantity-warning {
    margin: 10px -16px;

    @include breakpoint(medium) {
      margin: 10px 0;
    }
  }

  &__quantity-notification {
    margin-top: $s1;
  }

  #{$request-quote-modal}__similar-items {
    padding: $s2;

    @include breakpoint(medium) {
      padding: 0 $s4 $s3 $s4;
    }

    .product-replacement-wrapper__content {
      padding-top: $s2;

      @include breakpoint(medium) {
        padding-top: 0;
      }

      .line-item-image__wrapper {
        margin-right: $s1-5;

        @include breakpoint(medium) {
          margin-right: $s1;
        }
      }
    }

    .product-replacement-wrapper__title {
      padding: $s1-5 $s2;

      @include breakpoint(medium) {
        padding: $s2 $s3;
      }
    }

    .similar-items-line-item {
      padding-right: 0;

      @include breakpoint(medium) {
        padding-left: $s1;
      }

      &__price-block {
        @include breakpoint(medium) {
          width: 41%;
          justify-content: flex-start;
        }
      }

      &__price {
        @include breakpoint(medium) {
          width: 44%;
        }
      }

      &__name {
        font-size: 16px;
      }

      .similar-items-line-item-buttons {
        &__add-to-cart-btn {
          @include breakpoint(medium) {
            width: 109px;
            min-width: auto;
            padding: $s1 5px;
          }

          &.tertiary.active {
            .content {
              padding-left: $s1;
            }
            &::before {
              content: '';
              height: 14px;
              width: 6px;
            }
          }
        }

        &__request-quote {
          .similar-items-line-item-buttons__request-quote-btn {
            margin-right: $s1-5;

            @include breakpoint(medium) {
              margin-right: $s0-5;
            }
          }
        }
      }
    }
  }
}
