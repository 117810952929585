@import 'styles/_foundation';
@import 'styles/typography/_mixins';
@import 'styles/_variables';

$border: 1px solid $input-gray;

.order-summary {
  &__content {
    &-divider {
      border-bottom: $border;
      margin-left: $s1-5;
      margin-right: $s1-5;

      @include breakpoint(medium) {
        margin-left: $s2;
        margin-right: $s2;
      }
    }
  }

  &__details {
    @include df-p1-medium;
    padding: $s2 $s1-5;
    border-bottom: $border;

    @include breakpoint(medium) {
      padding: $s2;
    }

    &--with-additional-charges {
      border-bottom: none;
    }
  }

  &__title {
    @include df-h5-bold;
    margin-left: $s1-5;
    margin-right: $s1-5;
    font-size: 18px;
    padding-bottom: $s1-5;
    border-bottom: $border;

    @include breakpoint(medium) {
      margin-left: $s2;
      margin-right: $s2;
    }
  }

  &__item {
    @include df-p1-medium;
    display: flex;
    justify-content: space-between;
    color: $black;
    margin-bottom: $s1-5;
  }

  &__additional-charges {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-label {
      display: flex;
      align-items: center;
    }

    &-tooltip {
      margin-left: $s1;
    }
  }

  &__total {
    padding: $s2 $s1-5 0 $s1-5;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include breakpoint(medium) {
      padding: $s2 $s2 0 $s2;
    }
  }

  &__total-label {
    @include df-p1-bold;
  }

  &__total-value {
    @include df-h3-bold;
    font-size: 24px;
  }

  &__additional-charges-warning {
    padding: 10px $s1-5;
    border-radius: 0;

    @include breakpoint(medium) {
      padding: 10px $s2;
    }

    &-title {
      @include df-p2-bold;
      margin-bottom: 6px;

      .general-warning__warning-icon {
        margin-right: $s1;
      }
    }
    .general-warning__message {
      @include df-p2-medium;
      padding: 0;
    }
  }
}
