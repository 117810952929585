@import 'styles/_variables';
@import 'styles/_foundation';
@import 'styles/typography/_mixins';

.checkout-header {
  $checkout-header: &;

  position: relative;
  background-color: $red;
  color: $white;

  #{$checkout-header} &__container {
    padding: $s2;
  }

  &__content {
    display: flex;
    align-items: center;
  }

  &__logo {
    position: absolute;
    top: $s1-5;
    left: 50%;
    transform: translateX(-50%);

    @include breakpoint(medium) {
      position: static;
      margin-right: $s8;
      margin-top: -$s0-5;
      transform: none;
    }
  }

  &__secure-block {
    display: flex;
    margin-right: $s3;
  }

  &__secure-icon {
    margin-right: $s1;
    img {
      display: block;
    }

    @include breakpoint(medium) {
      margin-right: $s1-5;
    }
  }

  &__secure-status {
    align-self: flex-end;
    text-transform: uppercase;
    opacity: 0.6;

    @include df-h4-bold;
    @include breakpoint(medium) {
      @include df-h5-bold;
    }
  }

  &__continue-shopping-link {
    display: none;
    margin-left: auto;

    @include breakpoint(medium) {
      display: block;
      @include df-p1-medium;
    }
  }
}
