@import 'styles/_variables';

.cor-filters-drawer {
  & {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    z-index: 50;

    background-color: $cor-white;
    overflow-y: auto;

    transform: translateX(-100%);
    transition: transform 200ms;
  }

  &--opened {
    transform: translateX(0);
  }

  &__header {
    padding-top: 15px;
    padding-bottom: 15px;

    & .grid-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 25px;
    }
  }

  &__header-button {
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding: 0;
  }

  &__header-button--close-drawer {
    text-align: left;
    width: 28px;
  }

  &__actions {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  &__actions button {
    margin-bottom: 8px;
  }
}
