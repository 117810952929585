@import 'styles/_variables';
@import 'styles/_foundation';
@import 'styles/mixins/_typography';
@import 'styles/_cor-rich-text';

.cor-three-column {
  padding: 40px 0;

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 28px;
    @include breakpoint($cor-desktop) {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 40px;
      margin: 94px auto;
    }
  }

  &__column-left {
    font-family: $museo-500;
    @include breakpoint($cor-desktop) {
      flex: 0 0 35%;
      padding-right: 8%;
    }
  }

  &__column-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
    width: 100%;
    @include breakpoint($cor-desktop) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-auto-rows: auto;
      gap: 45px 100px;
      flex: 1 0 56%;
    }
  }

  &__text-columns {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex: 1 1 auto;
    width: 100%;
    @include breakpoint($cor-desktop) {
      flex-direction: row;
      flex: 1;
      flex-wrap: nowrap;
      width: 45%;
    }
  }

  &__text-column {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    gap: 25px 10px;
    &:not(:last-of-type) {
      margin-bottom: 25px;
    }
  }

  &__text-column-middle {
    @include breakpoint($cor-desktop) {
      padding-right: 10%;
      width: 40%;
    }
  }

  &__text-column-right {
    @include breakpoint($cor-desktop) {
      padding-left: 15%;
      width: 60%;
      border-left: 1px solid $cor-light-gray;
    }
  }

  &__button-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    @include breakpoint($cor-desktop) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 40px;
    }
  }

  &__buttons-list {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    flex-shrink: 0;
    gap: 22px 0;
    @include breakpoint($cor-desktop) {
      gap: 26px;
      justify-content: flex-start;
      grid-column: 1 / span 2;
      grid-row: 2;
      flex: 0 1 auto;
    }
  }

  &__button-row-label {
    font-weight: 700;
    width: 100%;
    margin-bottom: 22px;
    @include breakpoint($cor-desktop) {
      flex: 0 0 auto;
      padding-top: 15px;
      margin-right: 30px;
      margin-bottom: 0;
      width: auto;
    }
  }

  &__button {
    width: 48%;
    max-width: none;
    @include breakpoint($cor-desktop) {
      width: 270px;
    }
  }

  &--white {
    background-color: $cor-bg-gray-lightest;
  }
  &--light-tan {
    background-color: $cor-bg-gray;
  }
  &--yellow {
    background-color: $cor-dawn-yellow;
  }
}

body:not(.international-font) .cor-three-column {
  &__title {
    @include cor-rich-text();
  }
}

body.international-font .cor-three-column {
  &__title {
    @include cor-rich-text($map: $neue-base);
  }
}
