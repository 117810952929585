@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

.how-to-create-list-modal {
  @include breakpoint(medium) {
    max-height: none;
  }

  &.modal__content.cell {
    margin: 0 auto;
    border-radius: 0;
    max-height: 650px;
    height: 100%;

    @include breakpoint(medium) {
      width: fit-content;
      max-width: 1170px;
      border-radius: 4px;
      min-height: 540px;
    }
  }

  .modal__close-button {
    top: 12px;

    @include breakpoint(medium) {
      top: 20px;
    }

    &::before,
    &::after {
      height: 28px;
      background-color: $dark-gray;
    }
  }

  .guide-section {
    border: none;
    align-items: center;
    padding: 0;

    &__content {
      width: 100%;
    }

    &__title {
      @include df-h3-bold;
      margin-bottom: $s2;
      padding: 0 $s5;

      @include breakpoint(medium) {
        @include df-h4-bold;
        padding: 0;
        margin-bottom: $s1;
      }
    }

    &__description {
      @include df-p1-medium;

      @include breakpoint(small down) {
        @include df-p2-medium;
        padding: 0 $s1 $s1;
      }
    }

    .guide-tile__item {
      padding: $s3-5 $s1-5 $s2;
    }
  }
}
