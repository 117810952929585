@import 'styles/_variables';
@import 'styles/_foundation';
@import 'styles/typography/_mixins';

.product-replacement-line-item-buttons {
  &__add-to-cart-btn.cor-button {
    position: relative;
    min-width: 144px;

    &.active {
      font-family: $frutiger-65-bold;
      background-color: $light-gray;
      border-color: $light-gray;
      color: $red;

      .content {
        padding-left: $s2-5;
      }

      &::before {
        position: absolute;
        content: '';
        top: 10px;
        left: 28px;
        display: inline-block;
        transform: rotate(45deg);
        height: 16px;
        width: 9px;
        border-bottom: 2px solid $white;
        border-right: 2px solid $white;
      }
    }

    &.disabled {
      pointer-events: none;
    }
  }

  &__request-quote-btn {
    display: flex;
    align-items: center;

    button {
      margin-right: $s2;
      min-width: 248px;
    }

    @media (max-width: 960px) {
      .custom-tooltip {
        .custom-tooltip__content {
          width: auto;
          margin-left: -35px;

          &:after,
          &:before {
            right: 43px;
            left: auto;
          }
        }
      }
    }
  }

  &__add-to-cart-btn,
  &__request-quote-btn {
    max-height: 40px;

    @include breakpoint(small down) {
      width: 100%;
    }
  }
}
