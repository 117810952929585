@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography';

.cancel-quote-with-reason-modal {
  &__content {
    @include df-p2-medium;
    display: flex;
    flex-flow: column;
    padding: 0 $s2-5;

    @include breakpoint(medium) {
      padding: 0 $s4;
      @include df-p1-medium;
    }
  }

  &__description {
    margin-bottom: $s1-5;
  }

  &__footer {
    display: flex;
    flex-direction: flex-end;
    align-items: center;

    @include breakpoint(medium) {
      flex-direction: row;

      button {
        margin: 0 0 0 $s4;
      }
    }
  }

  .custom-radio-button {
    margin-bottom: $s1;
  }

  textarea {
    @include df-p2-medium;
    line-height: 24px;
    margin-top: 2px;
    padding: $s1-5 $s2;
    border: 1px solid $input-gray;
    border-radius: 8px;
    resize: none;

    @include breakpoint(medium) {
      margin-top: $s0-5;
    }
  }

  &__cancel-button.cor-button.link-1 {
    @include df-cta;
  }

  &__cancel-request-button {
    width: auto;
    margin-left: $s2;
  }
}
