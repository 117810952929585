.page-content {
  width: 100%;
  padding-top: $s2;
  padding-bottom: 88px;

  @include breakpoint(medium) {
    padding-top: $s4;
    padding-bottom: 120px;
  }
}
