.cart-counter {
  position: absolute;
  min-width: 18px;
  top: -1px;
  height: 18px;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font: 11px $frutiger-65-bold;
  background: $white;
  border: 2px solid $red;
  color: $red;
  left: 62%;
  transform: translateX(-60%);

  &__value {
    padding: $s0-5 2px 2px 2px;
  }

  @include breakpoint(small down) {
    min-width: 16px;
    height: 16px;
  }
}
