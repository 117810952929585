@import 'styles/_variables';
@import 'styles/typography/_mixins';
@import 'styles/_foundation';

.cancel-quote-request-modal {
  &__comment {
    display: flex;
    flex-direction: column;
    padding: 0 $s2-5;
    margin-bottom: $s4;

    @include breakpoint(medium) {
      padding: 0 $s4;
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
  }

  &__cancel {
    margin-left: $s4;
    padding: $s1-5 35px;
  }

  &__comment-label {
    margin-bottom: $s1-5;
  }

  &__comment-input {
    @include df-p2-medium;
    border: 1px solid $input-gray;
    border-radius: $s0-5;
    resize: none;
    padding: $s1-5 $s2;
  }
}
