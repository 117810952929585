@import 'styles/_foundation';
@import 'styles/typography/_mixins';

.order-pickup-message {
  $order-pickup-message: &;

  border: 1px solid $orange;
  background-color: $light-yellow;
  box-shadow: 0.6px 0.8px 18px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  margin: 0 $s2;
  padding: 10px;
  color: $black;

  @include breakpoint(medium) {
    padding: $s2-5 $s2 $s2-5 $s2;
    margin: 0;
  }

  &__title-wrapper {
    display: flex;
    align-items: center;

    @include breakpoint(small down) {
      padding-right: $s2;
    }
  }

  &__icon {
    width: 40px;
    height: 40px;
    margin-right: $s2;
    flex: 0 0 40px;

    @include breakpoint(medium) {
      width: 56px;
      height: 56px;
      flex-basis: 56px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  &__title {
    font: 13px/1.6 $museo-500;
    letter-spacing: 0.5px;

    @include breakpoint(medium) {
      font: 18px/1.33 $museo-500;
      letter-spacing: 0.2px;
    }

    span {
      font-weight: bold;
    }
  }

  &__note {
    @include df-p2-medium;
    line-height: 1.5;
    margin-top: $s1;

    @include breakpoint(medium) {
      font-size: 16px;
      margin-top: $s1-5;
    }
  }

  &--full-size {
    height: 100%;
    align-items: center;
    text-align: center;
    padding: 0 $s5;
    margin-bottom: 0;

    #{$order-pickup-message}__icon {
      width: 80px;
      height: 80px;
      margin-right: 0;
      margin-bottom: $s3;
    }

    #{$order-pickup-message}__title-wrapper {
      flex-direction: column;
    }

    #{$order-pickup-message}__title {
      font-size: 20px;
    }
  }
}
