@import 'styles/_variables';
@import '/src/styles/mixins/typography';
@import 'styles/_foundation';

.subnav-window {
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  min-height: 100px;
  overflow: hidden;
  transition: height 250ms linear;

  @include breakpoint(medium) {
    transition: none;
  }
}
