.text-button {
  @include df-cta;
  color: $red;
  text-decoration: underline;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;

  &:hover {
    color: $light-red;
  }

  &:disabled {
    color: $dark-gray;
    cursor: not-allowed;
  }
}
