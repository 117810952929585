@import 'styles/_variables';
@import 'styles/typography/_mixins';
@import 'styles/_foundation';

.product-indicators {
  @include df-caption;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  color: $white;

  &--medium {
    padding: 2px;

    @include breakpoint(medium) {
      padding: $s0-5;
    }
  }

  &--small {
    font: 10px/1.45 $frutiger-65-bold;
    padding: 1px;
  }

  &--large {
    font: 11px/1.45 $frutiger-65-bold;
    letter-spacing: 1.2px;
    padding: 9px $s1 6px;
  }

  &--out-of-stock {
    background-color: $brown;
  }

  &--low-in-stock {
    background-color: $yellow;
    color: $black;
  }
}
