@import 'corporate-ui/cor-typography/cor-typography';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

.cor-checkbox-field {
  $checkbox-field: &;
  position: relative;
  display: flex;
  color: $aubergine-gray;
  flex-direction: column;
  width: fit-content;

  @include breakpoint($cor-desktop) {
    margin-top: 19.53px;
  }

  &__error-message__container {
    min-height: 26px;
  }

  &__error-message {
    color: $cor-dawn-red;
    @extend .cor-typography__body-small !optional;
  }

  &__label {
    display: flex;
    align-items: center;
    width: fit-content;
    cursor: pointer;
    font-size: 14px;
    line-height: 1.43;

    @include breakpoint($cor-desktop) {
      align-items: center;
    }
  }

  &--error {
    #{$checkbox-field}__checkmark {
      border-color: $red;
    }
  }
}
