@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

$dotSize: 6px;
$dotSizeHomePage: 4px;

.order-cutoff-notification {
  $order-cutoff-notification: &;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  background-color: $orange;
  color: $white;

  @include breakpoint(medium) {
    flex-direction: unset;
  }

  strong {
    @include df-p1-bold;
  }

  &__context {
    @include df-p1-medium;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    padding: $s1 $s5;

    @include breakpoint(medium) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: $s1 $s5;
      width: 100%;
    }
  }

  &__title {
    @include df-h4-bold;
    margin: $s1 0;

    @include breakpoint(medium) {
      width: 27%;
      text-align: end;
    }
  }

  &__message {
    width: 300px;

    @include breakpoint(medium) {
      width: 40%;
      max-width: 500px;
    }

    span {
      @include df-p1-bold;
    }
  }

  &__timer {
    font: 30px/1.5 $frutiger-65-bold;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    width: 100%;
    justify-content: center;

    @include breakpoint(medium) {
      font-size: 40px;
      margin-left: 0;
    }

    span {
      font-size: 18px;

      @include breakpoint(medium) {
        font-size: 20px;
      }
    }

    &-container {
      @include breakpoint(medium) {
        width: 30%;
      }
    }
  }

  &__list {
    &:after {
      content: '';
      display: inline-block;
      width: $dotSize;
      height: $dotSize;
      border-radius: $dotSize/2;
      background-color: $white;
      margin: 0 $s1-5 $s0-5;
    }

    &:last-child::after {
      content: none;
    }
  }

  &__close-button {
    position: absolute;
    top: $s2;
    right: $s2;
    background: none;
    border: none;
    outline: none;
    color: $white;
    padding: 0;

    @include breakpoint(medium) {
      right: 13px;
    }

    img {
      width: 20px;
      height: 20px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__toggle-arrow {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid $white;
    transition: all 0.3s ease;
    align-self: center;
    margin-bottom: $s1-5;

    &-collapsed {
      transform: rotate(180deg);
    }

    &-expanded {
      transform: rotate(0deg);
    }
  }

  &--expired {
    color: $black;
    background-color: $light-coral;
    border: 1px solid $red;
    box-shadow: 1px 1px 5px $input-gray;

    #{$order-cutoff-notification} {
      &__list {
        &:after {
          background-color: $black;
        }
      }

      &__toggle-arrow {
        border-bottom: 6px solid $red;
      }
    }
  }

  &--home-page {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    position: relative;
    margin: 0 $s2;

    @include breakpoint(medium) {
      width: 100%;
      z-index: 1;
      max-height: unset;
      margin: 6px auto 0;
    }

    #{$order-cutoff-notification} {
      &__context {
        flex-direction: column;
        padding: $s1 7px 6px;

        @include breakpoint(medium) {
          align-items: flex-start;
          flex-wrap: wrap;
          width: 100%;
          padding-right: $s2;
          padding-bottom: 30px;
        }
      }

      &__general-info {
        width: 100%;
        display: flex;
        flex-direction: column;

        @include breakpoint(medium) {
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }
      }

      &__title {
        font: 18px/1.33 $museo-700;
        letter-spacing: 0;
        margin: 0 0 $s0-5 0;
        text-transform: none;

        @include breakpoint(medium) {
          width: 100%;
          text-align: left;
          margin: 0;
        }
      }

      &__timer {
        @include df-h2-mobile;
        font-size: 18px;
        letter-spacing: 1.4px;
        margin: 0;

        span {
          @include df-p2-bold;
          font-size: 12px;
        }

        @include breakpoint(small down) {
          font-size: 20px;
          justify-content: flex-start;
        }

        &-container {
          @include breakpoint(medium) {
            width: auto;
          }
        }
      }

      &__list {
        white-space: nowrap;

        &:after {
          width: $dotSizeHomePage;
          height: $dotSizeHomePage;
          border-radius: $dotSizeHomePage/2;
          margin: 0 $s0-5 $s0-5;
        }

        &:last-child::after {
          content: none;
        }
      }

      &__message {
        font: 14px/1.43 $museo-500;
        width: 100%;

        div {
          margin-top: $s1;
        }

        span {
          font: 14px/1.43 $museo-700;
        }
      }

      &__close-button {
        top: 14px;
        right: 3px;

        img {
          width: 12px;
          height: 12px;
        }
      }
    }

    &#{$order-cutoff-notification}--expired {
      background-color: $red;
      color: $white;

      @include breakpoint(medium) {
        margin-top: 6px;
      }

      #{$order-cutoff-notification} {
        &__context {
          padding-bottom: $s1;
        }

        &__list {
          &:after {
            background-color: $white;
          }
        }

        &__message {
          div {
            margin-top: 0;
          }
        }
      }
    }
  }
}

.order-updates__inner-orders:not(.order-updates__inner-orders--narrow) {
  .order-cutoff-notification--home-page {
    @include breakpoint(medium) {
      right: -#{$s3 + 1};
    }
  }
}
