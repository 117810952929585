@import 'styles/_variables';
@import 'styles/_foundation';
@import 'styles/typography/_mixins';

.product-replacement-wrapper {
  border: 1px solid $red;
  border-radius: 4px;
  overflow: hidden;

  &__title {
    @include df-h4-bold;
    display: flex;
    align-items: center;
    padding: $s1-5 $s2;
    background: $light-coral;

    img {
      margin-right: 10px;
    }

    @include breakpoint(medium) {
      @include df-h5-bold;
    }
  }

  &__content {
    padding: 0 $s2 $s2;
  }
}
