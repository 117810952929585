@import 'styles/_foundation';
@import 'styles/_variables.scss';
@import 'styles/typography';

.tabs-scrollable {
  position: relative;

  $tabs-scrollable: &;

  .tabs {
    border-bottom: 1px solid $input-gray;
  }

  .tabs__list {
    overflow-x: auto;
    white-space: nowrap;
    overflow-y: hidden;

    @include breakpoint(medium) {
      margin-bottom: -1px;
    }

    @include breakpoint(small down) {
      padding-top: 34px;
    }
  }

  .tabs__tab {
    width: auto;
    position: relative;
    outline: none;

    @include breakpoint(medium) {
      padding: $s2 $s3 $s1-5;
      margin-right: $s1-5;

      &:after {
        background-color: $input-gray;
        position: absolute;
        width: 100%;
        content: '';
        height: 1px;
        left: 0;
        bottom: 0;
      }

      &--selected {
        color: $black;

        &:after {
          background-color: $white;
        }

        #{$tabs-scrollable} {
          z-index: 1;
        }
      }

      &--disabled {
        border-bottom: none;
        color: $input-gray;

        &:after {
          display: none;
        }

        #{$tabs-scrollable} {
          z-index: 0;
        }
      }
    }
  }

  @include breakpoint(small down) {
    background-color: $snow;

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      content: '';
      background-color: $input-gray;
      width: 100%;
      height: 2px;
    }

    .tabs {
      border-bottom: none;
      padding: 0 $s2;
    }

    .tabs__tab {
      position: relative;
      color: $achromatic-gray;
      background-color: transparent;
      border: none;
      padding: 0 5px 14px 5px;
      margin-right: $s4;

      &:last-child {
        margin-right: 0;
      }

      &--selected {
        color: $black;

        &:after {
          position: absolute;
          background-color: $red;
          width: 100%;
          left: 50%;
          right: 50%;
          transform: translate(-50%, 50%);
          bottom: 1px;
          height: 2px;
        }
      }

      &--disabled {
        position: relative;
        background-color: transparent;
        color: $input-gray;
        border: none;
        pointer-events: none;
      }
    }
  }
}
.tabs-scrollable--centered {
  .tabs__list {
    justify-content: center;
    gap: $s10;
    @include breakpoint(medium) {
      justify-content: flex-start;
      gap: $s1-5;
    }

    .tabs__tab {
      margin: 0;
      min-width: 60px;
    }
  }
}
