@import 'styles/_variables';

.full-screen-banner {
  z-index: $modal-z-index;

  &--is-open {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: $white;
  }
}
