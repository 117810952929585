@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

.video-modal {
  &__title {
    @include df-h2;
    margin-right: $s2-5;
    margin-bottom: $s2;

    @include breakpoint(medium) {
      margin-bottom: $s4;
    }
  }

  &__video {
    margin-top: $s2;
    margin-bottom: $s2;
    padding: 0 $s2-5;

    @include breakpoint(medium) {
      margin-top: $s4;
      margin-bottom: $s3;
      padding: 0 $s4;
    }
  }

  &__description {
    @include df-p1-medium;
    margin-bottom: $s4;

    @include breakpoint(medium) {
      margin-bottom: $s3;
    }

    strong {
      @include df-p1-bold;
    }

    a {
      @include df-p1-bold;
      color: $red;
    }
  }

  &__close-button {
    float: right;
    width: 100%;

    @include breakpoint(medium) {
      max-width: 168px;
    }
  }
}
