@import 'styles/_variables';
@import 'styles/typography/_mixins';
@import 'styles/_foundation';

.discontinued-with-replacement-item {
  padding: 0 2px $s0-5 $s1;

  @include breakpoint(medium) {
    padding: 0 $s1 $s0-5 $s1;
  }

  &__discontinued-item {
    margin: $s2 0 25px 0;

    @include breakpoint(medium) {
      margin: $s2 0 27px 0;
    }
  }

  &__alternate-title {
    @include df-p2-bold;
    color: $red;
    margin-bottom: $s1;
  }

  &__alternate-item {
    position: relative;
    display: flex;
    flex-flow: column;

    @include breakpoint(medium) {
      flex-flow: row;
      justify-content: space-between;
      align-items: flex-end;
    }

    &-price {
      @include df-h1-mobile;
      margin-left: 91px + $s3;
      margin-bottom: $s1;
      text-align: center;
    }

    &__button {
      display: flex;
      justify-content: flex-end;
      position: relative;

      .hierarchy-accounts-special-order-message {
        max-width: 200px;

        @include breakpoint(small down) {
          max-width: 100%;
        }
      }

      .product-replacement-line-item-buttons {
        &__request-quote-btn {
          width: auto;
          align-self: flex-end;
          position: relative;

          button {
            margin: 0 $s2 0;
            border-radius: 8px;
            min-width: auto;
          }

          .cor-button {
            @include breakpoint(small down) {
              padding: $s1;
            }
          }
        }

        &__add-to-cart-btn.cor-button {
          max-width: 144px;
          position: absolute;
          bottom: 0;
          right: 5px;
        }
      }
    }

    button {
      margin-left: 91px + $s3;

      @include breakpoint(medium) {
        margin-left: $s3;
      }
    }
  }
}
