@font-face {
  font-family: 'FrutigerBold';
  src: url('../../assets/fonts/frutiger/FrutigerLTStd-Bold.otf');
}

@font-face {
  font-family: 'FrutigerRegular';
  src: url('../../assets/fonts/frutiger/FrutigerLTStd-Regular.otf');
}

@font-face {
  font-family: 'FrutigerRegularItalic';
  src: url('../../assets/fonts/frutiger/FrutigerLTStd-RegularItalic.otf');
}

@font-face {
  font-family: 'MuseoLight';
  src: url('../../assets/fonts/museo/museo300-regular.otf');
}

@font-face {
  font-family: 'MuseoRegular';
  src: url('../../assets/fonts/museo/Museo500-Regular.otf');
}

@font-face {
  font-family: 'MuseoBold';
  src: url('../../assets/fonts/museo/museo700-regular.otf');
}

@font-face {
  font-family: 'NeueFrutigerWorldHeavy';
  src: url('../../assets/fonts/neue-frutiger/NeueFrutigerWorld-Heavy.ttf');
}

@font-face {
  font-family: 'NeueFrutigerWorldItalic';
  src: url('../../assets/fonts/neue-frutiger/NeueFrutigerWorld-It.ttf');
}

@font-face {
  font-family: 'NeueFrutigerWorldRegular';
  src: url('../../assets/fonts/neue-frutiger/NeueFrutigerWorld-Regular.ttf');
}

@font-face {
  font-family: 'NeueFrutigerWorldThin';
  src: url('../../assets/fonts/neue-frutiger/NeueFrutigerWorld-Thin.ttf');
}

// NORMALIZED FONTS
@font-face {
  font-family: museo;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url('../../assets/fonts/museo/museo300-regular.otf');
}

@font-face {
  font-family: museo;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url('../../assets/fonts/museo/Museo500-Regular.otf');
}

@font-face {
  font-family: museo;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('../../assets/fonts/museo/museo700-regular.otf');
}

@font-face {
  font-family: 'frutiger';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('../../assets/fonts/frutiger/FrutigerLTStd-Bold.otf');
}

@font-face {
  font-family: 'frutiger';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url('../../assets/fonts/frutiger/FrutigerLTStd-Regular.otf');
}

@font-face {
  font-family: 'frutiger';
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src: url('../../assets/fonts/frutiger/FrutigerLTStd-RegularItalic.otf');
}

@font-face {
  font-family: 'Frutiger LT Pro';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/frutiger-lt-pro/FrutigerLTProCondensed.otf');
}
@font-face {
  font-family: 'Frutiger LT Pro';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/frutiger-lt-pro/FrutigerLTProRoman.otf');
}
@font-face {
  font-family: 'Frutiger LT Pro';
  font-style: italic;
  font-weight: 400;
  src: url('../../assets/fonts/frutiger-lt-pro/FrutigerLTProCondensedIta.otf');
}
@font-face {
  font-family: 'Frutiger LT Pro';
  font-style: italic;
  font-weight: 400;
  src: url('../../assets/fonts/frutiger-lt-pro/FrutigerLTProItalic.otf');
}

@font-face {
  font-family: 'Frutiger LT Pro';
  font-style: normal;
  font-weight: 700;
  src: url('../../assets/fonts/frutiger-lt-pro/FrutigerLTProBold.otf');
}
@font-face {
  font-family: 'Frutiger LT Pro';
  font-style: normal;
  font-weight: 700;
  src: url('../../assets/fonts/frutiger-lt-pro/FrutigerLTProBoldCn.otf');
}
@font-face {
  font-family: 'Frutiger LT Pro';
  font-style: italic;
  font-weight: 700;
  src: url('../../assets/fonts/frutiger-lt-pro/FrutigerLTProBoldCnIta.otf');
}
@font-face {
  font-family: 'Frutiger LT Pro';
  font-style: italic;
  font-weight: 700;
  src: url('../../assets/fonts/frutiger-lt-pro/FrutigerLTProBoldItalic.otf');
}
@font-face {
  font-family: 'Neue Frutiger World Thin';
  font-style: normal;
  font-weight: 300;
  src: url('../../assets/fonts/neue-frutiger/NeueFrutigerWorld-Thin.ttf');
}
@font-face {
  font-family: 'Neue Frutiger World Regular';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/neue-frutiger/NeueFrutigerWorld-Thin.ttf');
}
@font-face {
  font-family: 'Neue Frutiger World Italic';
  font-style: italic;
  font-weight: 400;
  src: url('../../assets/fonts/neue-frutiger/NeueFrutigerWorld-It.ttf');
}
@font-face {
  font-family: 'Neue Frutiger World Heavy';
  font-style: normal;
  font-weight: 700;
  src: url('../../assets/fonts/neue-frutiger/NeueFrutigerWorld-Heavy.ttf');
}
