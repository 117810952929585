@import 'styles/_variables';
@import 'styles/_foundation';
@import '/src/styles/mixins/typography';

.region-link {
  z-index: $dropdown-z-index;
  position: relative;

  &__button {
    background-color: transparent;
    display: flex;
    align-items: center;
    column-gap: 10px;
    text-transform: capitalize;
    border-radius: 6px;
    border: 0;
    cursor: pointer;
    color: $black;

    &.is-active {
      background-color: $light-tan;
    }
  }

  &__icon {
    width: 16px;

    &--selected {
      width: 20px;
    }
  }

  &__panel {
    background: $light-tan;
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    padding: 10px 0;
    box-shadow: 0 0 30px rgba($gray, 0.45);
    border-radius: 6px;

    @include breakpoint(medium) {
      right: 0;
      transform: translateX(0);
    }

    &--down {
      top: 3rem;
      overflow-y: auto;
      right: 0;
      transform: translateX(0);
    }

    &--up {
      bottom: 3rem;
    }
  }

  &__list > li {
    &:not(:last-of-type) {
      margin-bottom: 3px;
    }
    & > a {
      text-decoration: none;
      white-space: nowrap;
      display: flex;
      align-items: center;
      column-gap: 10px;
      padding: 5px 10px;

      &:hover {
        text-decoration: none;
        background-color: $cor-region-link;

        .link-text > .link-text__language {
          color: $white;
        }
      }
    }
  }
}

body:not(.international-font) .region-link {
  &__button {
    @include font-frutiger-regular($size: 13);
  }

  &__list > li {
    & > a {
      .link-text {
        &__country {
          @include font-frutiger-bold($size: 11);
        }
        &__language {
          @include font-frutiger-regular($size: 11);
        }
      }
    }
  }
}

body.international-font .region-link {
  &__button {
    @include font-neue-frutiger-regular($size: 13);
  }

  &__list > li {
    & > a {
      .link-text {
        &__country {
          @include font-neue-frutiger-bold($size: 11);
        }
        &__language {
          @include font-neue-frutiger-regular($size: 11);
        }
      }
    }
  }
}
