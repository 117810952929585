@import 'styles/_variables';
@import 'styles/components/_cor-checkbox.scss';
@import 'styles/typography/_mixins';
@import 'corporate-ui/cor-typography/cor-typography';

.cor-filter-dropdown-option > .custom-checkbox__label {
  & {
    color: $aubergine-gray;
    align-items: center;

    @extend .cor-typography !optional;
    @extend .cor-typography__navigation-body-2 !optional;
  }

  & > .custom-checkbox__input {
    @extend .cor-checkbox__input !optional;
  }

  & > .custom-checkbox__checkmark {
    @extend .cor-checkbox__checkmark !optional;
  }
}
