@import 'styles/_variables';
@import 'styles/_foundation';
@import 'styles/typography/_mixins';

.buy-it-again-item-buttons {
  &__add-to-cart.tertiary,
  &__add-to-cart.secondary {
    width: 135px;
    display: flex;
    justify-content: center;
    align-items: baseline;

    @include breakpoint(small down) {
      width: 100%;
    }

    &.active {
      font-family: $frutiger-65-bold;

      .content {
        padding-left: $s1-5;
      }

      &::before {
        content: '';
        top: 5px;
        left: 28px;
        display: inline-block;
        transform: rotate(45deg);
        height: 16px;
        width: 9px;
        border-bottom: 2px solid $aubergine-gray;
        border-right: 2px solid $aubergine-gray;
      }
    }

    &.disabled {
      pointer-events: none;
      display: flex;
      justify-content: center;
      align-items: baseline;
      color: $aubergine-gray;
    }
  }

  &__request-quote {
    display: flex;
    align-items: center;

    button {
      margin-right: $s4;

      @include breakpoint(medium) {
        width: 136px;
        margin-right: 5px;
      }
    }

    @media (max-width: 960px) {
      .custom-tooltip {
        .custom-tooltip__content {
          width: auto;
          margin-left: -78px;

          &:after,
          &:before {
            right: 12px;
            left: auto;
          }
        }
      }
    }
  }

  &__button {
    text-decoration: none;
    border-radius: 14px;
    max-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint(small down) {
      width: 100%;
    }
  }
}
