.cor-checkbox {
  $checkbox-field: &;

  &__checkmark {
    position: relative;
    width: 18px;
    height: 18px;
    margin-right: 16px;
    border-radius: 4px;
    border: 1px solid $aubergine-gray;
    flex: 1 0 auto;

    &:after {
      display: none;
      content: '';
      position: absolute;
      left: 6px;
      top: 3px;
      width: 4px;
      height: 8px;
      border: solid $white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      background: initial;
    }
  }

  &__input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;

    &:hover {
      & ~ #{$checkbox-field}__checkmark {
        border-color: $aubergine-gray;
      }
    }

    &:checked {
      & ~ #{$checkbox-field}__checkmark {
        border: none;
        background: $aubergine-gray;

        &:after {
          display: block;
        }
      }

      &:hover {
        & ~ #{$checkbox-field}__checkmark {
          background: $aubergine-gray;
        }
      }
    }

    &:disabled {
      & ~ #{$checkbox-field}__checkmark {
        border: none;
        background: $aubergine-gray;
      }
    }
  }
}
