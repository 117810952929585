@import 'styles/typography/_mixins';
@import 'styles/_foundation';
@import 'styles/_variables';

.empty-shopping-list {
  padding: 13px 0 $s2;
  height: 100%;

  @include breakpoint(medium) {
    padding: 50px $s6 $s6 0;
  }

  &__content {
    height: 100%;

    .grid-x {
      height: 100%;
    }

    &__tabs {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }

    &__tabs-item {
      padding: $s2-5 29px;
      margin-bottom: -5px;
      border: 1px solid transparent;
      cursor: pointer;

      img {
        width: 65px;
      }
    }

    &__tabs-item.active {
      border: 1px solid $input-gray;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
      border-radius: 8px;
    }

    &__main {
      border-left: 1px solid $input-gray;
      text-align: center;
      display: flex;
      height: 100%;
      flex-direction: column;
      margin-top: 5px;
    }

    &__main-header {
      margin-top: $s1;

      &-title {
        @include df-h4-medium;
        color: $black;
        margin-bottom: 9px;
      }

      &-description {
        @include df-p1-medium;
        color: $light-gray-text;
        margin-bottom: $s7;
      }
    }

    &__main-description {
      @include df-p2-medium;
      text-align: left;
      margin: auto;
      width: 424px;
      flex: 1;
      color: $light-gray-text;
    }

    &__main-button {
      display: flex;
      justify-content: flex-end;

      .cor-button {
        border-radius: 14px;
      }
    }
  }
}
