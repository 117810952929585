@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

.change-password-modal {
  &__title {
    @include df-h2;
    color: $black;
  }

  &__description {
    @include df-p1-medium;
    margin-bottom: $s2;
  }

  &__hint {
    font: 14px/20px $frutiger-56-italic;
  }

  &__fields-container {
    margin-bottom: $s3;
  }

  &__form--invalid {
    input {
      border: 1px solid $red;
    }
  }

  .text-field {
    margin-top: $s3;
  }

  &__footer {
    justify-content: flex-start;
    gap: 8px;

    @include breakpoint(small down) {
      flex-wrap: wrap;
    }
  }

  &__content {
    padding: 0 $s2-5;

    @include breakpoint(medium) {
      padding: 0 $s4;
    }
  }
}
