@import 'styles/_variables';
@import 'styles/typography/_mixins';
@import 'styles/_foundation';

$user-list-item-icon-size: 20px;
$back-button-icon-size: 20px;
$edit-user-button-icon-size: 18px;

.location-users-info-modal {
  $location-users-info-modal: &;

  &__description {
    @include df-p2-medium;
    margin: $s2-5 0 $s2;
  }

  &__info-section {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    max-height: 500px;
    border-radius: 4px;
    box-shadow: 0.6px 0.8px 24px 0 rgba(0, 0, 0, 0.16);

    @include breakpoint(medium) {
      height: 380px;

      &:after {
        position: absolute;
        content: '';
        width: 1px;
        top: 20px;
        left: 235px;
        bottom: 0;
        background: $input-gray;
      }
    }
  }

  &__users-lists {
    flex: 1 0;
    overflow-y: auto;

    @include breakpoint(medium) {
      max-width: 235px;
    }

    &-group {
      padding-top: $s2-5;
      margin-bottom: $s4;

      &:last-child {
        margin-bottom: 0;
      }

      &-title {
        @include df-p2-bold;
        padding-left: $s2-5;
        margin-bottom: $s1-5;
      }
    }
  }

  &__users-list-item {
    @include df-p2-medium;
    position: relative;
    padding: $s1 $s3-5 $s1 $s2-5;
    cursor: pointer;

    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &:hover,
    &.active {
      background: $light-coral;
    }

    &:after {
      position: absolute;
      top: 50%;
      right: 12px;
      content: '';
      background: url('../../../assets/images/icons/icon-single-arrow-grey.svg');
      background-size: cover;
      height: $user-list-item-icon-size;
      width: $user-list-item-icon-size;
      transform: rotate(180deg) translateY(50%);
    }
  }

  &__guide {
    @include df-p1-medium;
    flex: 1 0;
    line-height: 2;
    padding: $s4 $s9 $s4 $s4;
  }

  &__user-info {
    flex: 1 0;
    overflow-y: auto;

    @include breakpoint(medium) {
      padding: $s2-5 $s4;
    }

    &-wrapper {
      position: relative;
      padding: $s1 0 $s4;
    }

    &-title {
      @include df-p2-bold;
      margin-bottom: $s1-5;
    }

    &-name {
      font: 16px/20px $frutiger-55-roman;
      text-transform: uppercase;
      margin-bottom: $s3;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;

      @include breakpoint(medium) {
        font: 18px/20px $frutiger-65-bold;
      }
    }

    &-section {
      @include df-p2-medium;
      margin-bottom: $s3;

      p:not(:last-child) {
        padding-bottom: 10px;
      }

      > p {
        font: 12px/20px $frutiger-55-roman;
        text-transform: uppercase;
        padding-bottom: $s1;
      }

      div {
        padding-left: $s2;

        @include breakpoint(medium) {
          padding-left: $s5;
        }
      }

      &--deleted {
        font-style: italic;
        color: $caption-gray;
      }
    }
  }

  &__caption {
    @include df-p2-italic;
    margin: $s1-5 0 $s3;
  }

  &__buttons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;

    @include breakpoint(small down) {
      #{$location-users-info-modal}__close-button {
        max-width: 154px;
      }
    }

    @include breakpoint(small down) {
      #{$location-users-info-modal}__resend-email-button {
        max-width: 185px;
      }
    }
  }

  &__mobile-user-actions {
    position: absolute;
    top: 8px;
    right: 0;
  }

  &__mobile-delete-user-button {
    img,
    &--disabled {
      height: 18px;
      width: 14px;
    }
  }

  &__mobile-edit-user-button {
    margin-left: $s4;

    img {
      height: $edit-user-button-icon-size;
      width: $edit-user-button-icon-size;
    }
  }

  &__desktop-user-actions {
    .cor-button:not(:last-child) {
      margin-right: 10px;
    }
  }

  &__back-button {
    position: relative;

    &.cor-button {
      &.text {
        font-family: $frutiger-55-roman;
        padding-left: $s2;
      }
    }

    &:after {
      position: absolute;
      content: '';
      left: -6px;
      top: 50%;
      height: $back-button-icon-size;
      width: $back-button-icon-size;
      background: url('../../../assets/images/icons/icon-single-arrow.svg');
      transform: translateY(-50%);
    }
  }

  &__close-button,
  &__resend-email-button {
    margin-left: auto;
    width: 100%;

    @include breakpoint(medium) {
      width: auto;
      min-width: 180px;
    }
  }

  &__content {
    padding: 0 $s2-5;

    @include breakpoint(medium) {
      padding: 0 $s4;
    }
  }

  &__footer {
    justify-content: space-between;
  }
}
