@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

$mobileIconSize: 25px;
$desktopIconSize: 32px;

.where-did-you-find-us-modal {
  &__description {
    @include df-p1-medium;
    margin-top: $s2;

    @include breakpoint(medium) {
      font: 18px/1.5 $frutiger-55-roman;
      margin-top: $s1-5;
    }
  }

  &__radio-buttons {
    margin: $s4 0 $s6 0;

    @include breakpoint(medium) {
      margin: $s2-5 0 $s10 0;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    @include breakpoint(medium) {
      justify-content: flex-end;
      flex-direction: row;
      .text {
        margin-right: $s4;
      }
    }
  }

  .custom-radio-button {
    display: flex;
    align-items: center;

    &__label a {
      text-decoration: none;
    }

    &:not(:last-child) {
      margin-bottom: $s2;

      @include breakpoint(medium) {
        margin-bottom: $s2-5;
      }
    }
  }

  &__close {
    width: 100%;

    @include breakpoint(medium) {
      width: 180px;
    }
  }

  &__go-back {
    @include breakpoint(small down) {
      margin-bottom: $s1-5;
    }
  }

  &__content {
    padding: $s2-5;

    @include breakpoint(medium) {
      padding: $s4-5;
    }
  }
}
