@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

$mobile-icon-size: 24px;
$desktop-icon-size: 32px;

.reorder-success-modal {
  &__title {
    @include df-h2;
    display: flex;

    @include breakpoint(medium) {
      @include df-h3-bold;
    }

    img {
      margin-right: $s2;
      height: $mobile-icon-size;
      width: $mobile-icon-size;

      @include breakpoint(medium) {
        margin-right: $s2-5;
        height: $desktop-icon-size;
        width: $desktop-icon-size;
      }
    }
  }

  &__content {
    margin-bottom: $s5;
    padding: 0 $s2-5;

    @include breakpoint(medium) {
      padding: 0 $s4;
    }
  }

  &__section {
    border-bottom: 1px solid $input-gray;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;

    @include breakpoint(medium) {
      padding-bottom: 31px;
      margin-bottom: 18px;
    }

    &--bordered {
      margin-bottom: 18px;
      border-bottom: 1px solid $input-gray;

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }

    &-message {
      @include df-p1-medium;
      margin-bottom: $s2;
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }

    .loading-indicator {
      position: static;
      transform: none;
      margin: auto;
    }
  }

  &__deactivated-item-sku,
  &__special-order-sku,
  &__out-of-stock-sku {
    strong {
      @include df-p1-bold;
      margin-right: $s2;
    }

    p {
      @include df-p2-medium;
      line-height: 24px;
      color: $black;
    }
  }

  &__exceeded-max-quantity-sku,
  &__short-supply-sku {
    strong {
      @include df-p1-bold;
    }
  }

  &__deactivated-item-sku {
    margin-bottom: $s2-5;
  }

  &__special-order-sku,
  #{&}__out-of-stock-sku {
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 18px;

    button.cor-button,
    .cor-button {
      @include df-p1-medium;
      align-self: flex-end;
      margin-left: auto;
      white-space: nowrap;

      @include breakpoint(medium) {
        align-self: flex-start;
      }
    }
  }

  &__footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: -6px -10px;

    > button {
      flex: 1 0;
      white-space: nowrap;
      margin: 6px 10px;
    }
  }

  &__close-btn {
    @include breakpoint(medium) {
      max-width: 120px;

      @at-root button#{&} {
        margin-left: auto;
      }
    }
  }

  .discontinued-with-replacement-slider {
    margin-bottom: 14px;

    @include breakpoint(medium) {
      margin-bottom: 9px;
    }
  }
}
