@import 'styles/_variables';
@import 'styles/_foundation.scss';

.cor-contentstack-video {
  width: 100%;
  margin: 0 auto;
  height: 0;
  padding-top: 56.25%;
  position: relative;

  &__player {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .cor-play-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
