@import 'styles/_variables';
@import 'styles/typography/_mixins';
@import 'styles/_foundation';

.remove-user-confirmation-modal {
  color: $black;

  &__message {
    @include df-p1-medium;
    padding: 0 $s2-5;

    @include breakpoint(medium) {
      padding: $s1 $s4 0 $s4;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    margin: $s3-5 0 2px;

    @include breakpoint(medium) {
      flex-direction: row;
      justify-content: flex-end;
      margin: $s5 0 0 5px;
    }
  }

  &__proceed-button {
    margin-bottom: $s1-5;
    width: 100%;

    @include breakpoint(medium) {
      margin-bottom: 0;
      margin-left: $s4;
      width: 229px;
    }
  }

  &__close-button {
    width: 100%;

    @include breakpoint(medium) {
      width: auto;

      &.cor-button.secondary {
        @include df-p1-medium;
        border: none;
        color: $red;
        text-decoration: underline;
        padding: $s1-5 0;
        width: fit-content;

        &:hover:not(:disabled) {
          background-color: $white;
        }
      }
    }
  }
}
