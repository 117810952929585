@import 'styles/_foundation';
@import 'styles/_variables';

.corporate--recipe {
  & > * {
    @media print {
      break-inside: avoid;
    }
  }

  $max-width-container: 800px;

  .cor-rich-content {
    max-width: $max-width-container !important;
    padding: 20px 0;
    margin-bottom: 20px;
  }

  .cor-video-component .grid-container,
  .cor-image {
    padding-left: 1rem;
    padding-right: 1rem;
    @include breakpoint($cor-desktop) {
      padding-left: 0;
      padding-right: 0;
      max-width: $max-width-container;
    }
  }

  .cor-detail-hero-banner__date-author {
    &,
    &:hover {
      color: $mid-gray;
    }
  }

  .cor-buttons-group {
    align-content: flex-start;
    margin: 0;
    @include breakpoint($cor-desktop) {
      margin-bottom: 74px;
    }
  }
  .cor-buttons-group__row {
    padding: 0 1rem;
  }
  .cor-detail-hero-banner {
    padding-bottom: 0;
    @include breakpoint($cor-desktop) {
      padding-bottom: 34px;
    }
  }

  .cor-video-component {
    @media print {
      display: none;
    }
  }
}

.cor-detail-related-content__recipes {
  &.cor-featured-content {
    background-color: $cor-bg-gray;
    padding-top: 45px;
    padding-bottom: 50px;

    @include breakpoint($cor-desktop) {
      padding-top: 75px;
      padding-bottom: 70px;
    }
  }
}
