@import 'styles/_variables';

.cor-bg-color {
  &__light-tan {
    background-color: $cor-bg-gray;
  }
  &__white {
    background-color: $cor-bg-gray-lightest;
  }
  &__yellow {
    background-color: $cor-dawn-yellow;
  }
}
