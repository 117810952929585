@import 'styles/_foundation';
@import 'styles/_variables';

.cor-news-listing {
  & {
    margin-top: 40px;
    margin-bottom: 40px;
    position: relative;
    min-height: 300px;

    @include breakpoint($cor-desktop) {
      margin-top: 60px;
      margin-bottom: 70px;
    }

    & > .pagination {
      justify-content: center;
    }
  }

  &__cards {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    margin-bottom: 40px;
    justify-items: center;

    @include breakpoint($cor-desktop) {
      grid-template-columns: repeat(3, 1fr);
      row-gap: 40px;
      column-gap: 55px;
      margin-bottom: 73px;
      justify-items: stretch;
    }
  }

  &__pagination-quantity {
    display: none;
    text-align: right;

    @include breakpoint($cor-desktop) {
      display: block;
      margin-bottom: 24px;
    }
  }

  & .pagination-item:not(.pagination-item--prev):not(.pagination-item--next) {
    display: none;
  }
}
