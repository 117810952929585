@import 'styles/_variables';
@import 'styles/_foundation';

.cor-buttons-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: $s2-5 0;
  padding: 0;
  list-style: none;
  flex-direction: column;
  gap: $s2-5;
  &__row {
    display: flex;
    gap: $s2-5;
    flex-direction: column;
    width: 100%;
    padding: $s2-5;
    align-items: center;
    @include breakpoint(640px) {
      flex-direction: row;
      align-items: start;
      width: auto;
    }
  }
}
