@import 'styles/_variables';
@import 'styles/_foundation';
@import 'styles/typography/_mixins';
@import 'styles/components/_carousel';

$mobile-dot-size: 20px;
$desktop-dot-size: 24px;
$controlSize: 56px;

.products-carousel {
  position: relative;

  @include breakpoint(medium) {
    margin-bottom: $s6;
  }

  > header {
    position: relative;
    margin: $s1 $s1;

    @include breakpoint(medium) {
      margin: 14px 102px 0;
    }

    > h2 {
      @include df-h2;
    }

    > h3 {
      margin: $s3 0;
    }

    > a {
      position: absolute;
      right: 0;
      bottom: 8px;
      color: $red;
      @include df-p2-bold;

      @include breakpoint(small down) {
        display: none;
      }
    }
  }

  &__slider {
    max-width: 1152px;
    margin: 0 auto;
    padding: $s1 $s0-5;
    background: $white;

    @include breakpoint(medium) {
      padding: $s2;
    }

    &.swiper {
      position: static;
    }

    .swiper {
      &-pagination {
        &.swiper-pagination-horizontal {
          position: relative;
          right: 0;
          bottom: -10px;
          padding-top: $s2;

          @include breakpoint(medium) {
            bottom: -23px;
            padding-bottom: $s2-5;
          }
        }

        &-horizontal {
          &.swiper-pagination-bullets {
            .swiper-pagination-bullet {
              width: $mobile-dot-size;
              height: $mobile-dot-size;
              border: 1px solid $input-gray;
              background-color: $white;
              border-radius: 100%;
              opacity: 1;

              @include breakpoint(medium) {
                width: $desktop-dot-size;
                height: $desktop-dot-size;
              }

              &-active {
                background-color: $orange;
                border: 1px solid transparent;
              }

              button {
                &:before {
                  display: none;
                }
              }
            }
          }
        }
      }

      &-slide {
        display: flex;
        height: auto;
        max-width: 50%;

        @include breakpoint(medium) {
          max-width: 358px;
        }

        & > div {
          width: 100%;
          height: 100%;
        }

        &:nth-child(4n) {
          @include breakpoint(medium) {
            .custom-tooltip__content {
              margin-left: -45px;

              &:after {
                right: 4px;
                left: auto;
              }
            }
          }
        }

        &-wrapper {
          display: flex;
          justify-content: center;
        }
      }

      &-button-next,
      &-button-prev {
        width: $controlSize;
        height: $controlSize;
        z-index: $base-z-index;
        top: 42%;
        background: url('~assets/images/icons/icon-products-carousel-arrow.svg') no-repeat;

        &:hover {
          opacity: 0.8;
        }

        &:after {
          display: none;
        }

        @include breakpoint(small down) {
          display: none;
        }
      }

      &-button-prev {
        left: 0;
      }

      &-button-next {
        right: 0;
        top: 45%;
        transform: translate(0, -50%) rotate(180deg);
      }
    }
  }

  &__item-wrapper {
    outline: none;
    height: 100%;
  }

  &__item {
    margin: $s0-5;
    height: calc(100% - #{$s1});

    .product-card {
      &__icon-check {
        display: inline-block;
      }
      &__status {
        bottom: 8px;
      }

      &__quantity {
        @include breakpoint(small down) {
          align-self: flex-start;
        }
      }
    }

    @include breakpoint(medium) {
      margin: $s1;
      height: calc(100% - #{$s2});

      .product-card__status {
        bottom: 16px;
      }

      &-single {
        width: fit-content;
        margin: 0 auto;
      }
    }
  }
  .product-card {
    box-shadow: 0.6px 0.8px 7px 0 rgba(0, 0, 0, 0.12);

    @include breakpoint(medium) {
      box-shadow: 0.6px 0.8px 12px 0 rgba(0, 0, 0, 0.12);
    }

    &__price {
      font-size: 20px;

      @include breakpoint(medium) {
        font-size: 24px;
      }
    }

    &__footer {
      margin-right: $s1-5;
    }
  }

  .custom-tooltip__content {
    max-width: 180px;

    @include breakpoint(medium) {
      max-width: 220px;
    }
  }
}
