@import 'styles/_foundation.scss';
@import 'styles/_variables';
@import '/src/styles/mixins/typography';

$mobile-circle-size: 50px, 110px;
$desktop-circle-size: 50px, 191px;
$circle-stroke-color: $red;
$circle-stroke-background: $light-gray;
$circle-stroke-width: 6;

.loading-indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: $loader-z-index;
  text-align: center;

  > img {
    animation: 3s ease-in-out infinite both spin;
    max-width: 100%;

    width: nth($mobile-circle-size, 2);
    height: nth($mobile-circle-size, 2);

    @include breakpoint(medium) {
      width: nth($desktop-circle-size, 2);
      height: nth($desktop-circle-size, 2);
    }
  }

  .caption {
    @include font-museo-regular($size: 14, $lineheight: 32px, $spacing: 0.3px);
    margin: $s2 0 $s1;
    width: 100%;

    @include breakpoint(medium) {
      @include font-museo-regular($size: 16, $lineheight: 32px, $spacing: 0.3px);
      margin-top: $s3;
    }
  }

  // smaller version of spinner...
  &.is-small {
    > img {
      width: nth($mobile-circle-size, 1);
      height: nth($mobile-circle-size, 1);

      @include breakpoint(medium) {
        width: nth($desktop-circle-size, 1);
        height: nth($desktop-circle-size, 1);
      }
    }
    .caption {
      @include font-museo-regular($size: 14, $lineheight: 20px, $spacing: 0.3px);

      @include breakpoint(medium) {
        @include font-museo-regular($size: 16, $lineheight: 20px, $spacing: 0.3px);
        margin-top: $s2;
      }
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  &--fullsized {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
  }
}
