@import '../variables';

@mixin cross-close-icon($size, $color, $hoverColor) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: $size;
  height: $size;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;

  &:hover:before,
  &:hover:after {
    background-color: $hoverColor;
  }

  &:before,
  &:after {
    position: absolute;
    top: 0;
    left: $size / 2 - 1px;
    content: '';
    height: $size;
    width: 2px;
    background-color: $color;
  }

  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}

@mixin cross-close-button($icon-size: 20px, $button-size: $icon-size, $color: $black, $hoverColor: $input-gray) {
  position: relative;
  width: $button-size;
  height: $button-size;
  margin: -#{($button-size - $icon-size) / 2};

  i {
    @include cross-close-icon($icon-size, $color, $hoverColor);
  }
}

@mixin css-triangle($direction, $height, $width, $color: $black) {
  border-style: solid;
  height: 0;
  width: 0;

  @if ($direction == 'up') {
    border-color: transparent transparent $color transparent;
    border-width: 0 ($width/2) $height ($width/2);
  }
  @if ($direction == 'down') {
    border-color: $color transparent transparent transparent;
    border-width: $height ($width/2) 0 ($width/2);
  }
  @if ($direction == 'right') {
    border-color: transparent transparent transparent $color;
    border-width: ($height/2) 0 ($height/2) $width;
  }
  @if ($direction == 'left') {
    border-color: transparent $color transparent transparent;
    border-width: ($height/2) $width ($height/2) 0;
  }
}

@mixin srOnly {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
