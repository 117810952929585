@import 'styles/_variables';
@import 'styles/_foundation';

$icon-desktop-size: 32px;
$icon-mobile-size: 24px;

.error-notification {
  display: flex;
  align-items: center;
  background: $red;
  margin-bottom: $s2;
  padding: $s1 14px;
  color: $white;
  font: 14px/1.43 $frutiger-65-bold;

  @include breakpoint(medium) {
    font: 16px/1.5 $frutiger-65-bold;
  }

  &__icon {
    font-size: 0;
    min-width: $icon-mobile-size;
    width: $icon-mobile-size;
    margin-right: $s1;

    @include breakpoint(medium) {
      width: $icon-desktop-size;
    }

    img {
      width: 100%;
    }
  }

  a {
    text-decoration: underline;

    &:hover {
      opacity: 0.8;
    }
  }
}
