@import 'styles/_variables';
@import 'styles/_foundation';
@import 'styles/mixins/_typography';
@import 'styles/_cor-rich-text';

.cor-leadership {
  $maxTilesWidthTotal: 1320px;
  background-color: $cor-bg-gray-bright;
  padding: 50px 0;
  @include breakpoint($cor-desktop) {
    padding-top: 92px;
    padding-bottom: 120px;
  }

  &__container {
    max-width: $maxTilesWidthTotal;
    margin: 0 auto;
  }

  &__head {
    padding: 0 1rem 50px 1rem;
    max-width: 800px;
    margin: 0 auto;
  }

  &__title {
    margin-bottom: 1rem;
  }

  &__title,
  &__description {
    text-align: center;
  }

  &__tiles {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1em;
    @include breakpoint($cor-desktop) {
      padding: 0;
    }
  }
}

body:not(.international-font) .cor-leadership {
  &__description {
    @include cor-rich-text(
      $map: (
        'p': (
          'color': #{$mid-gray},
        ),
      )
    );
  }
}

body.international-font .cor-leadership {
  &__description {
    @include cor-rich-text(
      $map: (
        'p': (
          'face': neueFrutiger,
          'color': #{$mid-gray},
        ),
      )
    );
  }
}
