@import 'styles/_variables';
@import 'styles/_foundation';
@import 'styles/typography/_mixins';

$iconSize: 20px;

.expandable-section {
  $expandable-section: &;

  border-bottom: 1px solid $light-gray;
  padding-right: $s2-5;

  @include breakpoint(medium) {
    padding-right: 0;
  }

  &:last-child {
    border: none;
  }

  &__title {
    @include df-s2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $red;
    cursor: pointer;
    padding: $s1-5 0;

    @include breakpoint(medium) {
      padding: $s2 0 $s1-5;
    }
  }

  &__custom-expand-icon {
    transition: transform 0.25s ease-out;

    #{$expandable-section}--expanded & {
      transform: rotate(180deg);
    }
  }

  &__inner {
    overflow: hidden;
    max-height: 0;

    #{$expandable-section}--expanded & {
      max-height: none;
    }
  }

  &__expand-icon {
    position: relative;
    width: $iconSize;
    height: $iconSize;
    border: 1px solid $light-gray;
    flex-shrink: 0;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: $red;
      transition: transform 0.25s ease-out;
    }

    /* Vertical line */
    &:before {
      width: 2px;
      height: 10px;
      margin-left: -1px;
      transform: rotate(0) translateY(-50%);
      transform-origin: top;
    }

    /* horizontal line */
    &:after {
      width: 10px;
      height: 2px;
      margin-top: -1px;
      transform: rotate(0) translateX(-50%);
      transform-origin: left;
    }

    #{$expandable-section}--expanded & {
      &:before {
        transform: rotate(90deg) translateY(-50%);
      }
      &:after {
        transform: rotate(180deg) translateX(-50%);
        opacity: 0;
      }
    }
  }

  #{$expandable-section} {
    &__inner-enter {
      max-height: 0;
    }

    &__inner-enter-done {
      max-height: 5000px;
      transition: all 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    }

    &__inner-exit {
      max-height: 5000px;
    }

    &__inner-exit-done {
      max-height: 0;
      transition: all 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    }
  }

  &--without-animation {
    #{$expandable-section} {
      &__inner {
        overflow: visible;
      }

      &__inner-enter-done,
      &__inner-exit-done {
        max-height: none;
        transition: none;
      }
    }
  }
}
