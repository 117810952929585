@import 'styles/_foundation';
@import 'styles/_variables';

// BASE STYLES
// src/components/layout/corporate-page-layout.scss

.cor-rich-content-component {
  background-color: $white;
  padding: 1rem;
}

.cor-rich-content {
  .table-container {
    overflow-x: auto;
  }

  table {
    border: 1px solid $black;

    td,
    th {
      border: 1px solid $black;
      padding: $s1;
    }

    td {
      padding: $s1 $s4 $s1 $s1;

      ul {
        margin-top: 0;

        > li {
          margin: 0 0 0 $s2-5;
          font-style: italic;
        }
      }
    }
  }

  hr {
    border: 1px solid $light-gray;
    margin: $s5 0 $s3;
  }

  img {
    position: relative;
    max-width: 100%;
  }

  .cor-rte-video {
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
    margin-bottom: $s2-5;

    > iframe {
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }

  figure {
    max-width: 100% !important;

    @include breakpoint(small down) {
      float: none !important;
    }

    img {
      max-width: 100% !important;
      margin-bottom: $s2-5;
    }
  }

  figcaption {
    display: none;
  }
}

body:not(.international-font) .cor-rich-content {
  em {
    font-family: $frutiger-56-italic;
  }

  strong {
    font-family: $frutiger-65-bold;
  }
}

body.international-font .cor-rich-content {
  em {
    font-family: $neue-frutiger-italic;
  }

  strong {
    font-family: $neue-frutiger-700;
  }
}
