@import 'styles/_variables';
@import 'styles/typography';
@import 'corporate-ui/cor-typography/cor-typography';

.pagination {
  display: flex;
  align-items: center;

  &-item {
    $pagination-item: &;
    $pagination-icon-size: 24px;
    $pagination-item-size: 44px;
    margin: 0 $s0-5;
    color: $aubergine-gray;
    cursor: pointer;
    @include df-cta;

    &__icon {
      width: $pagination-icon-size;
      height: $pagination-icon-size;
      background: $aubergine-gray;
      margin-bottom: 2px;
      mask-repeat: no-repeat !important;
      mask-position: center !important;
      display: inline-block;
    }

    &__arrow-right {
      mask: url('../../assets/images/icons/icon-caret-right-grey.svg');
    }
    &__arrow-left {
      mask: url('../../assets/images/icons/icon-caret-left-grey.svg');
    }

    &--active {
      background: $cor-bg-gray;
    }

    &--page,
    &--etc {
      margin: 0;
      width: $pagination-item-size;
      height: $pagination-item-size;
      border-radius: 50%;
      border: 1px solid transparent;

      .pagination-link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: $pagination-item-size;
        height: $pagination-item-size;
        margin: -1px;
        padding-top: 2px; // TODO: remove top padding when fonts will be fixed

        @extend .cor-typography !optional;
        @extend .cor-typography__body-large !optional;
      }

      &:hover {
        border: 1px solid $cor-bg-gray;
      }
    }

    &--etc {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding-bottom: 5px;
    }

    &--prev,
    &--next {
      span {
        margin-top: 1px;
        text-decoration-line: underline;
      }

      .pagination-link {
        display: flex;
        height: $pagination-item-size + 1px; // TODO: change to 24 when fonts will be fixed
        align-items: center;
      }
    }

    &--disabled {
      pointer-events: none;
      color: $mid-gray;

      #{$pagination-item}__icon {
        background: $mid-gray;
      }
    }
  }
}
