@import 'styles/_variables';
@import 'styles/_foundation';
@import 'styles/typography/_mixins';

.sku-quantity-limitation-alert {
  @include df-p2-italic;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: $red;

  @include breakpoint(medium) {
    font-size: 14px;
  }

  &__icon {
    width: 20px;
    margin-right: 9px;
  }
}
