@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

.collapsible-consent-section {
  width: 100%;
  border: 1px solid $input-gray;
  border-radius: 1px;
  padding: $s2 $s1-5;

  &__container {
    display: flex;
  }

  &__content {
    margin-right: $s1-5;
    line-height: 20px;
    font-size: 14px;

    &.collapsed {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    &.expanded {
      height: auto;
    }
  }

  &__checkbox {
    margin-top: $s2-5;

    .custom-checkbox__label {
      align-items: center;
    }

    .custom-checkbox__label-text {
      @include df-p1-medium;
      color: $black;
    }
  }

  &__icon {
    margin-right: $s0-5;
    cursor: pointer;

    &-image {
      width: 16px;
      height: 16px;
    }

    .arrow-up {
      transform: rotate(-90deg);
    }
    .arrow-down {
      transform: rotate(90deg);
    }
  }
}
