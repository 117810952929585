@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

$mobile-icon-size: 24px;
$desktop-icon-size: 32px;

.add-exceeded-item-to-cart-modal {
  .modal-header__title-text {
    display: flex;
  }

  &__content {
    padding: 0 $s4;
  }

  &__section {
    &-message {
      @include df-p1-medium;
      margin-bottom: $s2;
    }
  }

  &__exceeded-max-quantity-sku,
  &__short-supply-sku {
    margin-top: 10px;

    strong {
      @include df-p1-bold;
    }

    p {
      @include df-p2-medium;
      line-height: 24px;
      color: $black;
    }
  }

  &__footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > button {
      flex: 1 0;
      white-space: nowrap;
      margin: 6px 10px;
    }
  }

  &__close-btn {
    @include breakpoint(medium) {
      max-width: 120px;

      @at-root button#{&} {
        margin-left: auto;
      }
    }
  }
}
