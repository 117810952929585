@import 'styles/_foundation';
@import 'styles/_variables';

.cor-featured-content__card-with-publication-date {
  & {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 20px rgba(219, 219, 219, 0.5);
    border-radius: 6px;
    overflow: hidden;
    height: 100%;
    max-width: 344px;
    min-width: 50px;
    text-decoration: initial;

    @include breakpoint($cor-desktop) {
      max-width: initial;
    }
  }

  &[href]:hover .cor-featured-content__card-with-publication-date__description__title {
    @include breakpoint($cor-desktop) {
      text-decoration: underline;
    }
  }

  &[href]:hover .cor-featured-content__card-with-publication-date__description__publication-date {
    @include breakpoint($cor-desktop) {
      text-decoration: underline;
    }
  }

  &__image__container {
    height: 214px;
    overflow: hidden;

    @include breakpoint($cor-desktop) {
      height: 252px;
    }
  }

  &__image {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  &__description {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: $cor-white;
    padding: 25px 13px;

    @include breakpoint($cor-desktop) {
      padding: 30px;
    }
  }

  &__description__category {
    margin-bottom: 10px;
  }

  &__description__title {
    margin-bottom: 8px;

    @include breakpoint($cor-desktop) {
      margin-bottom: 20px;
    }
  }

  &__description__description {
    margin-bottom: 11px;

    @include breakpoint($cor-desktop) {
      margin-bottom: 20px;
    }
  }

  &__description__publication-date {
    margin-top: auto;
  }
}
