@mixin content-inset($max-width: $grid-container-max, $padding-vert: 25px, $padding-mobile: 0) {
  $grid-container-shim: 32;
  margin: 0 auto;
  @if (length($padding-vert) > 1) {
    padding: nth($padding-vert, 1) $padding-mobile nth($padding-vert, 2);
  } @else {
    padding: $padding-vert $padding-mobile;
  }
  width: 100%;

  @include breakpoint(medium) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  @include breakpoint($max-width) {
    padding-left: calc((100% - #{$max-width - $grid-container-shim}) / 2);
    padding-right: calc((100% - #{$max-width - $grid-container-shim}) / 2);
  }
}
