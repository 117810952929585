@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

$warning-icon-size-mobile: 20px;
$warning-icon-size-desktop: 24px;

.account-warning {
  $account-warning: &;

  z-index: $base-z-index;

  &__inner-wrapper {
    display: flex;
    align-items: center;
    height: auto;
    background-color: $light-yellow;
    border: 1px solid $yellow;
    padding: $s2;

    @include breakpoint(medium) {
      width: 100%;
      justify-content: center;
    }

    @include breakpoint(small down) {
      display: flex;
      position: relative;

      #{$account-warning}__container #{$account-warning}__content-toggle {
        align-self: flex-end;

        &.cor-button.link-2 {
          color: $black;
          font-weight: 700;
          display: inline;
        }

        &.hidden {
          display: none;
        }
      }
    }
  }

  &__inner {
    @include df-p1-bold;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-left: $s1-5;

    @include breakpoint(medium) {
      padding: 0;
      display: inline-block;
      letter-spacing: normal;
    }

    @include breakpoint(small down) {
      flex: 1;
      padding-left: $s0-5;

      & > div {
        display: inline;
      }

      &--collapsed {
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }

  &__container {
    display: flex;

    @include breakpoint(medium) {
      width: 100%;
      text-align: center;
      justify-content: center;
    }
  }

  &__warning-icon {
    width: $warning-icon-size-mobile;
    height: $warning-icon-size-mobile;
    margin-right: 5px;

    @include breakpoint(medium) {
      width: $warning-icon-size-mobile;
      height: $warning-icon-size-mobile;
      margin-bottom: -3px;
    }
  }

  &__content {
    @include breakpoint(medium) {
      width: 100%;
    }
  }
}
