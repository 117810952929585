@import 'styles/_variables';
@import 'styles/typography/_mixins';
@import 'styles/_foundation';

.cor-product-status-labels {
  text-transform: uppercase;
  text-align: center;
  color: $white;
  overflow: hidden;

  &__wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin: -#{$s0-5} -6px;
  }

  &--small {
    font: 10px/1.45 $frutiger-65-bold;
    letter-spacing: 1px;

    .cor-product-status {
      padding: 3px $s1-5;

      &--out-of-stock {
        padding: 1px $s1-5;
      }

      &--phasing-out {
        padding: 3px 6px;
        width: 100%;
        max-width: 150px;
        margin-right: 15px;
      }
    }
  }

  &--large {
    font: 11px/1.45 $frutiger-65-bold;
    letter-spacing: 1.2px;

    .cor-product-status {
      &--out-of-stock {
        padding: 0 $s1-5;
      }
    }
  }

  &--responsive {
    @include df-caption;
  }
}

.cor-product-status {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px $s1-5;
  border-radius: $s1-5;
  height: 18px;
  margin: $s0-5 6px;
  z-index: $base-z-index;
  white-space: nowrap;

  &--special-order {
    background: $green;
  }

  &--discontinued {
    background: $brown;
  }

  &--phasing-out {
    background: $yellow;
    color: $black;
  }

  &--cancelled {
    background: $dark-gray;
  }

  &--out-of-stock {
    background-color: $white;
    color: $dark-gray;
    border: 2px solid $dark-gray;
  }
}
