@import 'styles/_variables';
@import 'styles/_foundation';
@import 'styles/typography/_mixins';

$desktopTooltipIconSize: 28px;
$mobileTooltipIconSize: 20px;

.alternative-items-line-item {
  $alternative-items-line-item: &;

  display: flex;
  flex-direction: column;
  padding: 22px 0 19px;

  @include breakpoint(small down) {
    &#{&}--single-status-label {
      .product-status-labels__wrapper {
        justify-content: flex-start;
        margin-left: 0;
      }

      .product-status {
        margin-left: 0;
      }
    }
  }

  @include breakpoint(medium) {
    padding: $s2 0 $s2 10px;
    flex-direction: column;
  }

  .line-item-image__wrapper {
    margin-right: $s1-5;

    @include breakpoint(medium) {
      margin-right: $s2;
    }
  }

  &:last-child {
    padding-bottom: 0;
  }

  &__content {
    display: flex;
    width: 100%;

    @include breakpoint(medium) {
      margin-bottom: 6px;
    }
  }

  &__line-item-info {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__info {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    height: fit-content;
    margin-bottom: 10px;

    @include breakpoint(medium) {
      align-items: center;

      #{$alternative-items-line-item}__name,
      #{$alternative-items-line-item}__buttons {
        min-height: 49px;
      }
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid $input-gray;
  }

  &:last-child {
    @include breakpoint(medium) {
      padding-top: $s2;
      padding-bottom: 0;
    }
  }

  &__brand {
    @include df-caption;
    color: $brown;
    margin-bottom: 10px;

    @include breakpoint(medium) {
      margin-bottom: $s0-5;
    }
  }

  &__name {
    @include df-h4-bold;

    @include breakpoint(medium) {
      font-size: 18px;
    }
  }

  &__sku-info {
    @include breakpoint(medium) {
      width: 32%;
    }

    &-item {
      @include df-p2-medium;
      display: flex;
      align-items: center;
      color: $dark-gray;

      @include breakpoint(medium) {
        &:not(:last-child) {
          border-right: 1px solid $input-gray;
        }

        &:not(:first-child) {
          padding: 0 $s1;
        }
      }

      span {
        margin-right: $s0-5;
      }
    }

    &-characteristics {
      display: flex;
      flex-direction: column;
      width: 100%;

      @include breakpoint(medium) {
        flex-direction: row;
        margin-top: auto;
      }
    }
  }

  &__item-number {
    color: $black;
    margin-bottom: 10px;

    &--no-status {
      margin-bottom: 0;
    }

    @include breakpoint(medium) {
      padding-right: $s1-5;
      margin-bottom: 0;
    }
  }

  &__price-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 26px;

    @include breakpoint(medium) {
      width: 33%;
      margin-top: 0;
    }

    @include breakpoint(small down) {
      padding: 0 6px;
    }

    &--with-total-price {
      @include breakpoint(medium) {
        width: 45%;
      }
    }
  }

  &__price {
    @include breakpoint(medium) {
      width: 30%;
    }

    &-label {
      @include df-caption;
      color: $dark-gray;
      margin-bottom: 15px;
    }

    &-value {
      font: 18px/1.33 $museo-500;

      @include breakpoint(medium) {
        @include df-s1;
      }
    }

    &-quantity {
      width: fit-content;

      @include breakpoint(medium) {
        min-width: 40%;
      }

      &-label {
        @include df-caption;
        color: $dark-gray;
        margin-bottom: $s2;

        @include breakpoint(medium) {
          margin-bottom: $s1;
        }
      }
    }
  }

  .product-status-labels {
    @include breakpoint(small down) {
      margin-top: 10px;
    }
  }

  .product-status {
    &-labels__wrapper {
      @include breakpoint(small down) {
        margin-left: $s7;
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 26px;

    @include breakpoint(medium) {
      margin-top: 0;
    }

    .icon-added-to-cart {
      margin-left: $s2;

      @include breakpoint(medium) {
        margin: 0 $s2 0 0;
      }
    }
  }

  &-buttons {
    &__request-quote {
      .custom-tooltip {
        &__icon {
          width: $mobileTooltipIconSize;
          height: $mobileTooltipIconSize;

          @include breakpoint(medium) {
            width: $desktopTooltipIconSize;
            height: $desktopTooltipIconSize;
          }

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .hierarchy-accounts-special-order-message {
    @include breakpoint(medium) {
      max-width: 200px;
    }

    @include breakpoint(small down) {
      padding-top: $s2;
      border-top: 1px solid $input-gray;
      margin-top: 18px;
    }
  }

  &__link {
    text-decoration: none;
  }
}
