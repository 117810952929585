@import 'styles/_foundation';
@import 'styles/_variables.scss';
@import 'styles/typography';

.tabs {
  width: 100%;
  border-bottom: 2px solid $input-gray;

  &__tab {
    @include df-p2-bold;
    padding: 10px $s4;
    color: $black;
    letter-spacing: 0.2px;
    border: 2px solid $input-gray;
    background-color: $snow;
    border-bottom: none;
    margin-right: $s1-5;
    margin-bottom: 0;
    border-radius: 8px 8px 0 0;

    @include breakpoint(medium) {
      @include df-p1-bold;
      padding: $s2 $s3;
      letter-spacing: 0.4px;
      margin-right: $s1;
    }

    @include breakpoint(small down) {
      width: 170px;
    }

    &:hover {
      cursor: pointer;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      cursor: pointer;
    }

    &:last-child {
      margin-right: 0;
    }

    &--selected {
      position: relative;
      color: $black;
      background-color: $white;
      border: 2px solid $red;
      border-bottom: none;
      padding: $s2;
      border-radius: 8px 8px 0 0;

      @include breakpoint(medium) {
        padding: 14px 22px;
      }
    }

    &--disabled {
      position: relative;
      background-color: $light-snow;
      color: $input-gray;
      border: 2px solid $input-gray;
      pointer-events: none;
    }

    &--disabled,
    &--selected {
      &:after {
        position: absolute;
        width: 100%;
        content: '';
        height: 3px;
        left: 0;
        bottom: -3px;
        z-index: $base-z-index;
      }
    }

    &--disabled {
      &:after {
        background-color: $light-snow;
      }
    }

    &--selected {
      &:after {
        background-color: $white;
      }
    }
  }

  &__list {
    display: flex;
    justify-content: flex-start;
    padding-top: $s2;
  }
}
