@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

.session-timeout-warning {
  background: $light-yellow;
  padding: $s1-5 $s2;
  color: $black;

  @include breakpoint(medium) {
    padding: 10px 0;
  }

  @include df-p1-bold;

  &__content {
    display: flex;

    @include breakpoint(medium) {
      align-items: flex-start;
      justify-content: center;
    }

    img {
      margin-right: $s1;
      height: 21px;
      width: 23px;

      @include breakpoint(medium) {
        margin-right: 5px;
      }
    }
  }
}
