@import 'styles/_variables';
@import 'styles/_foundation';
@import 'styles/mixins/_typography';
@import 'styles/_cor-rich-text';

.cor-two-column {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    @include breakpoint($cor-desktop) {
      margin: 0 auto;
      flex-direction: row;
      padding: 80px 0;
    }
  }

  &__head {
    text-align: center;
    padding-top: 40px;
    @include breakpoint($cor-desktop) {
      padding-top: 80px;
    }
  }

  &__headline {
    text-align: center;
  }

  &__separator {
    display: none;
    background: $cor-light-gray;
    width: 1px;
    @include breakpoint($cor-desktop) {
      display: block;
    }
  }

  &--white {
    background-color: $cor-bg-gray-lightest;
  }
  &--light-tan {
    background-color: $cor-bg-gray;
  }
  &--yellow {
    background-color: $cor-dawn-yellow;
  }
}

body:not(.international-font) .cor-two-column {
  &__rich-text {
    @include cor-rich-text(
      $map: (
        'h2': (
          'size': 36px,
          'lineheight': 34px,
        ),
        'p': (
          'size': [14px,
          16px],
          'lineheight': [24px,
          28px],
          'spacing': [.01rem,
          .01rem],
        ),
      )
    );
  }
}

body.international-font .cor-two-column {
  &__rich-text {
    @include cor-rich-text(
      $map: (
        'h2': (
          'face': neueFrutiger,
          'size': 36px,
          'lineheight': 34px,
        ),
        'p': (
          'face': neueFrutiger,
          'size': [14px,
          16px],
          'lineheight': [24px,
          28px],
          'spacing': [.01rem,
          .01rem],
        ),
      )
    );
  }
}
