@import 'styles/_variables';
@import 'styles/_foundation.scss';
@import 'styles/_cor-rich-text';

.back-to-top {
  position: fixed;
  bottom: rem(50px);
  right: rem(20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: rem(15px) rem(20px);
  background-color: $white;
  border-radius: rem(5px);
  box-shadow: 0px 0px rem(20px) rgba(119, 118, 118, 0.3);
  z-index: 10;
  appearance: none;
  border: 0;
  cursor: pointer;

  &-enter {
    transition: opacity 500ms;
    opacity: 0;
  }

  &-enter-done {
    transition: opacity 500ms;
    opacity: 1;
  }

  &-exit {
    transition: opacity 500ms;
    opacity: 1;
  }

  &-exit-done {
    transition: opacity 500ms;
    opacity: 0;
  }

  > svg {
    margin-bottom: rem(5px);
  }

  > span {
    @include font-frutiger-bold($size: 12);
    text-transform: uppercase;
    color: $aubergine-gray;
  }
}
