@import 'styles/_foundation';
@import 'styles/typography/_mixins';
@import 'styles/_variables';
@import 'styles/mixins';

.mobile-account-menu-banner {
  display: flex;
  flex-direction: column;

  &__welcome-message {
    @include df-h2-mobile;
    padding: 10px $s3;
    background: #efefef;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__top-bar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: $s2 30px;
  }

  &__my-account-button {
    display: flex;
    align-items: baseline;
    margin-right: auto;

    &:before {
      @include css-triangle('left', 12px, 8px);
      content: '';
      display: block;
      margin-right: $s1;
    }
  }

  &__close-button {
    @include cross-close-button(16px, 48px);
    align-self: flex-end;
  }

  &__content {
    flex: 1;
    padding: 0 $s3;
    overflow: scroll;
  }

  &__menu {
    &-item {
      display: flex;
      align-items: flex-start;
      border-top: 1px solid $input-gray;
      padding: $s1;
      a,
      span {
        width: 100%;
        padding: 14px 6px;
        display: flex;
      }

      a {
        text-decoration: none;
      }

      &.selected {
        color: $red;
      }
      &.deleted {
        color: $dark-gray;
      }
      img {
        display: block;
        margin-right: $s1;
        width: 20px;
        height: 18px;
      }
    }
  }

  &__location-menu-item {
    position: relative;

    &:after {
      @include css-triangle('right', 12px, 8px);
      content: '';
      position: absolute;
      top: 50%;
      right: 4px;
      transform: translateY(-50%);
    }
  }

  &__button-wrapper {
    padding: $s1-5 $s3;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.08);

    .cor-button {
      width: 100%;
    }
  }
}
