@import 'styles/_variables';
@import 'styles/_foundation';
@import 'styles/_cor-rich-text';
@import 'styles/mixins';

.cor-chef-notes {
  background-color: $cor-white;
  padding: 0 1.5rem;
  @include breakpoint($cor-desktop) {
    padding: 0;
  }

  &__section {
    background-color: $cor-bg-gray-light;
    padding: 2.5rem 2rem;
    border-radius: 10px;
    @include breakpoint($cor-desktop) {
      background-color: $cor-bg-gray-lightest;
      border-radius: 20px;
      margin-left: -60px;
      margin-right: -60px;
      padding: 54px 60px;
    }
  }

  &__additional-details {
    margin-bottom: 40px;
    @include breakpoint($cor-desktop) {
      margin-bottom: 90px;
    }
  }
  m &__chef-container {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    grid-template-rows: minmax(10px, auto);
    @include breakpoint($cor-desktop) {
      grid-template-columns: 2fr 1fr;
    }
  }

  &__chef-container-label {
    grid-column: 1 / 2;
    margin-bottom: 13px;
    @include breakpoint($cor-desktop) {
      margin-bottom: 11px;
    }
  }

  &__chef-name-location {
    font-family: $frutiger-65-bold;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    grid-column: 1 / 2;
    .cor-chef-notes__chef-social {
      display: none;
      @include breakpoint($cor-desktop) {
        display: inline-block;
      }
      svg {
        margin-left: 26px;
      }
    }
  }

  &__chef-social {
    display: block;
    grid-column: 1 / 2;
    @include breakpoint($cor-desktop) {
      display: none;
    }
    svg {
      width: 14px;
      height: 14px;
    }
    &-label {
      @include srOnly;
    }
  }

  &__chef-bio {
    grid-column: 1 / -1;
    @include breakpoint($cor-desktop) {
      grid-column: 1 / 2;
    }
  }

  &__chef-image-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    grid-column: 2;
    grid-row: 1 / 5;

    @include breakpoint($cor-desktop) {
      grid-column: 2;
      grid-row: 1 / 5;
    }
  }

  &__chef-image {
    object-fit: cover;
    object-position: center;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    border: 8px solid $cor-white;
    aspect-ratio: 1 / 1;
    box-shadow: 5px 5px 30px #dbdbdb;
    @include breakpoint($cor-desktop) {
      width: 165px;
      height: 165px;
    }
  }

  .cor-two-column-details {
    padding-bottom: 0;
  }

  .cor-share-social__title {
    color: $mid-gray;
    font-weight: 700;
  }
}

body:not(.international-font) .cor-chef-notes {
  &__additional-details-rich-text {
    @include cor-rich-text();
    @include cor-rich-text(
      $map: (
        'p': (
          'size': [14px,
          14px],
          'lineheight': [24px,
          24px],
          'spacing': [.01rem,
          .01rem],
        ),
      )
    );
  }
}

body.international-font .cor-chef-notes {
  &__additional-details-rich-text {
    @include cor-rich-text(
      $map: (
        'p': (
          'face': neueFrutiger,
          'size': [14px,
          14px],
          'lineheight': [24px,
          24px],
          'spacing': [.01rem,
          .01rem],
        ),
      )
    );
  }
}
