@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

$tooltipIconSize: 28px;

.product-card {
  $product-card: &;
  display: flex;
  flex-direction: column;
  height: 100%;
  color: $black;
  position: relative;
  box-shadow: 0.6px 0.8px 18px rgba(black, $alpha: 0.08);
  border-radius: 4px;
  overflow: hidden;

  .product-indicators {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  &__seasonal-label {
    @include df-caption;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $black;
    width: 100%;
    height: 36px;
    border: 1px solid $white;
    border-radius: 4px 4px 0 0;
  }

  &__add-to-shopping-list {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: $base-z-index;
    font-size: 0;

    #{$product-card}--seasonal & {
      top: 44px;
    }

    .custom-tooltip__icon {
      width: 100%;
      height: auto;
    }
  }

  &__link {
    text-decoration: none;
  }

  &__media {
    position: relative;
    border-bottom: 1px solid $light-gray;
  }

  &__statuses-wrapper {
    @include df-caption;
    position: absolute;
    display: flex;
    flex-direction: column-reverse;
    bottom: $s1;
    right: $s1;
    left: $s1;
    color: $white;
    line-height: 1.2;

    @include breakpoint(medium) {
      bottom: 2px;
      left: 10px;
      right: 20px;
      flex-direction: row;
      line-height: 1.45;
    }
  }

  &__status {
    padding: $s0-5 $s1-5;
    border-radius: $s1-5;

    &--special-order {
      background: $green;
      margin-bottom: $s1;

      @include breakpoint(medium) {
        margin-bottom: 0;
      }
    }

    &--discontinued {
      background: $brown;

      @include breakpoint(medium) {
        margin-right: $s1-5;
      }
    }
  }

  &__category {
    @include df-caption;
    position: absolute;
    bottom: $s1;
    left: $s1;
    max-width: calc(100% - #{$s2});
    padding: 5px $s1 3px;
    background: $red;
    border-radius: $s4;
    color: $white;
    text-align: center;

    @include breakpoint(medium) {
      bottom: $s2;
      left: $s2;
      padding: 5px $s1-5 3px;
      max-width: calc(100% - #{$s4});
    }
  }

  &__banner {
    @include df-caption;
    position: absolute;
    width: 100%;
    top: 0;
    text-align: center;
    padding: $s1;

    &--out-of-stock {
      background-color: $brown;
      padding: $s0-5;
      color: $white;
      text-transform: uppercase;
    }
  }

  &__info {
    margin: $s1-5 $s1-5 $s2;

    #{$product-card}--sku-product & {
      margin: $s1-5 $s1-5 $s0-5;
    }

    @include breakpoint(medium) {
      margin: $s2-5 $s2-5 $s2 $s2-5;
    }
  }

  &__brand {
    color: $brown;
    margin-bottom: $s0-5;
    @include df-caption;
    line-height: 1.2;

    @include breakpoint(medium) {
      line-height: 1.45;
    }
  }

  &__name {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: $s1;
    @include df-h4-bold;
    // Fallback for browsers that don't support line-clamp
    max-height: (16px * 1.25) * 3;

    #{$product-card}--sku-product & {
      margin-bottom: $s0-5;
    }

    @include breakpoint(medium) {
      @include df-h5-bold;
      // Fallback for browsers that don't support line-clamp
      max-height: (18px * 1.33) * 3;
      margin-bottom: $s1-5;
    }
  }

  &__packing {
    @include df-p2-italic;
    margin-bottom: $s1;
    color: $dark-gray;
  }

  &__sku {
    @include df-p2-bold;
  }

  &__flavor,
  &__color {
    @include df-p2-medium;
  }

  &__sku,
  &__flavor,
  &__color {
    margin-bottom: $s0-5;
  }

  #{$product-card}__quick-shop-cta {
    @include df-p2-italic;
    color: $red;
    width: auto;

    @include breakpoint(medium) {
      margin-top: $s0-5;
    }

    &.disabled {
      outline: none;
      color: $input-gray;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    margin: auto $s1-5 $s1-5;

    @include breakpoint(medium) {
      align-items: normal;
      margin: auto $s2-5 $s2-5;
    }
  }

  &__price-wrapper {
    display: flex;
    justify-content: space-between;
    flex-flow: column nowrap;
    width: 100%;
    margin-bottom: $s1;

    @include breakpoint(medium) {
      margin-bottom: $s1-5;
      flex-flow: row nowrap;
      align-items: center;
    }

    &.segmentation {
      #{$product-card}__price {
        margin-bottom: $s0-5;
      }
    }
  }

  &__footer-price {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: $s2;

    @include breakpoint(medium) {
      margin-bottom: $s1-5;
    }

    &.segmentation {
      align-self: flex-start;

      @include breakpoint(medium) {
        align-self: center;
      }

      #{$product-card}__price {
        margin-bottom: $s0-5;

        @include breakpoint(medium) {
          margin-bottom: 0;
        }
      }
    }
  }

  &__price {
    @include df-h2;
    line-height: 1.4;

    @include breakpoint(medium) {
      line-height: 1.43;
    }

    &--small {
      @include df-h4-bold;
    }
  }

  &__segmentation-price {
    @include df-p2-bold;

    @include breakpoint(medium) {
      @include df-p1-bold;
    }

    &-label {
      color: $red;
    }
  }

  .product-card__quantity {
    align-self: flex-start;
  }

  &__quantity {
    margin: $s2 0 $s2;

    &-label {
      color: $red;
      @include df-caption;

      @include breakpoint(medium) {
        font-size: 10px;
      }
    }

    .quantity-control {
      margin-top: $s0-5;
    }
  }

  &__buy-now-button {
    width: 100%;

    @include breakpoint(small down) {
      &.cor-button {
        padding: $s1 $s0-5;
      }
    }
  }

  &__footer-request-actions {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    width: 100%;

    @include breakpoint(medium) {
      flex-direction: row;
    }

    .custom-tooltip {
      margin-left: 0;
      margin-bottom: $s1;

      @include breakpoint(medium) {
        margin-left: $s1-5;
        margin-bottom: 0;
      }

      &__icon {
        width: $tooltipIconSize;
        height: $tooltipIconSize;
      }

      &__content {
        @include df-p2-medium;
        width: auto;
        box-shadow: 0 1.5px 16px 0 rgba(0, 0, 0, 0.21);

        @include breakpoint(medium) {
          max-width: 226px;
        }
      }
    }
  }

  #{$product-card}__add-to-cart {
    width: 100%;
    max-height: 40px;
    position: relative;

    @include breakpoint(medium) {
      margin-left: auto;
    }

    &.active {
      font-family: $frutiger-65-bold;
      color: $aubergine-gray;

      .content {
        padding-left: $s2-5;
      }

      &::before {
        position: absolute;
        content: '';
        top: 5px;
        left: 28px;
        display: inline-block;
        transform: rotate(45deg);
        height: 16px;
        width: 9px;
        border-bottom: 2px solid $aubergine-gray;
        border-right: 2px solid $aubergine-gray;
      }
    }

    &.disabled {
      pointer-events: none;
    }

    @include breakpoint(small down) {
      @include df-p2-bold;
      letter-spacing: normal;
    }
  }

  &--alternative-button {
    margin-left: 0;
    width: 100%;

    @include breakpoint(small down) {
      @include df-p2-bold;
      letter-spacing: normal;
    }
  }

  &__special-order-actions {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    justify-content: space-between;
    width: 100%;

    @include breakpoint(medium) {
      flex-flow: row nowrap;
    }

    .custom-tooltip {
      margin-left: 0;
      margin-bottom: $s1;

      @include breakpoint(medium) {
        margin-left: $s1-5;
        margin-bottom: 0;
      }

      &__icon {
        width: $tooltipIconSize;
        height: $tooltipIconSize;
      }

      &__content {
        @include df-p2-medium;
        width: auto;
        max-width: 100%;
        box-shadow: 0 1.5px 16px 0 rgba(0, 0, 0, 0.21);

        @include breakpoint(medium) {
          max-width: 226px;
        }
      }
    }
  }

  &__request-quote-button {
    width: 144px;
    max-height: none;

    @include breakpoint(medium) {
      width: 100%;
    }

    &.active {
      background-color: $light-gray;
      border-color: $light-gray;
      color: $red;
    }

    @include breakpoint(small down) {
      @include df-p2-bold;
      letter-spacing: normal;
    }
  }

  &--discontinued {
    &:after {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }

    #{$product-card}__status {
      z-index: $base-z-index;
      background-color: $brown;
    }

    #{$product-card}__add-to-cart {
      background-color: $white;
    }

    #{$product-card}__brand {
      color: $dark-gray;
    }
  }

  &--added {
    #{$product-card}__add-to-cart {
      background-color: $light-gray;
    }
  }

  &#{$product-card}--sku-product {
    #{$product-card}__statuses-wrapper {
      bottom: $s1;
      right: $s1;
      left: $s1;

      @include breakpoint(medium) {
        bottom: $s2;
        right: 10px;
        left: 10px;
      }

      .product-status-labels {
        width: 100%;

        &__wrapper {
          justify-content: flex-start;
        }
      }
    }
  }

  .icon-added-to-cart {
    width: 23px;

    @include breakpoint(small only) {
      @at-root #{$product-card}:not(#{$product-card}--sku-product)#{&} {
        margin-top: 6px;
      }
    }
  }

  .product-status--out-of-stock.product-status {
    height: 23px;
    padding: $s0-5 $s1-5 3px;

    @include breakpoint(medium) {
      height: 24px;
      padding: $s0-5 $s1-5;
    }
  }
}
