@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/_cor-layouts.scss';
@import 'styles/_cor-rich-text';

.corporate--news {
  $max-width-container: 800px;
  .corporate__breadcrumbs {
    background-color: $cor-bg-gray-lightest;
  }

  .cor-detail-hero-banner {
    .cor-typography__h1 {
      font-size: 36px;
      @include breakpoint($cor-desktop) {
        font-size: 50px;
      }
    }
  }

  .cor-detail-hero-banner__text-content {
    padding: 60px 30px;
    @include breakpoint($cor-desktop) {
      padding: 40px 79px;
    }
  }

  .cor-rich-content {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .cor-two-column {
    .grid-container {
      max-width: none;
      padding: 0;
    }
  }
  .cor-video-component {
    .grid-container {
      @include breakpoint($cor-desktop) {
        max-width: 1164px;
        padding: 0;
      }
    }
  }
  .cor-two-column--white {
    background-color: $cor-white;
  }
  .cor-rich-content,
  .cor-image,
  .cor-video-component .grid-container,
  .cor-two-column__container {
    padding-left: 1rem;
    padding-right: 1rem;
    @include breakpoint($cor-desktop) {
      padding-left: 0;
      padding-right: 0;
      max-width: $max-width-container;
    }
  }
}

.cor-detail-related-content__news {
  padding-top: 10px !important;
  padding-bottom: 10px !important;

  @include breakpoint($cor-desktop) {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
}

body:not(.international-font) .corporate--news {
  & .cor-rich-content {
    @include cor-rich-text(
      $map: (
        'p': (
          'size': [14px,
          14px],
          'lineheight': [24px,
          24px],
          'spacing': [.01rem,
          .01rem],
        ),
        'span': (
          'size': [14px,
          14px],
          'lineheight': [24px,
          24px],
          'spacing': [.01rem,
          .01rem],
        ),
      )
    );
  }
}

body.international-font .corporate--news {
  & .cor-rich-content {
    @include cor-rich-text(
      $map: (
        'p': (
          'face': neueFrutiger,
          'size': [14px,
          14px],
          'lineheight': [24px,
          24px],
          'spacing': [.01rem,
          .01rem],
        ),
        'span': (
          'face': neueFrutiger,
          'size': [14px,
          14px],
          'lineheight': [24px,
          24px],
          'spacing': [.01rem,
          .01rem],
        ),
      )
    );
  }
}
