@import 'styles/_variables';
@import 'styles/typography/_mixins';
@import 'styles/_foundation';

.quick-add-to-shopping-list-modal {
  color: $black;
  overflow: auto;
  padding: 0;

  &__content {
    min-height: 100%;
  }

  &.modal__content {
    min-height: 300px;

    @include breakpoint(medium) {
      min-height: 500px;
    }
  }

  &,
  &__content-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__header {
    padding-top: $s0-5;

    @include breakpoint(medium) {
      padding: $s1-5 0 $s4;
    }
  }

  &__content-wrapper {
    overflow-y: auto;
    align-items: center;
    margin-bottom: $s3;

    @include breakpoint(medium) {
      overflow-y: initial;
    }
  }

  &__image {
    margin-top: 61px;
    width: 180px;

    .loading-indicator {
      width: 100%;
      height: auto;

      img {
        vertical-align: top;
      }
    }

    @include breakpoint(medium) {
      margin: $s1-5 0 $s2;
    }
  }

  &__statuses-wrapper {
    position: relative;
    bottom: 18px;

    .product-status-labels__wrapper {
      justify-content: center;
    }
  }

  &__sku-weight {
    @include df-p2-medium;

    @include breakpoint(medium) {
      @include df-p1-medium;
    }

    b {
      @include df-p2-bold;

      @include breakpoint(medium) {
        @include df-p1-bold;
      }
    }
  }

  &__group-selector {
    margin-bottom: $s3;
  }

  &__variant-selector,
  &__group-selector {
    width: 100%;

    @include breakpoint(medium) {
      width: 360px;
    }

    @include breakpoint(small only) {
      .product-variant-selector__multiple-selectors {
        flex-wrap: wrap;
      }

      .product-variant-selector__dropdown-wrapper {
        flex: 1 0 100%;
        width: 100%;
        margin-bottom: $s2-5;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .product-variant-selector__dropdown:after {
        display: none;
      }
    }

    .product-variant-selector {
      margin-top: $s1-5;

      &__button {
        margin: 6px;
      }

      &__single-selectors {
        margin: -6px;
      }
    }

    .product-group-selector .select__menu-list {
      max-height: 88px;
      overflow: none auto;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: $s2 $s2 $s1 $s3;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.08);
    background-color: $white;
    z-index: 1;

    @include breakpoint(medium) {
      padding: $s2-5 $s4;
      z-index: initial;
    }
  }
}
