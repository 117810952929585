@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

.empty-order-updates {
  border-radius: 4px;
  padding: $s3;
  background-color: $white;

  @include breakpoint(medium) {
    padding: 90px 0;
    border: 1px solid $input-gray;
  }

  &__title {
    @include df-h4-medium;
    font-size: 20px;
    color: $black;
    margin-bottom: $s3;
    text-align: center;

    @include breakpoint(medium) {
      margin-bottom: $s4;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    margin-bottom: $s5;

    img {
      height: 140px;
      width: 112px;

      @include breakpoint(medium) {
        height: 141px;
        width: 106px;
      }
    }
  }

  &__start-shopping-btn {
    display: flex;
    justify-content: center;
  }
}
