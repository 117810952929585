@import 'styles/_variables';
@import 'styles/_foundation.scss';
@import 'styles/typography/_mixins';

.hierarchy-accounts-special-order-message {
  @include df-p3-medium;
  display: flex;
  align-items: flex-start;

  @include breakpoint(medium) {
    @include df-p2-bold;
    align-items: center;
  }

  &__img-wrapper {
    width: 18px;
    height: 16px;
    margin-right: 5px;

    @include breakpoint(medium) {
      width: 25px;
      height: 22px;
      margin-right: 13px;
    }

    img {
      height: 100%;
    }
  }
}
