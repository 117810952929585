@import 'styles/_variables';
@import 'styles/typography/_mixins';
@import 'styles/_foundation';

$mobileIconSize: 25px;
$desktopIconSize: 32px;

.edit-user-info-success-message {
  z-index: $modal-z-index;
  color: $black;

  &__message {
    @include df-p1-medium;
    line-height: 2;
    margin-top: $s2;

    @include breakpoint(medium) {
      margin-left: $s2-5 + $desktopIconSize;
      margin-top: $s3-5;
    }
  }

  &__locations {
    margin-left: $s3;
  }
}
