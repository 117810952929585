@import 'styles/_variables';
@import 'styles/typography/_mixins';
@import 'styles/_foundation';

$tooltipIconSize: 28px;

.quick-shop-modal {
  $quick-shop-modal: &;
  color: $black;
  padding-bottom: 0;
  max-width: 864px;
  position: relative;

  @include breakpoint(small down) {
    .product-group-selector .select__menu {
      z-index: $page-header-z-index;
      max-height: 120px;
    }
  }

  .product-group-selector .select .select__menu-list {
    max-height: 110px;
  }

  &#{&} {
    margin: 0 auto;

    &--with-bottom-padding {
      @include breakpoint(small down) {
        padding-bottom: 19px;
      }
    }
  }

  &--with-loading-indicator {
    &.modal__content {
      min-height: 300px;

      @include breakpoint(medium) {
        min-height: 500px;
      }
    }
  }

  &__content {
    padding-bottom: $s9;

    @include breakpoint(medium) {
      padding-bottom: 0;
      overflow-y: auto;
      height: 100%;
    }

    &.quick-shop-modal__sku--discontinued-with-replacement {
      @include breakpoint(small down) {
        padding-bottom: 0;
      }
    }
  }

  &.modal__content {
    padding: 0;
    border-radius: 0;

    @include breakpoint(small down) {
      height: 100%;
      max-height: calc(100dvh - 16px);
    }

    @include breakpoint(medium) {
      overflow-y: hidden;
      border-radius: 4px;
    }
  }

  @include breakpoint(medium) {
    padding-bottom: $s4;
    margin-left: calc(16.66667% - 2rem);
  }

  .product-card__link {
    position: relative;
    display: block;
  }

  &__statuses-wrapper {
    position: absolute;
    left: $s1-5;
    right: $s1-5;
    bottom: $s1-5;

    @include breakpoint(medium) {
      left: $s2;
      right: $s2;
      bottom: $s2;
    }
  }

  &__image {
    padding: $s2 $s2 0;
    margin-bottom: $s2;

    @include breakpoint(medium) {
      margin-bottom: 0;
      padding: 0;
    }

    img {
      width: 100%;
      height: 100%;
    }

    &-has-warning-msg {
      margin-bottom: 10px;
    }
  }

  &__quantity-warning {
    margin-bottom: $s2;
    position: relative;

    @include breakpoint(medium) {
      margin-bottom: 25px;
    }
  }

  &__product-details {
    padding: 0;
    display: flex;
    flex-direction: column;

    @include breakpoint(medium) {
      padding: $s4 $s4 0;
      flex-direction: row;
    }
  }

  &__image-msg-wrapper {
    position: relative;

    @include breakpoint(medium) {
      max-width: 296px;
      margin-right: $s4;
    }
  }

  &__product-info {
    padding: 0 $s2;
    width: 100%;

    @include breakpoint(medium) {
      padding: 0;
    }
  }

  &__product-info-main {
    border-bottom: 1px solid $input-gray;
    margin-bottom: $s3;
  }

  &__brand {
    @include df-caption;
    color: $brown;
    margin-bottom: $s0-5;
  }

  &__name {
    @include df-h3-bold;
    margin-bottom: $s2;
    font-size: 20px;

    @include breakpoint(medium) {
      margin-bottom: $s3;
    }
  }

  &__label {
    @include df-p2-medium;
    width: 18%;
    margin-right: $s2;

    @include breakpoint(medium) {
      @include df-p1-medium;
    }
  }

  &__info-value {
    @include df-p2-bold;
    color: $red;

    @include breakpoint(medium) {
      @include df-p1-bold;
    }
  }

  &__quantity-block {
    margin-bottom: $s2-5;

    @include breakpoint(medium) {
      margin-bottom: $s3;
    }

    &-special-order {
      margin-bottom: 25px;

      @include breakpoint(medium) {
        margin-bottom: 34px;
      }
    }
  }

  &__info-item {
    margin-bottom: $s1;
    display: flex;

    &:last-child {
      margin-bottom: $s2-5;

      @include breakpoint(medium) {
        margin-bottom: $s3;
      }
    }
  }

  &__special-order-actions {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    justify-content: space-between;
    width: 100%;

    @include breakpoint(medium) {
      flex-flow: row nowrap;
    }

    .custom-tooltip {
      margin-left: 0;
      margin-bottom: $s1;

      @include breakpoint(medium) {
        margin-left: $s1-5;
        margin-bottom: 0;
      }

      &__icon {
        width: $tooltipIconSize;
        height: $tooltipIconSize;
      }

      &__content {
        @include df-p2-medium;
        width: 100%;
        box-shadow: 0 1.5px 16px 0 rgba(0, 0, 0, 0.21);
        position: relative;

        @include breakpoint(medium) {
          max-width: 226px;
        }
      }
    }
  }

  &__price {
    @include df-h1;
    line-height: 1.4;

    @include breakpoint(medium) {
      font-size: 30px;
      line-height: 1.43;
    }
  }

  &__add-to-cart-wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;

    .icon-added-to-cart {
      margin-right: 14px;

      @include breakpoint(medium) {
        margin-right: $s2-5;
      }
    }

    button {
      width: 145px;
    }

    @include breakpoint(medium) {
      width: auto;

      button {
        width: 168px;
      }
    }

    &-disabled {
      width: 100%;

      .cor-button {
        width: 100%;
      }
    }
  }

  &__add-to-cart {
    display: flex;
    justify-content: center;

    &.cor-button.active {
      background-color: $light-red;
      border-color: $light-red;
      color: $white;

      .content {
        padding-left: $s1-5;
      }

      &::before {
        content: '';
        display: inline-block;
        transform: rotate(45deg);
        height: 16px;
        width: 9px;
        border-bottom: 2px solid $white;
        border-right: 2px solid $white;
        margin-top: -2px;
      }
    }

    &.disabled {
      pointer-events: none;
    }
  }

  &__special-order-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @include breakpoint(medium) {
      justify-content: flex-end;
    }

    .custom-tooltip {
      margin-bottom: $s1;
      margin-left: $s1-5;

      .custom-tooltip__content {
        &.show {
          &:after {
            left: 80%;

            @include breakpoint(280px down) {
              left: 95%;
            }

            @include breakpoint(medium) {
              left: 50%;
            }
          }
        }

        @include breakpoint(280px down) {
          left: 120px !important;
        }
      }

      @include breakpoint(medium) {
        margin-bottom: 0;
      }

      &__icon {
        width: $tooltipIconSize;
        height: $tooltipIconSize;
      }

      &__content {
        @include df-p2-medium;
        width: 100%;
        box-shadow: 0 1.5px 16px 0 rgba(0, 0, 0, 0.21);

        @include breakpoint(medium) {
          max-width: 226px;
        }
      }
    }
  }

  &__contact-msr-button {
    width: 100%;

    @include breakpoint(medium) {
      width: 170px;
    }

    &.cor-button.active {
      background-color: $light-red;
      border-color: $light-red;
      color: $white;
    }

    &.disabled {
      pointer-events: none;
    }
  }

  .modal__close-button {
    background-color: $white;
    padding: $s2;
    right: $s1;
    top: 0;
    position: fixed;
    z-index: $sticky-view-z-index;

    @include breakpoint(medium) {
      position: absolute;
      right: 25px;
      top: 25px;
    }
  }

  .modal__close-button:before,
  .modal__close-button:after {
    top: 2px;
    left: 15px;

    height: 28px;
  }

  &__quantity-notification {
    margin-top: 5px;
    flex: 1 0 100%;

    @include breakpoint(medium) {
      margin-top: 10px;
    }
  }

  &__quantity-segmentation {
    display: flex;
    justify-content: space-between;
  }

  &__quantity-label-area {
    @include breakpoint(medium) {
      width: auto;
    }
  }

  &__segmentation-amount {
    text-align: end;
  }

  &__segmentation-price-label {
    @include df-caption;
    margin-bottom: $s2;
    color: $dark-gray;
  }

  &__segmentation-price {
    @include df-h3-medium;

    @include breakpoint(medium) {
      font-size: 20px;
    }
  }

  &__segmentation-price-label-desktop {
    @include breakpoint(medium) {
      margin-bottom: 0;
    }
  }

  &__segmentation-amount-desktop {
    @include breakpoint(medium) {
      margin-right: $s4;
      align-self: center;
    }
  }

  &__quantity-label {
    @include df-caption;
    color: $dark-gray;
  }

  &__quanty-control {
    margin-top: $s1;
  }

  &__unit-price {
    font: 16px/1.25 $museo-500;
    color: $dark-gray;
  }
  &__price-seperator {
    margin: 0 2px 0 3px;
  }

  &__unit-price-label {
    @include df-caption;
    color: $dark-gray;
  }

  &__quantity-control {
    margin-top: $s1;
  }

  &__message-block {
    margin-bottom: $s3;

    @include breakpoint(medium) {
      margin-bottom: 25px;
    }

    &-label {
      margin-bottom: $s1;
    }

    &-input {
      outline: none;
      resize: none;
      width: 100%;
      font: 14px/1.71 $frutiger-55-roman;
      color: $dark-gray;
      border: 1px solid $input-gray;
      border-radius: 8px;
      background-color: $white;
      margin: 0;
      padding: $s1;
      min-height: 97px;
    }
  }

  &__discountinued-message {
    @include df-p1-bold;
    margin-bottom: $s3;

    @include breakpoint(medium) {
      margin-bottom: 25px;
    }
  }

  &__variant-selector {
    background-color: $white;
    border: none;
    padding: $s2-5 0;
    border-bottom: 1px solid $input-gray;

    @include breakpoint(medium) {
      padding: $s3 0;
    }

    @at-root #{$quick-shop-modal}__sku--short-supply & {
      border: none;
    }

    @at-root #{$quick-shop-modal}__sku--discontinued#{$quick-shop-modal}__sku--discontinued-no-replacement & {
      border: none;
    }
  }

  &__sku--discontinued-no-replacement {
    .select__menu {
      z-index: $drop-zone-z-index;
    }
  }

  &__product-variant-dropdown {
    margin-top: $s1-5;

    .product-variant-selector {
      &__multiple-selectors {
        display: block;

        @include breakpoint(medium) {
          display: flex;
          justify-content: space-between;
        }
      }

      &__dropdown-wrapper {
        position: relative;
        width: 100%;
        margin-bottom: $s2;

        @include breakpoint(medium) {
          position: relative;
          width: 44%;
        }
      }
    }
  }

  &__sku-weight {
    @include df-p2-medium;

    b {
      @include df-p2-bold;

      @include breakpoint(medium) {
        @include df-p1-bold;
      }
    }

    @include breakpoint(medium) {
      @include df-p1-medium;
    }
  }

  .quantity-control__button-minus,
  .quantity-control__button-plus {
    z-index: initial;
  }

  .product-card__label {
    @include df-caption;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: $s1;
    text-align: center;
    background: $brown;
    color: $white;
    text-transform: uppercase;
  }

  .product-replacement-wrapper {
    margin: $s1-5 0 0 0;

    @include breakpoint(small down) {
      margin: 0;
    }
  }

  &__replacements {
    padding: 0 $s2;
    margin-bottom: $s3;

    @include breakpoint(medium) {
      padding: 0 $s4;
      margin-bottom: 25px;
    }

    &-discontinued-with-replacement {
      padding: 0 $s2 $s2;
      margin-bottom: 0;

      @include breakpoint(medium) {
        padding: 0 $s4 $s3;
      }
    }
  }

  &__footer {
    position: fixed;
    bottom: 0;
    background-color: $white;
    left: 8px;
    right: 8px;
    padding: $s1-5 $s2;
    z-index: $sticky-view-z-index;
    border-radius: 0 0 4px 4px;
    justify-content: space-between;

    @include breakpoint(medium) {
      position: sticky;
      padding: $s2-5 $s4;
      display: flex;
      justify-content: flex-end;
    }

    &-discontinued-with-replacement {
      display: none;
    }

    &-with-no-variants {
      @include breakpoint(medium) {
        margin-top: 25px;
      }
    }
  }

  &__price-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @include breakpoint(medium) {
      width: 468px;
    }
  }

  .product-replacement {
    width: 100%;
    margin-bottom: 0;

    .alternative-items-line-item {
      &__price-block {
        align-items: center;
      }

      &__price-quantity {
        @include breakpoint(medium) {
          width: fit-content;
        }
      }
    }
  }

  .product-replacement-wrapper {
    &__title {
      padding: $s1-5 $s2;

      @include breakpoint(medium) {
        padding: $s2 $s3;
      }
    }
  }

  &__similar-items {
    .alternative-items-line-item {
      padding: $s2-5 0;

      &__price-block {
        @include breakpoint(medium) {
          width: 41%;
          justify-content: space-around;
          align-items: center;
          padding: $s2 0;
        }
      }

      &__price-quantity {
        @include breakpoint(medium) {
          width: fit-content;
        }
      }

      &__name {
        font-size: 16px;
      }

      &__buttons {
        width: 100%;

        @include breakpoint(medium) {
          width: 190px;
        }
      }

      .alternative-items-line-item-buttons {
        &__add-to-cart-btn {
          @include breakpoint(medium) {
            width: 144px;
            padding: $s1 5px;
          }

          &.tertiary.active {
            .content {
              padding-left: $s1;
            }
            &::before {
              content: '';
              height: 14px;
              width: 6px;
            }
          }
        }
        &__request-quote {
          width: 100%;

          @include breakpoint(medium) {
            width: 190px;
          }
        }

        &__request-quote-btn {
          @include breakpoint(medium) {
            width: 154px;
          }
        }
      }
    }
  }

  &__out-of-stock-message {
    @include df-p1-bold;
    margin-bottom: $s3;

    @include breakpoint(medium) {
      letter-spacing: 0.2px;
      margin-bottom: 25px;
    }
  }

  &#{&} .product-status--out-of-stock.product-status {
    font-size: 11px;
    line-height: 1.2;
    padding: 2px $s1-5 0;
  }

  .product-group-selector {
    margin-top: $s3;

    & + .quick-shop-modal__discountinued-message {
      margin-top: $s2;

      @include breakpoint(medium) {
        margin-top: $s3;
      }
    }

    &__label {
      @include df-caption;
      margin-bottom: 18px;
      color: $dark-gray;

      @include breakpoint(medium) {
        margin-bottom: $s2-5;
      }
    }

    &__selector-block {
      max-width: 360px;

      &:last-child {
        margin-bottom: $s3;

        @include breakpoint(medium) {
          margin-bottom: $s4;
        }
      }
    }

    .select {
      &__control {
        height: 52px;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

        @include breakpoint(medium) {
          height: 60px;
        }
      }

      &__indicators {
        width: 55px;
      }
    }
  }

  .product-card__link .product-indicators {
    top: 0;
    left: 0;
    right: 0;
  }

  .product-status {
    border-radius: 20px;
  }

  .product-variant-selector {
    .select__control {
      height: 52px;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

      @include breakpoint(medium) {
        height: 60px;
      }

      .select__indicators {
        width: 55px;
      }
    }
  }

  .alternative-items-line-item__sku-info {
    @include breakpoint(medium) {
      margin-right: $s2-5;
    }
  }

  .similar-items {
    @include breakpoint(small down) {
      margin-top: $s2-5;
    }
  }

  .product-new-arrival-badge {
    @include df-h3-bold;
    font-size: 20px;
  }
}
