@import 'styles/_variables';
@import '/src/styles/mixins/typography';
@import 'styles/_foundation';

.cor-footer-newsletter {
  & {
    flex-direction: column;
    display: flex;
    row-gap: 15px;
    max-width: 238px;
    min-width: 200px;

    @include breakpoint($cor-desktop) {
      row-gap: 24px;
    }
  }

  .cor-footer__menu-link__title {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .newsletter__description {
    color: $mid-gray;
  }

  .newsletter__cta {
    margin-top: -15px;
  }

  .newsletter__form {
    display: flex;
    flex-direction: column;
  }

  .newsletter__input {
    input {
      border: none;
    }
  }

  .newsletter__submit {
    width: 100%;
    border: 1px solid #d8400e;
    padding: 14px 0;
    background-color: transparent;
    border-radius: 6px;
    color: $aubergine-gray;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    cursor: pointer;
  }
}

body:not(.international-font) .cor-footer-newsletter {
  .newsletter__description {
    @include font-frutiger-regular($size: 14);
  }

  .newsletter__input {
    input {
      @include font-frutiger-regular($size: 14);
    }
  }

  .newsletter__submit {
    @include font-museo-regular($size: 14);
  }
}

body.international-font .cor-footer-newsletter {
  .newsletter__description {
    @include font-neue-frutiger-regular($size: 14);
  }

  .newsletter__input {
    input {
      @include font-neue-frutiger-regular($size: 14);
    }
  }

  .newsletter__submit {
    @include font-neue-frutiger-regular($size: 14);
  }
}
