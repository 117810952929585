@import 'styles/_foundation';
@import 'styles/_variables';

.cor-filtered-list {
  &__header {
    background-color: $cor-bg-gray-light;
    padding: 25px 0;

    @include breakpoint($cor-desktop) {
      padding: 55px 0;
    }

    & > .grid-container {
      display: flex;
      flex-direction: column;
      gap: 24px;

      @include breakpoint($cor-desktop) {
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
      }
    }
  }

  &__header-controls {
    display: flex;
    justify-content: space-between;
    column-gap: 16px;
  }

  &__body {
    background-color: $cor-white;
    margin-top: 25px;
    margin-bottom: 80px;
    position: relative;

    @include breakpoint($cor-desktop) {
      margin-top: 40px;
    }

    & > .grid-container {
      min-height: 300px;
    }

    & .pagination {
      justify-content: center;
    }
  }

  &__body-header {
    display: flex;
    column-gap: 25px;
    align-items: center;
    margin-bottom: 24px;
    justify-content: space-between;
  }

  &__chips {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }

  &__cards {
    row-gap: 32px;
    column-gap: 16px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 32px;

    @include breakpoint($cor-desktop) {
      row-gap: 80px;
      column-gap: 46px;
      grid-template-columns: repeat(4, 1fr);
      margin-bottom: 80px;
    }
  }

  &__cards > .cor-featured-content__card {
    width: initial;
  }

  &__pagination-quantity {
    display: none;
    margin-left: auto;

    @include breakpoint($cor-desktop) {
      display: initial;
    }
  }

  &__filters {
    display: flex;
    column-gap: 20px;
  }
}
