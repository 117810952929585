@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

$input-height: 48px;
.add-credit-card-form {
  &__field {
    margin-bottom: $s2-5;

    @include breakpoint(medium) {
      margin-bottom: $s1-5;
    }

    &-contact-name {
      display: flex;
      flex-direction: column;

      @include breakpoint(medium) {
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: $s1-5;
      }

      .text-field {
        margin-bottom: $s2-5;

        @include breakpoint(medium) {
          margin-bottom: 0;
          width: 49%;
        }

        &__input {
          padding: 10px $s2;
        }
      }
    }

    &-expiration {
      display: flex;
      flex-direction: column;

      @include breakpoint(medium) {
        flex-direction: row;
        gap: 12px;
      }
      .text-field {
        margin-bottom: $s2-5;

        @include breakpoint(medium) {
          margin-bottom: 0;
        }
      }

      @include breakpoint(small down) {
        :nth-child(2) {
          order: 1;
        }
        .add-credit-card-form____field-expiration-cvv {
          margin-bottom: $s2-5;
        }
      }
    }
  }

  &__card-type {
    display: flex;
    align-items: center;
    padding: 10px $s2;
    border: 1px solid $input-gray;
    height: $input-height;
    border-radius: 8px;
    justify-content: space-between;
  }

  &__card-type-image-block {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
  }

  &__card-type-image {
    margin-right: $s2;
  }

  &__input {
    border: 1px solid $input-gray;
    height: $input-height;
    border-radius: 8px;

    @include breakpoint(medium) {
    }

    &-exp {
      padding: 10px $s2;
      margin-bottom: $s2-5;

      @include breakpoint(medium) {
        margin-bottom: 0;
      }
    }

    &-ccn {
      height: 48px;
      margin-right: $s2;
      width: 220px;
    }

    &-cvv {
      padding: 10px $s2;
      height: $input-height;
      border-radius: 8px;
      border: 1px solid $input-gray;
      display: flex;
      align-items: center;

      @include breakpoint(medium) {
        width: 154px;
      }

      .custom-tooltip {
        margin-left: auto;
      }
    }
  }

  .invalid-input {
    border: 1px solid $red;
  }

  .on-focus {
    border: 1px solid $cor-region-link;
  }

  .error-label {
    color: $red;
  }

  .error-msg {
    &__ccn,
    &__cvv,
    &__exp {
      @include df-p2-italic;
      color: $red;
      margin: $s1 0 10px;
    }
  }

  .invalid-input {
    border: 1px solid $red;
  }

  .on-focus {
    border: 1px solid $cor-region-link;
  }

  .error-msg {
    &__ccn,
    &__cvv,
    &__exp {
      @include df-p2-italic;
      color: $red;
      margin: $s1 0 10px;
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    align-items: center;
    padding: $s2;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $white;
    height: 80px;
    box-shadow: 0px -8px 10px -6px rgba(0, 0, 0, 0.1);

    @include breakpoint(medium) {
      gap: 24px;
      padding: $s2 $s4;
    }

    &-cancel {
      @include df-p1-medium;
      text-decoration: underline;
      color: $red;
      cursor: pointer;
    }

    .button {
      padding: $s1-5 $s3;
      height: 48px;
      text-align: center;
      display: flex;
      justify-content: center;
    }
  }

  .text-field__label {
    @include df-p1-medium;
    margin-bottom: $s1;
  }

  .text-field {
    ::placeholder {
      @include df-p2-italic;
      color: $input-gray;
      opacity: 1;
    }

    &__error-message {
      @include breakpoint(medium) {
        max-width: 283px;
      }
    }
  }

  .custom-tooltip__content {
    @include df-p2-medium;
    max-width: 168px;
    padding: $s1 13px $s1 $s1-5;
  }
}
