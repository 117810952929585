@import 'styles/_variables';
@import 'styles/typography/_mixins';
@import 'styles/_foundation';

.add-to-shopping-list-modal-list-item {
  $add-to-shopping-list-modal-list-item: &;

  padding: 18px $s2-5;
  border: 1px solid $input-gray;
  border-radius: 4px;
  margin-bottom: $s1-5;

  &--favorites {
    background-color: #fff5f5;
    border: 1px solid #f24d41;
  }

  &__favorites-icon {
    margin-right: $s1;
    mask-size: 100%;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    mask-position: center;
    width: 20px;
    height: 20px;
    background-color: $red;
  }

  &--disabled {
    #{$add-to-shopping-list-modal-list-item}__list-name,
    #{$add-to-shopping-list-modal-list-item}__items-number-label,
    #{$add-to-shopping-list-modal-list-item}__items-number-value {
      color: $achromatic-gray;
    }

    #{$add-to-shopping-list-modal-list-item}__favorites-icon {
      background-color: $achromatic-gray;
    }
  }

  &__list-checkbox {
    display: inline-block;
  }

  .checkbox__label {
    @include df-p2-bold;
    word-break: break-all;

    @include breakpoint(medium) {
      @include df-p1-bold;
    }
  }

  &__items-number {
    margin-left: $s4;

    @include breakpoint(medium) {
      margin-left: 0;
    }
  }

  &__items-number-label {
    @include df-caption;
    color: $red;
  }

  &__items-number-value {
    @include df-h4-medium;
  }

  &__form {
    display: flex;
    width: 100%;
    align-items: flex-start;
    flex-direction: column;

    @include breakpoint(medium) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__list-name {
    display: flex;
    align-items: flex-end;
    width: 100%;
    margin-bottom: $s2;

    @include breakpoint(medium) {
      width: 60%;
      margin-bottom: 0;
    }
  }
}
