@import 'styles/_foundation';
@import 'styles/_variables';

.cor-filter-button {
  & {
    background-color: $cor-bg-gray;
    padding: 14px 30px;
    border-radius: 6px;
    border: none;
    color: $aubergine-gray;
    font-size: 16px;
    font-family: $museo-500;
    display: flex;
    column-gap: 10px;
    justify-content: space-between;
    position: relative;
    align-items: center;
  }

  &--opened,
  &--selected {
    background-color: $cor-white;
  }

  & > img {
    transition: transform 0.1s linear;
  }
  &--opened > img {
    transform: rotate(180deg);
  }

  &:not([disabled]) {
    cursor: pointer;
  }
}
