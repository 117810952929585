@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/mixins';

.payment-methods-error-modal {
  .modal__close-button {
    width: 18px;
    height: 18px;
  }
  .modal-header__title-text {
    font-size: 24px;
  }

  .cor-button.tertiary {
    border-radius: 8px;
    height: 48px;
    padding: $s1-5 $s3;
    margin-top: 0;

    @include breakpoint(medium) {
      width: fit-content;
      min-width: 96px;
    }
  }
}
