@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

.cor-featured-content__card-product {
  & {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 175px;
    min-width: 50px;

    @include breakpoint($cor-desktop) {
      width: 240px;
    }
  }

  &__media {
    height: 100%;

    &--with-overlay:after {
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      z-index: $base-z-index;
      content: '';
      width: 100%;
      background-color: rgba(45, 41, 38, 0.6);
      pointer-events: none;
    }
  }
  &__media-container {
    height: 100%;
    position: relative;
  }

  &__statuses-wrapper {
    position: absolute;
    left: 10px;
    bottom: 10px;
  }

  &__image__container {
    height: 175px;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(219, 219, 219, 0.5);
    border-radius: 6px;

    @include breakpoint($cor-desktop) {
      height: 240px;
    }
  }

  &__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__description {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-top: 16px;

    @include breakpoint($cor-desktop) {
      padding-top: 24px;
    }
  }

  &__description__category {
    margin-bottom: 4px;
  }

  &-button-container {
    margin-top: 10px;
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column-reverse;

    @include breakpoint(medium) {
      flex-direction: row;
    }

    .custom-tooltip {
      margin: 0 0 $s1;

      @include breakpoint(medium) {
        margin: 0 $s1 0 $s2;
      }

      &__icon {
        width: 28px;
        height: 28px;
      }
    }
  }

  &-button.cor-button {
    width: 100%;
  }

  &-key-account {
    display: flex;
    justify-content: center;
    align-items: center;

    &-img {
      height: 20px;
      width: 20px;
    }

    &-message {
      @include df-p2-bold;
      margin: 34px 0 0 7px;

      @include breakpoint(medium) {
        margin-top: 0;
      }
    }
  }
}
