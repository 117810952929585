@import 'styles/_foundation';
@import 'styles/_variables';

.home {
  $home: &;

  .order-updates {
    &-wrapper {
      width: 100%;
      padding-top: $s2;
      background: url('../../assets/images/patterns/pattern-circles.svg'), $white;

      @include breakpoint(medium) {
        padding-top: 0;
        margin-bottom: -#{$s1};
      }

      .grid-container {
        @include breakpoint(medium) {
          max-width: 1360px;
        }
      }
    }
  }

  .a-spot-banner {
    margin-top: $s3-5;

    .cor-hero {
      .cor-hero-container--inset {
        @include breakpoint($cor-desktop) {
          margin: 0 auto;
        }
      }
    }
  }

  .products-carousel.recently-viewed-items-carousel {
    .swiper-pagination.swiper-pagination-horizontal {
      @include breakpoint(small down) {
        padding-bottom: 10px;
      }
    }
  }
}
