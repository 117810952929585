@import 'styles/_variables';
@import 'styles/_foundation';
@import 'styles/mixins';

.cor-featured-content__small-card-title-category {
  & {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    width: 175px;
    min-width: 50px;

    @include breakpoint($cor-desktop) {
      row-gap: 20px;
      width: initial;
    }
  }
  &__image__container {
    height: 175px;
    border-radius: 6px;
    overflow: hidden;

    @include breakpoint($cor-desktop) {
      height: 300px;
    }
  }
  &__image {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
  &__description {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    text-decoration: none;
  }
  &__title--h3.cor-typography {
    @include breakpoint($cor-desktop) {
      line-height: 28px;
      font-size: 22px;
    }
  }
  &__link-label {
    @include srOnly;
  }
}
