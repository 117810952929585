@import 'styles/_variables';
@import 'styles/_foundation';
@import '/src/styles/mixins/typography';

.main-nav-item {
  text-align: center;
  border-bottom: 1px solid $gray;

  @include breakpoint(medium) {
    border-bottom: 0;
  }

  &.is-open {
    background-color: $cultured;

    @include breakpoint(medium) {
      background-color: transparent;
    }
  }

  &__menu-link {
    column-gap: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom-width: 3px;
    border-bottom-color: transparent;
    border-bottom-style: solid;

    &:not(.has-submenu):hover {
      @include breakpoint(medium) {
        border-bottom-color: $cor-dawn-nav-orange;
      }
    }

    &.has-submenu {
      justify-content: space-between;
    }

    &.is-open {
      @include breakpoint(medium) {
        border-bottom-color: $cor-dawn-nav-orange;
      }
    }

    & > a {
      text-decoration: none;
      margin-right: 10px;
      padding: 25px;
      width: 100%;
      text-align: left;
      display: flex;
      align-items: center;

      svg {
        margin-left: 10px;
        display: initial;

        @include breakpoint(medium) {
          display: none;
        }
      }
    }

    > button {
      color: $aubergine-gray;
      appearance: none;
      outline: none;
      border: 0;
      background: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 5px;
      width: 100%;
      padding: 25px;

      @include breakpoint(medium) {
        cursor: default;
      }
    }
  }
}

.mobile-corporate-menu {
  background-color: $white !important;

  & ~ .main-subnav-container {
    border-top: 1px solid $gray;
  }
}
// Styling for CSSTransitions
.main-subnav {
  width: 100%;
  &-enter {
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 200ms, transform 300ms;
  }

  &-enter-done {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 200ms, transform 300ms;
  }

  &-exit {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 200ms, transform 300ms;
  }

  &-exit-done {
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 200ms, transform 300ms;
  }

  &__loading {
    min-height: 150px;
    width: 100%;
    position: relative;
  }
}

.main-subnav-container {
  min-height: auto;
  min-width: auto;
  overflow: hidden;
  display: none;

  &.is-open {
    display: block;
    overflow: visible;

    @include breakpoint(medium) {
      display: inline-block;
    }
  }

  @include breakpoint(medium) {
    position: absolute;
    transform: translateX(-50%);
    max-height: 0;
    min-width: auto;
    min-height: auto;
    top: 100%;
    padding-top: 30px;
  }

  &.two-column {
    min-width: 400px;
  }
}

.main-nav-item--first .main-subnav-container {
  @include breakpoint(medium) {
    transform: translateX(-10%);
  }
  @include breakpoint(1200px) {
    transform: translateX(-30%);
  }

  @include breakpoint(1500px) {
    transform: translateX(-50%);
  }
}

body:not(.international-font) .main-nav-item {
  &__menu-link,
  &__menu-link > button,
  &__menu-link > a > button {
    @include font-museo-regular($size: 16, $lineheight: 1.25);
  }
}

body.international-font .main-nav-item {
  &__menu-link,
  &__menu-link > button,
  &__menu-link > a > button {
    @include font-neue-frutiger-regular($size: 16, $lineheight: 1.25);
  }
}
