@import 'styles/_variables';
@import 'styles/_foundation';
@import 'styles/mixins/_typography';
@import 'styles/_cor-rich-text';

.cor-leadership-tile {
  $padding: 25px;
  $borderRadius: 6px;
  flex: 0 1 50%;
  position: relative;
  z-index: $base-z-index;
  @include breakpoint($cor-desktop) {
    flex-basis: 20%;
  }

  &__container {
    background-color: inherit;
    position: relative;
  }

  &__thumbnail {
    padding: $padding;
    position: relative;
    z-index: 0;
  }

  &__job {
    line-height: 1.5;
  }

  &__active-description {
    background: $cor-white;
    position: absolute;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 200%;
    top: 0;
    left: 0;
    z-index: 1;
    box-shadow: 0 0 20px rgba(219, 219, 219, 0.5);
    border-radius: $borderRadius;
    flex-wrap: wrap;
    overflow: hidden;

    @include breakpoint($cor-desktop) {
      flex-wrap: nowrap;
      background-color: inherit;
      width: calc(100% * 3);
      height: 100%;
    }

    .cor-leadership-tile__info {
      width: 50%;
      @include breakpoint($cor-desktop) {
        width: auto;
      }
    }

    .cor-leadership-tile__thumbnail {
      display: flex;
      gap: 1rem;
      align-items: flex-end;
      width: 100%;
      padding-bottom: 0;
      @include breakpoint($cor-desktop) {
        display: block;
        flex: 0 0 auto;
        width: calc(100% / 3);
        padding-bottom: 25px;
      }
    }

    .cor-leadership-tile__image {
      width: calc(50% - 25px);
      margin-bottom: 0;
      @include breakpoint($cor-desktop) {
        width: auto;
        margin-bottom: 1rem;
      }
    }
  }

  &__description {
    background-color: $white;
    padding: 18px $padding $padding $padding;
    height: 100%;
    @include breakpoint($cor-desktop) {
      padding: $padding;
    }
  }

  &__description-container {
    color: $mid-gray;
    overflow-y: auto;
    margin-bottom: 1rem;
    max-height: 360px;
    padding-right: 1rem;
    @include breakpoint($cor-desktop) {
      height: calc(100% - 50px);
      max-height: none;
    }
  }

  &__image {
    width: 100%;
    height: auto;
    aspect-ratio: 1/1;
    border-radius: $borderRadius;
    overflow: hidden;
    margin-bottom: 1rem;
    & img {
      object-fit: cover;
      object-position: center;
      height: 100%;
      width: 100%;
    }
  }

  &__close-button {
    border: 0;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
    color: $mid-gray;
    text-decoration: underline;
  }

  //  state
  &.is-active {
    background: $cor-white;
    z-index: $base-z-index + 10;
  }

  &--left {
    .cor-leadership-tile__active-description {
      left: auto;
      right: 0;
      @include breakpoint($cor-desktop) {
        flex-direction: row-reverse;
      }

      .cor-leadership-tile__description {
        @include breakpoint($cor-desktop) {
          padding-right: 0;
        }
      }

      .cor-leadership-tile__info {
        text-align: right;
        @include breakpoint($cor-desktop) {
          text-align: left;
        }
      }

      .cor-leadership-tile__thumbnail {
        flex-direction: row-reverse;
        @include breakpoint($cor-desktop) {
          flex-direction: initial;
        }
      }
    }
  }
  &--right {
    @include breakpoint($cor-desktop) {
      .cor-leadership-tile__description {
        padding-left: 1rem;
      }
    }
  }
}

body:not(.international-font) .cor-leadership-tile {
  &__description-container {
    @include cor-rich-text(
      $map: (
        'p': (
          'size': [14px,
          14px],
          'lineheight': [24px,
          24px],
          'spacing': [.01rem,
          .01rem],
        ),
      )
    );
  }
}

body.international-font .cor-leadership-tile {
  &__description-container {
    @include cor-rich-text(
      $map: (
        'p': (
          'face': neueFrutiger,
          'size': [14px,
          14px],
          'lineheight': [24px,
          24px],
          'spacing': [.01rem,
          .01rem],
        ),
      )
    );
  }
}
